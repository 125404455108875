import { fetchSelectedSimple, useReferenceFieldClick } from "../effects/useFieldClick";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useSSelector } from "../../util";
import { useRouteMatch } from "react-router";
import { pushGPSReport } from "../../actions";

interface Options {
  allowMultiSelect?: boolean;
}

interface SelectionState {
  id: number;
  clickTime: number;
}

export const useSelectionNavigator = (options: Options = {}) => {
  const { allowMultiSelect = false } = options;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<SelectionState | null>(null);
  const coords = useRouteMatch("/report/:coords?")?.params?.coords;

  const handleSelect = (gps: string, field?: { [key: string]: any }, e?: MouseEvent) => {
    if ((selected?.id && field?.id && selected.id === field.id) || !gps) {
      if (selected && Date.now() - selected.clickTime < 200) return; // Safari hell
      dispatch(push(`/`));
      setSelected(null);
      return;
    }

    if (allowMultiSelect && e?.ctrlKey) {
      let url = `/select/${gps}`;
      if (coords) {
        url = `/select/${coords};${gps}`;
      }

      dispatch(push(url));
      return;
    }

    dispatch(pushGPSReport(gps));

    if (field) {
      setSelected({ id: field.id, clickTime: Date.now() });
    }
  };

  useInitialField(handleSelect);
  useReferenceFieldClick(handleSelect);
  return handleSelect;
};

const useInitialField = (callback: (gps: string, field: any) => void) => {
  const path = useSSelector((state) => state.router.location.pathname);

  useEffect(() => {
    const handle = async () => {
      const gps = /report\/([\d.,]+)/.exec(path)?.[1];
      if (gps) {
        const field = await fetchSelectedSimple(gps);

        callback(gps, field);
      }
    };

    handle();
    // This only needs to happen once when the map mounts, all changes to the path
    // afterwards will be done by useSelectionNavigator.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
