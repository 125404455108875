import React, { useEffect } from "react";
import { Icon } from "react-fa";
import i18n from "i18next";

type ChartElement = (any) => React.ReactElement;

export interface ChartProps {
  data: {
    state: "fetching" | "fetched" | "failed" | string;
    data: any[];
  } & any;
  height?: number;
  inView: boolean;
  component: ChartElement;
  refresh: () => void;
  cropfield: any;
}

export const Chart = ({
  data: { state, data, ...dataProps },
  inView,
  refresh,
  component,
  ...rest
}: ChartProps & any) => {
  useEffect(() => {
    if (!state && inView) refresh(); // TODO or smallscreen?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!(state || inView)]);

  switch (state) {
    case "fetching":
      return (
        <p>
          {i18n.t("Fetch data")} <Icon spin name="spinner" />
        </p>
      );
    case "fetched":
      if (Object.keys(data).length === 0) {
        return <p />;
      }
      break;
    case "failed":
      return <p>{i18n.t("No data")}</p>;
    default:
      return <p>{i18n.t("Initialising")}</p>;
  }
  return React.createElement(component, { data, dataProps, ...rest });
};
