import i18n from "i18next";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Icon } from "react-fa";
import "./ShareModal.scss";
import { A } from "../util";

type Props = {
  text?: any;
  onClose: (e?: any) => void;
  url?: string;
};

export const ShareModal = (props: Props) => {
  const { text, onClose, url } = props;
  const encodedUrl = encodeURIComponent(url ? window.location.host + url : String(window.location));
  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_share_modal"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          {i18n.t("Share {{subject}}", { subject: text.thisSubject })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="share-btns">
          <li>
            <A
              href={`https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(
                window.location.origin
              )}&ref_src=twsrc%5etfw&text=${encodeURIComponent(
                String(i18n.t("View {{subject}} on CropFields", { subject: text.thisSubject }))
              )}&tw_p=tweetbutton&url=${encodedUrl}&via=${i18n.t("CropFields")}`}
            >
              <Icon name="twitter" />
            </A>
          </li>
          <li>
            <A href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}>
              <Icon name="facebook" />
            </A>
          </li>
          <li>
            <A
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl.replace(
                "development.",
                ""
              )}`}
            >
              <Icon name="linkedin" />
            </A>
          </li>
          <li>
            <a
              href={`mailto:?subject=${text.subject}%20op%20${i18n.t(
                "CropFields"
              )}&body=${encodeURIComponent(
                String(i18n.t("View {{subject}} on CropFields", { subject: text.thisSubject })) +
                  ": "
              )}${encodedUrl}`}
            >
              <Icon name="envelope-o" />
            </a>
          </li>
          <li>
            <A
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                i18n.t("I want to share {{subject}}", { subject: text.thisSubject }) + ": "
              )}${encodedUrl}`}
              data-action="share/whatsapp/share"
            >
              <Icon name="whatsapp" />
            </A>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>{i18n.t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
};
