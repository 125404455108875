import { SearchkitComponent, StatefulAccessor, ValueState } from "searchkit";
import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { track } from "../../track";

class AggregateAccessor extends StatefulAccessor {
  constructor(key) {
    super(key);
    this.state = new ValueState(key);
  }
  beforeBuildQuery() {
    // move this accessor to last accessor to redefine existing queries
    const accessors = this.searchkit.accessors.accessors,
      self = accessors.indexOf(this),
      last = accessors.length - 1;
    if (self >= 0 && self !== last) {
      accessors[self] = accessors[last];
      accessors[last] = this;
    }
  }

  addAggToAggs(aggs, agg) {
    if (!aggs) return false;
    let modified = false;
    for (const key of Object.keys(aggs)) {
      const a = aggs[key],
        ag = a && a.aggs;
      if (ag) {
        modified |= this.addAggToAggs(ag, agg);
      } else if (a.terms) {
        a.aggs = agg;
        modified = true;
      }
    }
    return modified;
  }

  buildOwnQuery(query) {
    const state = this.state.getValue();
    if (state === "ha" && query.query.aggs) {
      let aggs = cloneDeep(query.query.aggs);
      const modified = this.addAggToAggs(aggs, {
        area: {
          sum: {
            field: "area",
          },
        },
      });
      if (modified) return query.setAggs(aggs);
    }
    return query;
  }
}

export class AggregateSelector extends SearchkitComponent {
  defineAccessor() {
    return new AggregateAccessor("agg");
  }

  toggleItem(key) {
    this.accessor.state = this.accessor.state.setValue(key);
    this.searchkit.performSearch();
  }

  render() {
    const selected = (this.accessor && this.accessor.state.getValue()) || "#";
    return (
      <button
        onClick={() => {
          const value = selected === "#" ? "ha" : "";
          this.toggleItem(value);
          track("facet", "toggle_agg", value);
        }}
        className="btn btn-default sk-mtoggle pull-right"
      >
        {selected}
      </button>
    );
  }
}
