import React from "react";
import "./EWSDateSelector.scss";
import { useSSelector } from "../util";
import { Icon } from "react-fa";
import Button from "@material-ui/core/Button";
import { InputLabel } from "@material-ui/core";
import { getLayerDay } from "../map/map_util";
import { useDispatch } from "react-redux";
import { updateLayerConfig } from "../actions";

export const EWSDateSelector = () => {
  const dispatch = useDispatch(),
    { layer, layerConfig } = useSSelector(({ layers, activeUser }) => ({
      layer: layers.selected?.[0] || null,
      layerConfig: layers.config,
    })),
    ewsDay = getLayerDay(layerConfig, "ewsP", { delta: 0, format: "D/M/YYYY" }),
    delta = layerConfig?.["ewsP"]?.delta || 0;

  return (
    layer === "ewsP" && (
      <div className="ews-date-selector-wrapper">
        <Button
          className="date-selector-btn"
          disabled={delta === -1}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            dispatch(updateLayerConfig({ ewsP: { delta: delta - 1 } }));
          }}
        >
          <Icon name="chevron-left" />
        </Button>

        <InputLabel className="date-selector-date-field" disabled={true}>
          {ewsDay}
        </InputLabel>

        <Button
          className="date-selector-btn"
          disabled={delta === 3}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            dispatch(updateLayerConfig({ ewsP: { delta: delta + 1 } }));
          }}
        >
          <Icon name="chevron-right" />
        </Button>
      </div>
    )
  );
};
