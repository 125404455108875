import React from "react";
import { Link } from "react-router-dom";
import { accountFeatures, useSSelector } from "../../util";
import { Pro } from "../../components/Pro";
import { config } from "../../Config";

export const PremiumLeaseWrapper = ({ children }) => {
  const premium = useSSelector((s) => !!accountFeatures(s.activeUser.account)?.premium);
  if (!premium) {
    return (
      <div>
        <h5 className="mb-2 mx-2">Pacht</h5>
        <Pro msg="Gebruik Pacht" required="premium" /> Voor het uploaden en delen van pachtpercelen
        heb je Boer&Bunder Premium nodig. Lees er meer over{" "}
        <Link to="/pacht">de pachtfunctionaliteit</Link> of{" "}
        <a
          href={`${config.DACOM_URL}/subscription/order/subscription?product_variant=boer_en_bunder_premium`}
        >
          bestel Boer&Bunder premium
        </a>
        .
      </div>
    );
  }
  return children;
};

export const MyLease = ({ farm }) => (
  <PremiumLeaseWrapper>
    <div>
      <h1>Pacht</h1>
      <h4>Maak je pachtbeleid in 3 stappen openbaar</h4>
      <ol>
        <li>
          Voeg je pachtpercelen toe via deze knop.{" "}
          <Link className="btn btn-success d-block my-3" to="/lists">
            Mijn pachtpercelen
          </Link>
        </li>
        <li>
          Formuleer je duurzame pachtbeleid via deze knop.{" "}
          <Link className="btn btn-success d-block my-3" to="/page/leaseconditions">
            Mijn duurzame pachtbeleid
          </Link>
        </li>
        <li>
          Maak je pachtbeleid publiek. Je wordt nu automatisch toegevoegd aan de lijst met duurzame
          verpachters op <Link to="/pacht">deze</Link> pagina{" "}
          <Link className="btn btn-success d-block my-3" to="/my_farm/settings">
            Maak publiek
          </Link>
        </li>
      </ol>
    </div>
  </PremiumLeaseWrapper>
);
