import React, { memo, useEffect, useState } from "react";
import { config } from "../Config";
import i18n from "i18next";
import moment from "moment";
import { bbAxios, useSSelector } from "../util";
import cx from "classnames";
import "./Legend.scss";

interface LabelDataType {
  label: string;
  color: string;
}

type LabelData = (LabelDataType & any) | number;

export const EWS_MAPPING: LabelData[] = [
  { label: "100%", color: "#a40000", pressure: 100, align: "right" },
  { label: "65%", color: "#DC3545", pressure: 65, align: "right" },
  { label: "50%", color: "#E86430", pressure: 50, align: "right" },
  { label: "40%", color: "#F3921C", pressure: 40, align: "right" },
  { label: "35%", color: "#FFC107", pressure: 35, align: "right" },
  { label: "15%", color: "#94B426", pressure: 15, align: "right" },
  { label: "0%", color: "#28A745", pressure: 0, align: "right" },
  { label: "51% - 100%", color: "#DC3545", pressure: 100, align: "right" },
  { label: "36% - 50%", color: "#F3921C", pressure: 50, align: "right" },
  { label: "0% - 35%", color: "#28A745", pressure: 35, align: "right" },
];
export const PRECIPITATION_DEFICIT: LabelData[] = [
  { label: "+100%", color: "#DC3545" }, // moet in mm (per dag verschillend)
  { label: "+80%", color: "#E86430" }, // rood is VEEL deficit == DROOG
  { label: "+60%", color: "#F3921C" },
  { label: "+40%", color: "#FFC107" },
  { label: "+20%", color: "#94B426" },
  { label: "0", color: "#28A745" },
  { label: "-20%", color: "#39c78b" },
  { label: "-40%", color: "#39B6B8" },
  { label: "-60%", color: "#0073B9" },
  { label: "-80%", color: "#004289" },
  { label: "-100%", color: "#101F69" }, // blauw is negatieve deficit == NAT
];

function countryMapping(country: string): Record<string, LabelData[]> {
  const m = i18n.t("meter");
  switch (country) {
    case "NL":
      return {
        lease: [
          { color: "#13c937", label: "Eigendom" },
          { color: "#e5ff1f", label: "Langdurige pacht" },
          { color: "#fd7012", label: "Kortdurige pacht" },
          { color: "#f035fd", label: "Erfpacht" },
          { color: "#70a7ff", label: "Overige pacht" },
        ],
        nature: [], // nature doen we als we aNLB doen
        bparcel: [{ label: "Agrarisch Areaal NL", color: "#00FF6D", align: "right" }], // nature doen we als we aNLB doen
        cadastre: [
          { label: i18n.t("Cadastral field"), color: "#000000" },
          { label: i18n.t("Building"), color: "#ec3324" },
          { label: i18n.t("Preliminary"), color: "#cc9900" },
        ],
        bufferZone: [{ label: i18n.t("Buffer zone"), color: "#000000" }],
        dem: [
          { label: `250 ${m}`, color: "#c78171", align: "right" },
          { label: `50 ${m}`, color: "#ebbc61", align: "right" },
          { label: `15 ${m}`, color: "#f7fb66", align: "right" },
          { label: `10 ${m}`, color: "#d1f562", align: "right" },
          { label: `5 ${m}`, color: "#87e45d", align: "right" },
          { label: `0 ${m}`, color: "#6fb4a5", align: "right" },
          { label: `-5 ${m}`, color: "#52749c", align: "right" },
        ],
        ewsP: [7, 8, 9].map((index) => EWS_MAPPING[index]),
        // precip_deficit: [0, 3, 5, 7, 10].map((index) => PRECIPITATION_DEFICIT[index]),
        no_fly_zone: [
          { label: i18n.t("Forbidden"), color: "#ff0000" },
          { label: i18n.t("Limited"), color: "#ff943d" },
        ],
        gwp: [1253, 1254],
        // permanent_grass: [1427],
        n2k: [1451, 1452, 1453, { label: "Bufferzones", color: "#cc0000" }],
        nva: [{ label: "NV Gebied", color: "#cc0000" }],
        trench: [
          { label: "Waterloop", color: "#0055ff" },
          { label: "Greppel, droge sloot", color: "#ffaa00" },
          { label: "Watervlakte", color: "#00eaff" },
          { label: "Oever, slootkant", color: "#00ff2b" },
        ],
        rsp: [1843, 1844, 1948, 1949, 1950],
        rsi: [1473],
      };
    case "BE":
      return {
        cadastre: [{ label: i18n.t("Cadastral field"), color: "#000000" }],
        // see https://geoservices.informatievlaanderen.be/raadpleegdiensten/DHMV/wms?request=GetLegendGraphic&version=1.3.0&format=image%2Fpng&layer=DHMVI_DTM_5m&LANGUAGE=dut
        dem: [
          { label: `300 ${m}`, color: "#c78171" },
          { label: `250 ${m}`, color: "#ebbc61" },
          { label: `200 ${m}`, color: "#f7fb66" },
          { label: `150 ${m}`, color: "#d1f562" },
          { label: `100 ${m}`, color: "#87e45d" },
          { label: `50 ${m}`, color: "#6fb4a5" },
          { label: `0 ${m}`, color: "#52749c" },
        ],
        gwp: [1472, 1471, 1470, 1469],
        n2k: [1451, 1452, 1453],
        erosion: [1508, 1509, 1510],
        nitrate_area: [2089, 2090, 2088, 2091],
        nitrate_area_2021: [2089, 2090, 2088, 2091],
        vha: [
          { label: "Bevaarbaar", color: "#0000ff" },
          { label: "Geklaseerd, 1e cat", color: "#00ff00" },
          { label: "Geklaseerd, 2e cat", color: "#00ffff" },
          { label: "Geklaseerd, 3e cat", color: "#ff0000" },
          { label: "Niet geklasseerd", color: "#ffbf00" },
          { label: "Gracht alg. belang", color: "#ff00ff" },
        ],
      };
    case "DK":
      return {
        cadastre: [{ label: i18n.t("Cadastral field"), color: "#000000" }],
        dem: [],
        gwp: [1920, 2057],
        permanent_grass: [1427],
        n2k: [1451, 1452, 1453],
        organic: [1947],
      };
    case "WA":
      return {
        cadastre: [{ label: i18n.t("Cadastral field"), color: "#000000" }],
        dem: [
          { label: `700 ${m}`, color: "#c78171" },
          { label: `550 ${m}`, color: "#ebbc61" },
          { label: `400 ${m}`, color: "#f7fb66" },
          { label: `300 ${m}`, color: "#d1f562" },
          { label: `200 ${m}`, color: "#87e45d" },
          { label: `100 ${m}`, color: "#6fb4a5" },
          { label: `0 ${m}`, color: "#52749c" },
        ],
        gwp: [1944, 1945, 1946],
        n2k: [1451, 1452, 1453],
      };
    case "FR":
      return {
        cadastre: [{ label: i18n.t("Cadastral field"), color: "#000000" }],
        dem: [
          { label: `3000 ${m}`, color: "#68260c" },
          { label: `1500 ${m}`, color: "#dc6801" },
          { label: `1000 ${m}`, color: "#dda75a" },
          { label: `500 ${m}`, color: "#e3e6b6" },
          { label: `300 ${m}`, color: "#9daf95" },
          { label: `200 ${m}`, color: "#1bcc02" },
          { label: `0 ${m}`, color: "#98fc04" },
        ], // TODO labels DEM kloppen niet, callibreren met data
        erosion: [{ label: "Pentes pour l'agriculture (BCAE)", color: "#df2317" }],
        no_fly_zone: [
          { label: "Vol interdit", color: "#ff4e6f" },
          { label: "Hauteur maximale 30m", color: "#ffa195" },
          { label: "Hauteur maximale 50m", color: "#ffb054" },
          { label: "Hauteur maximale 60m", color: "#ffd760" },
          { label: "Hauteur maximale 100m", color: "#fbee6e" },
        ],
        n2k: [1451, 1452, 1453],
        organic: [1947],
      };
    case "DE_NW":
      return {
        cadastre: [{ label: "Alkis Flurstücke", color: "#000000" }],
        nitrate: [{ label: "Nitratbelastete Gebiete", color: "#FF0000" }],
        dem: [
          // https://www.wms.nrw.de/geobasis/wms_nw_hoehenschichten?LAYER=nw_hoehenschichten_rgb&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&WIDTH=256&HEIGHT=256
          { label: `700 ${m}`, color: "#9a4836" },
          { label: `500 ${m}`, color: "#ca8f7b" },
          { label: `300 ${m}`, color: "#ecd01a" },
          { label: `100 ${m}`, color: "#d9ee8c" },
          { label: `25 ${m}`, color: "#82c08d" },
          { label: `0 ${m}`, color: "#009973" },
        ],
        gwp: [
          { label: "Zone I", color: "#ff0000" },
          { label: "Zone II", color: "#55ff00" },
          { label: "Zone III A", color: "#ffff00" },
          { label: "Zone III B", color: "#cdaa67" },
          { label: "Zone III C", color: "#732600" },
          { label: "Sonderzone Rhein", color: "#00a9e6" },
        ],
        n2k: [1451, 1452, 1453],
      };
    case "DE_NI":
      return { n2k: [1451, 1452, 1453], gwp: [2540, 2541, 2542] };
    case "DE_SH":
      return { n2k: [1451, 1452, 1453], gwp: [2540, 2541, 2542], parcel: [2859, 2860, 2861] };
    case "AT":
      return { n2k: [1451, 1452, 1453] };
    case "LU":
      return {
        n2k: [1451, 1452, 1453],
        cadastre: [{ label: i18n.t("Cadastral field"), color: "#000000" }],
        permanent_grass: [
          { label: i18n.t("Permanent grassland"), color: "#249819" },
          { label: i18n.t("Arable land"), color: "#985d0c" },
        ],
        dem: [
          // https://wiki.geoportail.lu/lib/exe/fetch.php?w=100&tok=ab5a78&media=en:legend:lidar:legende_mnt_lidar_2019.jpg
          { label: `550 ${m}`, color: "#ff2e06" },
          { label: `450 ${m}`, color: "#ff7d16" },
          { label: `350 ${m}`, color: "#fcea2d" },
          { label: `200 ${m}`, color: "#00fa56" },
          { label: `150 ${m}`, color: "#00affd" },
          { label: `130 ${m}`, color: "#171ed0" },
        ],
      };
    case "__":
      return {
        dem: [
          { label: `5000 ${m}`, color: "#ffffff" },
          { label: `3000 ${m}`, color: "#6e6e6e" },
          { label: `1500 ${m}`, color: "#9e0000" },
          { label: `800 ${m}`, color: "#a14300" },
          { label: `300 ${m}`, color: "#e8d67d" },
          { label: `0 ${m}`, color: "#107a2f" },
          { label: `-200 ${m}`, color: "#006147" },
        ],
        n2k: [1451, 1452, 1453],
        parcel: [],
        soil: [
          { label: i18n.t("Clay"), color: "#ffebaf" },
          { label: i18n.t("Silty Clay-Loam"), color: "#448970" },
          { label: i18n.t("Sandy Clay"), color: "#ff0000" },
          { label: i18n.t("Sandy Clay-Loam"), color: "#f57ab6" },
          { label: i18n.t("Clay-Loam"), color: "#a3ff73" },
          { label: i18n.t("Silt"), color: "#38a800" },
          { label: i18n.t("Loam"), color: "#e69800" },
          { label: i18n.t("Sand"), color: "#ffb58a" },
          { label: i18n.t("Loamy Sand"), color: "#9eaad7" },
          { label: i18n.t("Sandy Loam"), color: "#ffbee8" },
        ],
      };
    default:
      return {};
  }
}
const cache = {};

interface LegendState {
  legend: LabelData[];
  layer?: string;
  bounds?: L.LatLngBounds;
  tokens?: {};
  layerConfig?: {};
  loading?: boolean;
}

const Legend = ({ layerNames }) => {
  const [collapse, setCollapse] = useState(false);
  const [state, setState] = useState<LegendState>({ legend: [] });
  const props: any = useSSelector(({ layers, inView, activeUser }) => ({
    layer: layers.selected?.[0] || null,
    bounds: inView.bounds,
    tokens: activeUser.tokens,
    layerConfig: layers.config,
  }));
  useEffect(() => {
    const { layer, bounds, layerConfig } = props;
    if (!layer || collapse || !bounds) return;
    // ignore data fetching with collapse on/off
    if (state.layer === layer && state.bounds?.equals(bounds) && state.layerConfig === layerConfig)
      return;

    const newLegend =
      countryMapping(config.bb_country)[layer] ||
      // Sometimes we use the normal reference for testing purposes, querying for legend give an error
      (config.GEO_REFERENCE_URL.indexOf("?src=") >= 0 && []);
    if (newLegend) {
      if (newLegend !== legend) {
        setState({ legend: newLegend, layer });
      }
      return;
    }
    setState({ legend: legend, loading: true, layer, bounds, layerConfig }); // avoid concurrent XHR requests
    if (layer === "precip_deficit") {
      const def = layerConfig.precip_deficit;
      const selectedDay = def?.day && moment.unix(def.day);
      const day = (selectedDay || moment().subtract(1, "days")).format("YYYY-MM-DD");
      const key = `${layer}:${day}`;
      if (cache[key]) {
        setState({ legend: cache[key], layer, bounds, layerConfig });
        return;
      }
      cache[key] = [{ label: i18n.t("Loading") }];
      bbAxios
        .get(`https://d2c.crop-r.com/api/v3/precip_deficit/tms_legend/?day=${day}`)
        .then(({ data }) => {
          if (data.error_code) {
            data = [{ label: data.error_code }];
          } else {
            data = [10, 7, 5, 3, 1].map((i) => ({
              label: `${((data[i].deficit[0] + data[i].deficit[1]) / 2).toFixed()} mm`,
              color: `rgb(${data[i].color.join(",")}`,
              align: "right",
            }));
          }
          cache[key] = data;
          setState({ legend: data, layer, bounds, layerConfig });
        });
      return;
    }
    bbAxios
      .get(`${config.GEO_REFERENCE_URL}${bounds.toBBoxString()}&legend=${layer}`)
      .then(({ data }) => setState({ legend: data, layer, bounds, layerConfig }))
      .catch(() => setState({ legend: [], layer, bounds, layerConfig }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.layer, props.bounds, props.layerConfig, collapse]); // TODO make simpler?

  if (!props.layer) return null;

  const { legend, layer, loading } = state;
  const name = layerNames?.[layer] || i18n.t("legend");
  return (
    <div className="mod_bb_legend leaflet-control">
      <button
        onClick={(e) => {
          e.preventDefault();
          setCollapse(!collapse);
        }}
        data-toggle="collapse"
        className={collapse ? "collapsed" : ""}
      >
        {collapse ? i18n.t("legend") : name}
      </button>
      <div className={cx("legend", { collapse })}>
        <div className="inner_scroll">
          <ul>
            {legend?.map?.((code) => {
              let { label = "", color = "", align } = code;
              if (!color) {
                const c = config.codes[code];
                if (c) {
                  label = i18n.t(c.name);
                  if (c.group) color = c.group.color;
                }
              }
              return (
                <li key={label || code} style={align && { textAlign: align }}>
                  <i style={{ backgroundColor: color }} /> {label || "?"}
                </li>
              );
            })}
            {loading && (
              <li>
                <i className="fa fa-spinner fa-spin" /> {i18n.t("Loading")}...
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
const m = memo(Legend);
export { m as Legend };
