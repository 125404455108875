import React, { useEffect } from "react";
import L from "leaflet";

import { getStyle, STYLETYPES } from "../map_util";
import { GeoJSON, useMap } from "react-leaflet";
import { Layer } from "./Layer";
import wellknown from "wellknown";
import { useSSelector } from "../../util";

type Props = {
  zIndex?: number;
};

export const HighlightLayer = (props: Props) => {
  const { zIndex } = props;
  const field = useSSelector(highlightedSelector);

  if (!field) {
    return null;
  }

  return (
    <Layer name={"highlight-fields"} zIndex={zIndex}>
      <HighlightPolygon key={field.key} field={field} />
    </Layer>
  );
};

type HighlightPolygonProps = {
  field: any;
};

const HighlightPolygon = (props: HighlightPolygonProps) => {
  const { field } = props;

  return (
    <GeoJSON
      style={getStyle(STYLETYPES.SELECTED)}
      key={field.external_id}
      data={wellknown.parse(field.geometry)}
      interactive={false}
    />
  );
};

export const usePanToFields = (data) => {
  const map = useMap();

  useEffect(() => {
    if (data?.length) {
      const bounds = (L as any).geoJson(data).getBounds();
      const options = window.innerWidth >= 768 ? { paddingTopLeft: [600, 0] as L.PointTuple } : {};
      map.fitBounds(bounds, options);
    }
  }, [map, data]);
};

const highlightedSelector = (state) => {
  return (
    state.favorites?.fields?.find((f) => f.id === state.highlight.id) ||
    state.favorites?.sharedList?.fields?.find((f) => f.id === state.highlight.id) ||
    null
  );
};
