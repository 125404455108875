import i18n from "i18next";
import React, { useCallback, useState } from "react";
import { Button, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { favorite } from "../../actions";

interface INewListButton {
  newListType?: string;
}

export const NewListButton = (props: INewListButton) => {
  const { newListType = "CUSTOM" } = props;
  const [edit, setEdit] = useState(false);
  const [listname, setListname] = useState("");
  const dispatch = useDispatch();

  const handleAdd = useCallback(
    (e) => {
      dispatch(favorite(listname, "", [], null, null, newListType));
      setListname("");
      setEdit(false);
      e.preventDefault();
    },
    [dispatch, listname, newListType]
  );

  if (edit) {
    return (
      <form onSubmit={(e) => handleAdd(e)}>
        <FormGroup className="d-flex px-3">
          <InputGroup className="flex-grow-1 pr-3">
            <FormControl
              value={listname}
              onChange={(e) => setListname(e.target.value)}
              aria-describedby="basic-addon2"
            />
            <Button variant="primary" type="submit" disabled={!listname} className="btn">
              <span className="fa fa-plus" />
            </Button>
          </InputGroup>
          <Button
            variant="link"
            onClick={() => {
              setListname("");
              setEdit(false);
            }}
          >
            {i18n.t("Cancel")}
          </Button>
        </FormGroup>
      </form>
    );
  }
  return (
    <Button variant="link" onClick={() => setEdit(true)}>
      <span className="fa fa-plus-circle pr-2" />
      {i18n.t("New list")}
    </Button>
  );
};
