import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { ConfigurableFeature } from "../../panels/configurable/ConfigurableFeature";
import { config } from "../../Config";
import { accountFeatures, useSSelector } from "../../util";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { updateSettings } from "../../actions";
import "./Lease.scss";
import iconNavigation from "../../../img/icons/lease/icon_field_navigation.svg";
import iconCultivable from "../../../img/icons/lease/icon_cultivable.svg";
import iconRotation from "../../../img/icons/lease/icon_crop_rotation.svg";
import { showSubscriptionModal } from "../Pro";
import imgLogoBbPacht from "../../../img/logo/logo_bb_pacht.svg";
import imgLogoBinB from "../../../img/logo/logo_binb.jpg";
import imgLogoLNV from "../../../img/logo/logo_lnv.png";
import { PremiumLeaseWrapper } from "../../panels/lease/MyLease";
import { LeaseFarmList } from "./LeaseFarmList";

const ExternalA = ({ children, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
);

const ActivateLayer = ({ layer, icon, link = undefined }) => {
  const [active, pro] = useSSelector((s) => [
    s.layers.selected.includes(layer),
    accountFeatures(s.activeUser?.account).pro,
  ]);
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    if (!pro) {
      return showSubscriptionModal(dispatch);
    }
    dispatch(updateSettings({ showLease: true }));
    if (!active) dispatch({ type: "LAYER_TOGGLE", layer });
    dispatch(push("/" + (link || "")));
  }, [dispatch, pro, active, layer, link]);
  return <img className="icon mr-3 cursor-pointer" onClick={onClick} src={icon} alt="" />;
};

export const Lease = () => {
  return (
    <div className="bb_lease row justify-content-center pt-2">
      <div className="col-xs-12 col-sm-10 col-xl-8">
        <p>Welkom in de Boer&Bunder Pacht omgeving.</p>
        <p>
          Deze omgeving is ingericht om duurzame pachtconstructies te stimuleren en door het bieden
          van meer transparantie een gelijkwaardige samenwerking tussen pachter en verpachter te
          creëren. Met duurzame pachtconstructies worden afspraken gemaakt over duurzaam
          grondgebruik door de pachter, en de beloning die de verpachter daar tegenover zet. Met
          Boer&BunderPacht zie je onder andere de gebruikstitel (eigendom/pachtvorm) van alle
          landbouwpercelen in Nederland. Verpachters die een duurzaam pachtbeleid hanteren, kunnen
          deze invoeren op hun percelen. Zo krijg je een goed overzicht van de percelen die duurzaam
          verpacht worden, en welke beloningen daar tegenover staan. Wil je weten welke verpachters
          hier al aan meewerken? Klik <a href="#lessors">hier</a> om de actuele lijst met duurzame
          verpachters te bekijken..
        </p>
        <p>
          Activeer <Link to="/settings">hier</Link> je pachtomgeving om hier direct mee aan de slag
          te gaan en alle nieuwe functionaliteiten te ontdekken.
        </p>
        <p>
          Boer&BunderPacht is ontwikkeld in samenwerking met{" "}
          <ExternalA href="https://biodiversityinbusiness.eu/">Biodiversity in Business</ExternalA>.
          Deze ontwikkeling is mede mogelijk gemaakt door het{" "}
          <ExternalA href="https://startupinresidence.com/">
            StartUp in Residence programma
          </ExternalA>{" "}
          van het ministerie van LNV.
        </p>
        <div>
          Activeer hier Boer&BunderPacht om inzicht te krijgen in de gebruikstitel
          (eigendom/pachtvorm) van percelen en het duurzame pachtbeleid van verpachters:
          <ConfigurableFeature
            setting="showLease"
            name={i18n.t("Lease")}
            show={config.show.includes("lease")}
          />
        </div>
        <h2 className="pt-5 pb-2">Snel naar alle pachtinformatie</h2>
        <ul className="list-unstyled">
          <li className="mb-2">
            <ActivateLayer layer="lease" icon={iconNavigation} />
            Krijg inzicht in de gebruikstitel (eigendom/pachtvorm) van percelen
          </li>
          <li className="mb-2">
            <ActivateLayer layer="lease" icon={iconCultivable} link="my_farm/lease" />
            Upload je pachtpercelen
          </li>
          <li className="mb-2">
            <ActivateLayer layer="lease" icon={iconRotation} link="page/leaseconditions" />
            Laat als verpachter je duurzame pachtbeleid zien
          </li>
        </ul>
        <PremiumLeaseWrapper> </PremiumLeaseWrapper>
        <h2 className="pt-5 pb-2" id="lessors">
          Duurzame verpachters
        </h2>
        <p>
          Hier vind je alle verpachters die hun duurzame pachtbeleid openbaar hebben gemaakt op
          Boer&BunderPacht. Deze verpachters hebben duurzame pachtvoorwaarden opgesteld én hier een
          beloning voor de pachter aan gekoppeld. Wil je ook op deze lijst staan? Vul dan{" "}
          <Link to="/page/leaseconditions">hier</Link> je voorwaarden en beloningen in en maak deze
          openbaar.
        </p>
        <LeaseFarmList />
        <h2 className="pt-5 pb-2">Zelf een duurzame pachtovereenkomst opstellen?</h2>
        <p>
          Zoek je naar inspiratie voor het opstellen van een duurzame pachtovereenkomst? Download
          dan hieronder 2 voorbeelden:{" "}
          <ul>
            <li>
              {" "}
              <Link
                to="/static/pdf/Pachtovereenkomst%20nieuw%20-%20Landgoed%20Vilsteren.pdf"
                target="_blank"
              >
                Landgoed Vilsteren
              </Link>{" "}
            </li>
            <li>
              {" "}
              <Link to="/static/pdf/Pachtovereenkomst%20-%20Brabant%20Water.pdf" target="_blank">
                Brabant water
              </Link>{" "}
            </li>
          </ul>
        </p>
        <p>
          Boer&BunderPacht is ontwikkeld in samenwerking met{" "}
          <ExternalA href="https://biodiversityinbusiness.eu/">Biodiversity in Business</ExternalA>.
          Deze ontwikkeling is mede mogelijk gemaakt door het{" "}
          <ExternalA href="https://startupinresidence.com/">
            StartUp in Residence programma
          </ExternalA>{" "}
          van het ministerie van LNV.
        </p>
        <p>
          <img src={imgLogoBinB} height="100" alt={"Biodiversity in Business"} />
          <img src={imgLogoLNV} className="pl-2" height="100" alt={"Ministerie van LNV"} />
        </p>
      </div>
    </div>
  );
};

Lease.Title = () => (
  <div className="bb_lease row justify-content-center pt-2 flex-fill">
    <div className="col-xs-12 col-sm-10 col-xl-8">
      <img src={imgLogoBbPacht} width={500} height={80} alt={"Boer&Bunder Pacht"} />
    </div>
  </div>
);
