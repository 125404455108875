import React, { useCallback, useEffect, useRef } from "react";
import L, { DomEvent, LeafletMouseEvent } from "leaflet";
import { GeoJSON, Marker } from "react-leaflet";
import { config } from "../Config";
import "./map_util.scss";
import moment from "moment";
import memoize from "lodash/memoize";
import { ewsSecondaryCropsMapping, getEWSValueColor } from "../panels";
import { getCenter, getCode } from "../util";
import { v4 as uuidv4 } from "uuid";

export function latLon(feature) {
  const g = feature.geometry;
  return L.GeoJSON.coordsToLatLngs(g.coordinates, { MultiPolygon: 2 }[g.type] || 1);
}

export function staticMapUrl(gps, size = 100) {
  return `${config.LAYER_URL}/georeference_h/image/?p=${gps}&size=${size}${config.countryFilter}`;
}
export function staticMapSrcSet(gps, size = 100) {
  return `${staticMapUrl(gps, size * 2)} 2x`;
}
export const StaticMap = ({ gps, size = 100, ...props }) =>
  gps ? (
    <img
      alt="Map"
      src={staticMapUrl(gps, size)}
      srcSet={staticMapSrcSet(gps, size)}
      width={size}
      height={size}
      {...props}
    />
  ) : null;

export const CropfieldMap = ({ id, size = 100, ...props }) =>
  id ? (
    <img
      alt="Map"
      src={`/image/cropfield/${id}?size=${size}`}
      srcSet={`/image/cropfield/${id}?size=${size * 2} 2x`}
      width={size}
      height={size}
      {...props}
    />
  ) : null;

export const TopPolygon = (props) => {
  const { feature, onClick, ...otherProps } = props;

  const ref = useRef<L.GeoJSON>();
  // distinct key forces re-render; https://www.erichowey.dev/writing/advanced-mapping-with-gatsby-and-react-leaflet/
  const id = uuidv4();

  useEffect(() => {
    if (ref?.current) {
      ref.current.bringToFront();
    }
  }, [id]);

  const handleClick = useCallback(
    (e: LeafletMouseEvent) => {
      if (onClick) {
        onClick(e);
        DomEvent.stopPropagation(e);
      }
    },
    [onClick]
  );

  if (!feature) {
    return null;
  }

  return (
    <GeoJSON
      ref={ref}
      data={feature}
      eventHandlers={{
        click: handleClick,
      }}
      {...otherProps}
      key={id}
    />
  );
};

export const Label = (props) => {
  const { position, html, onClick, ...otherProps } = props;
  const myIcon = L.divIcon({
    iconSize: new L.Point(50, 50),
    html: `<div><span>${html}</span></div>`,
    className: "translabel",
  });

  const handleClick = useCallback(
    (e: LeafletMouseEvent) => {
      DomEvent.stopPropagation(e);

      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <Marker
      position={position}
      icon={myIcon}
      eventHandlers={{
        click: handleClick,
      }}
      {...otherProps}
    />
  );
};

export function getLayerDay(layerConfig, layerName, defaults) {
  const t = layerConfig?.[layerName]?.day,
    delta = layerConfig?.[layerName]?.delta || defaults.delta,
    format = layerConfig?.[layerName]?.format || defaults.format;
  let day = t ? moment.unix(t) : moment();
  if (delta) day = day.add(delta, "days");
  return day.format(format || "YYYY/M/D");
}

export const getBounds = memoize((b, gpsBuffer = 0) =>
  L.latLngBounds([b[1] - gpsBuffer, b[0] - gpsBuffer], [b[3] + 2 * gpsBuffer, b[2] + gpsBuffer])
);

export const STYLETYPES = {
  DEFAULT: "default",
  SELECTED: "selected",
  FILTERED: "filtered",
  TRENCHSELECTED: "trenchSelected",
  EWSMULTI: "ewsMulti",
  SHOW_CROP_COLOR: "show_crop_color",
};

export const getStyle = (style, props?) => {
  const defaultStyle = { fill: true, fillOpacity: 0, stroke: true };
  switch (style) {
    case STYLETYPES.SELECTED:
      return {
        ...defaultStyle,
        color: "#E6E628",
        weight: 10,
        opacity: 1,
        fillOpacity: 0.2,
        fillColor: "#E6E628",
      };
    case STYLETYPES.FILTERED:
      return {
        ...defaultStyle,
        color: "#839CE2",
        fillColor: "#E6E628",
        weight: 2,
        opacity: 0.7,
        fillOpacity: 0.5,
      };
    case STYLETYPES.TRENCHSELECTED:
      return {
        ...defaultStyle,
        color: "#139CC2",
        fillColor: "#139CC2",
        selected: false,
        weight: 3,
        opacity: 0.3,
      };
    case STYLETYPES.EWSMULTI:
      const cropDacomCode = props.feature.properties?.crop_dacom_code || "";
      const mainCropDacomCode =
        Object.keys(ewsSecondaryCropsMapping).includes(cropDacomCode) &&
        ewsSecondaryCropsMapping[cropDacomCode];
      const diseaseData = props.ewsMulti.data["NL"][props.selectedCrop][props.selectedDisease];
      const centroid = getCenter(props.feature, "", 5);
      const delta = props.layerConfig?.["ewsP"]?.delta || 0;
      const selectedDate = moment().add(delta, "days").format("YYYY-MM-DD");

      const fieldData = diseaseData.filter((d) => {
        const diseaseCoordinates = d.location.replace("(", "").replace(")", "").split(", ");
        return (
          parseFloat(diseaseCoordinates[0]) === parseFloat(centroid[0]) &&
          parseFloat(diseaseCoordinates[1]) === parseFloat(centroid[1])
        );
      });

      const ewsValue = fieldData[0]?.infection_chances?.[selectedDate];
      if (props.selectedCrop !== mainCropDacomCode) {
        return {
          ...defaultStyle,
          color: "#139CC2",
          fillColor: "#139CC2",
          selected: false,
          weight: 3,
          opacity: 0.5,
        };
      }
      return {
        ...defaultStyle,
        color: "#ffffff",
        fillColor: getEWSValueColor(ewsValue),
        selected: false,
        weight: 3,
        opacity: 0.5,
        fillOpacity: 1,
      };
    case STYLETYPES.SHOW_CROP_COLOR:
      const cropColor =
        props.feature?.properties?.crop_color ||
        getCode(props.feature?.properties?.crop_code, "color") ||
        "#CC7700";

      return {
        ...defaultStyle,
        color: "#139CC2",
        fillColor: `${cropColor}`,
        weight: 2,
        opacity: 0.5,
        fillOpacity: 1,
      };
    default:
      // style === STYLETYPES.DEFAULT or no style selected
      return {
        ...defaultStyle,
        color: "#139CC2",
        fillColor: "#139CC2",
        selected: false,
        weight: 3,
        opacity: 0.5,
      };
  }
};

export const parseLatLng = (latlng: { lat: number; lng: number }) => {
  return parseCoords([latlng.lat, latlng.lng]);
};

export const parseCoords = (coords: [number, number]) => {
  return `${coords[0].toFixed(5)},${coords[1].toFixed(5)}`;
};
