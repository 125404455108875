import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { favorite, selectList, deleteList, showModal } from "../actions";
import { accountFeatures, toWKT, useSSelector } from "../util";
import i18n from "i18next";
import { GenericPopper } from "./GenericPopper";
import { Button, InputGroup, FormControl, FormGroup, ListGroup, Row, Col } from "react-bootstrap";
import { Pro } from "../components/Pro";

interface ListViewProperties {
  locations: string[];
  sharedFieldsData?: any;
  fieldKey?: string;
}

export const ListView = ({
  locations,
  sharedFieldsData = [],
  fieldKey = "",
}: ListViewProperties) => {
  const dispatch = useDispatch();
  const [newList, setNewList] = useState(false); // newList popover
  const [listName, setListName] = useState("");
  const { fields, lists, selectedListId, selectedField, totalAllowedLists, isStaff } = useSSelector(
    (state) => ({
      fields: state.favorites?.fields || [],
      lists: state.favorites?.lists || [],
      selectedListId: state.favorites?.selectedList || "",
      selectedField: state.selection.field, // crops + feature,
      totalAllowedLists: state.activeUser?.account?.features?.lists || 0,
      isStaff: state.activeUser?.account?.user?.is_staff || false,
    })
  );
  const likedFields =
      locations && locations.length >= 1
        ? fields.filter((f) => locations.includes(f.point)).map((f) => f.id)
        : [],
    customLists = lists.filter((l) => l.type === "CUSTOM"),
    listsLimitReached = !isStaff && Boolean(customLists.length >= totalAllowedLists);

  const account = useSSelector((s) => s.activeUser?.account);
  const features = accountFeatures(account);

  const handleChange = (e) => setListName(e.target.value);

  const handleAdd = (e, selectedField) => {
    const selectedListId = e.currentTarget.id,
      selectedListName = e.currentTarget.innerText;
    let fieldsData = sharedFieldsData.length ? sharedFieldsData : [];
    if (selectedListId && !sharedFieldsData.length) {
      dispatch(selectList({ selectedList: selectedListId }));
    }
    if (selectedField?.state === "fetched") {
      const source = (selectedField.feature?.id || "").split("-")[1],
        year = source ? "20" + source.substr(3) : "",
        mainCrop = selectedField?.crops
          .filter((crop) => crop.year === parseInt(year))
          .sort((a, b) => (a.total_area > b.total_area ? 1 : -1))[0],
        geometry = toWKT(selectedField.feature, "") || "";

      fieldsData.push({
        source: source || "",
        external_id: selectedField.feature?.id || "",
        name: "",
        area: selectedField.feature?.properties?.area,
        geometry: geometry,
        crop_name: Object.keys(mainCrop?.extra || {})[0] || "",
        variety_name: "",
        crop_code: selectedField.feature?.properties?.code || mainCrop?.code,
        period_start: year ? year + "-05-01" : "",
      });
    }
    if (sharedFieldsData.length && fieldsData.length) {
      dispatch(
        favorite(listName || selectedListName, selectedListId || "", locations, fieldKey, null)
      );
      setNewList(false);
      setListName("");
      e.preventDefault();
      return;
    }

    if ((fieldsData.length && !sharedFieldsData.length) || !selectedListId)
      dispatch(
        favorite(
          listName || selectedListName,
          selectedListId || "",
          locations,
          fieldKey,
          fieldsData
        )
      );
    setNewList(false);
    setListName("");
    e.preventDefault();
  };

  return (
    <div className="list-view-wapper mb-3">
      {customLists.length ? (
        <ListGroup>
          {customLists.map((item) => {
            const allInList =
              likedFields.length &&
              (likedFields.length === locations.length || locations.length === 1)
                ? likedFields.every((el) => item.fields.includes(el))
                : null;
            return (
              <ListGroup.Item
                key={item.id}
                id={item.id}
                action
                onClick={(e) => handleAdd(e, selectedField)}
                className={allInList ? "pl-2" : null}
                active={selectedListId === item.id.toString()}
              >
                {allInList ? (
                  <span className="fa fa-check-circle mr-1 text-success" />
                ) : (
                  <span>&nbsp;</span>
                )}
                {item.name}
                <span className="number-likes float-right ml-1">{item.fields.length}</span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}
      {newList ? (
        <Row className="pt-3">
          <Col xs={12} md={12}>
            <form onSubmit={(e) => handleAdd(e, selectedField)}>
              <FormGroup className="d-flex px-3">
                <InputGroup className="flex-grow-1 pr-3">
                  <FormControl
                    value={listName}
                    onChange={handleChange}
                    aria-describedby="basic-addon2"
                  />
                  <Button variant="primary" type="submit" disabled={!listName} className="btn">
                    <span className="fa fa-plus" />
                  </Button>
                </InputGroup>
                <Button
                  variant="link"
                  onClick={() => {
                    setListName("");
                    setNewList(false);
                  }}
                >
                  {i18n.t("Cancel")}
                </Button>
              </FormGroup>
            </form>
          </Col>
        </Row>
      ) : (
        <Button variant="link" onClick={() => setNewList(true)} disabled={listsLimitReached}>
          <span className="fa fa-plus-circle pr-2" />
          {i18n.t("New list")}
        </Button>
      )}
      {!features.pro && <Pro required="pro" msg={i18n.t("This is a pro feature")} click={true} />}
      {listsLimitReached && features?.pro && (
        <Pro required="premium" msg={i18n.t("Maximum reached, upgrade to Premium")} click={true} />
      )}
    </div>
  );
};

interface LikeButtonProps {
  liked: boolean;
  location?: string;
  fieldKey?: string;
}

// This is the heart button
export const LikeButton = ({ liked = false, location, fieldKey }: LikeButtonProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { loggedIn } = useSSelector((s) => ({
    loggedIn: s.activeUser.state === "loggedIn",
  }));
  if (loggedIn) {
    return (
      <GenericPopper
        content={<ListView locations={[location]} fieldKey={fieldKey} />}
        open={open}
        placement="bottom"
        onClose={() => {
          setOpen(false);
          dispatch(selectList({ selectedList: null }));
        }}
      >
        <button
          key="fav"
          className={`hidden-print btn-favorite${liked ? " active" : ""}`}
          onClick={(e) => {
            setOpen(!open);
            e.preventDefault();
          }}
        />
      </GenericPopper>
    );
  } else {
    return (
      <button
        key="fav"
        className={`hidden-print btn-favorite${liked ? " active" : ""}`}
        onClick={() => {
          dispatch(
            showModal({
              text: i18n.t("Login to like farms and plots"),
              modal: "subscribe",
              feature: "Pro",
            })
          );
        }}
      />
    );
  }
};

interface LikeButtonLightProps {
  location: string;
  fieldKey?: string;
}

export const LikeButtonLight = ({ location, fieldKey }: LikeButtonLightProps) => {
  const dispatch = useDispatch();
  const { fields, lists, selectedList, loggedIn } = useSSelector((state) => ({
    loggedIn: state.activeUser.state === "loggedIn",
    fields: state.favorites?.fields || [],
    lists: state.favorites?.lists || [],
    selectedList: state.favorites?.selectedList,
  }));
  if (!loggedIn) return null;
  const field = fields.find((f) => {
      return f["point"] === location;
    }),
    liked = !!field,
    fieldInLists = field
      ? lists
          .filter((l) => l.fields.includes(field.id))
          .map((l) => l.name)
          .join(", ")
      : "";
  return (
    <button
      key="fav"
      className={`hidden-print float-right btn-favorite${liked ? " active" : ""}`}
      onClick={(e) => {
        if (selectedList) {
          dispatch(favorite(null, selectedList, [location], fieldKey));
        }
        e.preventDefault();
      }}
      title={fieldInLists}
    />
  );
};

export const DeleteListButton = ({ show = true }) => {
  const dispatch = useDispatch();
  const { selectedList } = useSSelector((state) => ({
    selectedList: state.favorites?.selectedList,
  }));
  if (!show) return null;
  return (
    <div className={`px-2 btn-group flex-fill ${selectedList ? "" : "d-none"}`} role="group">
      <Button
        key="deleteList"
        className="btn btn-delete-list"
        onClick={() => dispatch(deleteList(selectedList))}
        variant="outline-danger"
      >
        <i className="fa fa-trash font-size-1_5" />
      </Button>
    </div>
  );
};
