import { useDispatch } from "react-redux";
import React, { useCallback } from "react";
import { updateSatelliteImage } from "../../actions";
import { track } from "../../track";
import { ToggleButton } from "../diagrams";
import i18n from "i18next";

export const SoilPotencyButton = ({ show, growth, cropfield }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(
      updateSatelliteImage(cropfield, {
        imageType: growth.imageType,
        sp: !growth.sp,
      })
    );
    !growth?.sp && track("report", "satellite", "sp");
  }, [dispatch, cropfield, growth]);
  if (!show) return null;
  return (
    <ToggleButton
      active={growth.sp}
      tooltip={i18n.t("Show soil potential")}
      variant="default"
      onClick={onClick}
    >
      <i
        className={
          growth.imgState === "fetching" && growth.sp
            ? "fa fa-circle-o-notch fa-spin"
            : "fa fa-bolt"
        }
      />
    </ToggleButton>
  );
};
