import { FieldProp, FieldProps, Desc } from "./FieldProps";

export class DKFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp("gwp", "gwp-img", (e) =>
        Desc(
          "Denne grund er placeret i et grundvandsbeskyttelsesområde",
          e,
          "https://danskevv.dk/viden-om/grundvandsbeskyttelse/",
          "Grundvandsbeskyttelse"
        )
      ),
      new FieldProp("permanent_grass", "grs-img", (e) =>
        Desc(
          "Permanent græs",
          e,
          "https://lbst.dk/nyheder/nyhed/nyhed/vi-har-opdateret-imk-laget-for-permanent-graes/",
          "Landbrugsstyrelsen"
        )
      ),
      new FieldProp("erosion", "erosion-img", (e) =>
        Desc(
          "Jorderosion",
          e,
          "https://lbst.dk/nyheder/nyhed/nyhed/erosionsreglen-er-aendret/",
          "Landbrugsstyrelsen"
        )
      ),
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "Natura 2000 er betegnelsen for et netværk af beskyttede naturområder i EU. Områderne skal bevare og beskytte naturtyper og vilde dyre- og plantearter, som er sjældne, truede eller karakteristiske for EU-landene",
          e,
          "https://ec.europa.eu/environment/nature/natura2000/index_en.htm",
          "Natura 2000-områderne"
        )
      ),
      new FieldProp("organic", "organic-img", (e) =>
        Desc(
          "Økologiske mark",
          e,
          "https://lbst.dk/nyheder/nyhed/nyhed/se-hvor-de-oekologiske-marker-er/",
          "økologiske marker"
        )
      ),
    ];
  }
}
