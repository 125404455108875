import { streetviewImage } from "../../util";
import imgSample from "../../../img/sample/sample_farm.png";
import i18n from "i18next";
import React from "react";
import "./FarmPanelHeader.scss";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { IconButton } from "../../components/IconButton";

export const FarmPanelHeader = ({ onHide, farm, userAvatar, returnUrl = "" }) => {
  const dispatch = useDispatch();
  const farmPhoto = farm?.photo || "";

  const panelImage =
    farmPhoto && !farmPhoto.includes("get_text_avatar")
      ? farmPhoto
      : streetviewImage(farm, imgSample);
  return (
    <header className="mod_bb_farm_panel_header">
      <section
        className="c-hero c-hero--overlay-layer"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(8, 18, 15, 0.45), rgba(8, 18, 15, 0.45)), url(${panelImage})`,
        }}
      >
        <div className="header--button-container">
          <div className="button-group">
            {returnUrl && (
              <IconButton icon={"arrow-left"} onClick={() => dispatch(push(`${returnUrl}`))} />
            )}
          </div>
          <div className="button-group">
            <IconButton icon={"times"} onClick={onHide} />
          </div>
        </div>
        <div className="header--content">
          <div className="inner">
            {userAvatar && <img alt="" className="avatar" src={userAvatar} />}
            <h2 className="header--title">
              {i18n.t("Farm")} {farm?.name || ""}
            </h2>
          </div>
        </div>
      </section>
    </header>
  );
};
