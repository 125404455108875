import React, { useEffect } from "react";
import { useSSelector } from "../util";
import { config } from "../Config";
import { Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProductLinks } from "../actions";
import i18n from "i18next";
import "./Header.scss";
import { ImageCountry } from "./CountrySelect";
import { RNavDropdownItem } from "../../util/router-bootstrap";
import { LogoBB } from "../components/LogoBB";

export const ApplicationSwitch = () => {
  const dispatch = useDispatch();
  const productLinks = useSSelector((s) => s.productLinks);
  useEffect(() => {
    if (!productLinks.state) dispatch(getProductLinks());
  }, [dispatch, productLinks?.state]);
  const navApplicationSwitch = productLinks?.response;
  if (navApplicationSwitch?.switch?.length || navApplicationSwitch?.discover?.length) {
    return (
      <Navbar.Brand className={"nav navbar-brand-nav"}>
        <NavDropdown
          className="cropfields-dropdown"
          as={"ul"}
          id={"bb_dropdown"}
          title={
            <>
              <LogoBB />
              <ImageCountry country={config.bb_country} />
              <span className={"caret"} />
            </>
          }
        >
          <NavDropdown.Item as={"li"} eventKey="header-item-1">
            {i18n.t("Switch to")}
          </NavDropdown.Item>
          {navApplicationSwitch?.switch?.map((bbApp, index) => (
            <NavDropdown.Item eventKey={index} key={index} href={bbApp.url}>
              <img src={bbApp.img} width="25" height="25" alt={bbApp.name} />
              <span style={{ paddingLeft: "1rem" }}>{bbApp.name}</span>
            </NavDropdown.Item>
          ))}
          {navApplicationSwitch?.discover?.length ? (
            <>
              <NavDropdown.Divider />
              <NavDropdown.Item as={"li"} eventKey="header-item-2">
                {i18n.t("Discover")}
              </NavDropdown.Item>
              {navApplicationSwitch.discover.map((discover, index) => (
                <RNavDropdownItem
                  eventKey={"d" + index}
                  key={"d" + index}
                  href={"/page/" + discover.gid}
                >
                  <img src={discover.img} width="25" height="25" alt={discover.name} />
                  <span style={{ paddingLeft: "1rem" }}>{discover.name}</span>
                </RNavDropdownItem>
              ))}
            </>
          ) : null}
        </NavDropdown>
      </Navbar.Brand>
    );
  }
  return (
    <Navbar.Brand>
      <Link to="/" className="cropfields-dropdown">
        <LogoBB />
        <ImageCountry country={config.bb_country} />
      </Link>
    </Navbar.Brand>
  );
};
