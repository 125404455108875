import ReactGeosuggest from "react-geosuggest";
import { config } from "../../Config";
import { countryPlugins, GPSPlugin } from "./plugins";

export default class GeoSuggest extends ReactGeosuggest {
  // Missing typescript, @types not complete https://github.com/ubilabs/react-geosuggest/issues/467
  // plugins: GPSPlugin[];

  constructor(props) {
    super(props);
    this.plugins = [new GPSPlugin()];
    this.updateSuggests = this.updateSuggests.bind(this);
    const CountryPlugin = countryPlugins[config.bb_country];
    if (CountryPlugin) {
      this.plugins.push(new CountryPlugin());
    }
  }

  selectSuggest(suggestToSelect) {
    super.selectSuggest(suggestToSelect || this.state.suggests[0]);
  }

  geocodeSuggest(suggest) {
    if (!suggest.placeId && this.state.suggests[0]) suggest = this.state.suggests[0];
    for (let e of this.plugins) {
      const s = e.geocode(suggest.placeId);
      if (s) {
        s.then((a) => this.props.onSuggestSelect(a, this.state.userInput), console.error);
        return;
      }
    }
    // google maps implementation
    super.geocodeSuggest(suggest);
  }

  showSuggests() {
    const input = this.state.userInput;
    for (let e of this.plugins) {
      const suggests = e.suggests(input, { location: this.props.currentLocation });
      if (suggests) {
        suggests.then(this.updateSuggests, console.error);
        this.setState({ isSuggestsHidden: false });
        return;
      }
    }
    this.updateSuggests();
    this.setState({ isSuggestsHidden: false });
    // google maps implementation: super.showSuggests()
  }
}
