import { block, HitsList, renderComponent } from "searchkit";
import throttle from "lodash/throttle";
import React from "react";

export class MyHitsList extends HitsList {
  pageSize: number;
  container: HTMLElement;

  constructor(props) {
    super(props);
    this.pageSize = props.pageSize || 24;
    this.state = { rendered: this.pageSize };
    this.handleScroll = throttle(this.handleScroll, 100).bind(this);
  }

  componentDidMount() {
    this.container = document.getElementById("resultList");
    this.container.addEventListener("scroll", this.handleScroll);
  }

  handleScroll(e) {
    const c = this.container;
    const addData = c.scrollHeight - c.offsetTop - c.scrollTop - c.offsetHeight < 200;
    if (addData) {
      this.setState({ rendered: this.state.rendered + this.pageSize });
    }
  }

  componentWillUnmount() {
    this.container.removeEventListener("scroll", this.handleScroll);
    delete this.container;
  }

  render() {
    const { hits, mod, className, itemComponent } = this.props;
    const bemBlocks = {
      container: block(mod).el,
      item: block(`${mod}-hit`).el,
    };
    return (
      <div data-qa="hits" className={bemBlocks.container().mix(className)}>
        {hits.slice(0, this.state.rendered).map((result, index) =>
          renderComponent(itemComponent, {
            key: result._id,
            result,
            bemBlocks,
            index,
          })
        )}
      </div>
    );
  }
}
