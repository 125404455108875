import React from "react";
import { config } from "../../Config";
import { ImageOverlay } from "react-leaflet";

type HeightOverlayProps = {
  shadow: any;
  dem: any;
  bounds: any;
};
export const HeightOverlay = (props: HeightOverlayProps) => {
  const { shadow, dem, bounds } = props;

  const showShadow = shadow?.showShadow;
  const drainage = dem?.drainage;
  const imgKey = showShadow ? shadow?.shadowData?.img_key : dem?.data?.img_key;

  if (!imgKey) {
    return null;
  }

  const getURL = () => {
    let url = `${config.LAYER_URL}/height/img/${imgKey}/`;
    if (drainage && !showShadow) {
      url = url + "?type=drainage";
    }

    return url;
  };

  return <ImageOverlay url={getURL()} bounds={bounds} />;
};
