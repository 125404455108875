import React from "react";
import { useDispatch } from "react-redux";
import { useSSelector, accountFeatures } from "../../util";
import { Icon } from "react-fa";
import { updateSettings } from "../../actions";
import { Toggle, showSubscriptionModal } from "../../components";
import { track } from "../../track";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

interface ConfigurableFeatureProps {
  setting: string;
  name: string;
  onToggle?: (toggled: boolean) => void;
  show?: boolean;
  children?: React.ReactNode;
}
export const ConfigurableFeature = ({
  setting,
  name,
  onToggle,
  show = true,
  children,
}: ConfigurableFeatureProps) => {
  const dispatch = useDispatch();
  const activeUser = useSSelector((s) => s.activeUser);
  if (!show) return null;

  const loggedIn = activeUser.state === "loggedIn";
  const toggled = Boolean(activeUser.settings?.[setting]);
  const features = accountFeatures(activeUser.account);

  const toggle = (
    <div className="feature-header d-flex align-items-center">
      <Toggle
        isToggled={toggled}
        callback={() => {
          if (!loggedIn || !features.pro) return;
          track("update_settings", setting, !toggled);
          dispatch(updateSettings({ [setting]: !toggled }));
          onToggle?.(!toggled);
        }}
        onClick={() => (!loggedIn || !features.pro) && showSubscriptionModal(dispatch, "")}
      />
      <h5 className="mx-3 my-2">{name}</h5>
    </div>
  );

  const wrap = children ? (
    <Accordion className="shadow-none border-bottom">
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<Icon name="chevron-down" />}
      >
        {toggle}
      </AccordionSummary>
      <AccordionDetails>
        <div className="feature-context">{children}</div>
      </AccordionDetails>
    </Accordion>
  ) : (
    toggle
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="feature">{wrap}</div>
      </div>
    </div>
  );
};
