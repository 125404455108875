import React, { useEffect, useRef, useState } from "react";
import { Alert, ListGroup, Overlay, Tooltip } from "react-bootstrap";
import { LeaseData } from "../../report/reference/Lease";
import { bbAxios } from "../../util";
import { config } from "../../Config";

const LeaseFarmListItem = ({ leaseFarm }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div className="d-flex">
      <img alt="" className="avatar mr-3" src={leaseFarm.photo} />
      <span className="flex-grow-1 d-flex flex-column align-self-center">
        <h4>{leaseFarm.name}</h4>
      </span>
      <span>
        <button className="btn btn-link" ref={target} onClick={() => setShow(!show)}>
          Voorwaarden
        </button>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              <LeaseData leaseData={leaseFarm.lease_data} />
            </Tooltip>
          )}
        </Overlay>
      </span>
    </div>
  );
};

export const LeaseFarmList = () => {
  const [leaseFarms, setLeaseFarms] = useState<any>();
  useEffect(() => {
    bbAxios("/api/farms/?lease=true&in_bbox=" + config.bounds.join(","))
      .then(({ data: farms }) => {
        setLeaseFarms(farms);
      })
      .catch((err) => {
        console.error("Error fetching leasefarms", err);
      });
  }, []);

  if (!leaseFarms?.length) {
    return <Alert variant="info">Nog geen openbare duurzame verpachters</Alert>;
  }
  return (
    <ListGroup>
      {Object.keys(leaseFarms).map((farmId, index) => (
        <ListGroup.Item key={index}>
          <LeaseFarmListItem leaseFarm={leaseFarms[farmId]} />
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
