import React from "react";
import L, { LatLngBoundsExpression } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { TopPolygon } from "./map_util";
import { latLon } from "./map_util";

const allOf = {
  zoomControl: false,
  attributionControl: false,
  keyboard: false,
  dragging: false,
  touchZoom: false,
  doubleClickZoom: false,
  scrollWheelZoom: false,
  boxZoom: false,
  tap: false,
};

interface SimpleMapProps {
  feature: any;
  extraBounds?: LatLngBoundsExpression[];
  height?: number;
  children?: React.ReactElement | React.ReactElement[];
}

export const SimpleMap = (props: SimpleMapProps) => {
  const bounds = new L.Polygon(latLon(props.feature)).getBounds();
  if (props.extraBounds) {
    for (const b of props.extraBounds) {
      bounds.extend(b);
    }
  }
  return (
    <div className="map_100p" style={{ height: (props.height || 300) + "px" }}>
      <MapContainer bounds={bounds} {...allOf}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {props.children || <TopPolygon key="feature" feature={props.feature} />}
      </MapContainer>
    </div>
  );
};
SimpleMap.TopPolygon = TopPolygon;
