import React from "react";
import { Pane } from "react-leaflet";
// import Control from "react-leaflet-control";
import "leaflet/dist/leaflet.css";
// import MeasureControl from "../../leaflet/MeasureControl";

export const DefaultZIndex = 500;

type Props = {
  name: string;
  zIndex?: number;
  children: React.ReactElement | React.ReactElement[];
};

export const Layer = (props: Props) => {
  const { name, zIndex = DefaultZIndex, children } = props;
  return (
    <Pane name={name} style={{ zIndex: zIndex }}>
      {children}
    </Pane>
  );
};
