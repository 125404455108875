import { getCenter, useSSelector } from "../../util";
import React, { useCallback } from "react";
import i18n from "i18next";
import { config } from "../../Config";
import { KeyVal } from "../header/components/KeyVal";
import { updateSettings } from "../../actions";
import { useDispatch } from "react-redux";
import { DetailRow } from "../../components/DetailRow";
import { Flag } from "../Flag";

const CoordinateInfo = ({ field }) => {
  const dispatch = useDispatch();
  const activeUser = useSSelector((s) => s.activeUser);
  const showXY = Boolean(activeUser.settings?.showXY);
  const onClick = useCallback(
    (e) => dispatch(updateSettings({ showXY: !showXY })),
    [showXY, dispatch]
  );
  const gps = getCenter(field.feature, null, 5).reverse().join(", ");
  const projected = getCenter(field.feature, config.projection, 0).join(", ");

  return (
    <>
      <div className="d-print-none">
        <DetailRow>
          {i18n.t("Coordinates")}:&nbsp;
          {showXY ? projected : gps}&nbsp;
          <button className="btn btn-xs btn-secondary" onClick={onClick}>
            {showXY ? "XY" : "GPS"}
          </button>
        </DetailRow>
      </div>
      <div className="d-none d-print-block">
        <KeyVal k={i18n.t("GPS coordinates")} small="GPS" v={gps} />
        <KeyVal k={i18n.t("XY coordinates")} small="XY" v={projected} />
      </div>
    </>
  );
};

export const FieldInfo = ({ field }) => {
  const location = field.zone2 ? `${field.zone2?.name}, ${field.zone1?.name}` : field.zone1?.name;
  const agriculturalArea = field.zonea?.name;
  const zoneTitle = field.zone2 ? i18n.t("Municipality") : i18n.t("Province");

  return (
    <>
      <DetailRow className="c-detail-row--flex" title={zoneTitle} value={location}>
        <div className="flag-container">
          <Flag field={field} attr="zone1" type="PRV" />
          <Flag field={field} attr="zone2" type="MUN" className="flag-icon2" />
        </div>
      </DetailRow>
      <DetailRow
        className="notsmall hidden-print"
        title={`${i18n.t("Agricultural area")}: `}
        value={agriculturalArea}
      />
      <CoordinateInfo field={field} />
    </>
  );
};
