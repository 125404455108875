import { bbAxios } from "../util";
import i18n from "i18next";
import { track } from "../track";
import { replace } from "connected-react-router";
import { refreshFavorites, showModal } from "./index";
import { NotificationManager } from "react-notifications";

export const favorite =
  (listName, listId, locations, fieldKey, fieldsData?, listType?: string) =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.activeUser?.state === "loggedIn") {
      dispatch({ type: "UPDATE_FAVORITES", state: "updating" });
      const response = await bbAxios
        .post("/update_fields/", {
          listName: listName,
          listId: listId,
          locations: locations,
          fieldKey: fieldKey,
          fieldsData: fieldsData,
          listType: listType,
        })
        .catch((err) => {
          NotificationManager.info(i18n.t("Failed to create favorite"));
          dispatch({ type: "UPDATE_FAVORITES", state: "failed" });
        });
      if (!(response && response.data)) return;
      let data = response.data,
        fields = data["fields"],
        lists = data["lists"];
      dispatch({ type: "UPDATE_FAVORITES", state: "updated", lists: lists, fields: fields });
      track("field", locations);
    } else {
      dispatch(
        showModal({
          text: i18n.t("Login to like farms and plots"),
          modal: "subscribe",
          feature: "Pro",
        })
      );
    }
  };

export const changeListName = (listName, listId) => async (dispatch, getState) => {
  const state = getState();
  if (state.activeUser && state.activeUser.state === "loggedIn") {
    dispatch({ type: "UPDATE_FAVORITES", state: "updating" });
    const response = await bbAxios
      .post("/change_list_name/", {
        listName: listName,
        listId: listId,
      })
      .catch((err) => {
        NotificationManager.info(i18n.t("Failed to edit name"));
        dispatch({ type: "UPDATE_FAVORITES", state: "failed" });
      });
    if (!(response && response.data)) return;
    let data = response.data,
      lists = data["lists"];
    dispatch({ type: "UPDATE_FAVORITES", state: "updated", lists: lists });
  }
};

export const selectList = (props) => (dispatch, getState) => {
  const state = getState();
  const lists = state.favorites?.lists || [];
  const selectedList = props?.selectedList;
  dispatch({ type: "SELECT_LIST", ...props });
  if (selectedList && /lists/.test(state.router.location.pathname)) {
    const key = lists.find((l) => l.id === parseInt(selectedList))?.key;
    // selected list should always have a key
    dispatch(replace(`/lists/${key}`));
  }
};

export const deleteList = (selectedList) => (dispatch, getState) => {
  const state = getState();
  if (state.activeUser?.state === "loggedIn" && selectedList) {
    dispatch({ type: "SELECT_LIST", selectedList: null });
    bbAxios
      .post("/delete_list/", { listId: selectedList })
      .then(({ data }) => {
        NotificationManager.error(i18n.t("Selected list was deleted"));
        const fields = data["fields"],
          lists = data["lists"];
        dispatch({
          type: "UPDATE_FAVORITES",
          state: "updated",
          lists: lists,
          fields: fields,
        });
      })
      .catch((err) => {
        console.error("Error by deleting list", err);
        NotificationManager.error(i18n.t("Failed to delete selected list"));
      });
  }
};

export const viewSharedList = (listKey) => (dispatch, getState) => {
  if (!listKey) return;
  dispatch({ type: "SELECT_LIST", selectedList: null });
  dispatch({ type: "SHARED_LIST", state: "updating" });
  bbAxios
    .post("/view_shared_list/", { listKey: listKey })
    .then(({ data }) => {
      const sharedData = { sharedList: data };
      dispatch({ type: "SHARED_LIST", state: "updated", ...sharedData });
    })
    .catch((err) => {
      console.error("Error by requesting shared list", err);
      dispatch({ type: "SHARED_LIST", state: "failed" });
      NotificationManager.error(i18n.t("Failed to show shared list"));
    });
};

export const getSharedField = (fieldKey) => (dispatch, getState) => {
  const state = getState();
  if (fieldKey && state.favorites?.sharedField?.state !== "fetching") {
    dispatch({ type: "SHARED_FIELD", sharedField: { state: "fetching" } });
    bbAxios
      .post("/get_shared_field/", { fieldKey: fieldKey })
      .then(({ data }) => {
        dispatch({ type: "SHARED_FIELD", sharedField: { state: "fetched", ...data } });
      })
      .catch((err) => {
        console.error("Error by requesting shared field", err);
        dispatch({ type: "SHARED_FIELD", sharedField: { state: "failed" } });
        dispatch(replace("/"));
        NotificationManager.error(i18n.t("Failed to show shared field"));
      });
  }
};

export const uploadP8 = (formData: any) => (dispatch) => {
  dispatch({ type: "UPDATE_FAVORITES", state: "updating" });
  bbAxios
    .post("/upload_p8/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      const fields = data["fields"];
      const lists = data["lists"];
      dispatch({ type: "UPDATE_FAVORITES", state: "updated", lists: lists, fields: fields });
      NotificationManager.success(i18n.t("List has been uploaded"));
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_FAVORITES", state: "updated" });
      NotificationManager.error(i18n.t("Failed to show shared field"));
      console.error("Error by requesting shared field", err);
    });
};

export const uploadLeaseShapeFile = (formData: any) => (dispatch) => {
  dispatch({ type: "UPDATE_FAVORITES", state: "updating" });
  bbAxios
    .post("/upload_lease_shp/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      const fields = data["fields"];
      const lists = data["lists"];
      dispatch({ type: "UPDATE_FAVORITES", state: "updated", lists: lists, fields: fields });
      NotificationManager.success(i18n.t("List has been uploaded"));
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_FAVORITES", state: "updated" });
      NotificationManager.error(i18n.t("Failed to show shared field"));
      console.error("Error by requesting shared field", err);
    });
};

export const makeListPublic = (farm, listKey) => (dispatch) => {
  bbAxios
    .post("/make_list_public/", {
      farm_id: farm.id,
      // eslint-disable-next-line camelcase
      list_key: listKey,
    })
    .then((data) => {
      dispatch(refreshFavorites(farm.external_id));
      NotificationManager.success(i18n.t("The public state of your list has been changed"));
    })
    .catch((err) => {
      console.error("Error while making list public", err);
      NotificationManager.error(i18n.t("Failed to make list public"));
    });
};

export const setHighlightField = (id: number) => {
  return {
    type: "SET_HIGHLIGHT",
    id,
  };
};

export const removeHighlightField = () => {
  return {
    type: "REMOVE_HIGHLIGHT",
  };
};

export const setEditingList = (id: number) => {
  return {
    type: "SET_EDITED_LIST",
    id,
  };
};

export const stopEditingList = () => {
  return {
    type: "SET_EDITED_LIST",
    id: null,
  };
};
