import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { viewSharedList } from "../../actions";
import { useSSelector } from "../../util";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import { PanelHeader } from "..";
import { ListAccordion } from "./ListAccordion";
import { NewListButton } from "./NewListButton";
import { LeaseList } from "./LeaseList";
import imgFavorite from "../../../img/icons/icon_favorite.svg";
import { ListView, GenericPopper } from "../../components";
import { PremiumLeaseWrapper } from "../lease/MyLease";
import { config } from "../../Config";

type Props = {
  listKey: any;
  select: any;
};

export const NewBunderOverview = ({ listKey, select }: Props) => {
  const shouldWait = useSSelector(
    ({ activeUser, favorites }) =>
      activeUser?.state === undefined ||
      activeUser?.state === "fetching" ||
      (activeUser?.state === "loggedIn" && favorites.lists === undefined)
  );
  const isShared = useSSelector(
    (s) => listKey && !s.favorites.lists?.some((l) => l.key === listKey)
  );
  const onClose = useCallback(() => select(), [select]);

  if (shouldWait) return <article className="mod_bb_farm active">{i18n.t("Loading")}...</article>;
  return isShared ? (
    <SharedList listKey={listKey} onClose={onClose} />
  ) : (
    <OwnLists listKey={listKey} onClose={onClose} />
  );
};

const OwnLists = ({ listKey, onClose }) => {
  const [lists, fields] = useSSelector(({ favorites }) => [
    favorites.lists || null,
    favorites.fields || null,
  ]);

  const showLease =
    useSSelector((s) => s.activeUser.settings?.showLease) && config.show.includes("lease");
  const ownLists = lists?.filter((list) => list.type === "CUSTOM").sort((a, b) => a.id - b.id);

  const explanation = (
    <span>
      {i18n.t("TextLikeFields")} <img alt={i18n.t("Favorite")} src={imgFavorite} />.
      {i18n.t("TextFieldsInView")}.
    </span>
  );

  return (
    <article className="mod_bb mod_bb_lists active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Lists overview")}</title>
      </Helmet>
      <PanelHeader onHide={onClose} explanation={explanation}>
        {i18n.t("Lists")}
      </PanelHeader>

      {showLease && (
        <PremiumLeaseWrapper>
          <LeaseList listKey={listKey} />
        </PremiumLeaseWrapper>
      )}
      {showLease && <h5 className="mt-4 mb-2 mx-2">{i18n.t("My lists")}</h5>}

      <ul className="accordion accordion__items" data-animation="on">
        {ownLists?.map((list) => (
          <ListAccordion key={list.id} list={list} listKey={listKey} fields={fields} />
        ))}
        <NewListButton />
      </ul>
    </article>
  );
};

const SharedList = ({ listKey, onClose }) => {
  const [sharedMenu, setSharedMenu] = useState(false);
  const dispatch = useDispatch();
  const sharedList = useSSelector((s) => s.favorites.sharedList);

  useEffect(() => {
    if (!listKey || sharedList) return;
    dispatch(viewSharedList(listKey));
  }, [dispatch, listKey, sharedList]);

  if (!sharedList) {
    return null;
  }

  const { lists, fields, creator } = sharedList || {};
  const list = lists[0]; // flaky design
  const locations = fields?.map((f) => f.point);

  if (!list) {
    return null;
  }

  return (
    <article className="mod_bb mod_bb_lists active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>
          {i18n.t("Shared list")} {list.name}
        </title>
      </Helmet>
      <PanelHeader onHide={onClose}>{list.name}</PanelHeader>
      <h5 className="my-2 mx-2">
        {i18n.t("List from {{name}}", { name: `${creator.first_name} ${creator.last_name}` })}
      </h5>
      <ul className="accordion accordion__items" data-animation="on">
        <ListAccordion
          key={list.id}
          list={list}
          listKey={listKey}
          fields={fields}
          ownList={false}
        />
      </ul>
      <GenericPopper
        content={<ListView locations={locations} sharedFieldsData={fields} />}
        open={sharedMenu}
        placement="bottom"
        onClose={() => setSharedMenu(false)}
      >
        <button
          className={`btn btn-add-likes ${fields?.length ? "" : "disabled"}`}
          onClick={(e) => {
            setSharedMenu(true);
            e.preventDefault();
          }}
        >
          <span className="fa fa-plus-circle" />
          {i18n.t("Add to list")}
        </button>
      </GenericPopper>
    </article>
  );
};
