import React from "react";
import "./SoilTextureTriangle.scss";
import { designs } from "./SoilTextureTriangleDesigns";

const Arrow = ({ e, factor }: { e: number[]; factor: number }) => (
  <line
    x1={e[0] * factor}
    y1={e[1] * factor}
    x2={e[2] * factor}
    y2={e[3] * factor}
    markerEnd="url(#arrow_head)"
    style={{ stroke: "#696969" }}
  />
);
const ALabel = ({ e, factor, label }: { e: number[]; factor: number; label: string }) => (
  <text
    transform={`translate(${e[0] * factor},${e[1] * factor}) rotate(${e[2]})`}
    dy="8"
    textAnchor="middle"
    style={{ fill: "#696969" }}
  >
    {label}
  </text>
);
const factorH = Math.sqrt(0.75);
const Mosaic = ({ points, color, factor, stroke = "#fff" }) => (
  <path
    d={`M${points
      .map((p) => `${factor * (p[1] + p[2] / 2)},${(p[0] + p[1]) * factor * factorH}`)
      .join("L")}Z`}
    fill={color}
    stroke={stroke}
  />
);
const MLabel = ({ points, text, factor, textBreak }) => {
  let c = [0, 0, 0];
  for (let p of points) {
    // eslint-disable-next-line no-loop-func
    p.forEach((val, i) => {
      c[i] += val;
    });
  }
  c = c.map((val) => val / points.length);
  const centroid = [factor * (c[1] + c[2] / 2), (c[0] + c[1]) * factor * factorH],
    words = textBreak ? text.split(" ") : [text];

  return (
    <g transform={`translate(${centroid[0]},${centroid[1] - (6 + 6 * words.length)})`}>
      <text>
        {words.map((text, i) => (
          <tspan key={i} x="0" dy="14">
            {text}
          </tspan>
        ))}
      </text>
    </g>
  );
};

export interface SoilTextureTriangleProps {
  triangle: keyof typeof designs;
  id?: string;
  selected?: any;
  size?: number;
  textBreak?: boolean;
  trans: (string) => string;
}

export const SoilTextureTriangle = ({
  triangle,
  id = "soil-triangle",
  selected = {},
  size = 300,
  textBreak = false,
  trans,
}: SoilTextureTriangleProps) => {
  const design = designs[triangle] || designs["usdaTexture"],
    factor = size / 100;
  return (
    <div id={id}>
      <div className="st-triangle">
        <svg width={size} height={size}>
          <defs>
            <marker
              id="arrow_head"
              markerHeight="8"
              markerWidth="8"
              orient="auto"
              refX="5"
              refY="0"
              viewBox="-5 -5 10 10"
            >
              <path d="M 0,0 m -5,-5 L 5,0 L -5,5 Z" style={{ fill: "#696969" }} />
            </marker>
          </defs>
          <g className="soiltriangle">
            {design.categories.map(({ name, color, points }, index) => {
              const label = trans ? trans(name) : name,
                sel = selected[label.toLowerCase()] || selected[name.toLowerCase()];
              return (
                <g
                  key={index}
                  className={`soiltype ${name.replace(/ /g, "_")}${sel ? " selected" : ""}`}
                  style={{ opacity: sel ? 1 : 0.3 }}
                >
                  <Mosaic color={color} stroke="#fff" points={points} factor={factor} />
                  <MLabel text={label} points={points} factor={factor} textBreak={textBreak} />
                </g>
              );
            })}
          </g>
          {[
            [57, 0, 100, 77],
            [0, 77, 43, 0],
            [100, 93, 0, 93],
          ].map((e, index) => (
            <Arrow key={index} e={e} factor={factor} />
          ))}
          {[
            [14, 43, -60],
            [84, 40, 60],
            [50, 95, 0],
          ].map((e, index) => (
            <ALabel key={index} e={e} factor={factor} label={design.labels[index]} />
          ))}
        </svg>
      </div>
    </div>
  );
};
