import React from "react";
import ReactDom from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import GoogleMapsLoader from "google-maps";
import { config } from "./bb/Config";
import Raven from "raven-js";
import { BBApp } from "./bb/BBApp";
import L from "leaflet";
import { setupTranslations } from "./bb/i18n/translations";
import { track } from "./bb/track";
import { history, store } from "./bb/store";
import debounce from "lodash/debounce";

import "./style/main.scss";
import "./style/countries.scss";
import "react-notifications/dist/react-notifications.css";

// hack for fixing map marker autodetect bug (see: https://github.com/Leaflet/Leaflet/issues/766)
L.Icon.Default.imagePath = "/static/leaflet/images/";
if (window.RAVEN) Raven.config(window.RAVEN, window.RAVEN_OPTIONS).install();
const bbCountry = window.bb_country || "NL";
const bbLang = window.bb_language || "nl";

const listener = (location) => {
  const m =
    /^\/(report|page|user|lists|farm|my_farm|filter|print_report|beta|ews)\/?([^/]+)?$/.exec(
      location.pathname
    );
  // Report wordt in Report.tsx na fetchen gelogd zodat de GPS (en veld-data) klopt
  if (m && m[1] !== "report") track("page", m[1], m[2]);
};
history.listen(listener);
setTimeout(function () {
  listener(window.location);
}, 200);

if (document.cookie.indexOf("bb_cookie_accepted") < 0 && config.MAX_FREE_CLICKS) {
  history.replace(`/page/welcome?next=${encodeURIComponent(window.location.pathname)}`);
}

/** Manage keys at https://console.cloud.google.com with user info.nl@cropx.com */
Object.assign(GoogleMapsLoader, {
  KEY:
    process.env.NODE_ENV !== "production"
      ? "AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y"
      : "AIzaSyDgDj_EfsKsZ39CS7RgVzmUa4icg-tTRzs",
  LIBRARIES: ["places"],
  LANGUAGE: bbLang.toLowerCase().substring(0, 2),
  REGION: bbCountry.toLowerCase(),
  VERSION: "quarterly",
});

setupTranslations();
// see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const resize = () => document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
window.addEventListener("resize", debounce(resize, 10));
resize();
if (L.version < "1.8" && L.Browser.safari && !L.Browser.mobile) {
  // https://github.com/Leaflet/Leaflet/pull/7260/
  L.Map.prototype._initHooks.splice(7, 1);
}

const isIE = navigator.userAgent.search(/MSIE/) >= 0;
if (!isIE) {
  config.loadData().then(() =>
    GoogleMapsLoader.load(function () {
      ReactDom.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <BBApp />
          </ConnectedRouter>
        </Provider>,
        document.getElementById("main")
      );
    })
  );
}
