import React, { useCallback, useState } from "react";
import i18n from "i18next";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { GenericPopper } from "../../../components/GenericPopper";
import { IconButton } from "../../../components/IconButton";
import { showSubscriptionModal } from "../../../components/Pro";
import { DropdownItem } from "../../../panels/list/Components/DropdownItem";
import { accountFeatures, getCenter, useSSelector } from "../../../util";

type Props = {
  cropfield?: any;
  bunder?: any;
  className?: string;
};

export const OtherMenu = ({ cropfield, className }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isPro = useSSelector((s) => accountFeatures(s.activeUser.account).pro);
  const coords = cropfield && getCenter(cropfield, null, 5).reverse().join(",");

  const printFactsheet = useCallback(() => {
    if (!isPro) {
      showSubscriptionModal(dispatch, undefined, "print");
      setOpen(false);
      return;
    }
    setOpen(false);
    dispatch(push(`/print_report/`));
  }, [dispatch, isPro]);

  const downloadShapefile = useCallback(() => {
    if (!isPro) {
      showSubscriptionModal(dispatch, undefined, "shp");
      setOpen(false);
      return;
    }
    window.open(`/export/shape?p=${coords}`);
    setOpen(false);
  }, [coords, dispatch, isPro]);

  return (
    <GenericPopper
      className={className}
      content={
        <>
          {coords && (
            <DropdownItem icon={"download"} title="Shapefile" onClick={downloadShapefile} />
          )}
          <DropdownItem icon={"print"} title={i18n.t("Report")} onClick={printFactsheet} />
        </>
      }
      open={open}
      placement="bottom"
      onClose={() => {
        setOpen(false);
      }}
    >
      <div>
        <IconButton
          icon="ellipsis-v"
          onClick={(e) => {
            setOpen(!open);
            e.preventDefault();
          }}
        />
      </div>
    </GenericPopper>
  );
};
