import React, { useState } from "react";
import { Pro } from "../../components/Pro";
import i18n from "i18next";
import { config } from "../../Config";
import cx from "classnames";

interface DisabledFilterProps {
  open?: boolean;
  title: string;
  text: string;
}

export const DisabledFilter = ({ open: initialOpen, title, text }: DisabledFilterProps) => {
  const [open, setOpen] = useState(!!initialOpen);
  return (
    <div className={cx("sk-hierarchical-menu-list closable", { open })}>
      <div
        onClick={(e) => setOpen(!open)}
        className="sk-hierarchical-menu-list__header toggle free"
      >
        {title} <Pro msg={i18n.t("Subscribe for all filter options")} />
      </div>
      <div className="sk-hierarchical-menu-list__root content">
        <div className="sk-hierarchical-menu-list__hierarchical-options">
          <div className="sk-hierarchical-menu-option">
            <div className="sk-hierarchical-menu-option__text">
              {config.bb_site.beta ? (
                <>{i18n.t("filterTextAccount")}</>
              ) : (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: i18n.t("Subscribe for filtering on <i>{{attr}}</i>", {
                        attr: text || title,
                      }),
                    }}
                  />
                  . <a href={`${config.bb_productPage}`}>{i18n.t("More information")}</a>{" "}
                  {i18n.t("or")}{" "}
                  {config.bb_site.free_trial ? (
                    <a href="/authorize">{i18n.t("get a free trial subscription to CropFields")}</a>
                  ) : (
                    <a href={config.bb_orderPage}>{i18n.t("subscribe here to CropFields")}</a>
                  )}
                  .
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
