import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "../panels";
import i18n from "i18next";
import { config } from "../Config";
import { useSSelector } from "../util";
import { Icon } from "react-fa";

interface LabelProps {
  inHeader?: boolean;
  includesLink?: boolean;
  showDescription?: boolean;
}

export const ConfigurableLabel = ({
  inHeader,
  includesLink = true,
  showDescription = true,
}: LabelProps) => {
  const [activeUser] = useSSelector((s) => [s.activeUser]),
    loggedIn = activeUser.state === "loggedIn";
  if (!config.labs) return null;
  const c =
    "badge badge-primary mx-2 font-size-small align-self-center" + (inHeader ? " in_header" : "");
  const component = includesLink ? (
    <Link className={c} to="/settings">
      <Icon name="cog" />
    </Link>
  ) : (
    <div className={c}>
      <Icon name="cog" />
    </div>
  );
  if (!showDescription) return component;

  const description = loggedIn
    ? i18n.t("Configure settings")
    : i18n.t("PRO subscribers can configure additional features here");
  return <Tooltip description={description}>{component}</Tooltip>;
};
