import moment from "moment";
import { config } from "../Config";
import { accountFeatures, bbAxios, toWKT } from "../util";

const parseGrowthData = (data, dates, year, statType) => {
  return data
    .sort()
    .map((key) => {
      const value = dates[key],
        stat = value.statistics[statType],
        valid = value.meta.valid;
      return {
        key: key,
        unix: moment(key).unix(),
        low: [stat.average - stat.std, stat.average],
        high: [stat.average, stat.average + stat.std],
        valid: valid,
      };
    })
    .filter((obj) => obj.unix > year.unix());
};

export const fetchGrowthDataByYear =
  (state, statType = "NDVI", startYear) =>
  async (dispatch, getState) => {
    const s = getState();
    const data = state.data;
    const satDataKey = statType.toLowerCase();
    const previousYear = moment(startYear, "YYYY-MM-DD").subtract(1, "years");
    const startDate = moment(previousYear).startOf("year").format("YYYYMMDD");
    const endDate = moment(previousYear).endOf("year").format("YYYYMMDD");
    const wkt = s.selection.field && toWKT(s.selection.field.feature);
    if (!wkt) return console.warn("Missing WKT data");

    dispatch({ type: "FETCH_SATELLITE_IMG_KEY", imgState: "fetching" });
    const url = `${config.SATELLITE_URL}/polygon_statistics/`;
    const response = await bbAxios
      .post(url, {
        wkt,
        start_date: startDate,
        end_date: endDate,
        types: statType,
      })
      .catch((err) => {
        dispatch({ type: "FETCH_GROWTH", state: "failed" });
      });
    if (!(response && response.data)) return;
    const resp = response?.data;
    const dates = resp["dates"];
    const fieldId = resp["meta"].field_id;

    const newGrowthData = parseGrowthData(Object.keys(dates), dates, previousYear, statType);
    const oldData = data[satDataKey];
    const imageType = statType.toLowerCase();

    data[satDataKey] = [...newGrowthData, ...oldData];
    dispatch({
      ...state,
      type: "FETCH_GROWTH",
      imgState: "fetched",
      data: data,
      fieldId,
      imageType: imageType,
      satDataKey: imageType,
    });
  };

const doUpdateGrowth = async (dispatch, state, statType = "NDVI") => {
  dispatch({ type: "FETCH_GROWTH", state: "fetching" });
  const minimumDate = moment(config.satellite_start, "YYYY-MM-DD");
  const wkt = state.selection.field && toWKT(state.selection.field.feature);
  if (!wkt) return console.warn("Missing WKT data");
  const startDate = minimumDate.format("YYYYMMDD");

  const url = `${config.SATELLITE_URL}/polygon_statistics/`;
  const response = await bbAxios
    .post(url, {
      wkt,
      start_date: startDate,
      types: statType,
    })
    .catch((err) => {
      dispatch({ type: "FETCH_GROWTH", state: "failed" });
    });

  if (!(response && response.data)) return;
  const resp = response?.data;
  const dates = resp["dates"];
  const fieldId = resp["meta"].field_id;
  const growthData = parseGrowthData(Object.keys(dates), dates, minimumDate, statType);

  const data = state?.selection?.growth?.data || {};

  if (!data.statType) {
    data[statType.toLowerCase()] = growthData;
  }
  dispatch({
    type: "FETCH_GROWTH",
    state: "fetched",
    data: data,
    fieldId,
    imageType: statType.toLowerCase(),
    satDataKey: statType.toLowerCase(),
  });
};

export const updateGrowth = () => async (dispatch, getState) => {
  const state = getState(),
    gps = state.selection.gps;
  if (state.selection.growth || !gps) return;
  dispatch({ type: "FETCH_GROWTH", state: "fetching" });

  await doUpdateGrowth(dispatch, state);
};

// TODO reuse state.imageData if available, do not always look up new stuff

export const changeSatelliteImage =
  ({ imageType = "ndvi" }) =>
  (dispatch, getState) => {
    const s = getState(),
      state = s.selection.growth;
    if (!state.imageData) return;
    dispatch(selectImageData(state.key, state.sp, imageType));
    dispatch({ type: "FETCH_SATELLITE_IMG_KEY", imageType: imageType });
    if (imageType !== "rgb") {
      dispatch({ type: "FETCH_SATELLITE_IMG_KEY", satDataKey: imageType });
    }
    const growthData = state.data[imageType];
    if (!growthData && imageType !== "rgb") {
      doUpdateGrowth(dispatch, s, imageType.toUpperCase());
    }
  };

export const selectImageData =
  (key, sp = false, imageType = "ndvi") =>
  (dispatch, getState) => {
    const state = getState().selection.growth;
    if (state.imageData && key === state.key) {
      if (imageType !== state.imageType) {
        dispatch({
          ...state,
          type: "FETCH_SATELLITE_IMG_KEY",
          imgkeyData: state.imageData[imageType + ".png"],
          imageType,
          sp,
        });
      }
    }
  };

export const updateSatelliteImage =
  (cropfield, { key = undefined, imageType = "ndvi", sp = false }) =>
  (dispatch, getState) => {
    const s = getState(),
      state = s.selection.growth,
      satDataKey = state.satDataKey || "ndvi",
      growthData = state.data[satDataKey],
      features = accountFeatures(s.activeUser?.account);

    if (state.imgState === "fetching" || state.state === "fetching" || state.state === "failed") {
      return;
    }
    const validData = growthData.filter((d) => d.valid);
    const firstDefault = validData[validData.length - (features.pro ? 1 : 3)] || validData[0];

    if (!key) {
      key = state.key || (firstDefault && firstDefault.unix);
    }
    if (!key) {
      return;
    }

    dispatch(selectImageData(key, sp, imageType));

    const date = moment.unix(key).format("YYYYMMDD");
    dispatch({ type: "FETCH_SATELLITE_IMG_KEY", imgState: "fetching", sp });
    bbAxios
      .post(`${config.SATELLITE_URL}/list_images/`, {
        wkt: toWKT(cropfield.geometry, 4326),
        legend: sp ? "sp" : "ndvi",
        date: date,
      })
      .then(({ data }) => {
        if (data.images) {
          const imageData = data["images"][date];
          if (imageData) {
            dispatch({
              type: "FETCH_SATELLITE_IMG_KEY",
              imgState: "fetched",
              imageData,
              imgkeyData: imageData[(sp ? "sp" : imageType) + ".png"],
              key: key,
              imageType,
              sp,
              bbox: data.bounding_box,
              legends: data.legends,
            }); // key && type
            return;
          }
        }
        dispatch({ type: "FETCH_SATELLITE_IMG_KEY", imgState: "failed" });
      })
      .catch(() => dispatch({ type: "FETCH_SATELLITE_IMG_KEY", imgState: "failed" }));
  };
