import { config } from "../../Config";
import React, { memo, useCallback, useState } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import { getLayerDay } from "../map_util";
import memoize from "lodash/memoize";
import { useSSelector } from "../../util";
import { EWSDateSelector, showSubscriptionModal } from "../../components";
import i18n from "i18next";
import "leaflet/dist/leaflet.css";
import "../../components/LeafletControls.scss";
import { useDispatch } from "react-redux";

const { BaseLayer, Overlay } = LayersControl;

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

export const layers = memoize((activeUser) => {
  const features = activeUser.account?.features || {};
  const settings = activeUser.settings || {};
  let _layers = {
    nva: "NV Gebieden",
    parcel: i18n.t("Fields"),
    lease: i18n.t("Lease"),
    soil: i18n.t("Soil type"),
    r_soil: i18n.t("Regulatory soil type"),
    trench: "NL " + i18n.t("waterways"),
    // bparcel: "AAN",
    // no_fly_zone: "No fly zones",
    // nature: "Natuurbeheerplan",
    bufferZone: "Bufferstroken",
    water_trap: "Watertrappen",
    // permanent_grass: i18n.t("Permanent grassland"),
    gwp: i18n.t("Ground water protection"),
    dem: i18n.t("Altitude"),
    cadastre: i18n.t("Cadastre"),
    n2k: "Natura 2000",
    property_i: i18n.t("Government property"),
    rsi: "Beregeningsverbod",
    rsp: "Aardappel beperking",
  };
  if (!(config.show.includes("lease") && settings.showLease)) delete _layers["lease"];

  if (features.precipDeficit && settings.showPrecipDeficit) {
    _layers["precip_deficit"] = "Neerslagtekort";
  }
  return _layers;
});

const nlBounds = L.latLngBounds(
  L.latLng(49.5075683573655, 2.541667),
  L.latLng(53.7, 7.39732444481026)
);

const pdokOpts = {
  attribution: 'Open data via <a href="https://www.pdok.nl/">pdok.nl</a>',
  maxZoom: MAX_ZOOM,
  maxNativeZoom: 18,
};
const refp = { zIndex: 50, tms: true, maxNativeZoom: 18, maxZoom: MAX_ZOOM };
export const Layers = memo(({ layers, accountFeatures, layerConfig, showPlotLayer }) => {
  const pro = accountFeatures.pro;
  const dispatch = useDispatch();

  if (!pro) {
    return (
      <LayersControl position="topright" autoZIndex={false}>
        <BaseLayer key="openstreetmap" checked={true} name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={19}
            maxZoom={MAX_ZOOM}
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <BaseLayer name="BRT Achtergrond (Pro)" key="BRT_FREE">
          <TileLayer
            layer="standaard"
            format="image/png"
            eventHandlers={{
              add: (e) => {
                showSubscriptionModal(dispatch, "");
              },
            }}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        {["2023", "2022", "2021"].map((year) => (
          <BaseLayer name={`${i18n.t("Orthophoto")} ${year} (Pro)`} key={`Orthophoto_${year}_FREE`}>
            <TileLayer
              maxZoom={MAX_ZOOM}
              maxNativeZoom={19}
              format="image/png"
              eventHandlers={{
                add: (e) => {
                  showSubscriptionModal(dispatch, "");
                },
              }}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
        ))}
        {["2020", "2019", "2018"].map((year) => (
          <BaseLayer name={`${i18n.t("Orthophoto")} ${year} (Pro)`} key={`Orthophoto_${year}_FREE`}>
            <TileLayer
              layer={`${year}_ortho25`}
              eventHandlers={{
                add: (e) => {
                  showSubscriptionModal(dispatch, "");
                },
              }}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
        ))}
        <BaseLayer name="Google maps (Pro)" key={"GOOGLE_FREE"}>
          <TileLayer
            eventHandlers={{
              add: (e) => {
                showSubscriptionModal(dispatch, "");
              },
            }}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </BaseLayer>
        <Overlays layers={layers} layerConfig={layerConfig} showPlotLayer={showPlotLayer} />
      </LayersControl>
    );
  }

  return (
    <LayersControl position="topright">
      <BaseLayer key="openstreetmap" checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name="BRT Achtergrond" key="BRT_PRO">
        <TileLayer
          url="https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=EPSG:3857&LAYER={layer}&FORMAT={format}&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}"
          layer="standaard"
          format="image/png"
          {...pdokOpts}
        />
      </BaseLayer>
      {["2023", "2022", "2021"].map((year) => (
        <BaseLayer name={`${i18n.t("Orthophoto")} ${year}`} key={`Orthophoto_${year}_PRO`}>
          <TileLayer
            url={`https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/${year}_orthoHR/EPSG:3857/{z}/{x}/{y}.jpeg`}
            attribution={pdokOpts.attribution}
            maxZoom={MAX_ZOOM}
            maxNativeZoom={19}
          />
        </BaseLayer>
      ))}
      {["2020", "2019", "2018"].map((year) => (
        <BaseLayer name={`${i18n.t("Orthophoto")} ${year}`} key={`Orthophoto_${year}_PRO`}>
          <TileLayer
            url="https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/{layer}/EPSG:3857/{z}/{x}/{y}.jpeg"
            layer={`${year}_ortho25`}
            {...pdokOpts}
          />
        </BaseLayer>
      ))}
      <BaseLayer name="Google maps" key={"GOOGLE_PRO"}>
        <GoogleLayer
          maptype="HYBRID"
          googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y"
          attribution='&copy; <a href="https://www.google.com/maps/">Google Map Data</a>'
        />
      </BaseLayer>
      <Overlays layers={layers} layerConfig={layerConfig} showPlotLayer={showPlotLayer} />
    </LayersControl>
  );
});
const Overlays = ({ layers, layerConfig, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=NL";
  const { selectedDisease } = useSSelector((state) => ({
    selectedDisease: (state.ewsConfig && state.ewsConfig.selectedDisease) || "",
  }));
  const ewsDay = getLayerDay(layerConfig, "ewsP", { delta: 0 }),
    precipitationDay = getLayerDay(layerConfig, "precip_deficit", {
      delta: 0,
    });
  const ewsURL = `${config.SFAM_URL}/ews/tile/NL/${ewsDay}/${selectedDisease}/{z}/{x}/{y}.png?key=BB_fbak73udPEDhKoCdgIxo7lRM75uUZsmr`;

  return (
    <>
      {" "}
      <Overlay
        name={`${i18n.t("Fields")} ${config.crop_year}`}
        checked={(layers || []).includes("parcel")}
      >
        <TileLayer url={histUrl} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Fields O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} type="data" opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={"Agrarisch Areaal Nederland"} checked={(layers || []).includes("bparcel")}>
        <TileLayer
          url={layerUrl + "&style=stroke:000000"}
          minZoom={13}
          opacity={0.5}
          type="geo"
          src="17"
          {...refp}
        />
      </Overlay>
      <Overlay name="NV Gebieden" checked={(layers || []).includes("nva")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="1210" {...refp} />
      </Overlay>
      <Overlay name="Grondsoort" checked={(layers || []).includes("soil")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="25" {...refp} />
      </Overlay>
      <Overlay name="Wettelijke grondsoort" checked={(layers || []).includes("r_soil")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="113" {...refp} />
      </Overlay>
      <Overlay name="Buffer zone" checked={(layers || []).includes("bufferZone")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="172" {...refp} />
      </Overlay>
      <Overlay name="Watertrappen" checked={(layers || []).includes("water_trap")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="33" {...refp} />
      </Overlay>
      <Overlay name="Waterwin" checked={(layers || []).includes("gwp")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="35" {...refp} />
      </Overlay>
      <Overlay name="Natuur" checked={(layers || []).includes("nature")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="41" {...refp} />
      </Overlay>
      <Overlay name="Landelijke sloten" checked={(layers || []).includes("trench")}>
        <TileLayer url={layerUrl} opacity={1} type="water" src="121" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={(layers || []).includes("n2k")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="57" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000 buffer" checked={(layers || []).includes("n2k")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="1209" {...refp} />
      </Overlay>
      <Overlay name="Overheidseigendom" checked={(layers || []).includes("property_i")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="94" {...refp} />
      </Overlay>
      <Overlay name="Beregeningsverbod" checked={(layers || []).includes("rsi")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="54" {...refp} />
      </Overlay>
      <Overlay name="Gebruikstitel" checked={(layers || []).includes("lease")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="174" {...refp} />
      </Overlay>
      <Overlay name="Aardappel beperking" checked={(layers || []).includes("rsp")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="79" {...refp} />
      </Overlay>
      {selectedDisease && (
        <Overlay name="Early warning" checked={(layers || []).includes("ewsP")}>
          <TileLayer
            // The URL is immutable, so changing it does not update the layer.
            // Setting a different key changes this into a new componenent, so a
            // refresh will happen. Technically a ref could be used to refresh without this trick.
            key={ewsURL}
            url={ewsURL}
            opacity={0.5}
            maxNativeZoom={10}
            bounds={nlBounds}
          />
          <EWSDateSelector />
        </Overlay>
      )}
      <Overlay name="Precipitation deficit" checked={(layers || []).includes("precip_deficit")}>
        <TileLayer
          url={`https://d2c.crop-r.com/precip_deficit/tile/NL/${precipitationDay}/{z}/{x}/{y}.png`}
          tms={true}
          opacity={0.5}
          maxNativeZoom={10}
          bounds={nlBounds}
        />
      </Overlay>
      <Overlay name="Kadaster" checked={(layers || []).includes("cadastre")}>
        <WMSTileLayer
          url="https://service.pdok.nl/kadaster/kadastralekaart/wms/v5_0"
          minZoom={16}
          minNativeZoom={17}
          opacity={1}
          format="image/png"
          transparent={true}
          layers="Kadastralekaart"
          {...pdokOpts}
        />
      </Overlay>
      <Overlay name="Hoogte" checked={(layers || []).includes("dem")}>
        <WMSTileLayer
          url="//service.pdok.nl/rws/ahn/wms/v1_0"
          name="Algemene Hoogtekaart Nederland"
          opacity={0.5}
          layers="dtm_05m"
          {...pdokOpts}
        />
      </Overlay>
    </>
  );
};
