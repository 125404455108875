import { push } from "connected-react-router";
import L from "leaflet";
import React, { useCallback, useEffect } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { useDispatch } from "react-redux";
import { fetchFarms } from "../../actions";
import { useSSelector } from "../../util";
import { useZoom, usePanToFoundLocation } from "../effects/effects";

import imgMapPinClients from "../../../img/map-pin-clients.png";
import imgMapPinClients2 from "../../../img/map-pin-clients@2x.png";
import imgTractorPin from "../../../img/tractor-pin-r.png";

import { config } from "../../Config";

export const FarmMarkers = () => {
  const farms = useSSelector((state) => state.farms);
  const pathname = useSSelector((state) => state.router.location.pathname);
  const zoomLevel = useZoom();
  const dispatch = useDispatch();

  const renderFarmMarker = useCallback(
    (farm: any) => {
      const handleClick = () => {
        let path = `/farm/${farm.id}/${farm.name}`;
        if (pathname === path) {
          path = "/";
        }
        dispatch(push(path));
      };

      if (farm.lat && farm.lng && farm.public) {
        return (
          <Marker
            key={farm.id}
            position={[farm.lat, farm.lng]}
            icon={icons.farm}
            title={`${farm.name} (${farm.city})`}
            opacity={0.7}
            eventHandlers={{
              click: (e) => handleClick(),
            }}
          />
        );
      }
    },
    [dispatch, pathname]
  );

  useEffect(() => {
    dispatch(fetchFarms());
  }, [dispatch]);

  if (typeof farms === "string") {
    return null;
  }

  if (zoomLevel < 9) {
    return null;
  }

  return (
    <>
      {Object.keys(farms).map((id) => {
        return renderFarmMarker(farms[id]);
      })}
    </>
  );
};

export const CurrentLocationMarker = () => {
  const latlng = useSSelector((state) => state?.currentLocation?.latlng || null);
  const dispatch = useDispatch();

  const handleLocationFound = useCallback(
    (e) => {
      const b = config.bounds;
      if (!e.latlng || !L.latLngBounds([b[1], b[0]], [b[3], b[2]]).contains(e.latlng)) {
        return;
      }
      // TODO: rewrite action and reducer, way too much parsing is needed here.
      dispatch({ type: "SET_LOCATION", location: { latlng: e.latlng } });
    },
    [dispatch]
  );

  useMapEvents({
    locationfound: handleLocationFound,
  });

  if (!latlng) {
    return null;
  }
  return <Marker position={latlng} icon={icons.tractor} />;
};

export const SearchResultMarker = () => {
  const searchLocation = useSSelector((state) => state.quickSearchResults);
  usePanToFoundLocation();

  if (!searchLocation) {
    return null;
  }

  return <Marker position={searchLocation} />;
};

const icons = {
  farm: L.icon({
    iconUrl: imgMapPinClients,
    iconRetinaUrl: imgMapPinClients2,
    iconSize: [42, 48],
    iconAnchor: [24, 48],
  }),
  tractor: L.icon({
    iconUrl: imgTractorPin,
    iconSize: [46, 53],
    iconAnchor: [23, 53],
  }),
};
