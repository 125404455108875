import { Card, ListGroup } from "react-bootstrap";
import i18n from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useSSelector } from "../../util";
import { config } from "../../Config";

const Tab = ({ children, ...props }) => (
  <Link {...props}>
    <ListGroup.Item className="myfarm-tab">{children}</ListGroup.Item>
  </Link>
);

export const DefaultTab = ({ selectedTabName, url }) => {
  const showLease =
    useSSelector((s) => s.activeUser?.settings?.showLease) && config.show.includes("lease");
  return (
    <>
      <Card className="mt-4 mx-2">
        <Card.Body>
          <Card.Text>
            {i18n.t("Make your farm and fields visible for all CropFields users.")}
          </Card.Text>
          <Link variant="primary" className="btn btn-success" to="/my_farm/settings">
            {i18n.t("Manage public profile")}
          </Link>
        </Card.Body>
      </Card>
      <Card className="mt-4 mx-2">
        <ListGroup variant="flush" activeKey={selectedTabName}>
          <Tab to={`${url}/overview`}>{i18n.t("Farm profile")}</Tab>
          <Tab to={`${url}/plans`}>{i18n.t("Plan")}</Tab>
          {showLease && <Tab to={`${url}/lease`}>{i18n.t("Lease")}</Tab>}
        </ListGroup>
      </Card>
    </>
  );
};
