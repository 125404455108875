import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "../../../components/IconButton";
import { showSubscriptionModal } from "../../../components/Pro";
import { accountFeatures, getCenter, useSSelector } from "../../../util";
import { LikeButton } from "./LikeButton";
import { OtherMenu } from "./OtherMenu";
import { ShareButton } from "./ShareButton";

type Props = {
  returnUrl: string;
  liked?: boolean;
  location?: any;
  fieldKey?: any;
  cropfield?: any;
};

export const HeaderButtons = (props: Props) => {
  const { returnUrl, liked, location, fieldKey, cropfield } = props;
  const dispatch = useDispatch();
  const isPro = useSSelector((state) => accountFeatures(state.activeUser.account).pro);
  const close = useCallback(() => dispatch(push("/")), [dispatch]);
  const goBack = useCallback(() => dispatch(push(`${returnUrl}`)), [returnUrl, dispatch]);

  const openMap = useCallback(() => {
    if (!isPro) {
      showSubscriptionModal(dispatch, undefined, "navigate");
      return;
    }
    window.open(
      `https://maps.apple.com/?daddr=${
        location || getCenter(cropfield, null, 5).reverse().join(",")
      }`
    );
  }, [cropfield, dispatch, isPro, location]);

  return (
    <div className="header--button-container">
      <div className="button-group">
        {returnUrl && <IconButton icon="arrow-left" onClick={goBack} />}
        <LikeButton liked={liked} location={location} fieldKey={fieldKey} />
        <IconButton icon="map-signs" onClick={openMap} />
        <ShareButton />
        <OtherMenu className="d-none d-md-block" cropfield={cropfield} />
      </div>
      <div className="button-group">
        <IconButton icon="times" onClick={close} />
      </div>
    </div>
  );
};
