import { config } from "../Config";
import Cookies from "universal-cookie";
import { track } from "../track";
import { accountFeatures, TS, useSSelector } from "../util";
import bertHank from "../../img/logo/bert-hank_abo.png";
import React, { useEffect } from "react";
import { SubscribeModalClicks } from "../modals";

export const RestrictedContent = ({ Component, ...props }) => {
  const [account, field] = useSSelector(({ selection, activeUser }) => [
    activeUser.account,
    selection.field,
  ]);
  const maxAllowedFreeClicks = config.MAX_FREE_CLICKS;
  const cookies = new Cookies();
  const clicks = cookies.get("clicks") || [];
  const bbCookieAccepted = cookies.get("bb_cookie_accepted");
  const features = accountFeatures(account);
  const showModal =
    !features.pro &&
    (!bbCookieAccepted || clicks.length > maxAllowedFreeClicks) &&
    (clicks.length - 1) % maxAllowedFreeClicks === 0;

  useEffect(() => {
    if (showModal && field.feature.id) {
      track("modal", "subscription", "proClick");
    }
  }, [field, showModal]);

  const children = <Component {...props} />;
  if (features.pro || !field.feature.id) return children;

  const plotId = parseInt(field.feature.id.split("-").slice(-1)[0]);
  const plotIndex = clicks.indexOf(plotId);
  if (
    bbCookieAccepted &&
    ((plotIndex >= 0 && plotId < maxAllowedFreeClicks) || clicks.length <= maxAllowedFreeClicks)
  )
    return children;
  if (config.bb_site.beta) {
    return (
      <main className="sections">
        <section id="section_subscription">
          <TS t="restrictedAccount" />
          <a href={config.bb_orderPage}>
            <img alt="Bert&Hank" width="150" src={bertHank} />
          </a>
        </section>
        <SubscribeModalClicks bbCookieAccepted={bbCookieAccepted} show={showModal} />
      </main>
    );
  }
  return (
    <main className="sections">
      <section id="section_subscription">
        <TS
          t={
            'Subscribe to view this data. CropFields is a website for farmers. It is used by many users for free. This is great, but we would like to make the site even better. Help us by <a href="{{url}}">subscribing to CropFields</a>.'
          }
          o={{
            // eslint-disable-next-line camelcase
            url: `${config.bb_orderPage}`,
          }}
        />
        <a href={config.bb_orderPage}>
          <img alt="Bert&Hank" width="150" src={bertHank} />
        </a>
      </section>
      <SubscribeModalClicks bbCookieAccepted={bbCookieAccepted} show={showModal} />
    </main>
  );
};
