import React from "react";
import { getCode } from "../../util";
import { TopPolygon } from "../map_util";

type NaturePolygonsProps = {
  field: any;
  section: string;
};
export const NaturePolygons = (props: NaturePolygonsProps) => {
  const { field, section } = props;
  const baseFeatures = getBaseFeatures(field, section);
  // TODO: check if i is unique and consistent.
  const polygons = baseFeatures?.map((feature) => (
    <TopPolygon
      key={feature.id}
      feature={feature}
      fillOpacity={1}
      fillColor={getColor(feature, field, section)}
      stroke={false}
    />
  ));

  return <>{polygons}</>;
};

function getBaseFeatures(field: any, section: string): any[] {
  if (section === "nature") {
    return [
      ...(field?.natura_2000_buffer?.features || []),
      ...(field?.natura_2000?.features || []),
    ];
  }
  return field[section]?.features || [];
}

function getColor(feature: any, field: any, section: string): string {
  return getCode(feature.properties.code, "color") || "#cc0000";
}
