import React, { useCallback, useEffect, useMemo, useRef } from "react";
import i18n from "i18next";
import { PanelHeader } from "../panels";
import { accountFeatures, getCode, TS, useSSelector } from "../util";
import { Helmet } from "react-helmet";
import { ShareFooter } from "../panels/Share";
import { useDispatch } from "react-redux";
import { fetchSelection } from "../actions";
import { track } from "../track";
import { ConfigurableLabel } from "../components";
import { config } from "../Config";
import { push } from "connected-react-router";
import { Tag } from "../panels/list/Tag";
import { StaticMap } from "../map/map_util";

const MultiSelectOptions = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className="row">
        <button
          className="btn btn-deselect-likes"
          onClick={() => {
            dispatch(push("/select"));
            dispatch({ type: "DESELECT" });
          }}
        >
          <span className="fa fa-minus-circle" />
          {i18n.t("Unselect plots")}
        </button>
      </div>
    </div>
  );
};

export const Selection = (props) => {
  const { gps, select } = props;

  const dispatch = useDispatch();
  const gpsRef = useRef(null);
  const { selectedFields, multiSelect, hasProAccount, showFieldSelectionTool } = useSSelector(
    ({ selection, multiSelect, activeUser }) => ({
      selectedFields: selection.selectedFields || [],
      multiSelect,
      hasProAccount: activeUser?.account?.features?.pro || false,
      showFieldSelectionTool: Boolean(activeUser.settings?.useFieldSelectionTool),
    })
  );

  useEffect(() => {
    if (gps === gpsRef.current) return; // effect on change of gps
    gpsRef.current = gps;
    if (!gps) {
      return;
    }
    track("selection", "view", gps);
    const points = gps.split(";");
    if (points.length !== selectedFields.length) {
      dispatch(fetchSelection(gps));
    }
  }, [gps, selectedFields, select, dispatch]);

  useEffect(() => {
    if (!multiSelect)
      dispatch({
        type: "SET_MULTI_SELECT",
        multiSelect: true,
      });
  }, [multiSelect, dispatch]);

  return (
    <article className="mod_bb mod_bb_my_farm active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Selection")}</title>
      </Helmet>
      <main className="bb_selection">
        <PanelHeader onHide={() => select()}>
          {i18n.t("Selected plots")}
          <ConfigurableLabel inHeader={true} />
        </PanelHeader>
        {hasProAccount || showFieldSelectionTool ? (
          <div>
            <MultiSelectOptions />
            {selectedFields?.map((selectedfield, i) => (
              <Item key={i} bunder={selectedfield} />
            ))}
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="col-md">
                <p>
                  <TS
                    t="SubscribeToSeeSharedData"
                    o={{ href: `${config.bb_productPage}#packages` }}
                  />
                </p>
              </div>
            </div>
          </div>
        )}
      </main>
      {(hasProAccount || showFieldSelectionTool) && selectedFields.length ? (
        <ShareFooter
          text={{
            thisSubject: i18n.t("this field selection"),
            subject: i18n.t("Field selection"),
          }}
          collection={selectedFields}
        />
      ) : null}
    </article>
  );
};

type ItemProps = {
  bunder: any;
};

const Item = (props: ItemProps) => {
  const { bunder } = props;
  const isPro = useSSelector((state) => accountFeatures(state.activeUser.account).pro);
  const dispatch = useDispatch();

  const area = useMemo(() => {
    const decimals = isPro ? 4 : 0;
    const area = bunder.feature.properties.area;

    const fixedArea = area ? area.toFixed(decimals) : "";
    return isPro ? `${fixedArea} ha` : ` ~${fixedArea} ha`;
  }, [bunder.feature.properties, isPro]);

  const cropName = useMemo(() => {
    const cropCode = bunder?.feature?.properties?.crop_code;
    if (!cropCode) {
      return "";
    }

    return getCode(cropCode)?.name || "";
  }, [bunder?.feature?.properties?.crop_code]);

  const handleClick = useCallback(() => {
    dispatch(push(`/plot/${bunder.point}`));
  }, [bunder.point, dispatch]);

  const renderImage = () => (
    <StaticMap className="media-object align-self-center" gps={bunder.point} size={60} />
  );

  return (
    <div className={`c-bunder-list-item`} onClick={handleClick}>
      <div className="c-bunder-list-item__image">{renderImage()}</div>
      <div className="c-bunder-list-item__content">
        <div className="c-bunder-list-item__title">{cropName}</div>
        <Tag title={area} />
      </div>
    </div>
  );
};
