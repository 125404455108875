import React from "react";
import { Button, Modal } from "react-bootstrap";
import i18n from "i18next";
import { Icon } from "react-fa";
import { A, numberFormat as fmt, useSSelector } from "../util";
import { useDispatch } from "react-redux";
import { LatLngLiteral } from "leaflet";

interface ExportModalProps {
  filter: string;
  center: LatLngLiteral;
  hitsCount: number;
  onHide: () => void;
}

const Row = ({ title, value }) => (
  <tr>
    <td>{title}:</td>
    <td align="right">{fmt(value)}</td>
  </tr>
);

export const ExportModal = ({ filter, center, hitsCount, onHide }: ExportModalProps) => {
  const dispatch = useDispatch();
  const { exports } = useSSelector(({ activeUser }) => ({
    exports: activeUser?.account?.exports || {},
  }));

  const exportsLeft = exports.total - exports.count,
    currentExportSize = Math.min(exports.size, hitsCount, exportsLeft);
  return (
    <Modal
      show={true}
      onHide={onHide}
      aria-labelledby="contained-modal-title"
      className="mod_bb_facet_modal"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">{i18n.t("Search filter exports")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="col-xs-3">
            <Icon
              name="download"
              style={{ fontSize: "80px", color: "#139CC2", paddingTop: "5px" }}
            />
          </div>
          <div className="col-xs-9">
            <p style={{ paddingTop: "20px" }}>{i18n.t("TextDownloadSearchFilter")}</p>
          </div>
          <div>
            <table className="bb-nr-specification table table-striped">
              <tbody>
                <Row title={i18n.t("Max annual fields export")} value={exports.total} />
                <Row title={i18n.t("Max fields in single export")} value={exports.size} />
                <Row title={i18n.t("Exported field count")} value={exports.count} />
                <Row title={i18n.t("Field export capacity")} value={exportsLeft} />
                <Row title={i18n.t("Fields in this export")} value={currentExportSize} />
                <Row
                  title={i18n.t("Export capacity after this export")}
                  value={exportsLeft - currentExportSize}
                />
              </tbody>
            </table>
          </div>
          {currentExportSize < hitsCount ? (
            <p>
              <span className="attention">
                {i18n.t("Attention! This export only contains a part of the search result")}
              </span>
              .{" "}
              {i18n.t(
                "It concerns the first {{count}} fields measured from the center of the map (GPS location {{gps}})",
                {
                  count: fmt(currentExportSize),
                  gps: `${center.lat.toFixed(5)},${center.lng.toFixed(5)}`,
                }
              )}
            </p>
          ) : null}
          <p>
            {currentExportSize < hitsCount ? (
              <Button onClick={onHide}>
                <Icon name="chevron-left" /> {i18n.t("Make selection smaller")}
              </Button>
            ) : null}
            &nbsp;
            <A
              onClick={() => {
                dispatch({ type: "EXPORTS_DECREASE", decreaseCount: currentExportSize });
              }}
              href={`/export/filter/${encodeURIComponent(JSON.stringify(filter))}?center=${
                center.lng
              },${center.lat}`}
              className="btn btn-success"
            >
              {i18n.t("Export {{count}} fields", { count: fmt(currentExportSize) })}
            </A>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{i18n.t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
};
