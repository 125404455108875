import { bbAxios, toWKT } from "../util";
import { config } from "../Config";

export const updateWater = (props) => (dispatch) => {
  dispatch({ type: "UPDATE_WATER", ...props });
};

function mapWaterFeatures(features) {
  // filter duplicate ids, add attribute isShore
  const ids = {};
  const result = [];
  for (const f of features) {
    if (ids[f.id]) continue;
    ids[f.id] = f;
    f.properties.isShore = ["oever, slootkant", "slik"].includes(f.properties.name);
    result.push(f);
  }
  return result;
}

export const getWater = (cropfield, intersectionBuffer = 1, buffer = 1) => {
  const type = "FETCH_WATER_DATA";
  return (dispatch, getState) => {
    dispatch({ type, state: "fetching" });
    bbAxios
      .post(
        `${config.LAYER_URL}/waterreference/intersect?` +
          new URLSearchParams({
            // eslint-disable-next-line camelcase
            intersection_buffer: String(intersectionBuffer),
            buffer: String(buffer),
          }),
        toWKT(cropfield.geometry)
      )
      .then(({ data }) => {
        data.features = mapWaterFeatures(data.features);
        dispatch({ type, state: "fetched", data });
      })
      .catch((err) => {
        dispatch({ type, state: "failed" });
      });
  };
};
