import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useSSelector } from "../../util";

import L from "leaflet";

export const usePanSelection = () => {
  const selection = useSSelector((state) => state.selection);
  const map = useMap();

  useEffect(() => {
    // TODO: this logic is duplicated in fieldSelection, maybe a utils function would be better
    const smallScreen = window.innerWidth < 768;

    if (!selection.field?.feature || !selection.field) {
      return;
    }
    let bounds = L.geoJSON(selection.field.feature).getBounds();
    let mapBounds = map.getBounds();

    if (!mapBounds.contains(bounds)) {
      map.fitBounds(bounds, {
        paddingTopLeft: [smallScreen ? 0 : 600, 0],
        maxZoom: map.getZoom() + 1,
      }); // maximaal 1 zoomlevel inzoomen
    } else if (!smallScreen) {
      const c = bounds.getNorthWest(); // check if
      const p = map.latLngToContainerPoint(c);
      if (p.x < 530) {
        map.panBy([p.x - 600, p.y < 100 ? -100 : 0]);
      }
    }
  }, [map, selection]);

  return;
};
