import { SimpleMap } from "../../map";
import { config } from "../../Config";
import i18n from "i18next";
import React, { useCallback, useEffect, useRef } from "react";
import L from "leaflet";
import { ImageOverlay } from "react-leaflet";
import { SatCardProps } from "../sections/Sections";
import { useDispatch } from "react-redux";
import { fetchGrowthDataByYear, updateSatelliteImage } from "../../actions";
import moment from "moment/moment";
import { accountFeatures, useSSelector } from "../../util";
import scrollIntoView from "scroll-into-view-if-needed";

const getYearPosition = (growthData, index, satData) => {
  return index === 0 || growthData[index - 1].key.substr(0, 4) !== satData.key.substr(0, 4)
    ? "l"
    : index === growthData.length - 1 ||
      growthData[index + 1].key.substr(0, 4) !== satData.key.substr(0, 4)
    ? "r"
    : "";
};

export const SatelliteImages = ({ growth, active, smallScreen, date, cropfield }) => {
  const { showGrowthCourse } = useSSelector(({ activeUser }) => ({
    showGrowthCourse: Boolean(activeUser.settings?.showGrowthCourse),
  }));

  const bounds = L.geoJSON(cropfield).getBounds();
  const img = growth.imgkeyData;

  if (showGrowthCourse && growth.data && growth.imageType) {
    return (
      <SatCards
        active={active}
        smallScreen={smallScreen}
        growth={growth}
        date={date}
        cropfield={cropfield}
      />
    );
  }
  if (smallScreen && img) {
    return (
      <div className="keymap">
        <SimpleMap feature={cropfield}>
          <ImageOverlay url={`${config.SATELLITE_URL}/download_images/${img}`} bounds={bounds} />
        </SimpleMap>
        <p className="legend">
          {i18n.t("Satellite image date")}: {date}
        </p>
      </div>
    );
  }
  return null;
};

const SatCards = ({ active, smallScreen, growth, date, cropfield }) => {
  const satDataKey = growth.satDataKey || "ndvi";
  const growthData = growth.data[satDataKey];
  const loading = growth.imgState === "fetching";
  const dispatch = useDispatch();
  const startYear = growthData[0] && growthData[0].key.substr(0, 8);
  const onClick = useCallback(
    () => dispatch(fetchGrowthDataByYear(growth, satDataKey.toUpperCase(), startYear)),
    [dispatch, growth, satDataKey, startYear]
  );
  const premium = useSSelector((s) => !!accountFeatures(s.activeUser.account)?.premium);

  if (!startYear) return null;
  return (
    <div
      className="thumbnails"
      style={{
        display: active || smallScreen ? "block" : "none",
      }}
    >
      <div className="card-container">
        {premium && (
          <button className="btn btn-success" onClick={onClick} disabled={loading}>
            {loading ? (
              <i className="far fa-solid fa-circle-o-notch fa-spin" />
            ) : (
              <i className="far fa-solid fa-plus" />
            )}
          </button>
        )}
        {growthData.map((d, i) => (
          <SatCard
            data={d}
            showYear={getYearPosition(growthData, i, d)}
            key={d.unix}
            selected={date === d.key}
            fieldId={growth.fieldId}
            imageType={growth.imageType}
            cropfield={cropfield}
          />
        ))}
      </div>
    </div>
  );
};

const SatCard = ({ data, showYear, selected, fieldId, imageType, cropfield }: SatCardProps) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    () => dispatch(updateSatelliteImage(cropfield, { key: data.unix, imageType: imageType })),
    [dispatch, data, imageType, cropfield]
  );
  const myRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selected && myRef.current) {
      scrollIntoView(myRef.current, {
        block: "nearest",
        inline: "center",
        scrollMode: "always",
        behavior: "smooth",
      });
    }
  }, [selected, fieldId]);
  return (
    <div ref={myRef} className="card float-right">
      <div className="image-year" style={{ textAlign: showYear === "r" ? "right" : "left" }}>
        {showYear ? data.key.substr(0, 4) : ""}
      </div>
      <div
        className="image-card"
        style={{ borderColor: selected ? (data.valid ? "#006e4b" : "#ff695b") : "" }}
        onClick={onClick}
      >
        <img
          alt={moment.unix(data.unix).format("D MMM")}
          src={`${config.SATELLITE_URL}/download_images/${fieldId}_${data.key}_${imageType}.png`}
        />
        <div className="image-date" style={{ color: !data.valid ? "#ff695b" : "" }}>
          {moment.unix(data.unix).format("D MMM")}
        </div>
      </div>
    </div>
  );
};
