import { RefinementListFilter, renderComponent } from "searchkit";
import { MyCheckboxItemList } from "./MyCheckboxItemList";
import { getTitle } from "./util";

export class MyRefinementListFilter extends RefinementListFilter {
  render() {
    if (!this.accessor) return null;
    const {
      containerComponent,
      showCount,
      id,
      countFormatter,
      collapsable,
      defaultCollapsed = true,
    } = this.props;
    const listComponent = MyCheckboxItemList;
    return renderComponent(
      containerComponent,
      {
        title: getTitle.call(this),
        className: id ? `filter--${id}` : undefined,
        disabled: !this.hasOptions(),
        collapsable, // dit zijn ze als enige vergeten, even een pull-request van maken
        defaultCollapsed,
      },
      [
        renderComponent(listComponent, {
          key: "listComponent",
          items: this.getItems(),
          itemComponent: this.props.itemComponent,
          selectedItems: this.getSelectedItems(),
          toggleItem: this.toggleFilter.bind(this),
          setItems: this.setFilters.bind(this),
          docCount: this.accessor.getDocCount(),
          showCount,
          translate: this.translate,
          countFormatter,
        }),
        this.renderShowMore(),
      ]
    );
  }
}
