import { config } from "../../Config";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import i18n from "i18next";
import memoize from "lodash/memoize";
import { getBounds } from "../map_util";
const { BaseLayer, Overlay } = LayersControl;

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  soil: i18n.t("Soil type"),
  cadastre: i18n.t("Cadastre"),
  permanent_grass: i18n.t("Permanent grassland"),
  dem: i18n.t("Altitude"),
  // gwp: i18n.t("Ground water protection"),
  n2k: "Natura 2000",
}));

const ref = memoize(() => ({
  tms: true,
  type: "data",
  maxNativeZoom: 18,
  maxZoom: MAX_ZOOM,
  bounds: getBounds(config.bounds),
}));

const opts = {
  attribution: '<a href="https://map.geoportail.lu/">geoportail.lu</a>',
  maxZoom: MAX_ZOOM,
  maxNativeZoom: 20,
  subdomains: "1234",
  zIndex: 50,
};

const orthoYears = ["2022", "2021", "2020", "2019", "2018", "2017", "2016", "2013", "2010", "2007"];

// see https://wmts1.geoportail.lu/opendata/demo/#
// see https://data.public.lu/en/datasets/
// https://wms.inspire.geoportail.lu/geoserver/lc/wms?SERVICE=WMS&service=WMS&version=1.3.0&request=GetCapabilities
export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=LU";
  const refp = ref();
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      {orthoYears.map((year) => (
        <BaseLayer key={year} name={`${i18n.t("Orthophoto")} ${year}`}>
          <TileLayer
            url="https://wmts{s}.geoportail.lu/opendata/wmts/{layer}/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.jpeg"
            layer={`ortho_${year}`}
            {...opts}
          />
        </BaseLayer>
      ))}
      <BaseLayer name={`Topo`}>
        <TileLayer
          url="https://wmts{s}.geoportail.lu/opendata/wmts/{layer}/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png"
          layer={`topo`}
          {...opts}
        />
      </BaseLayer>
      <BaseLayer name="Google maps">
        <GoogleLayer maptype="HYBRID" googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y" />
      </BaseLayer>
      <Overlay name="Grundstucke O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} opacity={0.5} type="data" src="142" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Cadastre")} checked={layers.includes("cadastre")}>
        <TileLayer
          url="https://wmts{s}.geoportail.lu/opendata/wmts/cadastre/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png"
          // url="https://wmts{s}.geoportail.lu/mapproxy_4_v3/wmts/{layer}/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png"
          layer="parcels_labels"
          minZoom={16}
          {...opts}
        />
      </Overlay>
      <Overlay name={i18n.t("Altitude")} checked={layers.includes("dem")}>
        <TileLayer
          url="https://wmts{s}.geoportail.lu/opendata/wmts/{layer}/GLOBAL_WEBMERCATOR_4_V3/{z}/{x}/{y}.png"
          layer="lidar_2019_mnt_public"
          {...opts}
        />
      </Overlay>
      <Overlay name={i18n.t("Permanent grassland")} checked={layers.includes("permanent_grass")}>
        <WMSTileLayer
          url="https://wms.inspire.geoportail.lu/geoserver/lc/wms"
          layers="LC.LandCoverSurfaces_permanent_grassland"
          format="image/png"
          transparent={true}
          maxNativeZoom={19}
          maxZoom={20}
        />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl + "&mask=CNTRY.LU"} opacity={0.5} src="57" {...refp} />
      </Overlay>
    </LayersControl>
  );
});
