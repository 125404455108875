/*eslint camelcase: ["error", {allow: ["place_id", "matched_substrings"]}]*/
import { proj4 } from "../../util";
import $ from "jquery";
import { config } from "../../Config";

type Coordinates = [number, number];
type BBOX = [number, number, number, number];
const inBounds = (coordinates: Coordinates, bbox: BBOX) =>
  coordinates[0] >= bbox[0] &&
  coordinates[0] <= bbox[1] &&
  coordinates[1] >= bbox[2] &&
  coordinates[1] <= bbox[3];

const mapBounds = {
  NL: { bbox: [0, 300000, 300000, 700000], srid: "EPSG:28992" },
  BE: { bbox: [17736, 297289, 23697, 245375], srid: "EPSG:31370" },
  DK: { bbox: [120000, 1000000, 5900000, 6500000], srid: "EPSG:25832" },
  WA: { bbox: [42270, 295156, 21162, 167589], srid: "EPSG:31370" },
  FR: { bbox: [-378305, 1212610, 6093283, 7186901], srid: "EPSG:2154" },
  DE_NW: { bbox: [266047, 5555511, 537972, 5833047], srid: "EPSG:25832" },
  DE_NI: { bbox: [342771, 5682911, 674202, 5971589], srid: "EPSG:25832" },
  DE_SH: { bbox: [445234, 5901508, 660949, 6115031], srid: "EPSG:25832" },
  AT: { bbox: [107724, 284970, 680575, 575953], srid: "EPSG:31287" },
  LU: { bbox: [48225, 56225, 105842, 139616], srid: "EPSG:2169" },
  __: { bbox: [-180, -90, 180, 90], srid: "EPSG:4326" },
};

class GPSPlugin {
  geocode(p) {
    const m = /^(GPS|XY) ([+-]?[\d.]+)[,\s]+([+-]?[\d.]+)/.exec(p);
    if (m) {
      let coordinates: [number, number] = [parseFloat(m[2]), parseFloat(m[3])];
      if (m[1] === "XY") {
        const country = mapBounds[config.bb_country];
        coordinates = proj4(country.srid).inverse(coordinates).reverse();
      }
      return Promise.resolve({
        location: { lat: coordinates[0], lng: coordinates[1] },
        zoom: 15,
      });
    }
  }
  suggests(input, props?) {
    if (input && /^(GPS\s?)?[-\d\s,.]*$/.exec(input)) {
      let m = /(?:GPS\s?)?([-\d.]+)[,\s]+([-\d.]+)/.exec(input);
      if (m) {
        let coordinates: [number, number] = [parseFloat(m[1]), parseFloat(m[2])];
        const country = mapBounds[config.bb_country];
        let location;
        if (inBounds(coordinates, [-90, 90, -180, 180])) {
          location = "GPS " + coordinates.map((n) => n.toFixed(5)).join(",");
        } else if (country && inBounds(coordinates, country.bbox)) {
          location = "XY " + coordinates.map((n) => n.toFixed()).join(",");
          coordinates = proj4(country.srid).inverse(coordinates).reverse();
        }
        if (location) {
          return Promise.resolve([
            {
              place_id: location,
              description: location,
              label: location,
              location: coordinates,
              zoom: 15,
              matched_substrings: [{ offset: 0, length: 0 }],
            },
          ]);
        }
      }
      if (!/^\d{4}([ \w]|\b)/.test(input)) return Promise.resolve(); // do not capture zipcodes
    }
  }
}

class PDOKPlugin {
  // see https://github.com/PDOK/locatieserver/wiki/API-Locatieserver
  geocode(p) {
    if (p && p.startsWith("pdok-")) {
      return new Promise((resolve, reject) =>
        $.getJSON(
          "https://api.pdok.nl/bzk/locatieserver/search/v3_1/lookup?id=" + p.substring(5),
          (data) => {
            if (data.response.numFound > 0) {
              const m = /([\d.]+) ([\d.]+)/.exec(data.response.docs[0].centroide_ll);
              if (m) {
                return resolve({
                  location: { lat: parseFloat(m[2]), lng: parseFloat(m[1]) },
                  zoom: 15,
                });
              }
            }
            reject("No results");
          }
        ).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }

  suggests(input, props) {
    if (input?.length > 2) {
      const loc = props.location;
      return new Promise((resolve, reject) =>
        $.getJSON(
          "https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest?fq=-type:gemeente&q=" +
            input +
            (loc ? `&lat=${loc.lat}&lon=${loc.lng}` : "")
        )
          .done((data) =>
            resolve(
              data.response.docs.map((s) => ({
                place_id: `pdok-${s.id}`,
                description: s.weergavenaam,
                label: data.highlighting[s.id],
                zoom: 15,
                matched_substrings: [{ offset: 0, length: 0 }],
              }))
            )
          )
          .fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }
}

class DKPlugin {
  // see https://dawa.aws.dk/adgangsadresser/autocomplete?q=horsens&type=adgangsadresse&side=1&per_side=7&noformat=1
  // see https://kortforsyningen.kms.dk/Geosearch?service=GEO&limit=40&resources=kommuner%2Cmatrikelnumre%2Copstillingskredse%2Cpolitikredse%2Cpostdistrikter%2Cregioner%2Cretskredse%2Csogne&search=horse&login=kortviser2&password=kfbruger1

  // Per 31-8 vervangen
  // https://docs.dataforsyningen.dk/#request-syntax
  // https://trello.com/c/URyuB8L6/177-opdatering-20042023-geosearch-lukker-31082023-anvend-i-stedet-gsearch

  geocode(p) {}

  suggests(input, props) {
    if (input?.length > 2) {
      return new Promise((resolve, reject) =>
        $.get({
          url: "https://api.dataforsyningen.dk/rest/gsearch/v1.0/adresse",
          dataType: "json",
          data: {
            q: input,
            token: config.countries.DK.token,
          },
          success: (data) =>
            resolve(
              data.map((s, i) => {
                const xy = s.geometri.coordinates[0];
                const center = proj4("EPSG:25832").inverse(xy);
                return {
                  place_id: `GPS ${center[1]},${center[0]},${i}`,
                  description: s.visningstekst,
                  label: s.visningstekst,
                  zoom: 15,
                  matched_substrings: [{ offset: 0, length: 0 }],
                };
              })
            ),
        }).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }
}

class GeoPuntBEPlugin {
  // see https://loc.geopunt.be/
  geocode(p) {
    if (p?.startsWith("geopuntbe-")) {
      return new Promise((resolve, reject) =>
        $.ajax({
          url: "https://geo.api.vlaanderen.be/geolocation/v4/Location",
          jsonp: "callback",
          dataType: "jsonp",
          data: {
            q: p.substring(10),
            // type: 'Thoroughfarename,Housenumber,FormattedAddress' // Werkt helaas niet goed
          },
          success: (data) => {
            // const result = data.LocationResult.filter(a => /^crab_(straat|huisnummer)/.test(a.LocationType))[0];
            const result = data.LocationResult[0];
            if (result?.Location) {
              return resolve({
                location: {
                  lat: result.Location.Lat_WGS84,
                  lng: result.Location.Lon_WGS84,
                },
                zoom: 15,
              });
            }
          },
        }).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }

  suggests(input, props) {
    if (input?.length > 2) {
      return new Promise((resolve, reject) =>
        $.ajax({
          url: "https://geo.api.vlaanderen.be/geolocation/v4/Suggestion",
          jsonp: "callback",
          dataType: "jsonp",
          data: {
            c: 10,
            q: input,
          },
          success: (data) =>
            resolve(
              data.SuggestionResult.map((s) => ({
                place_id: `geopuntbe-${s}`,
                description: s,
                label: s,
                zoom: 15,
                matched_substrings: [{ offset: 0, length: 0 }],
              }))
            ),
        }).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }
}

class WAPlugin {
  // see https://geoservices.wallonie.be/geolocalisation/doc/ws/index.xhtml/
  // http://geoservices.wallonie.be/geolocalisation/rest/searchRues/boulevard%20avroy%20liege/
  // getNearestRue == reverse geocoding, searchPosition==geocode van 1 adres
  // http://geoservices.wallonie.be/geolocalisation/rest/searchAll/chaussee%20de%20Charleroi

  geocode(p) {}

  suggests(input, props) {
    if (input?.length > 2) {
      return new Promise((resolve, reject) =>
        $.ajax({
          url: "https://geoservices.wallonie.be/geolocalisation/rest/searchAll/" + input,
          dataType: "json",
          success: (data) =>
            resolve(
              data.resultats.map((s, i) => {
                const coord = s.x ? [s.x, s.y] : [(s.xMin + s.xMax) / 2, (s.yMin + s.yMax) / 2];
                const center = proj4("EPSG:31370").inverse(coord);
                let name = s.adresse || s.nom;
                if (s.type === "RUE")
                  name = `${name} (${s.commune || ""} / ${s.localites[0] || ""})`;
                return {
                  place_id: `GPS ${center[1]},${center[0]},${i}`,
                  description: name,
                  label: s.adresse || s.nom,
                  zoom: 15,
                  matched_substrings: [{ offset: 0, length: 0 }],
                };
              })
            ),
        }).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }
}

class FRPlugin {
  // See https://geoservices.ign.fr/documentation/services/services-geoplateforme/autocompletion
  geocode(p) {}

  suggests(input, props) {
    if (input?.length > 2) {
      return new Promise((resolve, reject) =>
        $.ajax({
          url: `https://data.geopf.fr/geocodage/completion`,
          data: {
            type: "PositionOfInterest",
            text: input,
            maximumResponses: 10,
          },
          dataType: "json",
          success: (data) =>
            resolve(
              data.results.map((s, i) => {
                let name = s.fulltext;
                return {
                  place_id: `GPS ${s.y},${s.x},${i}`,
                  description: name,
                  label: name,
                  zoom: 15,
                  matched_substrings: [{ offset: 0, length: 0 }],
                };
              })
            ),
        }).fail((xhr, status, err) => reject(status + " " + err))
      );
    }
  }
}

class KomootPlugin {
  // see https://photon.komoot.io/
  label(p) {
    switch (`${p.osm_key}:${p.osm_value}`) {
      case "place:city":
        return p.name;
      case "place:postcode":
        return `${p.postcode} ${p.city || p.state || ""}`;
      case "place:house":
      case "building:yes":
        return `${p.street} ${p.housenumber} ${p.city}, ${p.postcode} ${p.state}`;
      default:
        return p.name
          ? `${p.name} ${(p.city && p.city !== p.name && p.city) || ""}`
          : p.city || p.postcode || "";
    }
  }
  geocode(p) {}
  suggests(input, props) {
    if (!(input?.length > 2)) return;

    const loc = props.location; // || config.center;
    const locs = loc ? `&lat=${loc.lat}&lon=${loc.lng}` : "";
    const bbox = config.bounds.map((n) => n.toFixed(2)).join(",");
    const extra = /^\d+$/.test(input)
      ? "&osm_tag=place:postcode&osm_tag=place:town"
      : "&osm_tag=!shop&osm_tag=!landuse&osm_tag=!natural";
    // skip_keys=osm_key:shop
    return new Promise((resolve, reject) =>
      $.getJSON(
        `https://photon.komoot.io/api/?q=${input}${locs}&limit=5&lang=de&bbox=${bbox}${extra}`
      )
        .done((data) => {
          return resolve(
            data.features.map(({ geometry, properties: p }, index) => ({
              place_id: `GPS ${geometry.coordinates
                .reverse()
                .map((n) => n.toFixed(5))
                .join(",")},${index}`,
              description: this.label(p),
              label: this.label(p),
              zoom: 15,
              matched_substrings: [{ offset: 0, length: 0 }],
            }))
          );
        })
        .fail((xhr, status, err) => reject(status + " " + err))
    );
  }
  //
}

// https://www.adressregister.at/adressregister/address/doGeocode?query=wiener&token=223be40d-8bd8-4c64-91a5-6956289baa7c

// Hmmm, mag alleen gebruik worden door de overheid
// https://www.geoportal.nrw/geocodingmap
// https://www.gis-rest.nrw.de/bkg/gdz_ortssuche/suggest?outputformat=json&query=GEl&count=10&filter=bundesland:Nordrhein-Westfalen
// class DENWPlugin {
//   geocode(p) {}
//
//   suggests(input, props) {
//     if (input?.length > 2) {
//       return new Promise((resolve, reject) =>
//         $.ajax({
//           url: "https://www.gis-rest.nrw.de/bkg/gdz_ortssuche/suggest",
//           dataType: "json",
//           data: {
//             outputformat: "json",
//             query: input,
//             count: 5,
//             filter: "bundesland:Nordrhein-Westfalen",
//           },
//           success: (data) => {
//             return resolve([]);
//           },
//         }).fail((xhr, status, err) => reject(status + " " + err))
//       );
//     }
//   }
// }

const countryPlugins = {
  NL: PDOKPlugin,
  BE: GeoPuntBEPlugin,
  DK: DKPlugin,
  WA: WAPlugin,
  FR: FRPlugin,
  DE_NW: KomootPlugin,
  DE_NI: KomootPlugin,
  DE_SH: KomootPlugin,
  AT: KomootPlugin,
  LU: KomootPlugin,
  __: KomootPlugin,
};
export { countryPlugins, GPSPlugin };
