import React, { memo } from "react";
import i18n from "i18next";
import { SectionProps } from "./Sections";
import { Soil, SoilProps } from "../soil/Soil";
import { SectionTitle } from "../util";
import { TS } from "../../util";

export const SectionSoil = memo(
  ({
    active,
    size = "sized",
    field,
    smallScreen,
    SoilComponent = Soil,
  }: SectionProps & { SoilComponent?: React.FunctionComponent<SoilProps> }) => (
    <section id="section_soil" className={`${size}${active ? " active" : ""}`}>
      <SectionTitle name={i18n.t("Soil type")}>
        <TS t="l_info_soil" />
      </SectionTitle>
      <SoilComponent cropfield={field} smallScreen={smallScreen} />
    </section>
  )
);
