import React from "react";
import i18n from "i18next";

import { HitsStats } from "searchkit";
import { Icon } from "react-fa";
import { ExportModal } from "../ExportModal";
import { track } from "../../track";

export class MyHitsStats extends HitsStats {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }
  render() {
    const hitsCount = this.searchkit.getHitsCount(),
      // @ts-ignore
      { features = {}, center } = this.props;
    return (
      <>
        <div className={this.bemBlocks.container()} data-qa="hits-stats">
          <div className={this.bemBlocks.container("info")} data-qa="info">
            {features.export ? (
              <button
                className="btn btn-success"
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                onClick={() => {
                  this.setState({ modal: true });
                  track(
                    "facet",
                    "export_modal",
                    encodeURIComponent(JSON.stringify(this.searchkit.query.getFilters()))
                  );
                }}
              >
                {i18n.t("{{hitsCount}} results", { hitsCount: hitsCount })}&nbsp;
                <Icon name="download" />
              </button>
            ) : (
              <span>{i18n.t("{{hitsCount}} results", { hitsCount: hitsCount })}</span>
            )}
          </div>
        </div>
        {this.state.modal && (
          <ExportModal
            center={center}
            filter={this.searchkit.query.getFilters()}
            hitsCount={hitsCount}
            onHide={() => this.setState({ modal: false })}
          />
        )}
      </>
    );
  }
}
