import React, { useEffect } from "react";
import i18n from "i18next";
import { streetviewImage, useSSelector, followOnSocialMedia } from "../../util";
import { useDispatch } from "react-redux";
import { Carousel } from "react-bootstrap";
import { ShareFooter } from "../Share";
import { FarmPanelHeader } from "./FarmPanelHeader";
import { config } from "../../Config";
import imgSample from "../../../img/sample/sample_farm.png";
import { Helmet } from "react-helmet";
import { Bunder } from "../Bunder";
import { selectFarm } from "../../actions";

export const MissingAddress = () => (
  <span>
    <a href={`${config.DACOM_URL}/cropr/settings/farm/`}>{i18n.t("Set address")}</a>.
  </span>
);

export const PublicFarm = ({ select, id }) => {
  const dispatch = useDispatch();
  const farmId = parseInt(id);
  const { farms } = useSSelector(({ farms }) => ({
    farms,
  }));
  const publicFarm = farms?.[farmId];
  useEffect(() => {
    if (!publicFarm) dispatch(selectFarm(farmId));
  }, [dispatch, publicFarm, farmId]);
  if (!publicFarm || typeof publicFarm === "string") {
    return <article className="mod_bb_farm active">{i18n.t("Loading")}...</article>;
  }
  return (
    <article className="mod_bb mod_bb_farm active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Farm")}</title>
      </Helmet>
      <FarmPanelHeader
        farm={publicFarm}
        userAvatar={""}
        onHide={() => {
          dispatch({ type: "DESELECT" });
          select();
        }}
      />
      <main className="sections">
        <section id="section_facts" className="mb-3">
          <h5 className="text-primary">{i18n.t("About the farm")}</h5>
          <dl>
            <dt>{i18n.t("Name")}</dt>
            <dd>{publicFarm?.name}</dd>
            <dt>{i18n.t("Address")}</dt>
            <dd>{publicFarm?.street || <MissingAddress />}</dd>
            <dt>{i18n.t("Zip code")}</dt>
            <dd>{publicFarm?.zipcode || <MissingAddress />}</dd>
            <dt>{i18n.t("City")}</dt>
            <dd>{publicFarm?.city || <MissingAddress />}</dd>
            {publicFarm?.website && (
              <>
                <dt>{i18n.t("Website")}</dt>
                <dd>{publicFarm.website}</dd>
              </>
            )}
            {publicFarm?.email && (
              <>
                <dt>{i18n.t("Email")}</dt>
                <dd>{publicFarm.email}</dd>
              </>
            )}
          </dl>
          {followOnSocialMedia(publicFarm)}
        </section>

        {publicFarm?.public_list?.fields && (
          <section id="section_bunder">
            <h5 className="text-primary">{i18n.t("Public Plots")}</h5>
            <div className="bb_bunder_container">
              {publicFarm.public_list.fields.map((bunder) => (
                <Bunder key={bunder.id} bunder={bunder} returnUrl={window.location.pathname} />
              ))}
            </div>
          </section>
        )}

        <section id="section_pictures">
          <h5 className="text-primary">{i18n.t("Photos")}</h5>
          <Carousel>
            <Carousel.Item>
              <img alt="Farm" src={streetviewImage(publicFarm, imgSample)} />
              <Carousel.Caption>
                <p>{i18n.t("The farm")}</p>
              </Carousel.Caption>
            </Carousel.Item>
            {publicFarm?.id && (
              <Carousel.Item>
                <img alt={i18n.t("Farm")} src={`/image/farm/${publicFarm?.id}?size=400`} />
              </Carousel.Item>
            )}
          </Carousel>
        </section>
      </main>
      <ShareFooter text={{ thisSubject: i18n.t("this farm"), subject: i18n.t("Farm") }} />
    </article>
  );
};
