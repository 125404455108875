import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CropfieldMap, StaticMap } from "../../../map/map_util";
import { Tag } from "../Tag";
import { accountFeatures, periodString, useSSelector } from "../../../util";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { BunderMenuList } from "./BunderMenuList";
import { ShareModal } from "../../../modals";
import i18n from "i18next";
import { favorite, removeHighlightField, setHighlightField } from "../../../actions";
import { DropdownItem } from "./DropdownItem";
import classNames from "classnames";
import { useClick, useShareMobile } from "../hooks";

interface IBunder {
  area: number;
  period: string;
  crop_name: string;
  key: string;
  point: any;
  type: "PLAN" | "PUBLIC" | "LEASE";
  id: number;
  name?: string;
}

type MenuProps = {
  bunder: IBunder;
  listKey?: string;
  listId: string;
  link: string;
  isPro: boolean;
  setShareModal: (boolean) => void;
};

const BunderListMenu = (props: MenuProps) => {
  const { bunder, listId, listKey, isPro, link, setShareModal } = props;
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const [setMenu, cleanMenu] = useMemo(
    () => [() => setMenuVisible(true), () => setMenuVisible(false)],
    [setMenuVisible]
  );
  const [isEditing, isOwnList] = useSSelector(({ favorites }) => [
    favorites?.editingList === listId,
    favorites.lists?.some((list) => list.key === listKey) || false,
  ]);
  const shareMobile = useShareMobile();
  const downloadShapeFile = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(`/export/shape?p=${bunder.point}`);
      cleanMenu();
    },
    [bunder.point, cleanMenu]
  );

  const shareField = useCallback(
    (e) => {
      e.stopPropagation();
      setShareModal(true);
      cleanMenu();
    },
    [setShareModal, cleanMenu]
  );

  const removeField = useClick(
    (e) => {
      dispatch(favorite(null, listId, [bunder.point], bunder.key));
      cleanMenu();
    },
    [bunder.key, bunder.point, dispatch, listId, cleanMenu]
  );

  if (isEditing) {
    return (
      <button className="btn btn-tertiary c-bunder-list-item__danger" onClick={removeField}>
        <i className={`icon fas fa-trash-alt`} />
      </button>
    );
  }

  return (
    <BunderMenuList
      onCloseMenu={cleanMenu}
      visible={menuVisible}
      onClick={setMenu}
      menu={
        <ul className="c-dropdown__menu">
          <DropdownItem
            title={i18n.t("Share")}
            icon={"share-alt"}
            onClick={(e) => {
              cleanMenu();
              shareMobile(
                e,
                {
                  thisSubject: i18n.t("this field selection"),
                  subject: i18n.t("Field selection"),
                },
                link
              ) || shareField(e);
            }}
          />
          {isPro && (
            <DropdownItem
              title={i18n.t("Download Shapefile")}
              icon={"download"}
              onClick={downloadShapeFile}
            />
          )}
          {isPro && isOwnList && (
            <DropdownItem
              title={i18n.t("Remove")}
              icon={"trash-alt"}
              danger
              onClick={removeField}
            />
          )}
        </ul>
      }
    />
  );
};

const BunderImage = ({ bunder }) =>
  bunder.type === "PUBLIC" ? (
    <StaticMap className="media-object align-self-center" gps={bunder.point} size={60} />
  ) : (
    <CropfieldMap className="media-object align-self-center" id={bunder.id} size={60} />
  );

type Props = {
  className?: string;
  bunder: IBunder;
  listKey: any;
  returnUrl?: string;
  listId: any;
};

export const BunderListItem = (props: Props) => {
  const { className = "c-bunder-list-item", bunder, returnUrl = "" } = props;
  const period = periodString(bunder, "DD/MM/YY");
  const dispatch = useDispatch();
  const [shareModal, setShareModal] = useState(false);
  const link = `${returnUrl}/plot/${bunder.key}`;
  const [isPro, highlightID] = useSSelector(({ activeUser, highlight }) => [
    accountFeatures(activeUser.account).pro,
    highlight.id,
  ]);
  const [bunderClassName, setBunderClassName] = useState("");
  const decimals = isPro ? 4 : 0;
  const fixedArea = bunder.area ? bunder.area.toFixed(decimals) : "";
  const area = `${isPro ? "" : "~"}${fixedArea} ha`;

  const cropName = bunder.crop_name ? bunder.crop_name : bunder.name;

  const onClick = useCallback(() => dispatch(push(link)), [dispatch, link]);

  useEffect(() => {
    setBunderClassName(
      classNames(className, {
        [`c-bunder-list-item--hover`]: highlightID === bunder.id,
      })
    );
  }, [className, highlightID, bunder.id]);

  return (
    <div
      className={`c-bunder-list-item` && bunderClassName}
      onClick={onClick}
      onMouseEnter={() => dispatch(setHighlightField(bunder.id))}
      onMouseLeave={() => dispatch(removeHighlightField())}
    >
      <div className="c-bunder-list-item__image">
        <BunderImage bunder={bunder} />{" "}
      </div>
      <div className="c-bunder-list-item__content">
        <div className="c-bunder-list-item__title">{cropName}</div>
        {period && <Tag title={period} />}
        <Tag title={area} />
      </div>
      <div className="c-bunder-list-item__actions">
        <BunderListMenu
          listId={props.listId}
          bunder={bunder}
          link={link}
          isPro={isPro}
          listKey={props.listKey}
          setShareModal={setShareModal}
        />
        {shareModal && (
          <ShareModal
            text={{
              thisSubject: i18n.t("this field selection"),
              subject: i18n.t("Field selection"),
            }}
            url={link}
            onClose={(e) => {
              e?.stopPropagation();
              e?.preventDefault();
              setShareModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
