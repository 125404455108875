// @ts-nocheck

import i18n from "i18next";
import translationsResources from "./all";
import localResources from "./local/all";
import L from "leaflet";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/da";


export function setupTranslations() {
  const country = window.bb_country || "NL";
  const lng = window.bb_language; //({ BE: "nl", DK: "da", WA: "fr" }[country] || country).toLowerCase();
  i18n.init({
    lng: `${lng}-${country}`,
    resources: Object.assign({}, translationsResources, localResources),
    keySeparator: false,
    nsSeparator: false,
  });
  if (L.drawLocal) {
    L.drawLocal.draw.handlers.polyline.tooltip = {
      start: i18n.t("Click to start measurement"),
      cont: i18n.t("Click on the map to expand the measurement"),
      end: i18n.t(
        "Click on the map to expand the measurement, or click on the last point to finish the measurement"
      ),
    };
  }
  if (L.Control && L.Control.MeasureControl) L.Control.MeasureControl.TITLE = i18n.t("Measure distance");
  moment.locale(lng);
}
