import React from "react";
import { useMapEvents } from "react-leaflet";
import { useDispatch } from "react-redux";
import { Legend } from "../../components";
import { useSSelector, accountFeatures } from "../../util";
import { useZoom } from "../effects/effects";

type LayerHelperProps = {
  // layersComponent: typeof Layers[keyof typeof Layers];
  layersComponent: any;
  layerNames: any;
  showBRP?: boolean;
};

export const LayerHelper = (props: LayerHelperProps) => {
  const { layersComponent: LayersComponent, layerNames, showBRP = false } = props;
  const selectedLayers = useSSelector((state) => state.layers.selected);
  const layerConfig = useSSelector((state) => state.layers.config);
  const features = useSSelector((state) => accountFeatures(state.activeUser.account));
  const dispatch = useDispatch();
  const zoomLevel = useZoom();

  useMapEvents({
    baselayerchange: (e) => {
      dispatch(dispatch({ type: "SET_BACKGROUND", layer: e.name }));
    },
  });

  if (!LayersComponent) {
    return null;
  }

  return (
    <>
      <Legend layerNames={layerNames} />
      <LayersComponent
        showPlotLayer={showBRP && zoomLevel > 11 && zoomLevel < 14}
        layers={selectedLayers}
        accountFeatures={features}
        layerConfig={layerConfig}
      />
    </>
  );
};
