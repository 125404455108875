import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../actions";
import { useLocalStorage } from "../effects/useLocalStorage";
import { Button } from "react-bootstrap";
import moment from "moment";
import { config } from "../Config";
import i18n from "i18next";

type Props = {
  trialEndDate: string;
  className?: string;
};

export const TrialButton = ({ trialEndDate, className }: Props) => {
  const [showTrialExpired, setShowTrialExpired] = useLocalStorage("trialExpired", false);

  const given = moment(trialEndDate, "YYYY-MM-DD");
  const current = moment().startOf("day");
  const remainingDays = given.diff(current, "days") + 1;

  const trialExpired = 1 > remainingDays && remainingDays > -90;

  const dispatch = useDispatch();
  useEffect(() => {
    if (trialExpired && !showTrialExpired) {
      dispatch(showModal({ modal: "trialexpired" }));
      setShowTrialExpired(true);
    }
  }, [dispatch, setShowTrialExpired, showTrialExpired, trialExpired]);

  if (remainingDays < -90) {
    return null;
  }

  return !trialExpired ? (
    <Button className={className} variant="success" href={config.bb_orderPage}>
      <div>{i18n.t("Free Pro")}</div>
      <div>{i18n.t("{{days}} days remaining", { days: remainingDays })}</div>
    </Button>
  ) : (
    <Button className={className} variant="danger" href={config.bb_orderPage}>
      <div>{i18n.t("Free Pro expired")}</div>
      <div>{i18n.t("Click here")}</div>
    </Button>
  );
};
