import React, { memo } from "react";
import { ReportComponentProps } from "./Report";
import { useSSelector } from "../util";
import { getReportComponent } from "./index";

const ReportGlobal = ({ section = "" }: ReportComponentProps) => {
  const field = useSSelector(({ selection }) => selection.field);
  if (!field) return null;
  const Component = getReportComponent(field.country);
  return <Component section={section} />;
};
const m = memo(ReportGlobal);
export { m as ReportGlobal };
