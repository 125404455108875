import { FieldProp, FieldProps, Desc } from "./FieldProps";

export class FRFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "Outils fondamentaux de la politique européenne de préservation de la biodiversité, les sites Natura 2000 visent une meilleure prise en compte des enjeux de biodiversité dans les activités humaines. Ces sites sont désignés pour protéger un certain nombre d’habitats et d’espèces représentatifs de la biodiversité européenne. La liste précise de ces habitats et espèces est annexée à la directive européenne oiseaux et à la directive européenne habitats-faune-flore.",
          e,
          "https://www.natura2000.fr/",
          "Natura 2000"
        )
      ),
      new FieldProp("organic", "organic-img", (e) =>
        Desc("l'Agriculture Biologique en France", e, "https://cartobio.org/", "Biologique")
      ),
    ];
  }
}
