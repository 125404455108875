import { config } from "../../Config";
import React, { memo } from "react";
import { LayersControl, TileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import i18n from "i18next";
import memoize from "lodash/memoize";
import { getBounds } from "../map_util";
const { BaseLayer, Overlay } = LayersControl;

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  gwp: i18n.t("Ground water protection"),
  n2k: "Natura 2000",
}));

const ref = memoize(() => ({
  tms: true,
  type: "data",
  maxNativeZoom: 18,
  maxZoom: MAX_ZOOM,
  bounds: getBounds(config.bounds),
  zIndex: 50,
}));
// https://srvc1.doris.at/arcgis/rest/services/Rasterdaten/Orthofotos/MapServer/tile/3/228/278
// https://maps.wien.gv.at/basemap/1.0.0/WMTSCapabilities.xml
export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=AT";
  const refp = ref();
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name="Geoland Basemap">
        <TileLayer
          url="https://maps.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png"
          maxNativeZoom={20}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://basemap.at">basemap.at</a>'
        />
      </BaseLayer>
      <BaseLayer name={i18n.t("Orthophoto")}>
        <TileLayer
          url="https://maps.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg"
          maxNativeZoom={20}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://basemap.at">basemap.at</a>'
        />
      </BaseLayer>
      <BaseLayer name="Oberflächendarstellung">
        <TileLayer
          url="https://maps.wien.gv.at/basemap/bmapoberflaeche/grau/google3857/{z}/{y}/{x}.jpeg"
          maxNativeZoom={20}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://basemap.at">basemap.at</a>'
        />
      </BaseLayer>
      <BaseLayer name="Google maps">
        <GoogleLayer maptype="HYBRID" googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y" />
      </BaseLayer>
      <Overlay name={`${i18n.t("Fields")} ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Grundstucke O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} opacity={0.5} src="137" {...refp} />
      </Overlay>
      {/*http://srv.doris.at/arcgis/rest/services/Basisdaten/Basisdaten_Spezial/MapServer/5*/}
      <Overlay name={i18n.t("Ground water protection area")} checked={layers.includes("gwp")}>
        <TileLayer url={layerUrl} opacity={0.5} src="138" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} opacity={0.5} src="57" {...refp} />
      </Overlay>
    </LayersControl>
  );
});
