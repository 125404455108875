import React from "react";
import "./Toggle.scss";

interface ToggleProps {
  isToggled: boolean;
  callback: () => any;
  isDisabled?: boolean;
  onClick?: (any) => void;
}

export const Toggle = ({ isToggled, callback, isDisabled = false, onClick }: ToggleProps) => (
  <label className="mod_bb_toggle">
    <input
      type="checkbox"
      checked={isToggled}
      onChange={callback}
      disabled={isDisabled}
      onClick={onClick}
    />
    <span className="slider" />
  </label>
);
