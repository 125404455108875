import React from "react";
import wellknown from "wellknown";
import proj4 from "proj4";
import polylabel from "polylabel";
import { config, CodeType, GroupType } from "./Config";
import decodeHTMLString from "lodash/unescape";
import capitalize from "lodash/capitalize";
import "./util.scss";
import { shallowEqual, useSelector } from "react-redux";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { RootState } from "./store";
import axios from "axios";
import { store } from "./store";
import * as rax from "retry-axios";
import moment from "moment";
import linkedInLogo from "../img/logo/linkedin_logo.png";
import facebookLogo from "../img/logo/facebook_logo.png";

proj4.defs(
  "EPSG:28992",
  "+title=Amersfoort / RD New +proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908  +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs"
);
proj4.defs(
  "EPSG:31370",
  "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs"
);
proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");
proj4.defs(
  "EPSG:2154",
  "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:2169",
  "+proj=tmerc +lat_0=49.83333333333334 +lon_0=6.166666666666667 +k=1 +x_0=80000 +y_0=100000 +ellps=intl +towgs84=-189.681,18.3463,-42.7695,-0.33746,-3.09264,2.53861,0.4598 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3035",
  "+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
);
export { proj4 };

const cookies: any = document.cookie.split(";").reduce(function (obj, s) {
  const t = s.trim().split("=");
  obj[t[0]] = t[1];
  return obj;
}, {});
export const csrfHeaders = cookies.csrftoken ? { "X-CSRFToken": cookies.csrftoken } : {};

export function derenderText(text) {
  return decodeHTMLString(text).replace(/&#([0-9a-f]+);/gi, (match, group, position, text) => {
    return String.fromCharCode(parseInt(group, 10));
  });
}
export const isNumber = (nr) => !isNaN(parseFloat(nr)) && isFinite(nr);
export const image = (img) => img && (img.startsWith("/") ? img : "/static/" + img);
export const streetviewImage = (o, alt) => {
  if (o?.lng && o?.lat)
    return `https://maps.googleapis.com/maps/api/streetview?size=430x285&location=${o.lat},${o.lng}&key=AIzaSyD0lDXhbPEWSUAB5IGvDp5Aoc06VDNwwWU`;
  return alt;
};

function makeUrl(url) {
  if (url.startsWith("/") || url.startsWith("https://")) return url;
  return "https://" + url;
}

export const followOnSocialMedia = (farm) => {
  if (!(farm?.linkedin || farm?.facebook)) return null;
  return (
    <>
      <h5 className="info-type mt-3">{i18n.t("Follow on social media")}</h5>
      <div className="info">
        {farm.linkedin && (
          <A className="social-media-link" href={makeUrl(farm.linkedin)}>
            <img src={linkedInLogo} alt="Linkedin" className="social-media-logo" />
          </A>
        )}
        {farm.facebook && (
          <A className="social-media-link" href={makeUrl(farm.facebook)}>
            <img src={facebookLogo} alt="Facebook" className="social-media-logo" />
          </A>
        )}
      </div>
    </>
  );
};

export const CropImage = ({ code, name }: { code?: any; name?: string }) => {
  if (!name) {
    if (code) {
      if (code.code) code = code.code;
      name = config.codes[code]?.group?.icon;
    }
  }
  return name ? <i className={`icrop-${name}`} /> : null;
};

export const getCenter = (obj, proj?, digits?) => {
  // returns [x,y]. So reverse for lat_lon
  if (!obj?.geometry) return console.warn("Can not get center of", obj);
  if (!obj._center) {
    const geojson = obj.geometry.type ? obj.geometry : wellknown.parse(obj.geometry);
    if (!geojson.coordinates) return console.warn("Can not get center of", obj);
    obj._center = polylabel(geojson.coordinates, 0.00001);
  }
  let c = obj._center.slice();
  if (proj) {
    if (!String(proj).startsWith("EPSG:")) proj = `EPSG:${proj}`;
    c = proj4(proj, c);
  }
  return digits !== undefined ? c.map((n) => n.toFixed(digits)) : c;
};

export const getBBOX = (wkt) => {
  const ret = [null, null, null, null];
  wkt.match(/([\d.]+ [\d.]+)/g).forEach((e) => {
    const xy = e.split(" "),
      x = parseFloat(xy[0]),
      y = parseFloat(xy[1]);
    if (ret[0] === null || x < ret[0]) ret[0] = x;
    if (ret[1] === null || y < ret[1]) ret[1] = y;
    if (ret[2] === null || x > ret[2]) ret[2] = x;
    if (ret[3] === null || y > ret[3]) ret[3] = y;
  });
  return ret;
};

export const toWKT = (obj, proj?) => {
  if (!obj && obj.coordinates) return null;
  if (proj) {
    const p = proj4("EPSG:" + proj);
    obj = { type: obj.type, coordinates: obj.coordinates.map((c) => c.map((d) => p.forward(d))) };
  }
  return wellknown.stringify(obj);
};

export const fromWkt = (obj) => {
  if (obj.feature) {
    return obj.feature;
  }
  const geoJson = wellknown.parse(obj.geometry);
  if (geoJson) {
    geoJson.properties = obj;
    obj.feature = geoJson;
    return geoJson;
  }
};

export const crop = (
  cropfield // TODO
) => cropfield && config.codes[cropfield.code || cropfield.properties.crops?.[0]];

export const cropName = (cropfield) => {
  if (!cropfield) return "";
  let name = cropfield.crop?.name;
  if (!name) {
    const group = crop(cropfield);
    name = group?.name;
  }
  return name || null;
};
export const groupName = (cropfield) => i18n.t(crop(cropfield)?.group?.name || "");

export const shortCropName = (name) =>
  name &&
  capitalize(
    name
      .replace(/(Blijvend |Andere |Overige |gewassen|rassen|terreinen)/g, "")
      .split(/[\s,]/, 1)[0]
      .replace("/", " ")
  );

export function getCode(code): CodeType;
export function getCode(code, property): string;
export function getCode(code, property: "group"): GroupType;
export function getCode(code, property?): any {
  let c = config.codes[(code && code.code) || code];
  if (c && property) c = c[property] || (c.group && c.group[property]);
  return c || "";
}

export function getGroup(groupId: number): GroupType;
export function getGroup(groupId: number, property): string;
export function getGroup(groupId: number, property?: string): GroupType | string | undefined {
  let c = Object.entries(config.groups).find(([k, v]) => v.id === groupId);
  if (c) {
    const groupType = c[1];
    return property ? groupType?.[property] : groupType;
  }
}

export function formatDate(d) {
  if (d.date) d = d.date;
  return d.split("T")[0].split("-").reverse().join("-");
}

export function periodString(obj, format = "LL") {
  if (obj?.period_start) {
    const s = moment(obj.period_start).format(format);
    if (obj?.period_end) {
      return s + " - " + moment(obj.period_end).format(format);
    }
    return s;
  }
}

export interface accountFeaturesType {
  pro?: boolean;
  history?: boolean;
  premium?: boolean;
  ews?: boolean;
  precipDeficit?: boolean;
}
export function accountFeatures(account): accountFeaturesType {
  return account?.features || {};
}

export function accountFilterHistory(history, account, maxLength?) {
  const features = accountFeatures(account),
    year = features.history || config.crop_year - config.history_free;
  let result = history.filter((e) => e.year >= year);
  return maxLength ? result.slice(-maxLength) : result;
}

export function isPointInsideRing(point, ring) {
  let inside = false;
  const [x, y] = point;
  for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
    const [xi, yi] = ring[i];
    const [xj, yj] = ring[j];
    // eslint-disable-next-line no-mixed-operators
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function isPointInsideFeature(point, feature) {
  const type = feature?.geometry?.type || "";
  if (type.toUpperCase() !== "POLYGON") return false;
  if (!Array.isArray(point)) point = point.split(",").map(parseFloat).reverse();
  return isPointInsideRing(point, feature.geometry.coordinates[0]);
}

export function bbox(features) {
  const ret = [null, null, null, null];
  for (const f of features) {
    for (const [x, y] of f.geometry.coordinates[0]) {
      if (ret[0] === null || x < ret[0]) ret[0] = x;
      if (ret[1] === null || y < ret[1]) ret[1] = y;
      if (ret[2] === null || x > ret[2]) ret[2] = x;
      if (ret[3] === null || y > ret[3]) ret[3] = y;
    }
  }
  return ret;
}

export function numberFormat(nr, locale = "nl-NL") {
  nr = Number(nr);
  if (isNaN(nr)) return "0";
  return nr.toLocaleString(locale);
}

export function getHeightLabels(properties, names = ["1", "2", "3"]) {
  if (!properties) return names;
  return names.map((e) => properties["height" + e]?.start?.substring(0, 4) || e);
}

export function useSSelector<TState = RootState, TSelected = any>(
  selector: (state: TState) => TSelected
) {
  return useSelector(selector, shallowEqual);
}

export const ts = (s: string, o?: object) =>
  i18n.t(s, o).replace?.(/<a href="/g, '<a target="_blank" rel="noopener noreferrer" href="') || s;
export const TS = ({ t, o, safeLink = true }: { t: string; o?: object; safeLink?: boolean }) => (
  <span dangerouslySetInnerHTML={{ __html: safeLink ? ts(t, o) : i18n.t(t, o) }} />
);
export const MoreInfo = ({ o }) =>
  !o ? null : (
    <>
      <TS t={'See <a href="{{href}}">{{description}}</a> for more information'} o={o} />.
    </>
  );
export const FarmLink = ({ farm, children }: { farm: any; children?: React.ReactChildren }) => (
  <Link to={`/farm/${farm.id}/${encodeURIComponent(farm.name)}`}>{children || farm.name}</Link>
);

// External a-href thing
export const A = ({ children, ...props }) => (
  <a target="_blank" rel="noreferrer noopener" {...props}>
    {children}
  </a>
);

export function attachRetryAuthHandler(instance) {
  if (instance.defaults.raxConfig) return;
  instance.defaults.raxConfig = {
    instance: instance,
    httpMethodsToRetry: ["POST", "GET", "DELETE"],
    statusCodesToRetry: [[400, 401, 403]],
    retry: 1,
    noResponseRetries: 0,
    onRetryAttempt: (err) => {
      const url = err.config.baseURL || err.config.url;
      if (!getTokenKeyForHost(url)) {
        return console.log("Not retrying url", url);
      }
      return new Promise((resolve, reject) => {
        return axios({
          method: "GET",
          url: "/refresh_auth",
        })
          .then(({ data }) => {
            store.dispatch({ type: "UPDATE_TOKENS", tokens: data });
            applyHeaders(err.config);
            resolve();
          })
          .catch(function (error) {
            reject();
          });
      });
    },
  };
  rax.attach(instance);
}
export const getTokenKeyForHost = (url) =>
  config.tokenKeyPerHost[/(https?:\/\/.*?)\//.exec(url)?.[1]] ||
  config.tokenKeyPerHost[/(https?:\/\/.*?\/.*?)\//.exec(url)[1]];

export const getCountry = (cropfield) => cropfield.country || (config.__ ? "" : config.bb_country);

export const bbAxios = axios.create({});
export function applyHeaders(axiosConfig) {
  const headers = axiosConfig.headers;
  const url = axiosConfig.baseURL || axiosConfig.url;
  const tokens = store.getState().activeUser.tokens;
  if (!tokens) return axiosConfig;
  const localPrefix = window.location.protocol + "//" + window.location;
  if (!url.startsWith("http") || url.startsWith(localPrefix)) {
    Object.assign(headers, csrfHeaders);
  } else {
    const key = getTokenKeyForHost(url);
    if (key) {
      Object.assign(headers, tokens[key]);
    }
  }
  return axiosConfig;
}
bbAxios.interceptors.request.use(applyHeaders);
attachRetryAuthHandler(bbAxios);
