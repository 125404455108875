import React, { useCallback, useState } from "react";
import { Button, Col, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import i18n from "i18next";
import $ from "jquery";
import { A, csrfHeaders, useSSelector } from "../../util";
import { track } from "../../track";
import { Icon } from "react-fa";

interface CreatePrescriptionMapProps {
  show: boolean;
  video: string;
}

export const CreatePrescriptionMap = ({ show, video }: CreatePrescriptionMapProps) => {
  const [showModal, setModal] = useState(false);
  const onClose = useCallback(() => setModal(false), [setModal]);
  if (!show) return null;
  return (
    <>
      <button
        className="bb_download btn btn-success hidden-print"
        onClick={() => {
          setModal(true);
          track("cloudfarm_trial", "btn_click");
        }}
      >
        {i18n.t("Prescription map")}
      </button>
      {showModal && <CloudfarmModal video={video} onClose={onClose} />}
    </>
  );
};

const send = (e, setErrors, setModal, setSubmitting) => {
  e.preventDefault();
  track("cloudfarm_trial", "form_submit");
  setSubmitting(true);
  $.ajax({
    type: "POST",
    headers: csrfHeaders,
    url: "/cloudfarm_contact/",
    data: $(".cloudfarm_contact_form").serialize(),
    success: (data) => {
      setModal("step2");
      track("cloudfarm_trial", "form_success");
    },
    error: function (response) {
      console.error("failure", response);
      setErrors(response?.responseJSON?.errors);
    },
  }).always(() => {
    setSubmitting(false);
  });
};

export const CloudfarmModal = ({ onClose, video }) => {
  const [errors, setErrors] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState("step1");
  const activeUser = useSSelector((s) => s.activeUser);
  const user = activeUser?.account?.user;

  const getValidationState = (errors, name) => {
    const error = errors[name];
    if (error) return "error";
    return null;
  };
  return (
    <>
      <Modal
        show={modal === "step1"}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title"
        className="mod_bb_share_modal"
        animation={false}
        backdrop="static"
        backdropClassName="bb-modal-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            <strong>{i18n.t("Create prescription maps with Cloudfarm")}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Cloudfarm van Dacom maakt het bekijken en toepassen van perceelsdata zo makkelijk
            mogelijk. Je hebt al binnen 5 minuten een taakkaart. Begin dus eenvoudig met
            precisielandbouw en groei in stapjes door om jouw bedrijf verder te automatiseren.
          </p>
          <p>
            Ben je benieuwd hoe dit voor jou werkt?{" "}
            <A href={"https://www.dacom.nl/contact/"}>Neem contact op</A> met één van onze
            medewerkers, of vraag nu zelf gemakkelijk een proefabonnement op Cloudfarm aan!
          </p>
          <Row className="show-grid">
            <Col xs={12} md={4}>
              <p>Vul hieronder je gegevens in:</p>
              <br />
              <form
                className="cloudfarm_contact_form"
                onSubmit={(e) => send(e, setErrors, setModal, setSubmitting)}
                method="POST"
              >
                <FormGroup controlId={getValidationState(errors, "name")}>
                  <FormControl
                    type="text"
                    name="name"
                    placeholder="Mijn naam"
                    defaultValue={user ? user.first_name + " " + user.last_name : ""}
                  />
                  {errors?.name && <small className="text-muted">{errors.name}</small>}
                </FormGroup>
                <FormGroup controlId={getValidationState(errors, "email")}>
                  <FormControl
                    type="email"
                    name="email"
                    placeholder="Mijn e-mailadres"
                    defaultValue={user?.email || ""}
                  />
                  {errors?.email && <small className="text-muted">{errors.email}</small>}
                </FormGroup>
                <FormGroup controlId={getValidationState(errors, "phone_number")}>
                  <FormControl type="text" name="phone_number" placeholder="Mijn telefoonnummer" />
                  {errors?.["phone_number"] && (
                    <small className="text-muted">{errors["phone_number"]}</small>
                  )}
                </FormGroup>

                <Button type="submit" className="btn btn-success">
                  {submitting ? (
                    <>
                      <Icon spin name="spinner" /> Gegevens controleren
                    </>
                  ) : (
                    "Vraag proefabonnement aan"
                  )}
                </Button>
              </form>
            </Col>
            <Col xs={12} md={8}>
              <div className={"video-wide-screen"}>
                <video autoPlay loop style={{}}>
                  <source src={`/static/video/${video}.mp4`} type="video/mp4"></source>
                </video>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>{i18n.t("Close")}</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modal === "step2"}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title"
        className="mod_bb_share_modal"
        animation={false}
        backdrop="static"
        backdropClassName="bb-modal-backdrop"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            <strong>Je aanvraag is succesvol!</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Je proefabonnement wordt zo snel mogelijk door ons geactiveerd. Vervolgens nemen we
            binnen twee werkdagen telefonisch contact met je op om je op weg te helpen. Je kunt dan
            een maand gratis gebruik maken van Cloudfarm. Je ontvangt van ons nog een
            bevestigingsbericht per e-mail.
          </p>
          <br />
          <Row className="show-grid">
            <Col xs={12} lg={4}>
              <div
                className="case-card"
                onClick={() =>
                  window.open("https://www.dacom.nl/cases/bodempotentiekaart/", "_blank")
                }
              >
                <div>
                  <h2>Cloudfarm</h2>
                  <p>Cloudfarm brengt de potentie van ieder perceel in beeld</p>
                  <em>- Gert Sterenborg &amp; Erik-Jan Blanksma</em>
                  <span>Medewerkers van Dacom </span>
                  <A
                    className="btn btn-primary waves-effect waves-light"
                    href="https://www.dacom.nl/cases/bodempotentiekaart/"
                  >
                    Lees verder
                  </A>
                </div>
                <figure>
                  <img
                    src="https://www.dacom.nl/wp-content/uploads/2021/03/Schermafbeelding-2021-03-29-om-17.00.15.png"
                    alt=""
                  />
                </figure>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                className="case-card"
                onClick={() =>
                  window.open("https://www.dacom.nl/cases/ab-lijnen-johndeere/", "_blank")
                }
              >
                <div>
                  <h2>Cloudfarm</h2>
                  <p>In de winter alle AB-lijnen exact intekenen</p>
                  <em>- Niels Evers</em>
                  <span>Akkerbouwbedrijf Evers Drieborg</span>
                  <A
                    className="btn btn-primary waves-effect waves-light"
                    href="https://www.dacom.nl/cases/ab-lijnen-johndeere/"
                  >
                    Lees verder
                  </A>
                </div>
                <figure>
                  <img
                    src="https://www.dacom.nl/wp-content/uploads/2021/03/Schermafbeelding-2021-03-23-om-14.35.32.png"
                    alt=""
                  ></img>
                </figure>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                className="case-card"
                onClick={() =>
                  window.open("https://www.dacom.nl/cases/massey-ferguson-ablijnen/", "_blank")
                }
              >
                <div>
                  <h2>Cloudfarm</h2>
                  <p>Eén centraal, universeel en onafhankelijk platform</p>
                  <em>- Jan Pieter Evenhuis</em>
                  <span>Akkerbouw- en loonbedrijf Evenhuis VOF Giethoorn</span>
                  <A
                    className="btn btn-primary waves-effect waves-light"
                    href="https://www.dacom.nl/cases/massey-ferguson-ablijnen/"
                  >
                    Lees verder
                  </A>
                </div>
                <figure>
                  <img
                    src="https://www.dacom.nl/wp-content/uploads/2021/03/Schermafbeelding-2021-03-23-om-15.39.26.png"
                    alt=""
                  ></img>
                </figure>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>{i18n.t("Back to CropFields")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
