import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FarmPanelHeader } from "./FarmPanelHeader";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import { useSSelector } from "../../util";
import { selectMapFields } from "../../actions";
import { replace } from "connected-react-router";
import "./MyFarm.scss";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import { MyFarmSettings } from "./MyFarmSettings";
import { MyFarmOverview } from "./MyFarmOverview";
import { DefaultTab } from "./DefaultTab";
import { PlanTab } from "./PlanTab";
import { MyLease } from "../lease/MyLease";

export const MyFarm = ({ select }) => {
  const { fields, farm, lists, currentPlanExternalId, userAvatar, userFetched } = useSSelector(
    ({ activeUser, favorites }) => ({
      fields: favorites?.fields,
      farm: activeUser.account?.farm,
      lists: favorites?.lists,
      currentPlanExternalId: activeUser.account?.current_plan_id || "",
      userAvatar: activeUser.account?.user?.avatar_url || "",
      userFetched: activeUser.state && activeUser.state !== "fetching",
    })
  );
  const dispatch = useDispatch();
  const selectedTabName = useRouteMatch("/my_farm/:tab?").params.tab;
  const path = "/my_farm";

  const plans = useMemo(
    () =>
      (farm &&
        lists
          ?.filter((pl) => pl.type === "PLAN" && pl.farm?.id === farm.id)
          .sort((p1, p2) => p2.period_start.localeCompare(p1.period_start))) ||
      [],
    [farm, lists]
  );
  const plan =
    currentPlanExternalId && plans?.find((pl) => pl.external_id === currentPlanExternalId);
  const planFields = useMemo(
    () => plan && fields?.filter((f) => f.type === "PLAN" && plan.fields?.includes(f.id)),
    [plan, fields]
  );

  useEffect(() => {
    if (!planFields) return;
    if (selectedTabName !== "settings" && selectedTabName !== "rvo")
      dispatch(selectMapFields(planFields));
  }, [dispatch, planFields, selectedTabName]);

  useEffect(() => {
    // can't show my farm for not-loggedin-user
    if (userFetched && !farm) dispatch(replace("/"));
  }, [userFetched, farm, dispatch]);

  if (!farm) return null;
  return (
    <article className="mod_bb mod_bb_my_farm active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Farm overview")}</title>
      </Helmet>
      <FarmPanelHeader
        farm={farm}
        userAvatar={userAvatar}
        onHide={select}
        returnUrl={selectedTabName ? path : ""}
      />
      <main className="bb_my_farm">
        <Switch>
          <Route path={`${path}/settings`}>
            <MyFarmSettings />
          </Route>
          <Route path={`${path}/overview`}>
            <MyFarmOverview farm={farm} id={farm.id} own={true} />
          </Route>
          <Route path={`${path}/lease`}>
            <MyLease farm={farm} />
          </Route>
          <Route path={`${path}/plans/:planKey?`}>
            <PlanTab plans={plans} plan={plan} planFields={planFields} farm={farm} lists={lists} />
          </Route>
          <Route path="*">
            <DefaultTab selectedTabName={selectedTabName} url={path} />
          </Route>
        </Switch>
      </main>
    </article>
  );
};
