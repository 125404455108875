import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "../../panels/Tooltip";
import { Placement } from "react-bootstrap/Overlay";

interface ToggleButtonProperties {
  tooltip: React.ReactNode;
  active?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  variant?: string;
  placement?: Placement;
}

export const ToggleButton = ({
  tooltip,
  active,
  onClick,
  children,
  variant = "default",
  placement = "right",
}: ToggleButtonProperties) => (
  <Tooltip component={tooltip} placement={placement}>
    <Button
      className={active ? "active" : ""}
      variant={variant}
      onClick={(e) => {
        e.currentTarget.blur();
        onClick();
      }}
    >
      {children}
    </Button>
  </Tooltip>
);
