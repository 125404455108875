import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { useSSelector } from "../util";
import { SubscribeModal } from "./SubscribeModal";
import { ForcedLogoutModal } from "./ForcedLogoutModal";
import { TrialExpiredModal } from "./TrialExpiredModal";

export const GlobalModals = memo(() => {
  const dispatch = useDispatch();
  const { modal, text } = useSSelector((state) => state.modal);
  if (!modal) return null;
  const close = () => {
    dispatch({ type: "HIDE_MODAL" });
  };

  return (
    <>
      {modal === "subscribe" && <SubscribeModal onClose={close} text={text} />}
      {modal === "forceLoggedOut" && <ForcedLogoutModal onClose={close} />}
      {modal === "trialexpired" && <TrialExpiredModal onClose={close} />}
    </>
  );
});
