import { getCenter, periodString } from "../../util";
import React from "react";
import i18n from "i18next";
import { config } from "../../Config";
import { KeyVal } from "../header/components/KeyVal";
import { Pro } from "../../components/Pro";
import moment from "moment/moment";

export const FieldLocationInfo = ({ field, className = undefined }) => {
  const bunder = field.bunder,
    c = field.crops,
    crp = c?.length && config.codes[c[c.length - 1].code],
    decimals = 4,
    area = bunder ? bunder.area : field.feature.properties.area,
    publicSource = (field.feature?.id || "").split("-")[1],
    source = bunder?.source || publicSource;

  let period = bunder ? periodString(bunder, "DD/MM/YY") : "";
  if (!period && publicSource && Number(source.substr(3)) > 0) {
    period = moment(`20${source.substr(3)}-05-01`).format("LL");
  }
  const cropName =
    `${bunder?.crop_name || crp?.name || i18n.t("Unknown")}` +
    (bunder?.variety_name ? ` (${bunder?.variety_name})` : "");

  return (
    <dl className={className}>
      <KeyVal
        k={i18n.t("Crop")}
        v={config.isNative || bunder || !crp?.group ? cropName : i18n.t(crp.group.name)}
      />
      <dt>{i18n.t("Area")}</dt>
      <dd>
        {decimals < 2 ? "~" : ""}
        {area ? area.toFixed(decimals) : "?"} ha{" "}
        <Pro msg={i18n.t("Subscribe to view the exact area")} />
      </dd>
      <KeyVal k={i18n.t("Plot name")} v={bunder?.name} />
      <KeyVal k={i18n.t("Farm")} v={bunder?.farm_name} />
      <KeyVal k={i18n.t("Source")} v={source} />
      <KeyVal k={i18n.t("Plan")} v={bunder?.plan_name} />
      <KeyVal k={i18n.t("Period")} v={period} />

      <KeyVal k={i18n.t("Municipality")} v={field.zone2?.name} />
      <KeyVal k={i18n.t("Province")} v={field.zone1?.name} />
      <div className="notsmall hidden-print">
        <KeyVal k={i18n.t("Agricultural area")} v={field.zonea?.name} />
      </div>
      <KeyVal
        k={i18n.t("GPS coordinates")}
        small="GPS"
        v={getCenter(field.feature, null, 5).reverse().join(", ")}
      />
      <KeyVal
        k={i18n.t("XY coordinates")}
        small="XY"
        v={getCenter(field.feature, config.projection, 0).join(", ")}
      />
    </dl>
  );
};
