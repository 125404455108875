import React, { useCallback } from "react";
import classNames from "classnames";
import "./BunderListItem.scss";
import { Pro, showSubscriptionModal } from "../../../components/Pro";
import { accountFeatures, useSSelector } from "../../../util";
import { useDispatch } from "react-redux";

type Props = {
  className?: string;
  onClick?: (e: any) => void;
  title?: string;
  icon?: string;
  danger?: boolean;
  featureRequired?: "pro" | "premium";
  onFeatureRequired?: () => void;
};

export const DropdownItem = ({
  className,
  onClick,
  title,
  icon,
  danger = false,
  featureRequired,
  onFeatureRequired,
}: Props) => {
  const features = useSSelector((state) => accountFeatures(state.activeUser?.account));
  const dispatch = useDispatch();

  const commonProps = {
    className: classNames(className, {
      "c-dropdown__item": "c-dropdown__item",
      [`c-dropdown__item--danger`]: danger === true,
    }),
  };

  const handleClick = useCallback(
    (e) => {
      if (featureRequired && !features[featureRequired]) {
        showSubscriptionModal(dispatch, "");
        onFeatureRequired && onFeatureRequired();
        return;
      }

      onClick(e);
    },
    [dispatch, featureRequired, features, onClick, onFeatureRequired]
  );

  return (
    <li {...commonProps} onClick={handleClick}>
      {icon && <i className={`icon fas fa-${icon}`} />}
      {title}
      {featureRequired && <Pro required={featureRequired} />}
    </li>
  );
};
