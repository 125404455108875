import { HierarchicalFacetAccessor, HierarchicalMenuFilter, renderComponent } from "searchkit";
import i18n from "i18next";
import React from "react";
import { getTitle } from "./util";

export class MyHierarchicalMenuFilter extends HierarchicalMenuFilter {
  constructor(props) {
    super(props);
    this.state = { showAll: false, open: this.props.open };
  }
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.toggle !== undefined && this.state.open !== nextProps.toggle)
      this.setState({ open: nextProps.toggle });
  }
  showMore() {
    const { id, title, fields, orderKey, orderDirection } = this.props,
      showAll = !this.state.showAll;
    this.searchkit.removeAccessor(this.accessor);
    this.accessor = this.searchkit.addAccessor(
      new HierarchicalFacetAccessor(id, {
        id,
        title,
        fields,
        size: showAll ? 100 : this.props.size,
        orderKey,
        orderDirection,
      })
    );
    this.setState({ showAll });
    this.searchkit.performSearch();
  }

  renderOption(level, option) {
    /* Overriden to insert hectares into count */
    const { countFormatter, itemComponent } = this.props;
    const active = this.accessor.state.contains(level, option.key);

    return (
      <div key={option.key}>
        {renderComponent(itemComponent, {
          active,
          itemKey: option.key,
          showCount: true,
          bemBlocks: this.bemBlocks,
          onClick: this.addFilter.bind(this, option, level),
          label: this.translate(option.key),
          count: option.area
            ? option.area.value.toFixed() + " ha"
            : countFormatter(option.doc_count),
        })}
        {(() => {
          if (this.accessor.resultsState.contains(level, option.key)) {
            return this.renderOptions(level + 1);
          }
        })()}
      </div>
    );
  }

  render() {
    if (!this.accessor) return null;
    const a = this.accessor,
      res = a.results && a.results.aggregations[a.uuid],
      hits = a.results?.hits,
      percentage = hits && res && (hits.total / res.doc_count) * 100,
      display =
        percentage &&
        percentage < 100 &&
        percentage.toFixed(percentage >= 1 ? 0 : 1 - Math.round(Math.log10(percentage))),
      key =
        a.resultsState &&
        a.resultsState.value &&
        a.resultsState.value[a.resultsState.value.length - 1];
    let block = this.bemBlocks.container,
      classname = block()
        .mix(`filter--${this.props.id}`)
        .state({
          disabled: a.getBuckets(0).length === 0,
        });
    return (
      <div className={`${classname} closable ${this.state.open ? "open" : ""}`}>
        <div
          onClick={(e) => this.setState({ open: !this.state.open })}
          className={`${block("header")} toggle`}
        >
          {getTitle.call(this)}
          {display && (
            <span>
              {" "}
              ({key} {display}%)
            </span>
          )}
        </div>
        <div className={block("root") + " content"}>
          {this.renderOptions(0)}
          {this.props.size && this.props.size <= this.accessor.getBuckets(0).length && (
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            <a className="more" onClick={(e) => this.showMore()}>
              {this.state.showAll ? i18n.t("Less") : i18n.t("All") + "..."}
            </a>
          )}
        </div>
      </div>
    );
  }
}
