import React from "react";
import i18n from "i18next";
import { MyHierarchicalMenuFilter } from "./filters/MyHierarhicalMenuFilter";
import { MyRefinementListFilter } from "./filters/MyRefinementListFilter";
import { config } from "../Config";
import {
  BoolMustNot,
  CheckboxFilter,
  CheckboxItemComponent,
  NumericRefinementListFilter,
  TermQuery,
} from "searchkit";
import { MyPanel } from "./filters/MyPanel";
import { DisabledFilter } from "./filters/DisabledFilter";

export const Filters = ({ features, toggle }) => {
  const zones = i18n.t("Province") + " / " + i18n.t("Municipality");
  const years = Array(Math.min(config.crop_year - config.crop_year_start + 1, 4))
    .fill()
    .map((_, i) => config.crop_year - i);
  if (features.pro)
    return (
      <div>
        <MyHierarchicalMenuFilter
          toggle={toggle}
          // orderKey="_term"
          fields={["zone1", "zone2"]}
          size={100}
          title={zones}
          id="regio"
        />
        {[
          ["zonea", i18n.t("Agricultural area")],
          ["zonew", i18n.t("Water board")],
        ].map(
          ([key, title]) =>
            config.filters.includes(key) && (
              <MyRefinementListFilter
                key={key}
                id={key}
                title={title}
                field={key}
                operator="OR"
                collapsable={true}
                defaultCollapsed={!toggle}
              />
            )
        )}
        {years.map((year) => (
          <MyHierarchicalMenuFilter
            toggle={toggle}
            key={year}
            fields={[`cat_${year}`, `crop_${year}`]}
            title={`${year}`}
            id={`cat_${year}`}
            size={10}
            open={year === config.crop_year}
          />
        ))}
        {config.show.includes("lease") && (
          <MyRefinementListFilter
            id="use_title"
            title="Pacht"
            field="use_title"
            collapsable={true}
            operator="OR"
            defaultCollapsed={false}
            containerComponent={MyPanel}
            itemComponent={CheckboxItemComponent}
          />
        )}
        {[
          ["soil", i18n.t("Soil type")],
          ["r_soil", i18n.t("Regulatory soil type")],
          ["water_trap", i18n.t("Water trap")],
          ["property_i", i18n.t("Institute")],
        ].map(
          ([key, title]) =>
            config.filters.includes(key) && (
              <MyRefinementListFilter
                key={key}
                id={key}
                title={title}
                field={key}
                operator="OR"
                collapsable={true}
                size={key.includes("soil") ? undefined : 5}
                defaultCollapsed={!toggle}
              />
            )
        )}
        <NumericRefinementListFilter
          id="area"
          title={i18n.t("Area")}
          field="area"
          options={[
            { title: "0-2 ha", from: 0, to: 2 },
            { title: "2-4 ha", from: 2, to: 4 },
            { title: "4-10 ha", from: 4, to: 10 },
            { title: "10- ha", from: 10 },
          ]}
          multiselect={true}
          containerComponent={MyPanel}
          itemComponent={CheckboxItemComponent}
        />
        <MyRefinementListFilter
          id="natura_2000"
          title={i18n.t("Natura 2000")}
          field="natura_2000"
          operator="OR"
          collapsable={true}
          defaultCollapsed={!toggle}
        />
        <MyRefinementListFilter
          id="tags"
          title={i18n.t("Attributes")}
          field="tags"
          operator="AND"
          collapsable={true}
          defaultCollapsed={!toggle}
        />
        {features.premium &&
          config.crop_year - config.crop_year_start > 5 &&
          [4, 5].map((year) => (
            <CheckboxFilter
              key={year}
              id={`no-potato${year}`}
              title={i18n.t("Specialty filter")}
              label={i18n.t("No potatoes for {{year}} years", { year: year })}
              filter={BoolMustNot(
                years
                  .concat([config.crop_year - 4])
                  .map((year) => TermQuery("cat_" + year, "Potato"))
              )}
            />
          ))}
      </div>
    );
  const year = config.crop_year,
    yearFrom = Math.max(year - 3, config.crop_year_start);
  return (
    <div>
      <DisabledFilter title={zones} text={i18n.t("province and municipality borders")} />
      {year - config.crop_year_start > 0 && (
        <MyHierarchicalMenuFilter
          toggle={toggle}
          key={year}
          fields={[`cat_${year}`]}
          title={`${year}`}
          id={`cat_${year}`}
          size={10}
          open={true}
        />
      )}
      {year - 1 > yearFrom && (
        <DisabledFilter title={`${year - 1} .. ${yearFrom}`} text={i18n.t("crop rotation")} />
      )}
      {year - 1 === yearFrom && (
        <DisabledFilter title={`${year - 1} `} text={i18n.t("crop rotation")} />
      )}
      <DisabledFilter title={i18n.t("Soil type")} text={i18n.t("different soil types")} />
      <DisabledFilter title={i18n.t("Area")} text={i18n.t("<2ha, 2-4ha, 4-10ha and 10+ ha")} />
      <DisabledFilter title={i18n.t("Attributes")} text={i18n.t("different attributes")} />
    </div>
  );
};
