import React from "react";
import { Tooltip } from "../../panels";
import i18n from "i18next";
import { getCode, MoreInfo } from "../../util";
// TODO rewrite FieldProp to Children of FieldProps

export class FieldProp {
  attr: any;
  icon: any;
  description: any;
  content: any;
  constructor(attr, icon, description, content = null) {
    this.attr = attr;
    this.icon = icon;
    this.description = description;
    this.content = content;
  }

  render(value) {
    let { description, content, icon } = this;
    if (!value) return null;
    if (typeof description === "function") description = description.apply(this, [value]);
    if (typeof icon === "function") icon = icon.apply(this, [value]);
    if (typeof content === "function") content = content.apply(this, [value]);
    if (icon) content = <div className={`attribute-img ${icon}`}>{content}</div>;
    if (!description) return content;
    if (!React.isValidElement(description)) {
      description = <span>{description}</span>;
    }
    return (
      <Tooltip key={this.attr} delayHide={500} description={description}>
        {content}
      </Tooltip>
    );
  }
}

export class MultiFieldProp extends FieldProp {
  render(value) {
    return value.features.map((e) => super.render(e.properties));
  }
}

interface FieldPropsType {
  field: any;
}
export class FieldProps extends React.Component<FieldPropsType> {
  // override constructor and set this.fieldProps
  fieldProps: any[];
  render() {
    const { field } = this.props,
      foundProps = (field && this.fieldProps.filter((p) => field[p.attr])) || [];
    if (!foundProps.length) return null;
    return (
      <div className="field-attributes pt-2">{foundProps.map((p) => p.render(field[p.attr]))}</div>
    );
  }
}

export const Desc = (name, code, link, description) => {
  const codeDesc = i18n.t(getCode(code, "name"));
  return (
    <span>
      {name}
      {codeDesc && (
        <>
          {" "}
          <b>{codeDesc}</b>
        </>
      )}
      . <MoreInfo o={{ href: link, description }} />
    </span>
  );
};

interface DescriptionType {
  name?: string;
  code?: string;
  link?: string;
  children?: React.ReactNode;
}
export const Description = ({ name, code, link, children }: DescriptionType) =>
  Desc(name, code, link, children);
