import imgLogoBbDK from "../../img/logo/logo_bb_dk.svg";
import imgLogoBbFR from "../../img/logo/logo_bb_fr.svg";
import imgLogoBbDE from "../../img/logo/logo_bb_de.svg";
import imgLogoBbNL from "../../img/logo/logo_bb.svg";
import imgLogoBbCropFields from "../../img/logo/cropx_white_logo.svg";

import { config } from "../Config";
import i18n from "i18next";
import React from "react";

const imgLogoBB =
  {
    DK: imgLogoBbDK,
    WA: imgLogoBbFR,
    FR: imgLogoBbFR,
    DE: imgLogoBbDE,
    DE_NW: imgLogoBbDE,
    DE_NI: imgLogoBbDE,
    DE_SH: imgLogoBbDE,
    AT: imgLogoBbDE,
    LU: imgLogoBbFR,
    __: imgLogoBbCropFields,
  }[config.bb_country] || imgLogoBbNL;

export const LogoBB = ({ width = 142, height = 25 }) => (
  <img src={imgLogoBB} width={width} height={height} alt={i18n.t("CropFields")} />
);
