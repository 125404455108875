import React, { useCallback } from "react";
import { Pane } from "react-leaflet";
import { useDispatch } from "react-redux";
import { updateWater } from "../../actions";
import { waterColors } from "../../report/reference/Water";
import { track } from "../../track";
import { TopPolygon } from "../map_util";

type WaterPolygonsProps = {
  waterSelection: any;
  feature: any;
};
export const WaterPolygons = (props: WaterPolygonsProps) => {
  const { waterSelection, feature } = props;
  const trenches = waterSelection?.data?.features || [];
  const highlighted = waterSelection?.highlighted;
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (id) => {
      dispatch(
        updateWater({
          highlighted: highlighted !== id ? id : undefined,
        })
      );
      track("report", "water", "select_trench");
    },
    [dispatch, highlighted]
  );

  return (
    <>
      <TopPolygon feature={feature} fillOpacity={0.5} stroke={false} fillColor={"#E6E628"} />,
      {trenches.map((trench) => {
        return (
          <TrenchesPolygons
            key={trench.id}
            trench={trench}
            showShore={waterSelection.showShore}
            onClick={handleClick}
            highlight={trench.id === highlighted}
          />
        );
      })}
    </>
  );
};

type TrenchesPolygonsProps = {
  trench: any;
  showShore: boolean;
  highlight: boolean;
  onClick: (id: number) => void;
};
const TrenchesPolygons = (props: TrenchesPolygonsProps) => {
  const { trench, showShore, highlight, onClick } = props;
  const { name, isShore, buffered } = trench.properties;
  const color = highlight ? "red" : waterColors(name, showShore);

  const handleClick = useCallback(
    (e) => {
      if (!isShore) {
        onClick(trench.id);
      }
    },
    [isShore, onClick, trench.id]
  );

  if (!showShore && isShore) {
    return null;
  }

  const zIndex = isShore ? 300 : 301;
  return (
    <Pane key={trench.id} name={`trench_${trench.id}`} style={{ zIndex: zIndex }}>
      <TopPolygon
        key={trench.id}
        feature={buffered?.geometry || trench}
        weight={2}
        color={color}
        fillColor={color}
        fillOpacity={1}
        onClick={handleClick}
      />
    </Pane>
  );
};
