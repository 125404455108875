import React, { useMemo } from "react";
import { AttributionControl, MapContainer } from "react-leaflet";

import { config } from "../Config";
import { useSSelector } from "../util";
import { getBounds } from "./map_util";
import "leaflet/dist/leaflet.css";

import { SelectedField } from "./selectedField/SelectedField";
import { Layers } from "./index";
import { useSelectionNavigator } from "./selectedField/useSelectionNavigator";
import { FilterResultLayer } from "./brp/FilterResultLayer";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { BRPLayer } from "./brp/BRPLayer";
import { EWSFarmFieldsLayer } from "./brp/EWSLayer";
import {
  ListMap,
  BaseMap,
  FarmMap,
  CurrentLocationMarker,
  FarmMarkers,
  SearchResultMarker,
  MultiSelectMap,
  LayerHelper,
} from "./maps";
import { MultiSelectControl } from "./customControl/MultiSelectControl";

type MapViewProps = {
  layersComponent: typeof Layers[keyof typeof Layers];
  layerNames: string[];
  style?: any;
  polygonClick: (e: any) => void;
};
export const MapView = ({ layersComponent, layerNames, style }: MapViewProps) => {
  const zoom = useSSelector((state) => state.newMapState.zoom || null);
  const center = useSSelector((state) => state.newMapState.center || null);
  const bounds = useMemo(() => getBounds(config.bounds, 0.7), []);

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      maxBounds={bounds}
      maxBoundsViscosity={0.3}
      zoomControl={false}
      attributionControl={false}
      id="map"
      className="map"
      style={style}
    >
      <AttributionControl prefix="" />
      <Switch>
        <Route path="/ews">
          <LayerHelper layersComponent={layersComponent} layerNames={layerNames} />
          <EWSMap />
        </Route>
        <Route
          path={"/lists/:key?"}
          render={({ match }) => (
            <>
              <LayerHelper layersComponent={layersComponent} layerNames={layerNames} />
              <ListMap listKey={match.params.key} />
            </>
          )}
        />
        <Route
          path={["/my_farm/plans/:key", "/farm", "/my_farm"]}
          render={({ match }) => (
            <>
              <LayerHelper layersComponent={layersComponent} layerNames={layerNames} />
              <FarmMap planKey={match.params.key} />
            </>
          )}
        />
        <Route path={"/filter"}>
          <LayerHelper layersComponent={layersComponent} layerNames={layerNames} />
          <FilterMap />
        </Route>
        <Route path="/select/:gps?">
          <LayerHelper layersComponent={layersComponent} layerNames={layerNames} />
          <MultiSelectMap />
        </Route>
        <Route path="/">
          <LayerHelper layersComponent={layersComponent} layerNames={layerNames} showBRP={true} />
          <Map />
        </Route>
      </Switch>
    </MapContainer>
  );
};

const Map = () => {
  const allowMultiSelect = useSSelector((s) =>
    Boolean(s?.activeUser?.settings?.useFieldSelectionTool)
  );
  const select = useSelectionNavigator({ allowMultiSelect: allowMultiSelect });

  return (
    <BaseMap>
      <SelectedField />
      <BRPLayer onPolygonClick={select} />
      <FilterResultLayer />

      <CurrentLocationMarker />
      <SearchResultMarker />
      <FarmMarkers />
      <MultiSelectControl position="topright" />
    </BaseMap>
  );
};

const FilterMap = () => {
  useSelectionNavigator();

  return (
    <BaseMap>
      <FilterResultLayer />
    </BaseMap>
  );
};

const EWSMap = () => (
  <BaseMap>
    <EWSFarmFieldsLayer />
  </BaseMap>
);
