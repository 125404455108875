import React from "react";
import { config } from "../Config";
import { Nav, NavDropdown } from "react-bootstrap";
import imgCountryVL from "../../img/flags/flanders.png";
import imgCountryWA from "../../img/flags/wallonia.png";
import imgCountryDENW from "../../img/flags/DE_NW.png";
import imgCountryDENI from "../../img/flags/DE_NI.png";
import imgCountryDESH from "../../img/flags/DE_SH.png";
import imgCountryAT from "flag-icons/flags/4x3/at.svg";
import imgCountryNL from "flag-icons/flags/4x3/nl.svg";
import imgCountryDE from "flag-icons/flags/4x3/de.svg";
import imgCountryDK from "flag-icons/flags/4x3/dk.svg";
import imgCountryFR from "flag-icons/flags/4x3/fr.svg";
import imgCountryLU from "flag-icons/flags/4x3/lu.svg";
import imgCountryEU from "flag-icons/flags/4x3/eu.svg";
import i18n from "i18next";
import "./Header.scss";
import { accountFeatures, csrfHeaders, useSSelector } from "../util";
import $ from "jquery";
import { Icon } from "react-fa";

const imgCountryBE = imgCountryVL;

const imgCountries = {
  DK: imgCountryDK,
  BE: imgCountryBE,
  NL: imgCountryNL,
  WA: imgCountryWA,
  FR: imgCountryFR,
  DE: imgCountryDE,
  DE_NW: imgCountryDENW,
  DE_NI: imgCountryDENI,
  DE_SH: imgCountryDESH,
  AT: imgCountryAT,
  LU: imgCountryLU,
  __: imgCountryEU,
};
const globe = (
  <div style={{ height: "20px", width: "27px", display: "inline-block" }} className="country ml-2">
    <Icon name="globe" className="pl-2" />
  </div>
);
export const ImageCountry = ({ country }: { country: string }) =>
  !country ? (
    globe
  ) : (
    <img
      className="country ml-2"
      src={imgCountries[country]}
      height="20"
      width="27"
      alt={country}
      title={country}
    />
  );

export const CountrySelect = () => (
  <NavDropdown
    id="country_dropdown"
    className="bb_country_select"
    title={
      <span>
        <span className="d-sm-inline-block d-md-none">{i18n.t("Country choice")} </span>
        <ImageCountry country={config.bb_country} />
        <span className={"caret"} />
      </span>
    }
    as={"li"}
  >
    <ul style={{ display: "contents" }}>
      {config.bb_sites.map((e, i) =>
        e.code === "__" ? null : (
          <NavDropdown.Item key={e.code} eventKey={i + 1} as={"li"}>
            <Nav.Link className={e.beta && "beta"} href={`https://${e.url}/`}>
              <ImageCountry country={e.code} /> {e.name}
              {e.beta && <span className="badge label-info ml-2">beta</span>}
            </Nav.Link>
          </NavDropdown.Item>
        )
      )}
    </ul>
    <LanguageSelect />
  </NavDropdown>
);

function setLanguage(lang: string, url = "/set_language/") {
  $(
    `<form style="display:none;" action="${url}" method="post">
     <input type="text" name="csrfmiddlewaretoken" value="${csrfHeaders["X-CSRFToken"]}"/>
     <input name="language" type="text" value="${lang}"/>
     <input name="next" type="text" value="${encodeURI(window.location.href)}"/>
     </form>`
  )
    .appendTo($("body"))
    .submit();
}
const translations = [
  ["nl", "Nederlands"],
  ["da", "Dansk"],
  ["de", "Deutsch"],
  ["en", "English"],
  ["fr", "Français"],
];

const LanguageSelect = () => {
  const premium = useSSelector((s) => !!accountFeatures(s.activeUser.account)?.premium);
  if (!premium) return null;
  return (
    <>
      <hr />
      <div className="bb_language_select">
        {translations.map(([code, lang]) => (
          <React.Fragment key={lang}>
            &nbsp;
            {code === config.language ? (
              <span className="badge badge-success">{lang}</span>
            ) : (
              <button
                onClick={() => setLanguage(code)}
                style={{ padding: 0, backgroundColor: "white" }}
              >
                <span className="badge badge-secondary">{lang}</span>
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
