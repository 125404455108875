import { useDispatch } from "react-redux";
import { clickGPS } from "../../actions";
import { config } from "../../Config";
import i18n from "i18next";
import React from "react";

export const CropFieldGridItem = ({ bemBlocks, result }) => {
  const dispatch = useDispatch();
  const s = result._source;
  return (
    <div className={bemBlocks.item().mix(bemBlocks.container("item"))}>
      <a
        href={`/report/${s.center[1]},${s.center[0]}`}
        onClick={(e) => {
          e.preventDefault();
          dispatch(clickGPS({ lat: s.center[1], lng: s.center[0] }));
        }}
      >
        <img
          alt={result._id}
          data-qa="poster"
          className={bemBlocks.item("poster")}
          src={`${config.LAYER_URL}/georeference_h/image/?bid=${result._id}&size=170`}
          srcSet={`${config.LAYER_URL}/georeference_h/image/?bid=${result._id}&size=340 2x`}
          width="170"
          height="170"
        />
        <div data-qa="title" className={bemBlocks.item("title")}>
          {i18n.t(s["cat_" + config.crop_year])} / {s.zone1} / {s.zone2} / {s.area.toFixed(2)} ha /{" "}
          {s.soil}
        </div>
      </a>
    </div>
  );
};
