import React from "react";
import { Spinner } from "../../components";
import imgSample from "../../../img/sample/sample_photo.png";

export const HeaderLoader = () => {
  return (
    <header className="bunder_hero">
      <section
        className="c-hero c-hero--overlay-layer"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(8, 18, 15, 0.45), rgba(8, 18, 15, 0.45)), url(${imgSample})`,
        }}
      >
        <div>
          <div className="header--content">
            <Spinner />
          </div>
        </div>
      </section>
    </header>
  );
};
