import classNames from "classnames";
import React from "react";
import "./IconButton.scss";

type Props = {
  className?: string;
  icon?: string;
  onClick?: (any?) => void;
  active?: boolean;
  style?: "far" | "fas" | "fal";
};

export const IconButton = (props: Props) => {
  const { className = "", icon, onClick, style = "far", active = false, ...otherProps } = props;

  const buttonClasses = classNames(className, {
    "c-icon-btn": "c-icon-btn",
    "c-icon-btn--active": active,
  });

  return (
    <button className={buttonClasses} {...otherProps} onClick={onClick}>
      <i className={`${style} fa-${icon}`} />
    </button>
  );
};
