import React from "react";
import L from "leaflet";
import { STYLETYPES, TopPolygon, getStyle } from "../map_util";
import { accountFeatures, getCode, useSSelector } from "../../util";
import { LayerGroup, Marker } from "react-leaflet";
import { usePanSelection } from "./usePanSelection";
import { CadastrePolygons } from "./CadastrePolygons";
import { GrowthOverlay } from "./GrowthOverlay";
import { HeightOverlay } from "./HeightOverlay";
import { NaturePolygons } from "./NaturePolygons";
import { WaterPolygons } from "./WaterPolygons";
import { DefaultZIndex, Layer } from "../brp/Layer";

export const SelectedField = () => {
  const selection = useSSelector((state) => state.selection);
  const features = useSSelector((state) => accountFeatures(state.activeUser.account));
  const gps = selection?.gps?.length ? L.latLng(selection.gps) : null;
  const smallScreen = window.innerWidth < 768;

  usePanSelection();

  const renderMultiple = () => {
    return selection.selectedFields?.map((f, i) => (
      <TopPolygon
        key={`selected_field_${i}`}
        feature={f.feature}
        {...getStyle(STYLETYPES.SELECTED)}
      />
    ));
  };

  const renderDisplay = () => {
    const { field, section } = selection;
    const feature = field && field.state === "fetched" && field.feature;
    const bounds = L.geoJSON(feature).getBounds();

    if (!feature || !bounds) {
      return null;
    }

    if (smallScreen) {
      return <TopPolygon feature={field.feature} {...getStyle(STYLETYPES.SELECTED)} />;
    }

    switch (section) {
      case "nature":
      case "natura_2000":
      case "soil":
        return <NaturePolygons field={field} section={section} />;
      case "cadastre": // old name
      case "property":
        if (!features.pro) {
          return null;
        }
        return <CadastrePolygons field={field} feature={feature} />;
      case "growth":
        if (!selection.growth) {
          return;
        }
        return <GrowthOverlay growthSelection={selection.growth} bounds={bounds} />;
      case "height":
        return <HeightOverlay bounds={bounds} shadow={selection.shadow} dem={selection.dem} />;
      case "water":
        return <WaterPolygons waterSelection={selection.water} feature={feature} />;
      default:
        // TODO: turn into its own component.
        if (selection.year) {
          const data = field.crops.find((c) => c.year === selection.year);
          return data?.features?.map((f, i) => (
            <TopPolygon
              key={`s${i}-${f.id}`}
              feature={f}
              fillOpacity={1}
              fillColor={getCode(f.properties.code, "color")}
              weight={1}
              color="black"
            />
          ));
        }

        return <TopPolygon feature={field.feature} {...getStyle(STYLETYPES.SELECTED)} />;
    }
  };

  return (
    <div style={{ display: "none" }}>
      {gps ? <Marker position={gps} /> : null}
      <LayerGroup>
        <Layer name={"selected-field-details"} zIndex={DefaultZIndex + 10}>
          {selection.selectedFields?.length ? renderMultiple() : renderDisplay()}
        </Layer>
      </LayerGroup>
    </div>
  );
};
