import React from "react";
import i18n from "i18next";
import { SearchkitComponent } from "searchkit";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { config } from "../../Config";
import { Map } from "./Map";

class MyPie extends Pie {
  static renderLabelItem(option, props, value) {
    return Pie.renderLabelItem(option, { ...props, fill: "black" }, value);
  }
}

const TitleBox = ({ title = "", children, ...style }) => (
  <div style={style} className="titlebox">
    <div style={{ textAlign: "center", fontWeight: "bold" }}>{title}</div>
    <div>{children}</div>
  </div>
);

const formatter = (value, name, props) => [
  props.payload.unit === "ha" ? `${value.toFixed(2)} ha` : `${value} ${i18n.t("fields")}`,
  i18n.t(name),
];

function aggregate(aggregation, sKey, searchKeys, max) {
  const buckets = aggregation?.buckets;
  if (!buckets) return null;
  const search = !!(searchKeys && searchKeys.length);
  let agg = search ? buckets.filter((e) => searchKeys.indexOf(e.key) >= 0) : buckets;
  if (max) agg = agg.slice(0, max + 1);
  const res = agg.map(({ key, doc_count: docCount, area }) => ({
    key,
    unit: area ? "ha" : "#",
    value: area ? area.value : docCount,
    sKey,
  }));
  res.selected = search;
  res.total = res.reduce((sum, x) => sum + x.value, 0);
  return res;
}

export class ChartResult extends SearchkitComponent {
  constructor(props) {
    super(props);
    this.clickFacet = this.clickFacet.bind(this);
  }

  clickFacet(e) {
    const accessor = this.searchkit.accessors.statefulAccessors[e.payload.sKey],
      state = accessor.state;
    accessor.state = state.toggleLevel
      ? state.toggleLevel(e.payload.level || 0, e.key)
      : state.toggle(e.key);
    this.searchkit.performSearch();
  }

  render() {
    const { enabled } = this.props,
      results = this.getResults(),
      aggregations = results?.aggregations;
    if (!enabled) return null;
    if (!aggregations) return <h1>{i18n.t("No results")}...</h1>;

    // cat_2015, cat_2016, cat_2017, cat_2018 verdeling met een piechart
    // cat_2015, cat_2016, cat_2017, cat_2018 trendlijn van gewassen
    // area --> balkjes
    // r_soil12, soil12, watertrap
    // property_i --> niet compleet dus aanvullen tot 100% met lege waarden. Of TagCloud. Pie.{startAngle,endAmgle}
    // regio op minimap, zonea17, zonew12 ook op minimap

    const sState = this.searchkit.state;
    let years = { lines: [], crops: [], aggs: [] }; // {name: '2015', uv: 4000, pv: 2400, amt: 2400},
    const cats = Object.keys(aggregations).filter((key) => key.startsWith("cat_"));
    cats.sort((a, b) => b.localeCompare(a));
    for (const key of cats) {
      const searchKeys = sState[key] && sState[key][0];
      const res = aggregate(aggregations[key][key][key], key, searchKeys, 10);
      const elem = { name: key.substring(4) };
      for (const e of res) {
        elem[e.key] = e.value;
        elem.unit = e.unit;
        if (years.crops.indexOf(e.key) < 0) years.crops.unshift(e.key);
      }
      years.lines.push(elem);
      years.aggs.push(res);
    }
    let data = {};
    for (const key of Object.keys(aggregations)) {
      if (/(cat_|-)/.test(key)) continue;
      let aggregation = aggregations[key];
      if (key === "regio") {
        data.zone1 = aggregate(aggregation.zone1.zone1, "zone1", sState.regio[0]);
        if (aggregation.zone2)
          data.zone2 = aggregate(aggregation.zone2.zone2, "zone2", sState.regio[1]);
        continue;
      }
      const key2 = /(natura_2000|natura_2000_buffer|\D+)/.exec(key)[0];
      if (key2) {
        const res = aggregate(aggregation[key2], key2, sState[key2]);
        if (res)
          data[key2] = key2 === "area" ? res : res.sort((a, b) => a.key.localeCompare(b.key));
      }
    }

    return (
      <div>
        {cats.length > 1 && (
          <TitleBox title={i18n.t("Crop per year")}>
            <AreaChart
              width={350}
              height={250}
              data={years.lines}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis scale="sqrt" />
              <Tooltip formatter={formatter} />
              <Legend />
              {years.crops.map((e) => (
                <Area
                  key={e}
                  type="monotone"
                  dataKey={e}
                  stackId="1"
                  fill={config.names[e]?.group?.color || "#4e3"}
                  stroke={config.names[e]?.group?.color || "#4e3"}
                  name={i18n.t(e)}
                />
              ))}
            </AreaChart>
          </TitleBox>
        )}
        <TitleBox title={i18n.t("Province")} height="272px" width="250px">
          <Map
            zone="zone1"
            data={data.zone1}
            onClick={(name, e) => this.clickFacet({ payload: { sKey: "regio" }, key: name })}
          />
        </TitleBox>
        <TitleBox title={i18n.t("Municipality")} height="272px" width="250px" paddingLeft="10px">
          <Map
            height="250"
            width="250"
            zone="zone2"
            data={data.zone2 || []}
            onClick={(name, e) =>
              data.zone2 && this.clickFacet({ payload: { sKey: "regio", level: 1 }, key: name })
            }
          />
        </TitleBox>
        <div style={{ clear: "both" }} />
        {years.aggs.map((buckets, index) => (
          <TitleBox key={`years_${index}}`} title={years.lines[index].name}>
            <PieChart width={350} height={250}>
              <Tooltip formatter={formatter} />
              <MyPie
                data={buckets}
                nameKey="key"
                dataKey="value"
                label={(e) => (e.value / buckets.total > 0.01 ? i18n.t(e.key) : "")}
                onClick={this.clickFacet}
              >
                {buckets.map((e, i) => (
                  <Cell
                    key={`cell-${i}`}
                    fill={config.names[e.key]?.group?.color || "#3ed"}
                    stroke={buckets.selected ? "#139CC2" : null}
                    strokeWidth={3}
                  />
                ))}
              </MyPie>
            </PieChart>
          </TitleBox>
        ))}
        {["use_title", "soil", "r_soil", "water_trap"].map(
          (key, index) =>
            data[key] && (
              <TitleBox key={`data_${index}`} title={key}>
                <PieChart width={350} height={250}>
                  <Tooltip formatter={formatter} />
                  <MyPie
                    data={data[key]}
                    nameKey="key"
                    dataKey="value"
                    label={(e) => (e.value / data[key].total > 0.01 ? e.key : "")}
                    onClick={this.clickFacet}
                  >
                    {data[key].map((e, i) => (
                      <Cell
                        key={"c" + e.key}
                        stroke={data[key].selected ? "#139CC2" : null}
                        strokeWidth={3}
                        fill={config.names[e.key]?.group?.color || "#3ed"}
                      />
                    ))}
                  </MyPie>
                </PieChart>
              </TitleBox>
            )
        )}
        {data.area && (
          <TitleBox title={i18n.t("Field size")}>
            <BarChart
              width={350}
              height={250}
              data={data.area}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="key" />
              <YAxis scale="sqrt" />
              <Tooltip formatter={formatter} />
              <Bar dataKey="value" fill="#82ca9d" name={i18n.t("Number in group")} />
            </BarChart>
          </TitleBox>
        )}
        {/* regions count on a small country map, municipality if filtered on province */}
      </div>
    );
  }
}
