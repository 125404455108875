import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ColorBox } from "./util";
import { CropImage, getGroup } from "../util";
import { Tooltip } from "../panels";
import { config } from "../Config";
import i18n from "i18next";

const getSortedPredictionEntries = (predictions): [[string, number]] =>
  // @ts-ignore
  Object.entries(predictions).sort(([, a], [, b]) => b - a);

export const Prediction = ({ prediction, selected }) => {
  const predictions = getSortedPredictionEntries(prediction);
  const mostLikelyPrediction = getGroup(parseInt(predictions[0][0]));
  const percentages = predictions.map(([group, percentage]) => {
    const cropGroup = getGroup(parseInt(group));
    return (
      <p key={`prediction-${cropGroup.name}`}>
        <ColorBox color={cropGroup.color} />{" "}
        {i18n.t("{{percentage}}% chance on {{subject}}", {
          percentage: percentage.toFixed(),
          subject: i18n.t(cropGroup.name),
        })}
      </p>
    );
  });
  const elem = (
    <div
      className="icon-placeholder"
      style={
        selected
          ? {
              backgroundColor: mostLikelyPrediction.color,
              borderRadius: "10px",
              color: "#505050",
            }
          : null
      }
    >
      <CropImage name={mostLikelyPrediction.icon} />
    </div>
  );

  const description = (
    <span>
      {i18n.t("CropFields crop prediction for {{year}}", {
        year: config.crop_year + 1,
      })}
      :{percentages}
    </span>
  );

  return (
    <Fragment>
      {description ? <Tooltip description={description}>{elem}</Tooltip> : elem}
      <div>{config.crop_year + 1}</div>
      <div>{i18n.t(mostLikelyPrediction.name)}</div>
      <Link to="/beta" style={{ textDecoration: "none" }}>
        <span className="label label-labs">BETA</span>
      </Link>
    </Fragment>
  );
};
