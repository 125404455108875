import React, { memo, useState } from "react";
import { VerticalTodayLabel } from "../util";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
import { ReferenceLine } from "./util";
import moment from "moment";
import i18n from "i18next";
import { PRECIPITATION_DEFICIT } from "../../components/Legend";
import { uniqueId } from "lodash/core";
import { useSSelector } from "../../util";

interface WeatherTooltipProps {
  active?: boolean;
  payload?: any;
  label?: number;
}
const WeatherTooltip = ({ active, payload, label }: WeatherTooltipProps) => {
  if (!(active && payload[0])) return null;
  const p = payload[0];
  return (
    <div className="weather-tooltip">
      <div className="weather-tooltip-title">{moment.unix(label).format("DD-MM-YYYY")}</div>
      <table style={{ width: "200px" }}>
        <tbody>
          <tr>
            <td>{i18n.t("Deficit")}:</td>
            <td>{p.value.toFixed()}mm</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// TODO dit werkt nu alleen voor een tekort (negatieve waardes nog eens fixen)
const WeatherDiagram = ({ data, height = 250, onClick }) => {
  const layerConfig = useSSelector(({ layers }) => layers.config.precip_deficit);
  const now = moment().startOf("day").unix();
  const [_id] = useState(uniqueId("precipitationdef"));
  const selectedDay = layerConfig?.day || now;
  return (
    <AreaChart
      width={400}
      height={height + 50}
      data={data}
      margin={{ top: 5, right: 40, left: 0, bottom: 5 }}
      onClick={onClick}
    >
      <XAxis
        dataKey="unix"
        tickFormatter={(unix) => moment.unix(unix).format("DD-MM-YYYY")}
        type="number"
        scale="time"
        domain={[data[0].unix, data[data.length - 1].unix]}
      />
      <YAxis />
      <ReferenceLine x={selectedDay} strokeWidth="2" stroke="#888" />
      <ReferenceLine x={now} strokeWidth="10" label={<VerticalTodayLabel />} stroke="#ddd" />
      <defs>
        <linearGradient id={_id} x1="0" y1="0" x2="0" y2="160" gradientUnits="userSpaceOnUse">
          {PRECIPITATION_DEFICIT.slice(0, 4).map((e, i) => (
            <stop key={i} offset={`${(25 + (i * 100) / 4).toFixed()}%`} stopColor={e.color} />
          ))}
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="precipitation_deficit_accum"
        stroke={`url(#${_id})`}
        fill={`url(#${_id})`}
        name={i18n.t("Precipitation deficit")}
      />
      <Tooltip content={<WeatherTooltip />} />
    </AreaChart>
  );
};
const m = memo(WeatherDiagram);
export { m as WeatherDiagram };
