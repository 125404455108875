import React, { useCallback, useState } from "react";
import { A, accountFeatures, getCenter, useSSelector } from "../util";
import { Pro, showSubscriptionModal } from "../components/Pro";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import i18n from "i18next";
import { track } from "../track";
import "./Share.scss";
import { ShareModal } from "../modals";
import { MultiSelectField } from "../reducers";
import { DeleteListButton } from "../components/ListView";
import { Button } from "react-bootstrap";

// TODO add npm-module react-copy-to-clipboard link to copy the url on the desktop (instead of Whatsapp)
let _navigator: any = navigator;

function navShare(text) {
  if (window.innerWidth < 1024 && _navigator.share) {
    // 2021-03-30 window.innerWidth check as requested by Richard
    // see https://developers.google.com/web/updates/2016/09/navigator-share
    _navigator.share({
      title: i18n.t("CropFields link"),
      url: window.location,
      text: i18n.t("View {{subject}} on CropFields", { subject: text.thisSubject }),
    });
    return true;
  }
}

export const ShareButton = ({
  text,
  className = "btn-share",
  variant = "outline-primary",
  children = undefined,
}) => {
  const [showModal, setModal] = useState(false);

  return (
    <>
      <Button
        className={className}
        variant={variant}
        onClick={() => {
          navShare(text) || setModal(true);
          track("share", "show_modal", String(window.location));
        }}
      >
        {children || (
          <>
            <i className="fa fa-share-alt pr-2" /> {i18n.t("Share")}
          </>
        )}
      </Button>
      {showModal && <ShareModal text={text} onClose={() => setModal(false)} />}
    </>
  );
};

interface ShareFooterProps {
  text: any;
  cropfield?: any;
  bunder?: any;
  collection?: MultiSelectField[];
  view?: string;
}

export const ShareFooter = ({ text, cropfield, bunder, collection, view }: ShareFooterProps) => {
  const dispatch = useDispatch();
  const printFactsheet = useCallback(() => dispatch(push(`/print_report/`)), [dispatch]);
  const { pro } = useSSelector((state) => ({
    pro: accountFeatures(state.activeUser.account).pro,
  }));
  const coords =
    (cropfield && getCenter(cropfield, null, 5).reverse().join(",")) ||
    (collection && collection.map((f) => f.point).join(","));

  return (
    <footer className="mob_bb_share bg-white">
      <div className="d-flex py-2" aria-label="">
        {!bunder && (
          <div className="px-2 btn-group flex-fill" role="group">
            <ShareButton className="btn-share" text={text} />
          </div>
        )}
        {cropfield && (
          <div className="btn-group flex-fill" role="group">
            <button
              onClick={() => {
                pro ? printFactsheet() : showSubscriptionModal(dispatch, undefined, "print");
              }}
              type="button"
              className={`btn btn-outline-primary${pro ? "" : " disabled"}`}
            >
              {i18n.t("Report")} <Pro click={false} />
            </button>
          </div>
        )}
        {coords && (
          <div className="px-2 btn-group flex-fill" role="group">
            {pro ? (
              <A
                href={`/export/shape?p=${coords}${bunder ? "&s=" + bunder.id : ""}`}
                type="button"
                className="btn btn-outline-primary"
              >
                Shapefile
              </A>
            ) : (
              <button
                onClick={() => showSubscriptionModal(dispatch, undefined, "shp")}
                className="btn btn-outline-primary disabled"
              >
                Shapefile <Pro click={false} />
              </button>
            )}
          </div>
        )}
        <DeleteListButton show={view === "lists"} />
      </div>
    </footer>
  );
};
