import React from "react";
import { Icon } from "react-fa";

export const Spinner = () => {
  return (
    <h2 className="loading">
      <p className="text-center">
        <Icon spin name="spinner" />
      </p>
    </h2>
  );
};
