import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateCadastre } from "../../actions";
import { cadastreShortName } from "../../report/reference/Cadastre";
import { track } from "../../track";
import { getCenter } from "../../util";
import { TopPolygon, Label } from "../map_util";

type CadastrePolygonsProps = {
  feature: any;
  field: any;
};
export const CadastrePolygons = (props: CadastrePolygonsProps) => {
  const { feature, field } = props;
  const dispatch = useDispatch();
  const cadastre = field.cadastre;

  const handleClick = useCallback(
    (id) => {
      dispatch(
        updateCadastre({
          highlighted: cadastre.highlighted !== id ? id : undefined,
        })
      );
      track("report", "cadastre", "select_field");
    },
    [cadastre.highlighted, dispatch]
  );

  return (
    <>
      <TopPolygon
        key="cropfield_cad"
        feature={feature}
        fillOpacity={0.2}
        color={"#E6E628"}
        weight={5}
        fillColor="#E6E628"
      />
      {cadastre.features.map((feature) => (
        <CadastrePolygon
          highlight={cadastre.highlighted === feature.id}
          key={feature.id}
          feature={feature}
          onClick={handleClick}
        />
      ))}
    </>
  );
};

type CadastrePolygonProps = {
  highlight: boolean;
  feature: any;
  onClick: (id: number) => void;
};
const CadastrePolygon = (props: CadastrePolygonProps) => {
  const { onClick, feature, highlight } = props;

  const handleClick = useCallback(() => {
    onClick(feature.id);
  }, [feature.id, onClick]);

  const fillOpacity = highlight ? 1 : 0.5;
  const fillColor = highlight ? "#ffc341" : "#dddddd";

  return (
    <>
      <Label
        position={getCenter(feature).reverse()}
        html={cadastreShortName(feature)}
        onClick={handleClick}
      />
      <TopPolygon
        feature={feature}
        weight={1}
        color="black"
        dashArray="2, 5"
        fillOpacity={fillOpacity}
        fillColor={fillColor}
        onClick={handleClick}
      />
    </>
  );
};
