import { bbAxios, getCountry, toWKT } from "../util";
import { config } from "../Config";

export const updateHeightStatistics =
  (cropfield, version = "1") =>
  (dispatch, getState) => {
    // if (getState().selection.dem) return console.warn('trying to fetch again');
    const type = "FETCH_HEIGHT_STATISTICS";
    dispatch({ type, state: "fetching", version });
    bbAxios
      .post(`${config.LAYER_URL}/height/statistics/${version}`, {
        polygon: toWKT(cropfield.geometry),
        srid: 4326,
        country: getCountry(cropfield),
      })
      .then(({ data }) => dispatch({ type, state: "fetched", version, data }))
      .catch((err) => dispatch({ type, state: "failed", version }));
  };

export const updateShadowStatistics = (cropfield) => (dispatch, getState) => {
  const type = "FETCH_SHADOW_STATISTICS";
  dispatch({ type, shadowState: "fetching" });
  bbAxios
    .post(`${config.LAYER_URL}/height/shadow/`, {
      polygon: toWKT(cropfield.geometry),
      srid: 4326,
      country: getCountry(cropfield),
    })
    .then(({ data }) => {
      dispatch({
        type,
        shadowState: "fetched",
        shadowData: data,
        showShadow: true,
      });
    })
    .catch((err) => dispatch({ type, shadowState: "failed" }));
};
