import React from "react";
import { NavDropdown, NavItem, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

// https://github.com/react-bootstrap/react-router-bootstrap/issues/186#issuecomment-292798240

interface Props {
  href?: string;
  className?: string;
  children?: React.ReactNode;
  eventKey?: any;
  show?: boolean;
}

export const RNavDropdownItem = ({ href, className, children, eventKey, show = true }: Props) =>
  !show ? null : (
    <NavDropdown.Item as="li" eventKey={eventKey} className={className}>
      <LinkContainer to={href}>
        <Nav.Link>{children}</Nav.Link>
      </LinkContainer>
    </NavDropdown.Item>
  );

export const RNavItem = ({ href, className, children, eventKey, show = true }: Props) =>
  !show ? null : (
    <NavItem key={eventKey} className={className} as="li">
      <LinkContainer to={href}>
        <Nav.Link>{children}</Nav.Link>
      </LinkContainer>
    </NavItem>
  );
