import { SectionTitle } from "../util";
import React, { memo, useMemo } from "react";
import { SectionProps } from "./Sections";
import { TS, useSSelector } from "../../util";
import i18n from "i18next";

import { DEMDiagram } from "../diagrams";
import { Legend } from "./Legend";
import { ConfigurableLabel } from "../../components";

const SectionHeight = ({
  active,
  smallScreen,
  cropfield,
  features,
  inView,
  names,
  defaultVersion,
  labels,
}: SectionProps & { names: string[]; labels: string[]; defaultVersion?: string }) => {
  const [dem, shadow] = useSSelector(({ selection }) => [selection.dem, selection.shadow]);
  const shadowLegend = useMemo(
    () => ({
      1: i18n.t("No shadow"),
      2: i18n.t("Little shadow"),
      3: i18n.t("Some shadow"),
      4: i18n.t("Much shadow"),
    }),
    []
  );
  return (
    <section id="section_height" className={`large_sized ${active ? "active" : ""}`}>
      <SectionTitle name={i18n.t("Altitude")} extra={<ConfigurableLabel />}>
        <TS t="l_info_height" />
      </SectionTitle>
      <DEMDiagram
        dem={dem}
        shadow={shadow}
        cropfield={cropfield}
        smallScreen={smallScreen}
        multiple={features.pro}
        names={names}
        labels={labels}
        defaultVersion={defaultVersion}
        inView={inView}
        features={features}
      />
      {!smallScreen && shadow?.shadowData && shadow?.showShadow && (
        <Legend
          active={active}
          date={null}
          name={i18n.t("Shadow")}
          legendSpecs={shadow.shadowData.histogram
            .slice()
            .reverse()
            .map((h) => ({
              c: h.color,
              count: h.percentage,
              name: shadowLegend[h.min],
            }))}
        />
      )}
    </section>
  );
};
const m = memo(SectionHeight);
export { m as SectionHeight };
