import React from "react";
import i18n from "i18next";
import moment from "moment/moment";
import imgSample from "../../../img/sample/sample_photo.png";
import { accountFeaturesType, CropImage, getCenter, periodString } from "../../util";
import { config } from "../../Config";
import "./Header.scss";
import { Tag } from "../../panels/list/Tag";
import { HeaderButtons } from "./components/HeaderButtons";
import { Pro } from "../../components/Pro";
import { HeaderLoader } from "./HeaderLoader";

type Props = {
  accountFeatures?: accountFeaturesType;
  field: any;
  cropfield: any;
  select?: (any?) => void;
  liked?: boolean;
  gps?: string;
  returnUrl?: string;
};

export const Header = (props: Props) => {
  const { accountFeatures = {}, field, cropfield, liked, gps, returnUrl } = props;

  if (!cropfield) {
    return <HeaderLoader />;
  }

  const bunder = field.bunder;
  const crops = (field || cropfield).crops;
  const crop = crops?.length && config.codes[crops[crops.length - 1].code];
  const decimals = accountFeatures.pro ? 4 : 0;
  const area = bunder ? bunder.area : cropfield.properties.area;
  const center = bunder?.point || gps || getCenter(cropfield, null, 5).reverse().join(", ");
  const publicSource = (cropfield.id || "").split("-")[1];
  const source = bunder?.source || publicSource;

  let period = bunder ? periodString(bunder, "DD/MM/YY") : "";
  if (!period && publicSource && Number(source.substr(3)) > 0) {
    period = moment(`20${source.substr(3)}-05-01`).format("LL");
  }

  const cropName =
    `${bunder?.crop_name || crop?.name || i18n.t("Unknown")}` +
    (bunder?.variety_name ? ` ${bunder.variety_name}` : "");

  const totalArea = `${decimals > 0 ? "" : "~"}${area ? area.toFixed(decimals) : "?"} ha`;

  return (
    <header className="bunder_hero">
      <section
        className="c-hero c-hero--overlay-layer"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(8, 18, 15, 0.45), rgba(8, 18, 15, 0.45)), url(${imgSample})`,
        }}
      >
        <HeaderButtons
          returnUrl={returnUrl}
          liked={liked}
          location={center}
          fieldKey={bunder?.key}
          cropfield={cropfield}
        />
        <div className="header--content">
          {crop && (
            <div className="float-right">
              <figure className="c-hero__figure">
                <CropImage name={crop.group?.icon} />
              </figure>
            </div>
          )}
          <h4 className="header--title">{bunder?.name || cropName}</h4>
          <h5 className="header--subtitle">{period}</h5>
          <div className="tag-container">
            <Tag title={totalArea} />
            <Pro msg={i18n.t("Subscribe to view the exact area")} />
            <Tag title={i18n.t(crop?.group?.name) || cropName} />
            {source && <Tag title={`${i18n.t("Source")}: ${source}`} />}
            {bunder?.farm_name && <Tag title={`${i18n.t("Farm")}: ${bunder.farm_name}`} />}
            {bunder?.plan_name && <Tag title={`${i18n.t("Plan")}: ${bunder.plan_name}`} />}
          </div>
        </div>
      </section>
    </header>
  );
};
