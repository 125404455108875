import i18n from "i18next";
import React, { useCallback } from "react";

import { useSSelector } from "../../util";
import { useDispatch } from "react-redux";
import { makeFarmPublic, makeListPublic } from "../../actions";
import { push } from "connected-react-router";
import { ListSelector } from "./ListSelector";
import { config } from "../../Config";

export const MyFarmSettings = () => {
  const { farm, lists } = useSSelector(({ activeUser, favorites }) => ({
    farm: activeUser.account?.farm,
    lists: favorites?.lists || [],
  }));
  const dispatch = useDispatch();
  const publicList = lists.filter((l) => l?.public)[0],
    farmIsPublic = Boolean(farm?.public),
    publicListKey = publicList?.key || "";
  const onPublicListChange = useCallback(
    (listKey) => dispatch(makeListPublic(farm, listKey)),
    [farm, dispatch]
  );
  const onFarmPublicChange = useCallback(
    (e) => dispatch(makeFarmPublic(farm, e.currentTarget.checked)),
    [farm, dispatch]
  );

  if (!farm) {
    dispatch(push("/"));
    return null;
  }
  return (
    <div className="bb_farm media">
      <div className="media-body p-3 p-md-0">
        <h3 className="mt-3">{i18n.t("Settings")}</h3>
        <p>{i18n.t("Edit your profile and choose your settings.")}</p>
        <h5 className="mt-4">{i18n.t("Public")}</h5>
        <p>{i18n.t("Your contact information is public to other users.")}</p>
        <div>
          <input
            type="checkbox"
            aria-label={i18n.t("Make farm profile public")}
            onChange={onFarmPublicChange}
            defaultChecked={farmIsPublic}
          />{" "}
          {i18n.t("Yes, make my farm profile public")}
        </div>
        <div className="mt-2">
          {i18n.t("Make one of my lists public")}
          {config.show.includes("lease") && <span>, kies hier je pachtlijst.</span>}
          <ListSelector
            key="plan-selector"
            lists={lists}
            listKey={publicListKey}
            onChange={onPublicListChange}
            groupLists={true}
          />
        </div>
      </div>
    </div>
  );
};
