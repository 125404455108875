import { getCode, TS } from "../../util";
import React from "react";
import { Desc, FieldProp, FieldProps, MultiFieldProp } from "./FieldProps";
import { Flag } from "../Flag";

const nvwaPrefix = "https://www.nvwa.nl/onderwerpen/";
const regexExtId = /[^a-z]/g;

export class NLFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp(
        "no_fly_zone",
        "drone-restricted",
        Desc(
          "Voor hobbyisten is drone-vliegen hier beperkt toegestaan. Deze informatie is slechts indicatief",
          null,
          "https://rijksoverheid.nl/drones",
          "rijksoverheid.nl/drones"
        )
      ),
      new FieldProp("gwp", "gwp-img", (e) =>
        Desc(
          "Dit perceel ligt in een",
          e,
          "https://beschermjedrinkwater.nl/wat-zijn-grondwaterbeschermingsgebieden/",
          "beschermjedrinkwater.nl"
        )
      ),
      new FieldProp(
        "water_trap",
        "gwl-img",
        (e) => (
          <span>
            Dit perceel heeft grondwatertrap {getCode(e, "name")}. Lees meer{" "}
            <a href="https://www.h2owaternetwerk.nl/vakartikelen/landsdekkende-actuele-informatie-over-grondwatertrappen-digitaal-beschikbaar">
              over grondwatertrappen
            </a>
            .
          </span>
        ),
        (e) => getCode(e, "name").split(" = ")[0]
      ),
      // new FieldProp(
      //   "permanent_grass",
      //   "grs-img",
      //   Desc(
      //     "Dit is blijvend grasland",
      //     null,
      //     "https://www.rvo.nl/sites/default/files/2016/02/Blijvend%20graslandkaart.pdf",
      //     "blijvend grasland bij RVO"
      //   )
      // ),
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "Dit perceel valt in een gebied onder Natura 2000 regeling",
          e,
          "https://ec.europa.eu/environment/nature/natura2000/index_en.htm",
          "Europese Commissie"
        )
      ),
      new FieldProp("natura_2000_buffer", "dfz-img", (e) =>
        Desc(
          "Dit perceel valt in een derogatievrije zone",
          e,
          "https://www.rvo.nl/onderwerpen/mest/derogatie#voorwaarden-2024",
          "rvo.nl/onderwerpen/mest/derogatie"
        )
      ),
      new FieldProp("organic", "organic-img", "Biologisch perceel"),
      new FieldProp("forbidden_irrigation", "irrigation-potato-forbidden", (e) =>
        Desc(
          "Irrigatie beperking",
          e,
          `${nvwaPrefix}plantenziekten-en-plagen/bruinrot/verbodsgebieden-gebruik-oppervlaktewater`,
          "Verbodsgebieden"
        )
      ),
      new MultiFieldProp(
        "potato_disease",
        (e) => ((getCode(e) || {}).id === 1843 ? "potato-forbidden" : "potato-virus-img"),
        (e) => {
          if ((getCode(e) || {}).id === 1843) {
            return Desc(
              "",
              e,
              `${nvwaPrefix}teeltvoorschriften-akkerbouw-en-tuinbouw/documenten/plant/teeltvoorschriften/akkerbouw-en-tuinbouw/overzichtskaarten/aardappelmoeheid-aardappelteeltverbodsgebieden-overzichtskaart`,
              "Teeltverboden"
            );
          }
          return Desc(
            "Aardappel ziekte risico",
            e,
            `${nvwaPrefix}teeltvoorschriften-akkerbouw-en-tuinbouw/teeltvoorschrift-wratziekte`,
            "Teeltvoorschrift wratziekte"
          );
        },
        (e) => {
          const m = /Wratziekte Gebied ([A-C])$/.exec(getCode(e, "name") || "");
          return (m && m[1]) || "";
        }
      ),
      // new FieldProp('soil-pp', 'soil-pp', "Bodempaspoort")
      new FieldProp("property_i", "state-img", (e) => (
        <TS
          t="This plot is owned by state institute <i>{{name}}</i>"
          o={{ name: getCode(e, "name") }}
        />
      )),
      new FieldProp(
        "zonew",
        null,
        (e) => `Dit perceel ligt in waterschap ${e.name}`,
        (e) => (
          <div className="attribute-img">
            <Flag
              field={{
                name: e.name,
                // eslint-disable-next-line camelcase
                ext_id: (e.name || "").normalize("NFKD").toLowerCase().replace(regexExtId, ""),
              }}
              type="WTB"
              className="flag-iconfieldprop"
            />
          </div>
        )
      ),
      /*new FieldProp(
        "zonec",
        null,
        (e) => `Dit perceel ligt in Agrarisch natuurcollectief ${e.name}`,
        ({ name, ext_id }) => (
          <div className="attribute-img">
            <Flag
              field={{
                name,
                ext_id,
              }}
              type="ANC"
              className="flag-iconfieldprop"
            />
          </div>
        )
      ),*/
      new FieldProp(
        "ammonia",
        "nh4-img",
        Desc(
          "Valt in WAV-gebied (Wet Ammoniak en Veehouderij). WAV-gebieden zijn " +
            "zeer kwetsbare gebieden volgens de Wet Ammoniak en Veehouderij. " +
            "Dit zijn voor verzuring gevoelige gebieden, of delen daarvan, " +
            "die zijn gelegen binnen het Natuurnetwerk Nederland (NNN).",
          null,
          "https://www.infomil.nl/onderwerpen/landbouw/ammoniak/wav/vragen-en-antwoorden/zeer-kwetsbare/",
          "Kenniscentrum InfoMil"
        )
      ),
    ];
  }
}
