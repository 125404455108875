import $ from "jquery";
import _ from "lodash/core";
import { NotificationManager } from "react-notifications";
import {
  bbAxios,
  bbox,
  fromWkt,
  getBBOX,
  getCenter,
  getCode,
  isNumber,
  isPointInsideFeature,
  toWKT,
} from "../util";
import { goBack, push, replace } from "connected-react-router";
import { config } from "../Config";
import { track } from "../track";
import Raven from "raven-js";
import i18n from "i18next";
import L from "leaflet";
import Cookies from "universal-cookie";
import axios from "axios";

export * from "./height";
export * from "./lists";
export * from "./rvo";
export * from "./satellite";
export * from "./water";
export * from "./weather";

export function myPush(path) {
  if (!path.startsWith("/filter")) return push(path);
  return (dispatch, getState) => {
    return dispatch(push("/filter/" + (getState().searchFilter || "")));
  };
}

export const updateCadastre = (props) => (dispatch) => {
  dispatch({ type: "UPDATE_CADASTRE", ...props });
};

export const updateLease = (myConditions, myRewards) => {
  return {
    type: "UPDATE_LEASE",
    payload: {
      myConditions,
      myRewards,
    },
  };
};

export const refreshFavorites = (farmExternalId) => (dispatch) => {
  if (!farmExternalId) return;
  const type = "UPDATE_FAVORITES";
  dispatch({ type, state: "updating" });
  bbAxios(`/refresh_favorites/${farmExternalId}`)
    .then(({ data }) => {
      const { fields = [], lists = [] } = data;
      dispatch({
        type: type,
        state: "updated",
        lists: lists,
        fields: fields,
      });
    })
    .catch((err) => {
      console.error("Error while updating favorites", err);
      dispatch({ type, state: "failed" });
    });
};

export const clickGPS = (latlng, feature?) => (dispatch, getState) => {
  if (!(isNumber(latlng?.lat) && isNumber(latlng.lng))) return;
  const s = getState();
  if (/ews/.test(s.router.location.pathname)) {
    return;
  }

  if (s.followGps) dispatch({ type: "FOLLOW_GPS", followGps: false });
  if (s.favorites?.fields && /plot|farm/.test(s.router.location.pathname)) {
    // TODO use inspect feature for plot-identity
    const selectedPlot = s.favorites.fields.find((f) => {
      if (f.geometry) {
        const bb = getBBOX(f.geometry);
        return (
          L.latLngBounds([bb[1], bb[0]], [bb[3], bb[2]]).contains(latlng) &&
          ["PLAN", "REGULATORY"].indexOf(f.type) > -1
        );
      }
      return false;
    });
    if (selectedPlot) {
      // TODO support multiselect
      return dispatch(push(`/plot/${selectedPlot.key}`));
    }
  }
  if (feature) {
    // always click center of feature
    const center = getCenter(feature, undefined, 5);
    latlng = L.latLng(center[1], center[0]);
  }
  let param = `${latlng.lat.toFixed(5)},${latlng.lng.toFixed(5)}`;
  if (s.multiSelect) {
    const { selectedFields = [] } = s.selection;
    const p = [latlng.lng, latlng.lat];
    const newSelectedFields = selectedFields.filter((e) => !isPointInsideFeature(p, e.feature));
    const removed = newSelectedFields.length !== selectedFields.length;
    if (!removed) newSelectedFields.push({ gps: [latlng.lat, latlng.lng], point: param, feature });
    if (removed || (!removed && feature)) {
      dispatch({
        type: "FETCHED_FIELD",
        ...s.selection,
        selectedFields: newSelectedFields,
      });
    }
    param = newSelectedFields.map(({ point }) => point).join(";");
    // dispath: extend selectedFields with {point: param, feature}
    dispatch(push(`/select/${param}`));
  } else {
    dispatch(push(`/report/${param}`));
  }
};

export const fetchFarms = () => (dispatch, getState) => {
  if (getState().farms === "fetching") return;
  const type = "FETCH_FARMS";
  dispatch({ type });
  bbAxios("/api/farms/?in_bbox=" + config.bounds.join(","))
    .then(({ data: farms }) => {
      dispatch({ type, farms });
    })
    .catch((err) => {
      console.error("Error updating farms", err);
      NotificationManager.error(i18n.t("Failed to fetch farms"));
    });
};

export const makeFarmPublic =
  (farm, toggle = true) =>
  (dispatch) => {
    dispatch({ type: "REFRESH_ACCOUNT", refreshing: true });
    bbAxios
      .post("/make_farm_public/", {
        farm_id: farm.id,
        toggle,
      })
      .then(({ data }) => {
        dispatch({ type: "REFRESH_ACCOUNT", ...data }); // refreshes only farm from public_farm db
        dispatch(fetchFarms());
        dispatch(refreshFavorites(farm.external_id));
        NotificationManager.success(i18n.t("The public state of your farm has been changed"));
      })
      .catch((err) => {
        dispatch({ type: "REFRESH_ACCOUNT", refreshing: false });
        console.error("Error while making farm public", err);
        NotificationManager.error(i18n.t("Failed to make farm public"));
      });
  };

export const selectFarm = (farmId) => (dispatch, getState) => {
  const state = getState();
  const type = "SELECT_FARM";
  // This breaks some state cleaning. SO always reload the farm when this is clicked.
  // if (selection.state || selection?.id === farmId) return;
  dispatch({ type, farm: { state: "fetching" } });
  bbAxios(`/get_all_crop_fields/${farmId}`)
    .then(({ data: farmFields }) => {
      let farm = state.farms[farmId];
      if (!farm && state.activeUser.account && farmId === state.activeUser.account.farm_id)
        farm = state.activeUser.account.farm;
      if (!farm) {
        dispatch({ type, farm: { id: farmId, state: "failed" } });
        // dispatch(goBack());
      } else {
        dispatch({ type, farm, farmFields });
      }
      dispatch(getProductLinks());
    })
    .catch((err) => {
      console.error("Error selecting farm", err);
      NotificationManager.error(i18n.t("Failed to fetch farm plots"));
      dispatch({ type, farm: { id: farmId, state: "failed" } });
      dispatch(goBack());
    });
};

export const selectMapFields = (fields) => (dispatch, getState) => {
  const state = getState(),
    type = "SELECT_FARM",
    farm = state.activeUser?.account?.farm,
    farmFields = fields.filter((f) => f.geometry).map(fromWkt);
  dispatch({ type, farm, farmFields });
};

export const selectGPS =
  (gps, zoom = 15) =>
  (dispatch, getState) => {
    if (getState().router.location.pathname === "/search") dispatch(push("/"));
    dispatch({ type: "QUICK_SEARCH_RESULTS_UPDATE", marker: gps });
    dispatch({ type: "MAP_UPDATE", state: { center: gps, zoom: zoom } });
  };

export const updateView = (data) => ({ type: "VIEW_UPDATE", data });
export const updateLayerConfig = (data) => ({ type: "LAYER_UPDATE", config: data });
export const toggleCropYear = (year) => (dispatch, getState) => {
  dispatch({ type: "SELECT_YEAR", year: getState()?.selection?.year === year ? undefined : year });
};

export const setSection = (section, gps) => (dispatch) => {
  const path = window.location.pathname;
  if (gps)
    dispatch(
      replace(`${path.substr(0, path.indexOf(gps) + gps.length)}` + (section ? `/${section}` : ""))
    );
  dispatch({ type: "SET_SECTION", section: section });
};

export const refreshAccount = (farmId, planId) => (dispatch) => {
  dispatch({ type: "REFRESH_ACCOUNT", refreshing: true });
  const data = farmId || planId ? { farm_id: farmId, plan_id: planId } : null;
  $.getJSON("/refresh_account/", data, function (data) {
    dispatch({ type: "REFRESH_ACCOUNT", ...data });
    const externalId = data?.account?.farm?.external_id || "";
    dispatch(refreshFavorites(externalId));
  }).fail((xhr, status, err) => {
    dispatch({ type: "REFRESH_ACCOUNT", refreshing: false });
    NotificationManager.error(
      farmId ? i18n.t("Failed to change account") : i18n.t("Could not refresh")
    );
  });
};

export function enhanceCrops(crops, feature) {
  for (let d of crops) {
    let extra = d.features.reduce((memo, cropData) => {
      const p = cropData.properties,
        name = getCode(p, "name");
      if (!memo[name]) memo[name] = { area: 0, color: getCode(p, "color") || "#ffffff" };
      memo[name].area += p.area;
      return memo;
    }, {});
    _.each(extra, (value, key) => {
      extra[key].area = feature.properties.area
        ? Math.round((value.area * 100) / feature.properties.area)
        : "?";
    });
    d["extra"] = extra;
  }
}

// This action should be refactored in multiple actions.
// It does too many different things. Fort example the GPS argument could be coordinates, or a key of a field.
// Based on that the way the fetching is done changes. This also results in a different state.
// In the first case with coordinates, the field does ot have a bunder field, n the second case with the key it does.
// For some reason this is linked to wether a cropfield is liked or not. I feel `fetchCropField`
// should not have anything to do with the liked fields whatsoever, seperation of concerns.
export const fetchCropField = (gps) => async (dispatch, getState) => {
  dispatch({ type: "FETCH_FIELD", field: { state: "fetching" }, gps: gps });
  const state = getState(),
    ownFields = state.favorites?.fields || [],
    sharedField = state.favorites.sharedField;
  let request,
    point = "",
    bunder = null;
  if (gps.length === 1) {
    bunder = ownFields.find((f) => f.key === gps[0]) || sharedField;
    if (bunder?.geometry) {
      request = bbAxios.post(
        `${config.LAYER_URL}/history/`,
        typeof bunder.geometry === "string" ? bunder.geometry : toWKT(bunder.geometry)
      );
    } else {
      point = bunder?.point; // supporting old likes without geometry
    }
  } else {
    point = gps.join(",");
  }
  if (!request && point) {
    const source = config.__ ? "" : config.crop_year;
    request = bbAxios(`${config.LAYER_URL}/history/${source}?p=${point}${config.countryFilter}`);
  }
  if (!request) return;
  const response = await request.catch((err) => {
    console.warn("Can not fetch field");
    dispatch({ type: "DESELECT" });
    dispatch(replace("/"));
  });
  if (!response?.data) return;
  let data = response.data;
  let { country, crops, feature, cadastre } = data;
  if (bunder) feature.properties.area = bunder.area;
  if (country) feature.properties.country = country;
  enhanceCrops(crops, feature);
  // Filter cadastre plots for >200m^2 . We use simplified geometries for BRP, so be careful when interpreting small overlaps.
  cadastre = {
    features: ((cadastre && cadastre.features) || []).filter(
      (f) => f.properties.overlap_area > 0.02
    ),
  };
  if (!bunder && feature.id) {
    const cookies = new Cookies();
    let clicks = cookies.get("clicks") || [];
    let featureId = parseInt(feature.id.split("-").slice(-1)[0]);
    if (!clicks.includes(featureId)) {
      clicks.push(featureId);
      const date = new Date(); // always set expires on last day of this week (sunday)
      date.setDate(date.getDate() - date.getDay() + 7);
      date.setHours(0, 0, 0, 0);
      cookies.set("clicks", clicks, { path: "/", expires: date });
    }
  }
  dispatch({
    type: "FETCHED_FIELD",
    field: { state: "fetched", gps, bunder, ...data, cadastre },
  });
};

export const fetchSelection = (gps) => async (dispatch, getState) => {
  const { selection } = getState();

  const selectedFields = selection.selectedFields || [];

  const map = selectedFields.reduce((memo, f) => {
    memo[f.point] = f;
    return memo;
  }, {});

  const gpsCoords = gps.split(";");
  const toFetch = gpsCoords.filter((gps) => !map[gps]);

  if (!toFetch.length) {
    if (gpsCoords.length !== selectedFields.length) {
      dispatch({
        type: "FETCHED_FIELD",
        ...selection,
        selectedFields: selectedFields.filter((f) => gpsCoords.includes(f.point)),
      });
    }
    return;
  }

  // TODO first search state.inView.cropfields
  const url = config.GEO_REFERENCE_URL.replace("bbox=", "p=");
  const response = await bbAxios(`${url}${toFetch.join(",")}`);
  if (!(response && response.data)) return;
  let data = response.data;
  const newSelectedFields = selectedFields.filter((f) => gpsCoords.includes(f.point));
  for (const feature of data.features) {
    // find matching gps coordinate for feature
    const match = toFetch.find((param) => {
      const p = param.split(",").map(parseFloat).reverse();
      return isPointInsideFeature([p[0], p[1]], feature);
    });
    if (!match) {
      console.error("Can not match feature to GPS", feature, toFetch);
      continue;
    }
    newSelectedFields.push({ gps: match.split(",").map(parseFloat), point: match, feature });
  }
  if (newSelectedFields.length) {
    dispatch({
      type: "FETCHED_FIELD",
      ...selection,
      selectedFields: newSelectedFields,
    });
  }
  if (gpsCoords.length !== newSelectedFields.length) {
    const newGps = data.features.map((f) => getCenter(f, undefined, 5).join(",")).join(";");
    dispatch(replace(`/select/${newGps}`));
  }

  if (newSelectedFields.length) {
    dispatch(zoomTo(newSelectedFields.map((f) => f.feature)));
  }
};

export const zoomTo = (features) => {
  const b = bbox(features);
  return {
    type: "MAP_UPDATE",
    state: { bounds: L.latLngBounds([b[1], b[0]], [b[3], b[2]]) },
  };
};

export const checkLoggedInUser = () => (dispatch, getState) => {
  if (getState().activeUser.state) return console.warn("trying to fetch again");
  dispatch({ type: "GET_USER", state: "fetching" });
  axios("/get_user/")
    .then(({ data }) => {
      const { account, settings, tokens } = data;
      if (!_.isEmpty(account) && account.user) {
        const user = account.user || {};
        Raven.setUserContext({ email: user.email, name: `${user.first_name} ${user.last_name}` });
        Raven.setTagsContext({
          account: account.features?.pro ? "pro" : "free",
          staff: user.is_staff,
        });
        dispatch({ type: "GET_USER", state: "loggedIn", account, settings });
        dispatch(getProductLinks());
        const externalId = account?.farm?.external_id || "";
        dispatch(refreshFavorites(externalId));
        document.cookie = "clicks=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      } else {
        dispatch({ type: "GET_USER", state: "loggedOut" });
      }
      if (tokens) dispatch({ type: "UPDATE_TOKENS", tokens: tokens });
    })
    .catch((err) => {
      console.error("Error login", err);
      dispatch({ type: "GET_USER", state: "failed" });
    });
  const path = window.location.pathname;
  track("open", path.split("/")[1], document.referrer || path);
};

export const showModal = (props) => ({ type: "SHOW_MODAL", ...props });

export const updateSettings = (settings) => {
  return (dispatch, getState) => {
    const type = "UPDATE_SETTINGS";
    const { activeUser } = getState();
    if (activeUser?.state === "loggedIn") {
      bbAxios
        .post("/update_settings/", settings)
        .then(({ data }) => dispatch({ type, settings: data }))
        .catch((err) => {
          console.error("Can not update settings", err);
          dispatch({ type, settings });
        });
    } else {
      dispatch({ type, settings });
    }
  };
};

export const getProductLinks = () => (dispatch, getState) => {
  const farmDid = getState().activeUser?.account?.farm?.external_id;
  const type = "GET_PRODUCT_LINKS";

  dispatch({ type, state: "fetching" });
  const url = "/get_product_links/" + (farmDid || "");
  bbAxios(url)
    .then(({ data }) => dispatch({ type, state: "fetched", response: data }))
    .catch((err) => {
      console.error("Error getting product links", err);
      dispatch({ type: "GET_PRODUCT_LINKS", state: "failed" });
    });
};

export const updateLeaseConditions = (conditions) => {
  return (dispatch, getState) => {
    const { activeUser } = getState();
    if (activeUser?.state === "loggedIn") {
      bbAxios
        .post("/update_lease_conditions/", conditions)
        .then(() => {
          NotificationManager.success("Pachtvoorwaarden zijn geüpdate");
          dispatch({ type: "UPDATE_FARM_LEASE_DATA", ...conditions });
        })
        .catch((err) => {
          console.error("Can not update settings", err);
        });
    }
  };
};

export const fetchLeaseForField = (field) => {
  return (dispatch, getState) => {
    const url = "/find_lease/";
    const body = {
      geometry: field.geometry,
    };

    bbAxios
      .post(url, body)
      .then(({ data }) => dispatch(updateLease(data.myConditions, data.myRewards)))
      .catch((err) => {});
  };
};

export const layerToReport = {
  soil: "soil",
  r_soil: "soil",
  lease: "property",
  dem: "height",
  cadastre: "property",
  n2k: "nature",
};

export const pushGPSReport = (gps) => (dispatch, getState) => {
  const state = getState();
  const activeLayers = state.layers.selected;
  // look at selected background layer OR current section of current report
  const keepSection = activeLayers[0] ? layerToReport[activeLayers[0]] : state.selection.section;
  if (keepSection) {
    dispatch(push(`/report/${gps}/${keepSection}`));
  } else {
    dispatch(push(`/report/${gps}`));
  }
};
