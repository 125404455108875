import { Desc, FieldProp, FieldProps } from "./FieldProps";
import { getCode } from "../../util";

export class DENWFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp(
        "gwp",
        "gwp-img",
        (e) =>
          Desc(
            "Er ist untersagt, Stoffe, die Wasser verunreinigen können, einzubringen oder sie versickern zu lassen. Wasserschutzzone: ",
            e,
            "https://de.wikipedia.org/wiki/Wasserschutzgebiet",
            "Grundwasserschutzgebiet"
          ),
        (e) => getCode(e, "name")
      ),
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "In der Europäischen Union wurde 1992 beschlossen ein Schutzgebietsnetz (Natura 2000) aufzubauen, welches dem Erhalt wildlebender Pflanzen- und Tierarten und ihrer natürlichen Lebensräume dient. Dafür wurden verschiedene Richtlinien erlassen und in den einzelnen Ländern Schutzgebiete nach diesen Richtlinien erlassen",
          e,
          "http://www.ffh-gebiete.de/",
          "Natura 2000 in Deutschland"
        )
      ),
      new FieldProp("nitrate", "nitrate-img", (e) =>
        Desc(
          "Nitratbelastete Gebiete",
          e,
          "https://www.land.nrw/de/pressemitteilung/duengeregulierung-ausweisung-neuer-gebietskulisse",
          "Lanuv / ELWASWeb"
        )
      ),
    ];
  }
}
