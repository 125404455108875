import React from "react";
import { useDispatch } from "react-redux";
import { CustomControl } from "./CustomControl";
import { useRouteMatch } from "react-router";
import { push } from "connected-react-router";
import { useSSelector } from "../../util";

type Props = {
  position: L.ControlPosition;
};

export const MultiSelectControl = (props: Props) => {
  const { position } = props;

  const allowMultiSelect = useSSelector((s) =>
    Boolean(s?.activeUser?.settings?.useFieldSelectionTool)
  );

  const dispatch = useDispatch();
  const match = useRouteMatch("/select/");

  const handleClick = () => {
    if (!match) {
      dispatch(push("/select"));
    } else {
      dispatch(push("/"));
    }
  };

  if (!allowMultiSelect) {
    return <></>;
  }

  return (
    <CustomControl position={position}>
      <div className="leaflet-bar">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="leaflet-control-track-location" role="button" onClick={handleClick}>
          <i className={`fa fa-${match ? "object-ungroup" : "square-o"}`} />
        </a>
      </div>
    </CustomControl>
  );
};
