import React, { useCallback, useEffect } from "react";
import i18n from "i18next";
import { Bunder } from "../../panels";
import { config } from "../../Config";
import { RefreshButton } from "../../components";
import { ListSelector } from "./ListSelector";
import { replace } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { refreshAccount } from "../../actions";

export const PlanTab = ({ plans, plan, planFields, farm, lists }) => {
  const dispatch = useDispatch();
  const { planKey } = useParams();
  const onChange = useCallback(
    (planKey) => dispatch(replace(`/my_farm/plans/${planKey}`)),
    [dispatch]
  );
  useEffect(() => {
    if (!(planKey && plan && plan.key !== planKey)) return;
    const selectedList = lists?.find((l) => l.key === planKey);
    dispatch(refreshAccount(farm.external_id, selectedList.external_id));
  }, [farm.external_id, lists, planKey, plan, dispatch]);
  if (!plan) return null;
  return (
    <div>
      <ListSelector
        key="plan-selector"
        lists={plans || []}
        listKey={plan.key}
        onChange={onChange}
      />
      {planFields?.map((e) => (
        <Bunder key={e.id} bunder={e} returnUrl={`/my_farm/plans/${plan.key}`} />
      ))}
      {farm && (
        <div className={"mt-2"}>
          <a
            href={`${config.DACOM_URL}/cropr/farm/plan/`}
            className="d-none btn btn-primary d-md-inline-block mr-3"
          >
            <i className="fa fa-pencil mr-2" />
            {i18n.t("Edit plots")}
          </a>

          <RefreshButton farmExternalId={farm.external_id} planExternalId={plan.external_id} />
        </div>
      )}
    </div>
  );
};
