import React from "react";
import { connect } from "react-redux";
import { selectGPS } from "../actions";
import i18n from "i18next";
import GeoSuggest from "./suggest/GeoSuggest";
import "./QuickSearch.scss";
import { track } from "../track";

interface QuickSearchProps {
  dispatch: (any) => void;
  hide: boolean;
  currentLocation: any;
}

function cancel() {
  return false;
}

const QuickSearch = ({ dispatch, hide, currentLocation }: QuickSearchProps) => {
  if (hide) return <div />;

  function suggest(s, input?) {
    if (!(s && s.location)) return;
    let zoom = s.zoom;
    if (!zoom) {
      const viewport = s?.gmaps?.geometry?.viewport?.b;
      zoom = 15; // default
      if (viewport?.b) {
        // lijkt veranderd, met Ya enzo
        const GLOBE_WIDTH = 256,
          west = viewport.b,
          east = viewport.f,
          angle = (east - west + 360) % 360;
        zoom = Math.min(
          Math.round(
            Math.log((document.getElementById("map").clientWidth * 360) / angle / GLOBE_WIDTH) /
              Math.LN2
          ),
          15
        );
      }
    }
    dispatch(selectGPS(s.location, zoom));
    setTimeout(() => track("search", "suggest", input), 10); // change GPS first
  }

  return (
    <div className="mod_bb_quicksearch">
      <div>
        <form onSubmit={cancel}>
          <div className="form-group">
            <GeoSuggest
              className="geo_address"
              placeholder={i18n.t("Search for address, city, zip, GPS or XY")}
              country="NL"
              onSuggestSelect={suggest}
              currentLocation={currentLocation}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  hide: !!state.selection?.type && !props.forceShow,
  currentLocation: state.currentLocation?.latlng,
});
const m = connect(mapStateToProps)(QuickSearch);
export { m as QuickSearch };
