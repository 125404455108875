import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { updateLeaseConditions } from "../../actions";
import { useDispatch } from "react-redux";
import { useSSelector } from "../../util";

interface InpType {
  name: string;
  isChecked: boolean;
  inputValue?: string;
  type?: "checkbox" | "text";
  placeholder?: string;
  children: React.ReactNode;
}

const Inp = ({
  name,
  isChecked,
  inputValue = undefined,
  type = "checkbox",
  placeholder = undefined,
  children,
}: InpType) => {
  const [checked, setChecked] = useState(isChecked);
  const [value, setValue] = useState(inputValue);
  if (checked === undefined && checked !== isChecked) {
    setChecked(isChecked);
  }
  if (value === undefined && inputValue !== value) {
    setValue(inputValue);
  }
  let formElement;
  if (type === "checkbox") {
    formElement = (
      <>
        <input
          name={name}
          id={name}
          type="checkbox"
          checked={checked || false}
          onChange={() => {
            setChecked(!checked);
          }}
          className="form-check-input"
        />
        <label htmlFor={name} className="form-check-label">
          {children}
        </label>
      </>
    );
  } else {
    formElement = (
      <>
        <input
          name={name}
          id={`${name}Checkbox`}
          type="checkbox"
          className="form-check-input"
          checked={checked || false}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        />{" "}
        <label htmlFor={`${name}Checkbox`} className="form-check-label">
          {children}
        </label>{" "}
        <input
          name={name}
          id={`${name}Input`}
          type="text"
          placeholder={placeholder}
          disabled={!checked}
          className="form-control"
          value={(checked && value) || ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </>
    );
  }
  return (
    <li>
      <div className="form-group form-check">{formElement}</div>
    </li>
  );
};

export const LeaseConditions = () => {
  const dispatch = useDispatch();
  const leaseConditions = useSSelector(
    (state) => state.activeUser?.account?.farm?.lease_data || null
  );
  const submitForm = (e) => {
    e.preventDefault();
    const t = e.target;
    const conditions = {
      myConditions: {
        skal: t.skal.checked,
        obi: t.obi.checked,
        nat: t.nat.checked,
        fertilizer: t.fertilizer.checked,
        chemical: t.chemical.checked,
        other: t.otherCheckbox.checked,
        otherDesc: t.otherInput.value,
      },
      myRewards: {
        discount: t.rewardDiscountCheckbox.checked,
        discountDesc: t.rewardDiscountInput.value,
        long: t.rewardLong.checked,
        other: t.rewardOtherCheckbox.checked,
        otherDesc: t.rewardOtherInput.value,
      },
    };
    dispatch(updateLeaseConditions(conditions));
  };
  return (
    <div className="row justify-content-center pt-2">
      <div className="col-xs-12 col-sm-10">
        <form onSubmit={(e) => submitForm(e)}>
          <h2>Mijn (duurzame) pachtbeleid</h2>
          <p>
            In dit scherm kun je als verpachter aangeven welke duurzame beloningen en voorwaarden je
            hanteert op je pachtpercelen. Deze informatie wordt zichtbaar voor alle percelen die je
            hebt toegevoegd aan je profiel.
          </p>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <h3>Mijn voorwaarden</h3>
              <ul className="list-unstyled">
                <Inp name="skal" isChecked={leaseConditions?.myConditions?.skal}>
                  Skal gecertificeerd
                </Inp>
                <Inp name="obi" isChecked={leaseConditions?.myConditions?.obi}>
                  OBI ingevuld
                </Inp>
                <Inp name="nat" isChecked={leaseConditions?.myConditions?.nat}>
                  Natuurinclusief
                </Inp>
                <Inp name="fertilizer" isChecked={leaseConditions?.myConditions?.fertilizer}>
                  Geen kunstmest
                </Inp>
                <Inp name="chemical" isChecked={leaseConditions?.myConditions?.chemical}>
                  Geen Gewasbeschermingsmiddelen
                </Inp>
                <Inp
                  name="other"
                  isChecked={leaseConditions?.myConditions?.other}
                  inputValue={leaseConditions?.myConditions?.otherDesc}
                  type="text"
                  placeholder="..."
                >
                  Anders, namelijk
                </Inp>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6">
              <h3>Mijn beloningen</h3>
              <ul className="list-unstyled">
                <Inp
                  name="rewardDiscount"
                  isChecked={leaseConditions?.myRewards?.discount}
                  inputValue={leaseConditions?.myRewards?.discountDesc}
                  type="text"
                  placeholder="(korting in %)"
                >
                  Korting op de pachtprijs, namelijk
                </Inp>
                <Inp name="rewardLong" isChecked={leaseConditions?.myRewards?.long}>
                  Langdurige pachtovereenkomst
                </Inp>
                <Inp
                  name="rewardOther"
                  isChecked={leaseConditions?.myRewards?.other}
                  inputValue={leaseConditions?.myRewards?.otherDesc}
                  type="text"
                  placeholder="..."
                >
                  Anders, namelijk
                </Inp>
              </ul>
            </div>
          </div>
          <p>
            Je kunt alleen een voorwaarde invullen als hier ook een beloning aan hangt. Op deze
            manier stimuleren we een gelijkwaardige samenwerking tussen pachters en verpachters en
            een duurzaam pachtbeleid.
          </p>
          <Button variant="primary" type="submit" className="btn">
            Opslaan
          </Button>
        </form>
      </div>
    </div>
  );
};
