import React from "react";
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
import { Icon } from "react-fa";
import { Placement } from "react-bootstrap/Overlay";

// Tooltip that sticks on hover
interface TooltipProps {
  component?: any;
  description?: any;
  children?: any;
  placement?: Placement;
  delay?: number;
  delayHide?: number;
}

export const Tooltip = ({
  component,
  description,
  children,
  placement = "bottom",
  delay = 150,
  delayHide = 1000,
}: TooltipProps) => (
  <OverlayTrigger
    placement={placement}
    overlay={<BSTooltip id="single_tooltip">{component || description}</BSTooltip>}
    delay={{ show: delay, hide: delayHide }}
    trigger={["hover", "focus"]}
  >
    {children}
  </OverlayTrigger>
);

export const IconTooltip = ({ icon, children, ...rest }) => (
  <Tooltip component={children}>
    <Icon {...rest} name={icon} />
  </Tooltip>
);

export const ITooltip = ({ icon = "icon-circle", children, ...rest }) =>
  children ? (
    <Tooltip component={children}>
      <Icon {...rest} name={icon} />
    </Tooltip>
  ) : null;
