import React, { useCallback } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { config } from "../Config";
import { Icon } from "react-fa";
import { TS, useSSelector } from "../util";
import { Modal } from "react-bootstrap";
import imgBbLogo from "../../img/logo/bb_logo_192x192.png";
import i18n from "i18next";
import "./Page.scss";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { Contact, Lease } from "./pages";
import { LeaseConditions } from "./pages/LeaseConditions";

const Scrollable = ({ children, className = undefined, ...props }) => (
  <div className={`mod-bb-scroll-wrapper${className ? " " + className : ""}`} {...props}>
    {children}
  </div>
);

const ContentLegal = () => (
  <div className="row">
    <div className="col-12">
      <img src={imgBbLogo} alt={i18n.t("CropFields")} className="welcome-logo" />
      <p>
        {i18n.t("TextTerms")} <Icon name="info-circle" />
      </p>
      <p>{i18n.t("By using this website you agree to the terms above")}.</p>
    </div>
  </div>
);

const termsAndConditions = {
  nl: "https://www.dacom.nl/nl/algemene-voorwaarden/",
  en: "https://www.dacom.nl/en/terms-and-conditions/",
};
const privacyStatements = {
  nl: "https://www.dacom.nl/nl/privacyverklaring/",
  en: "https://www.dacom.nl/en/privacy-declaration/",
};

const ContentLegalCookie = () => (
  <div className="row">
    <div className="col-12">
      <img src={imgBbLogo} alt={i18n.t("CropFields")} className="welcome-logo" />
      <p>
        {i18n.t("TextTerms")} <Icon name="info-circle" />
      </p>
      <p>
        <TS
          t="TextCookies"
          o={{
            termsAndConditionsUrl: termsAndConditions[config.language] || termsAndConditions.en,
            privacyStatementUrl: privacyStatements[config.language] || privacyStatements.en,
            cookieDays: config.cookieDays,
          }}
        />
      </p>
    </div>
  </div>
);

const pageLink = (page) => (page.startsWith("/") ? page : `/page/${page}`);
const LinkOrNot = ({ page, selected = false, children }) =>
  !selected ? <Link to={pageLink(page)}>{children}</Link> : <>{children}</>;

export const Page = () => {
  const { name } = useParams();
  const location = useLocation();
  const { activeUser, productLinks } = useSSelector(({ activeUser, productLinks }) => ({
    activeUser,
    productLinks,
  }));
  const setCookie = useCallback(() => {
    new Cookies().set("bb_cookie_accepted", true, {
      path: "/",
      maxAge: 60 * 60 * 24 * config.cookieDays,
    });
  }, []);

  let content = (
      <div>
        <h2>{i18n.t("Page not found")}</h2>
      </div>
    ),
    title: JSX.Element | string = i18n.t("Back"),
    pTitle = "",
    scrollable = true,
    discoverLinks = productLinks?.response?.discover,
    discoverLink = discoverLinks
      ? discoverLinks.find((d) => {
          return d["gid"] === name;
        })
      : null;

  switch (name) {
    case "welcome":
      title = (
        <div className="page-title">
          <span className="d-inline-block d-md-none">{i18n.t("About CropFields")}</span>
          <span className="d-none d-md-inline-block">{i18n.t("CropFields")}</span>
        </div>
      );
      pTitle = i18n.t("CropFields");
      const m = /next=([^&]+)/.exec(location.search || ""),
        next = m ? decodeURIComponent(m[1]) : "/";
      return (
        <div>
          <Modal
            show={true}
            aria-labelledby="contained-modal-title"
            animation={false}
            backdrop="static"
            backdropClassName="bb-modal-backdrop"
          >
            <Modal.Header closeButton={false}>
              <Modal.Title id="contained-modal-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ padding: "10px" }}>
                {config.MAX_FREE_CLICKS ? <ContentLegalCookie /> : <ContentLegal />}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {config.MAX_FREE_CLICKS && (
                <Link style={{ float: "left" }} to={next}>
                  {i18n.t("Continue without cookies")}
                </Link>
              )}
              <Link type="button" className="btn btn-success" to={next} onClick={setCookie}>
                {i18n.t("I accept")}
              </Link>
            </Modal.Footer>
          </Modal>
        </div>
      );
    // break not required after return
    case "about":
      scrollable = false;
      pTitle = i18n.t("About CropFields");
      title = pTitle;
      content = (
        <Scrollable>
          <iframe title={pTitle} src={`${config.bb_productPage}?visibility=hide`} />
        </Scrollable>
      );
      break;
    case "data":
      scrollable = false;
      pTitle = i18n.t("About data");
      title = pTitle;
      content = (
        <Scrollable>
          <iframe title={pTitle} src={`${config.bb_dataPage}?visibility=hide`} />
        </Scrollable>
      );
      break;
    case "api":
      scrollable = false;
      pTitle = i18n.t("About API");
      title = pTitle;
      content = (
        <Scrollable>
          <iframe title={pTitle} src={`${config.bb_apiPage}?visibility=hide`} />
        </Scrollable>
      );
      break;
    case "legal":
      scrollable = false;
      pTitle = i18n.t("Terms");
      title = pTitle;
      content = (
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <ContentLegal />
          </div>
        </div>
      );
      break;
    case "subscription":
      scrollable = false;
      title = <div className="page-title">{i18n.t("Subscriptions")}</div>;
      pTitle = i18n.t("Subscriptions");
      content = (
        <Scrollable>
          <iframe title={pTitle} src={`${config.bb_productPage}?visibility=hide#pricing`} />
        </Scrollable>
      );
      break;
    case "sessions":
      title = pTitle = i18n.t("Manage sessions");
      content = (
        <Scrollable>
          <iframe title={pTitle} src="/sessions?header=false" />
        </Scrollable>
      );
      break;
    case "contact":
      title = pTitle = i18n.t("Contact");
      content = <Contact activeUser={activeUser} />;
      break;
    case "lease":
      pTitle = i18n.t("Lease");
      title = pTitle;
      if (config.language === "nl") {
        content = (
          <>
            <Lease.Title />
            <Lease />
          </>
        );
      }
      break;
    case "leaseconditions":
      pTitle = i18n.t("Lease");
      title = <Lease.Title />;
      content = <LeaseConditions />;
      break;
    default:
      if (discoverLink) {
        content = (
          <iframe
            title={pTitle}
            style={{ width: "100%", height: "calc(100% - 106px)" }}
            src={`${discoverLink.url}?visibility=hide`}
            frameBorder="0"
            allowFullScreen
          />
        );
      }
      break;
  }
  return (
    <section className="section_page">
      <Helmet>
        <title>{pTitle}</title>
      </Helmet>
      <div className={`container_page${scrollable ? " scrollable" : ""}`}>
        <div className="row">
          <div className="col-12">
            <h3 className="news_title d-flex justify-content-between align-items-center m-0">
              {title} <Link type="button" className="btn-close" style={{ float: "right" }} to="/" />
            </h3>
          </div>
        </div>
        {content}
      </div>
    </section>
  );
};
