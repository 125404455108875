import { ColorBox, SectionTitle } from "../util";
import React, { useEffect } from "react";
import { getCode, TS, useSSelector } from "../../util";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import { fetchLeaseForField } from "../../actions";

import "./Lease.scss";

export const LeaseData = ({ leaseData }) => {
  if (!(leaseData?.myConditions && leaseData?.myRewards)) {
    return (
      <p>
        <TS t="l_info_lease" safeLink={false} />
      </p>
    );
  }
  const { myConditions, myRewards } = leaseData;
  return (
    <div className="lease_conditions_popover">
      <p className="font-weight-bold">Voorwaarden</p>
      <ul>
        <LeaseListItem description="Skal gecertificeerd" enabled={myConditions.skal} />
        <LeaseListItem description="OBI ingevuld" enabled={myConditions.obi} />
        <LeaseListItem description="Natuurinclusief" enabled={myConditions.nat} />
        <LeaseListItem description="Geen kunstmest" enabled={myConditions.fertilizer} />
        <LeaseListItem
          description="Geen Gewasbeschermingsmiddelen"
          enabled={myConditions.chemical}
        />
        <LeaseListItem description={myConditions.otherDesc} enabled={myConditions.other} />
      </ul>
      <p className="font-weight-bold" style={{ textAlign: "start" }}>
        Beloningen
      </p>
      <ul>
        <LeaseListItem
          description={"Korting op de pachtprijs: " + myRewards.discountDesc}
          enabled={myRewards.discount}
        />
        <LeaseListItem description={myRewards.otherDesc} enabled={myRewards.other} />
      </ul>
    </div>
  );
};

export const Lease = ({ data, field, smallScreen, cropfield }) => {
  const useTitle = data?.code;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLeaseForField(cropfield));
  }, [cropfield, dispatch]);

  const leaseData = useSSelector((s) => s.selection?.field?.lease);

  return (
    <div className="mod_bb_lease">
      <SectionTitle name={i18n.t("Use title")}>
        <TS t="l_info_use_title" />
      </SectionTitle>

      {useTitle ? (
        <div>
          <ColorBox color={getCode(useTitle, "color")} />
          <TS
            t="This field is used as <i>{{useTitle}}</i>"
            o={{ useTitle: getCode(useTitle, "name") }}
          />
        </div>
      ) : (
        <div>{i18n.t("Lease status unknown")}</div>
      )}

      <br />

      <SectionTitle name="Duurzame Pacht">
        <LeaseData leaseData={leaseData} />
      </SectionTitle>
      <span className="d-flex">
        <p>{i18n.t("Sustainable lease conditions")}:</p>
        <p className="ml-1">{leaseData?.myConditions && leaseData?.myRewards ? "Ja" : "Nee"}</p>
      </span>
    </div>
  );
};

const LeaseListItem = ({ description, enabled }) =>
  !enabled ? null : (
    <li>
      <p>{description}</p>
    </li>
  );
