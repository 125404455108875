import React from "react";
import moment from "moment/moment";
import { LegendItemProps } from "./Sections";
import { numberFormat } from "../../util";
import "./Legend.scss";

export const Legend = ({ active, name, date, legendSpecs }) => {
  const totalCount = legendSpecs?.reduce((total, l) => total + l.count, 0);
  return (
    <div
      className="legend-container"
      style={{
        display: active ? "block" : "none",
      }}
    >
      <li className="legend-item">
        <header>
          <span className="legend-item-description">{name}</span>
          {date && <span className="legend-item-date">{moment(date).format("LL")}</span>}
        </header>
        {legendSpecs &&
          legendSpecs.map((l, i) => <LegendItem legendSpec={l} key={i} totalCount={totalCount} />)}
      </li>
    </div>
  );
};

const LegendItem = ({ legendSpec, totalCount }: LegendItemProps) => {
  const color = legendSpec.c.indexOf("#") === 0 ? legendSpec.c : "rgba(" + legendSpec.c + ")";
  return (
    <div className="legend-item-list px-0 container w-100">
      <div className="legend-item-list-item small row w-100 m-0">
        <div className="legend-item-specs clearfix">
          <span className="legend-item-spec-color">
            <svg width="20" height="20">
              <rect
                width="20"
                height="20"
                style={{
                  fill: color,
                }}
              />
            </svg>
          </span>{" "}
          &nbsp;
          <span className="legend-item-spec-numbers">
            {legendSpec.name
              ? legendSpec.name
              : `${numberFormat(legendSpec["min"]) || legendSpec["min"]}
            ${legendSpec["max"] ? " - " + numberFormat(legendSpec["max"]) : ""}`}
          </span>
        </div>
        {totalCount ? (
          <div className="legend-item-histo clearfix">
            <div className="legend-item-histo-bar-wrapper">
              <span
                className="legend-item-histo-bar"
                style={{
                  width: (legendSpec.count / totalCount) * 80 + "px",
                  backgroundColor: color,
                }}
              />
            </div>
            <div className="legend-item-histo-count">
              {Math.round((legendSpec.count / totalCount) * 1000) / 10}%
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
