import React, { memo } from "react";
import i18n from "i18next";
import { ColorBox, SectionTitle } from "../util";
import { SectionProps } from "./Sections";
import { getCode, MoreInfo, TS } from "../../util";
import { Nature } from "../reference";
import { Flag } from "../Flag";
import { Description } from "../fieldprops/FieldProps";
import { config } from "../../Config";

const Collective = ({ data, zones }) =>
  !data ? null : (
    <div className="mb-2">
      <Flag
        attr=""
        field={{
          name: data.name,
          ext_id: data.ext_id,
        }}
        type="ANC"
        className="flag-iconfull ml-1 my-1 pull-right"
      />
      <Description
        name={
          i18n.t("This field is located within the boundaries of nature collective") +
          ": " +
          data.full_name
        }
        link={data.url}
      >
        {i18n.t("the nature collective")}{" "}
      </Description>{" "}
      {i18n.t("This field is part of habitats")}:
      {zones?.features?.map((f, i) => (
        <div key={i}>
          <ColorBox color={getCode(f.properties.code, "color") || "#ffffff"} />{" "}
          {getCode(f.properties.code, "name")}
        </div>
      ))}
    </div>
  );

const natureNMoreInfo = {
  NL: {
    href: "https://www.rijksoverheid.nl/onderwerpen/natuur-en-biodiversiteit/natuurnetwerk-nederland",
    description: "NNN",
  },
};

const NatureN = ({ data }) =>
  !data ? null : (
    <div className="mb-2">
      <i className="far fa-leaf" />{" "}
      {i18n.t("This field is part of the Nature Network of this country")}.{" "}
      <MoreInfo o={natureNMoreInfo[config.bb_country]} />
    </div>
  );

export const SectionNature = memo(({ active, field, smallScreen }: SectionProps) => (
  <section id="section_nature" className={`${active ? "active" : ""}`}>
    <SectionTitle name={i18n.t("Nature")}>
      <TS t="l_info_nature" />
    </SectionTitle>
    <Collective data={field.zonec} zones={field.nature_s} />
    <NatureN data={field.nature_n} />
    <Nature
      field={field}
      smallScreen={smallScreen}
      emptyMessage={i18n.t("Not within Natura 2000")}
    />
  </section>
));
