import { bbAxios } from "../util";
import { config } from "../Config";
import moment from "moment";

export const updateEWS = (cropfield) => async (dispatch, getState) => {
  const type = "FETCH_EWS";
  dispatch({ type, state: "fetching" });
  const p = cropfield._center;
  const response = await bbAxios(
    `${config.SFAM_URL}/api/V1/ews/PHYTOPHTHORA/?lat=${p[1]}&lng=${p[0]}&region=NL`
  ).catch(() => dispatch({ type, state: "failed" }));
  if (!response?.data) return;
  const d = response.data["infection_chances"];
  const data = Object.keys(d)
    .sort()
    .map((key) => {
      return { unix: moment(key).unix(), value: d[key] };
    });
  dispatch({ type, state: "fetched", data });
};

export const updateEWSMulti = (multipointOfCentroids) => (dispatch, getState) => {
  const type = "FETCH_EWS_MULTI";
  if (multipointOfCentroids === "MULTIPOINT ()") {
    dispatch({ type, state: "fetched" });
    return;
  }

  dispatch({ type, state: "fetching" });
  bbAxios(`${config.SFAM_URL}/api/V1/ews/multi/?multipoint=${multipointOfCentroids}`)
    .then(({ data }) => {
      // temporary fix for the secondary crop types sent by SFAM
      const initialData = JSON.stringify(data)
        .replace("CROP_FRENCH-BEAN", "CROP_BEAN")
        .replace("CROP_HEAD-CABBAGE", "CROP_KAI-LAN");
      data = JSON.parse(initialData);
      dispatch({ type, state: "fetched", data });
    })
    .catch((err) => dispatch({ type, state: "failed" }));
};

export const getEWSConfig = () => (dispatch, getState) => {
  const type = "GET_EWS_CONFIG";
  const fetchState = getState().ewsConfig?.state;
  const headers = getState().activeUser.tokens.sfam;
  if (fetchState === "fetched" || fetchState === "fetching" || !headers) {
    return;
  }
  dispatch({ type, state: "fetching" });
  bbAxios(`${config.SFAM_URL}/api/V1/ews/config/`)
    .then(({ data }) => {
      // temporary fix for the secondary crop types sent by SFAM
      const initialData = JSON.stringify(data)
        .replace("CROP_FRENCH-BEAN", "CROP_BEAN")
        .replace("CROP_HEAD-CABBAGE", "CROP_KAI-LAN");
      data = JSON.parse(initialData);
      dispatch({ type, state: "fetched", data });
    })
    .catch((err) => dispatch({ type, state: "failed" }));
};

export const updateWeather = (cropfield) => async (dispatch, getState) => {
  const type = "FETCH_WEATHER";
  dispatch({ type, state: "fetching" });
  const p = cropfield._center;
  const year = new Date().getFullYear();
  const params = {
    format: "json",
    lat: p[1],
    lon: p[0],
    start_date: `${year}-04-01`,
    end_date: `${year}-10-01`,
    meta: "AT,PT",
  };
  const response = await bbAxios("https://d2c.crop-r.com/api/v3/daily_weather/", { params }).catch(
    (err) => dispatch({ type, state: "failed" })
  );
  if (!response?.data) return;
  let data = response.data.daily_weather.map((measurement) => ({
    ...measurement.values,
    unix: moment(measurement.day, "YYYY-MM-DD").unix(),
  }));
  dispatch({ type, state: "fetched", data });
};
