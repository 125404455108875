import React, { memo, useCallback } from "react";
import i18n from "i18next";
import moment from "moment/moment";

import { SectionTitle, SubscriptionFeature } from "../util";
import { GrowthDiagram, SelectionButtons, CreatePrescriptionMap } from "../diagrams";
import { changeSatelliteImage, updateGrowth, updateSatelliteImage } from "../../actions";
import { showSubscriptionModal, ConfigurableLabel } from "../../components";
import { SectionProps } from "./Sections";
import { useDispatch } from "react-redux";
import { TS, useSSelector } from "../../util";
import { track } from "../../track";
import { config } from "../../Config";
import { Legend } from "./Legend";
import { SatelliteImages, SoilPotencyButton } from "../satellite";
import "./SectionGrowth.scss";
import { ButtonGroup } from "react-bootstrap";

const lastTwoNotRecentImages = (list: []) => list.slice(-4, -2);

const SectionGrowth = ({ active, smallScreen, features, cropfield, inView }: SectionProps) => {
  const dispatch = useDispatch();
  const warnSubscription = useCallback(
    () =>
      showSubscriptionModal(
        dispatch,
        i18n.t("Subscribe to CropFields to view all satellite images"),
        "growth"
      ),
    [dispatch]
  );
  const changeSelection = useCallback(
    (type) => {
      dispatch(changeSatelliteImage({ imageType: type }));
      track("report", "satellite", type);
    },
    [dispatch]
  );
  const [growth, showWDVIImages, showSoilPotencyMap] = useSSelector(({ activeUser, selection }) => [
    selection.growth || {},
    Boolean(activeUser.settings?.showWDVIImages),
    Boolean(activeUser.settings?.showSoilPotencyMap),
  ]);
  const refresh = useCallback(() => dispatch(updateGrowth()), [dispatch]);
  const select = useCallback(
    (type, key, clicked) => {
      dispatch(updateSatelliteImage(cropfield, { key, imageType: growth.imageType }));
      if (clicked) track("report", "satellite", type);
    },
    [cropfield, growth.imageType, dispatch]
  );
  const date = growth.key && moment.unix(growth.key).format("YYYYMMDD");
  const fetching = !growth.sp && growth.imgState === "fetching";
  return (
    <section
      id="section_growth"
      className={`large_sized${active ? " active" : ""} state_${growth.state || "fetching"}`}
    >
      <SectionTitle
        name={i18n.t("Growth with satellite images")}
        icon={fetching && "spinner"}
        spin={fetching}
        extra={<ConfigurableLabel />}
      >
        <TS t="l_info_growth" />
      </SectionTitle>
      <GrowthDiagram
        growth={growth}
        cropfield={cropfield}
        smallScreen={smallScreen}
        refresh={refresh}
        select={select}
        inView={inView}
        filterNdviImg={features.pro ? null : lastTwoNotRecentImages}
        warnSubscription={warnSubscription}
      />
      <SatelliteImages
        growth={growth}
        active={active}
        smallScreen={smallScreen}
        date={date}
        cropfield={cropfield}
      />
      <SelectionButtons
        names={showWDVIImages ? ["rgb", "ndvi", "wdvi"] : ["rgb", "ndvi"]}
        active={growth.imageType}
        select={changeSelection}
      />
      <ButtonGroup size="sm" className="bb_toggleButton">
        <SoilPotencyButton
          show={features.pro && showSoilPotencyMap}
          growth={growth}
          cropfield={cropfield}
        />
      </ButtonGroup>
      <SubscriptionFeature
        show={!features.pro}
        title={`...${i18n.t("all images")}`}
        description={i18n.t("TextAllImagesPro")}
      />
      <CreatePrescriptionMap
        show={features.pro && config.bb_country === "NL"}
        video={"Taakkaart_NDVI"}
        {...growth}
      />
      {!smallScreen && growth.legends && (
        <Legend
          active={active}
          date={date}
          name={
            growth.sp && growth.imgState === "fetched"
              ? i18n.t("Growth potential")
              : growth.imageType.toUpperCase()
          }
          legendSpecs={
            growth.legends[growth.sp && growth.imgState === "fetched" ? "sp" : growth.imageType]
          }
        />
      )}
    </section>
  );
};

const m = memo(SectionGrowth);
export { m as SectionGrowth };
