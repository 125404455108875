import React from "react";
import "./Tag.scss";

type Props = {
  title: string;
  className?: string;
};

export const Tag = (props: Props) => {
  const { title } = props;

  return <div className="c-tag">{title}</div>;
};
