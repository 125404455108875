import i18n from "i18next";
import React, { memo } from "react";
import { CropHistory } from "../diagrams";
import { toggleCropYear } from "../../actions";
import { SectionProps } from "./Sections";
import { useDispatch } from "react-redux";
import { accountFilterHistory, TS, useSSelector } from "../../util";
import { SectionTitle } from "../util";
import { getFieldPropsComponent } from "../fieldprops";
import { FieldInfo } from "./FieldInfo";

const SectionFacts = ({
  active,
  field,
  features,
  size = "sized",
  title,
}: SectionProps & { title?: string }) => {
  const dispatch = useDispatch();
  const { account, year, showPredictCrop } = useSSelector(({ selection, activeUser }) => ({
    account: activeUser.account,
    year: selection.year,
    showPredictCrop: Boolean(activeUser.settings?.predictCrop),
  }));
  const cropHistory = accountFilterHistory(field.crops, account);
  const FieldProps = getFieldPropsComponent();
  return (
    <section id="section_facts" className={`${size}${active ? " active" : ""}`}>
      <SectionTitle name={title || i18n.t("Crop rotation")}>
        <TS t="l_info_facts" />
      </SectionTitle>
      <CropHistory
        data={cropHistory}
        select={(year) => {
          dispatch(toggleCropYear(year));
        }}
        year={year}
        prediction={showPredictCrop && field.prediction}
        hasMore={!features.pro}
      />
      {/*<h4>{i18n.t("Location")}</h4>*/}
      <FieldInfo field={field} />
      <FieldProps field={field} />
    </section>
  );
};
const m = memo(SectionFacts);
export { m as SectionFacts };
