import { accountFeatures, getHeightLabels, useSSelector } from "../util";
import React, { memo } from "react";
import { ReportComponentProps } from "./Report";
import { SoilDK } from "./soil/SoilDK";
import {
  SectionProperty,
  SectionGrowth,
  SectionHeight,
  SectionNature,
  SectionSoil,
  SectionFacts,
} from "./sections";
import { RestrictedContent } from "./RestrictedContent";

const ReportDK = ({ section = "" }: ReportComponentProps) => {
  const { account, field, bunder } = useSSelector(({ selection, activeUser }) => ({
    account: activeUser.account,
    field: selection.field,
    bunder: selection.bunder,
  }));
  const cropfield = field?.state === "fetched" ? field.feature : bunder,
    smallScreen = window.innerWidth < 768,
    features = accountFeatures(account);
  const p = { features, field, cropfield, smallScreen };

  return (
    <main className="sections">
      <SectionFacts
        active={!section || section === "facts"}
        features={features}
        smallScreen={smallScreen}
        cropfield={cropfield}
        field={field}
      />
      <SectionSoil active={section === "soil"} SoilComponent={SoilDK} {...p} />
      <SectionProperty active={section === "property"} {...p} />
      <SectionGrowth
        active={section === "growth"}
        inView={["property", "growth", "height"].includes(section)}
        {...p}
      />
      <SectionHeight
        active={section === "height"}
        inView={["property", "growth", "height"].includes(section)}
        names={["1", "2"]}
        labels={getHeightLabels(field, ["2007", "2015"])}
        defaultVersion={features.pro ? "2" : "1"}
        {...p}
      />
      <SectionNature active={section === "nature"} {...p} />
      <section id="section_last" />
    </main>
  );
};

const m = memo(({ section = "" }: ReportComponentProps) => (
  <RestrictedContent Component={ReportDK} section={section} />
));

export { m as ReportDK };
