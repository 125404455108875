import { useSSelector } from "../../util";
import { useMap } from "react-leaflet";
import { useEffect } from "react";

import L from "leaflet";

export const usePanToFarmFields = (enabled = true) => {
  const fields = useSSelector((state) => state.selection?.farmFields as any[]);
  const farm = useSSelector((state) => state.selection?.farm as any);

  const map = useMap();

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (fields?.length) {
      const bounds = (L as any).geoJson(fields).getBounds();
      const options = window.innerWidth >= 768 ? { paddingTopLeft: [600, 0] as L.PointTuple } : {};
      map.fitBounds(bounds, options);
      return;
    }

    if (farm?.lat && farm?.lng) {
      map.setView([farm.lat, farm.lng], 15);
      return;
    }
  }, [map, fields, farm, enabled]);
};

export const usePanToFields = (fields: any[]) => {
  const map = useMap();

  useEffect(() => {
    if (fields?.length) {
      const bounds = (L as any).geoJson(fields).getBounds();
      const options = window.innerWidth >= 768 ? { paddingTopLeft: [600, 0] as L.PointTuple } : {};
      map.fitBounds(bounds, options);
      return;
    }
  }, [map, fields]);
};
