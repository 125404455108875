import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import { config } from "../Config";
import { Link } from "react-router-dom";
import bertHank from "../../img/logo/bert-hank_abo.png";
import { freeTrialClick } from "../components/Pro";
import { AccountModal } from "./AccountModal";
import { useSSelector } from "../util";

interface SubscribeModalProperties {
  onClose: () => void;
  text: string;
}

export const SubscribeModal = ({ onClose, text }: SubscribeModalProperties) => {
  const isloggedIn = useSSelector((state) => state.activeUser.state === "loggedIn");

  const primaryButton = useMemo(() => {
    if (config.bb_site.free_trial && !isloggedIn) {
      return (
        <a
          className="btn btn-success float-left"
          href="/authorize?create=1"
          onClick={freeTrialClick}
        >
          {i18n.t("Free trial")}
        </a>
      );
    }

    if (isloggedIn) {
      return (
        <a className="btn btn-success" href={config.bb_orderPage}>
          {i18n.t("Subscribe")}
        </a>
      );
    }

    return (
      <a className="btn btn-info" href="/authorize">
        {i18n.t("Login")}
      </a>
    );
  }, [isloggedIn]);

  if (config.bb_site.beta) {
    return AccountModal(true, onClose);
  }

  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_subscribe"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n.t("Subscribe to use this functionality")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="float-right">
          <img alt="Bert&Hank" className="d-none d-sm-block" width="200" src={bertHank} />
          <img alt="Bert&Hank" className="d-inline-block d-sm-none" width="150" src={bertHank} />
        </div>
        <p>{text || i18n.t("Subscribe")}:</p>
        <div>
          <ul>
            {config.crop_year - config.crop_year_start > 3 && (
              <li>{i18n.t("View many historic crop rotation years")}</li>
            )}
            <li>{i18n.t("Route planner and precise field information")}</li>
            {config.cadastre && <li>{i18n.t("View cadastral data")}</li>}
            <li>{i18n.t("Follow satellite data")}</li>
            {config.bb_country !== "DE_NI" && (
              <li>{i18n.t("Height maps measured on different times")}</li>
            )}
            <li>{i18n.t("Create field report")}</li>
            <li>{i18n.t("Export field data to shape file")}</li>
            <li>{i18n.t("Search fields by attribute throughout the country")}</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default" onClick={onClose} to="/page/subscription">
          {i18n.t("More information")}
        </Link>
        {primaryButton}
      </Modal.Footer>
    </Modal>
  );
};
