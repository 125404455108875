/*eslint camelcase: ["error", {allow: ["sub_category", "farm_name", "farm_id"]}]*/
import { getCenter } from "./util";
import { config } from "./Config";
import axios from "axios";
import { store } from "./store";

const device = window.innerWidth < 600 ? "mobile" : window.innerWidth < 1024 ? "tablet" : "desktop";
export function track(category: string, subCategory?: string, data?: any) {
  /*
    category: Typically the object that was interacted with (e.g. 'Video')
    subCategory: string type of interaction (e.g. 'play')
    data: string specific data for the event
  */
  if (!store) return;
  const state = store.getState();
  const activeUser = state?.activeUser;
  const account = activeUser?.account;
  const feature = state.selection.field?.feature;
  const c = category === "report" && state.selection.field?.crops,
    crop = c?.length && config.codes[c[c.length - 1].code];
  const location =
    /^\/(?:report|select)\/([\d.,]+)/.exec(window.location.pathname)?.[1] ||
    (feature && getCenter(feature, null, 5).reverse().join(",")) ||
    state.selection.gps?.join(",") ||
    (state.inView.center && `${state.inView.center.lat},${state.inView.center.lng}`);
  if (process.env.NODE_ENV === "production") {
    const features = account?.features || {};
    axios({
      method: "POST",
      responseType: "json",
      data: JSON.stringify({
        category: category,
        sub_category: subCategory,
        data,
        crop: crop?.group?.name,
        location,
        device,
        subscription: !features.pro ? "free" : features.premium ? "premium" : "pro",
        user: account?.user?.username,
        farm_id: account?.farm_id,
        farm_name: account?.farm?.name,
      }),
      url: "/log_event/",
      headers: { "Content-Type": "application/json" },
    });
  } else if (process.env.NODE_ENV !== "test") {
    console.log("Event", category, subCategory, data || "", "GPS: " + location);
  }
}
// @ts-ignore
window.track = track;
