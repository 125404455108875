import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Control, DomUtil, DomEvent, ControlPosition } from "leaflet";
import { useMap } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

const DumbControl = Control.extend({
  options: {
    className: "",
    id: "",
    onOff: "",
    handleOff: function noop() {},
  },

  onAdd(/* map */) {
    const _controlDiv = DomUtil.create("div", this.options.className);
    _controlDiv.id = this.options.id;
    DomEvent.disableClickPropagation(_controlDiv);
    return _controlDiv;
  },

  onRemove(map) {
    if (this.options.onOff) {
      map.off(this.options.onOff, this.options.handleOff, this);
    }

    return this;
  },
});

type Props = {
  children: React.ReactNode;
  position: ControlPosition;
};

export const CustomControl = (props: Props) => {
  const map = useMap();
  const id = useRef(`custom-control-leaflet-${uuidv4()}`);
  const [el, setEl] = useState(null);

  useEffect(() => {
    const control = new DumbControl({ position: props.position, id: id.current } as any);
    map.addControl(control);
    setEl(document.getElementById(id.current));
    return () => {
      map.removeControl(control);
      setEl(null);
    };
  }, [map, props.position]);

  if (!el) {
    return null;
  }
  return ReactDOM.createPortal(props.children, el);
};
