import React from "react";
import i18n from "i18next";

import { getCode } from "../../util";
import { SummaryItems } from "../header/SummaryItems";
import { SoilTextureTriangleProps, SoilTextureTriangle } from "../../components";
import { SimpleMap } from "../../map";

export interface SoilProps {
  cropfield: any;
  smallScreen: boolean;
  size?: number;
  items?: Array<string>;
  triangle?: SoilTextureTriangleProps["triangle"];
  textBreak?: boolean;
  trans?: (string) => string;
}

export const Soil = ({
  cropfield,
  smallScreen,
  size = 300,
  items = ["soil", "r_soil"],
  triangle,
  textBreak = false,
  trans = undefined,
}: SoilProps) => {
  const features = cropfield.soil && cropfield.soil.features;
  if (!features) return <p>{i18n.t("No soil data available")}</p>;
  const selected = features.reduce((memo, f) => {
    const n: string = getCode(f.properties.code, "name") || "";
    memo[n.toLowerCase()] = true;
    if (trans) memo[trans(n).toLowerCase()] = true;
    return memo;
  }, {});
  return (
    <div>
      <div className="key">
        {items.map((key) => (
          <SummaryItems key={key} k={key} data={cropfield} />
        ))}
      </div>
      {!smallScreen ? (
        triangle && (
          <SoilTextureTriangle
            size={size}
            textBreak={textBreak}
            triangle={triangle}
            trans={trans}
            selected={selected}
          />
        )
      ) : (
        <div className="keymap">
          <SimpleMap feature={cropfield.feature} height={size}>
            {cropfield.soil.features.map((f, i) => (
              <SimpleMap.TopPolygon
                key={`${i}_${f.id}`}
                feature={f}
                fillOpacity={1}
                fillColor={getCode(f.properties.code, "color")}
                stroke={false}
              />
            ))}
          </SimpleMap>
        </div>
      )}
    </div>
  );
};
