import React from "react";

import { useSSelector } from "../../util";
import { fieldGeoData } from "./types";
import { getStyle, STYLETYPES } from "../map_util";
import { GeoJSON } from "react-leaflet";
import { Layer } from "./Layer";
import { usePanToFields } from "./utils";

type Props = {
  onPolygonClick?: (field: any) => void;
  zIndex?: number;
};

// Although this is called SelectedFarmFieldsLayer, it does not strictly display farm fields.
// Most importantly, it also renders the selected list.
export const EWSFarmFieldsLayer = (props: Props) => {
  const { onPolygonClick, zIndex } = props;
  const fields = useSSelector(ewsFieldSelector);

  usePanToFields(fields);

  return (
    <Layer name={"selected-fields"} zIndex={zIndex}>
      {fields.map((field) => (
        <EWSFieldPolygon key={field.properties.key} field={field} onPolygonClick={onPolygonClick} />
      ))}
    </Layer>
  );
};

type EWSFieldPolygonProps = {
  field: fieldGeoData;
  onPolygonClick?: (field: any) => void;
};
const EWSFieldPolygon = (props: EWSFieldPolygonProps) => {
  const { field } = props;
  const ewsMulti = useSSelector((state) => state.selection.ewsMulti);
  const selectedCrop = useSSelector((state) => state.ewsConfig.selectedCrop);
  const selectedDisease = useSSelector((state) => state.ewsConfig.selectedDisease);
  const layerConfig = useSSelector((state) => state.layers.config);

  if (ewsMulti?.state !== "fetched") {
    return null;
  }

  const style = getStyle(STYLETYPES.EWSMULTI, {
    feature: field,
    ewsMulti: ewsMulti,
    selectedCrop: selectedCrop,
    selectedDisease: selectedDisease,
    layerConfig: layerConfig,
  });

  return <GeoJSON style={style} data={field} />;
};

const ewsFieldSelector = (state) => {
  const fields = state.selection?.farmFields;
  const ewsMulti = state.selection?.ewsMulti;
  const selectedCrop = state.ewsConfig?.selectedCrop;
  const selectedDisease = state.ewsConfig?.selectedDisease;

  if (!fields) {
    return [];
  }

  if (ewsMulti?.state !== "fetched") {
    return [];
  }

  if (!selectedCrop) {
    return [];
  }

  if (!selectedDisease) {
    return [];
  }

  return fields.filter((field) => selectedCrop === field.properties.crop_dacom_code);
};
