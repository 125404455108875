import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { myPush as push, selectList } from "../actions";
import imgLogoDacom from "../../img/dacom.svg";
import imgLogoCropx from "../../img/cropx.svg";
import { config } from "../Config";
import i18n from "i18next";
import "./NavAside.scss";
import cx from "classnames";
import { useSSelector, accountFeatures, A } from "../util";
import { showSubscriptionModal } from "./Pro";

interface ItemProps {
  name: string;
  icon: string;
  children: React.ReactChildren;
  className?: string;
  hasIcon?: string;
  needPro?: boolean;
}
const Item = (props: ItemProps) => {
  const { name, icon, children, className, hasIcon, needPro = false } = props;
  const dispatch = useDispatch();
  const isShow = name.startsWith("show_");
  const path = "/" + (isShow ? name.substring(5) : "");
  const { pathname } = useLocation();
  const active = isShow && pathname.length > 1 && pathname.startsWith(path);

  const features = useSSelector((s) => accountFeatures(s.activeUser.account));

  const onClick = useCallback(() => {
    if (needPro && !features.pro) {
      showSubscriptionModal(dispatch, "");
      return;
    }
    const changed = pathname !== path && !active;
    dispatch({ type: "DESELECT" });
    dispatch(selectList({ selectedList: null }));
    if (changed) {
      dispatch(push(path));
    } else if (pathname !== "/") {
      dispatch(push("/"));
    }
  }, [needPro, features, dispatch, active, path, pathname]);

  return (
    <li
      onClick={onClick}
      role="presentation"
      className={cx("btn-aside", className, { active: active })}
    >
      <i className={`fal fa-${icon} font-size-2_2`} />
      <span>{children}</span>
      {hasIcon && <i className={`fas fa-${hasIcon} extra-icon`} />}
    </li>
  );
};

export const NavAside = memo(() => {
  const [activeUser, searchFilter] = useSSelector((s) => [s.activeUser, s.searchFilter]);
  const loggedIn = activeUser.state === "loggedIn";
  const hasBetaEWSFeature = Boolean(activeUser.settings?.showEWS && config.bb_country === "NL");

  return (
    <aside className="mod_bb_nav_aside">
      <div className="inner">
        <ul id="nav_aside_nav" className="nav nav-tabs">
          <Item
            name="show_filter"
            icon="filter"
            className={`d-none d-md-block ifilter ${searchFilter ? " active" : ""}`}
          >
            {i18n.t("Filter")}
          </Item>
          <Item name="show_search" icon="search" className="d-block d-md-none">
            {i18n.t("Search")}
          </Item>
          {loggedIn && (
            <>
              <Item key="bunder" name="show_lists" icon="clipboard-list-check" needPro>
                {i18n.t("Lists")}
              </Item>
              <Item key="farm" name="show_my_farm" icon="farm" needPro>
                {i18n.t("Farm")}
              </Item>
            </>
          )}
          {config.labs && (
            <Item name="show_settings" icon="cog" className={!loggedIn ? "" : "d-none d-md-block"}>
              {i18n.t("Settings")}
            </Item>
          )}
          {loggedIn && hasBetaEWSFeature && (
            <Item name="show_ews" icon="radar">
              {i18n.t("Radar")}
            </Item>
          )}
          {loggedIn && !hasBetaEWSFeature && config.bb_country === "NL" && (
            <li key="a" className="btn-aside d-block d-md-none" />
          )}
          {loggedIn && (
            <Item name="show_controls" icon="bars" className="d-block d-md-none">
              {i18n.t("Layers")}
            </Item>
          )}
          {!loggedIn && <li key="b" className="btn-aside d-block d-md-none" />}
          {!loggedIn && <li key="c" className="btn-aside d-block d-md-none" />}
          {loggedIn && config.bb_country !== "NL" && (
            <li key="b" className="btn-aside d-block d-md-none" />
          )}
          {!loggedIn && (
            <Item className="d-none d-block d-md-none" name="close_panel" icon="map-marker-alt">
              {i18n.t("Map")}
            </Item>
          )}
        </ul>
      </div>
      <div className="nav corner">
        <A className="company_logo" href="https://www.dacom.nl/">
          <img width="80" alt="Dacom" src={imgLogoDacom} />
        </A>
        <A className="company_logo" href="https://www.cropx.com/">
          <img width="70" alt="CropX" src={imgLogoCropx} />
        </A>
      </div>
    </aside>
  );
});
