import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getCenter } from "../../util";
import { DefaultZIndex } from "../brp/Layer";
import { BRPLayer } from "../brp/BRPLayer";
import { BaseMap } from "./BaseMap";
import { MultiSelectLayer } from "../brp/MultiSelectLayer";
import { MultiSelectControl } from "../customControl/MultiSelectControl";
import { useRouteMatch } from "react-router";

type MultiSelectMapProps = {};

export const MultiSelectMap = (props: MultiSelectMapProps) => {
  const dispatch = useDispatch();
  const points = useRouteMatch("/select/:points?")?.params?.points;

  const handleSelect = useCallback(
    (gps, field, e) => {
      let newPoints = [];
      if (points) {
        newPoints = points.split(";");
      }
      const point = getCenter(field, undefined, 5).reverse().join(",");

      newPoints.push(point);
      const newPath = `/select/${newPoints.join(";")}`;
      dispatch(push(newPath));
    },
    [dispatch, points]
  );

  return (
    <BaseMap>
      <BRPLayer zIndex={DefaultZIndex} onPolygonClick={handleSelect} />
      <MultiSelectLayer zIndex={DefaultZIndex + 1} />
      <MultiSelectControl position="topright" />
    </BaseMap>
  );
};
