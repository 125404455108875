import { ITooltip } from "./Tooltip";
import React from "react";
import "./PanelHeader.scss";

export const PanelHeader = ({ explanation = null, onHide, children }) => (
  <div className="row mod_bb_panel_header">
    <div className="col-12 d-flex px-4 px-md-2 align-items-end">
      <h2 className="explanation flex-grow-1 d-flex">
        {children}
        <ITooltip className="pl-2 text-primary font-size-1 align-self-center">
          {explanation}
        </ITooltip>
      </h2>
      <button onClick={onHide} className="btn-close close_panel" />
    </div>
  </div>
);
