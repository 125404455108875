import React, { useState } from "react";
import "./Accordion.scss";
import { useClick } from "../hooks";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import i18n from "i18next";

type Props = {
  title: string;
  subtitle?: any;
  onClick?: () => void;
  updateName?: (name) => void;
  children?: React.ReactNode;
  open?: boolean;
  optionMenu?: React.ReactNode;
};

export const Accordion = ({
  title,
  subtitle,
  onClick,
  updateName,
  children,
  open = false,
  optionMenu,
}: Props) => {
  const [nameEdit, setNameEdit] = useState<string | undefined>();
  const clickEdit = useClick(() => setNameEdit(title), [title]);
  const cancelEdit = useClick(() => setNameEdit(undefined), []);
  const saveEdit = useClick(() => {
    updateName(nameEdit);
    setNameEdit(undefined);
  }, [nameEdit]);
  return (
    <li
      className={"accordion__item js-accordion__item" + (open ? " accordion__item--is-open" : "")}
    >
      <div className="accordion__header" onClick={onClick}>
        <div className="accordion__content">
          <div className="accordion__content--title">
            {nameEdit !== undefined ? (
              <form onSubmit={saveEdit}>
                <div className="d-flex">
                  <InputGroup className="flex-grow-1 input-group-sm">
                    <FormControl
                      value={nameEdit}
                      onChange={(e) => setNameEdit(e.target.value)}
                      aria-describedby="basic-addon2"
                      onClick={clickEdit}
                    />
                    <Button variant="primary" size="sm" disabled={!nameEdit} onClick={saveEdit}>
                      <span className="fa fa-check" />
                    </Button>
                  </InputGroup>
                  <Button variant="link" onClick={cancelEdit}>
                    {i18n.t("Cancel")}
                  </Button>
                </div>
              </form>
            ) : (
              <>
                {title}
                <button
                  className={`btn btn-sm accordion__edit ${updateName ? "" : "d-none"}`}
                  onClick={clickEdit}
                >
                  <i className="fas fa-pencil small" />
                </button>
              </>
            )}
          </div>
          {subtitle}
        </div>
        <div className={`icon__group ${nameEdit !== undefined ? "d-none" : ""}`}>
          {optionMenu}
          <i className="icon accordion__icon-arrow fas fa-chevron-down" />
        </div>
      </div>
      {open && <div className="accordion__panel">{children}</div>}
    </li>
  );
};
