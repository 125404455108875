import { refreshAccount } from "../actions";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import i18n from "i18next";
import { useSSelector } from "../util";

export const RefreshButton = ({
  className,
  farmExternalId,
  planExternalId,
}: {
  className?: string;
  farmExternalId: string;
  planExternalId: string;
}) => {
  const dispatch = useDispatch();
  const refreshing = useSSelector(({ activeUser }) => activeUser.refreshing);
  const onClick = useCallback(
    () => dispatch(refreshAccount(farmExternalId, planExternalId)),
    [farmExternalId, planExternalId, dispatch]
  );

  return (
    <button onClick={onClick} className={cx("btn btn-light", className)} disabled={refreshing}>
      <i className={`fa fa-refresh${refreshing ? " fa-spin" : ""}`} /> {i18n.t("Refresh own data")}
    </button>
  );
};
