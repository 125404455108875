export function getTitle() {
  const a = this.accessor,
    title = this.props.title;
  if (a && a.results && a.results.hits) {
    const res = a.results.aggregations[a.uuid] || a.results.aggregations[a.key],
      hits = a.results.hits,
      percentage = res ? (hits.total / res.doc_count) * 100 : 0,
      display =
        percentage &&
        percentage < 100 &&
        percentage.toFixed(percentage >= 1 ? 0 : 1 - Math.round(Math.log10(percentage)));
    if (display) return `${title} (${display}%)`;
  }
  return title;
}
