import { config } from "../../Config";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import i18n from "i18next";
import memoize from "lodash/memoize";

const { BaseLayer, Overlay } = LayersControl;
const MAX_ZOOM = 21;

const geoserviceOpts = {
  attribution:
    'Open data dans <a href="https://geoservices.wallonie.be">geoservices.wallonie.be</a>',
};
export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  gwp: i18n.t("Ground water protection"),
  dem: i18n.t("Altitude"),
  cadastre: i18n.t("Cadastre"),
  n2k: "Natura 2000",
}));

export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=WA";
  const refp = { zIndex: 50 };
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      {["2022_ETE", "2021", "2020", "2019", "2018", "2017", "2016", "2015"].map((year) => (
        <BaseLayer key={year} name={`${i18n.t("Orthophoto")} ${year}`}>
          <WMSTileLayer
            url={`https://geoservices.wallonie.be/arcgis/services/IMAGERIE/ORTHO_${year}/MapServer/WMSServer`}
            layers="0"
            {...geoserviceOpts}
          />
        </BaseLayer>
      ))}
      <BaseLayer name="Google maps">
        <GoogleLayer maptype="HYBRID" googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y" />
      </BaseLayer>
      <Overlay name={`${i18n.t("Fields")} ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} tms={true} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Parcelles O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} tms={true} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="89" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Ground water protection area")} checked={layers.includes("gwp")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="90" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Altitude")} checked={layers.includes("dem")}>
        <WMSTileLayer
          url="https://geoservices.wallonie.be/arcgis/services/RELIEF/WALLONIE_MNT_2021_2022/MapServer/WMSServer"
          opacity={0.5}
          layers="0"
          {...geoserviceOpts}
        />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="57" {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Cadastre")} checked={layers.includes("cadastre")}>
        <WMSTileLayer
          url={`${config.GEOSERVER_URL}/geoserver/reference/wms`}
          layers="reference:cadastre_wa"
          format="image/png"
          transparent={true}
          minZoom={13}
          {...refp}
        />
      </Overlay>
    </LayersControl>
  );
});
