import React from "react";
import "./DetailRow.scss";

type Props = {
  className?: string;
  title?: string;
  value?: string;
  children?: React.ReactNode;
  onClick?: () => void;
};

export const DetailRow = (props: Props) => {
  const { className = "", children, title = "", value, onClick } = props;

  if (!children && !value) {
    return null;
  }

  if (!value) {
    return (
      <div className={`c-detail-row ${className}`} onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <div className={`c-detail-row ${className}`} onClick={onClick}>
      {!title ? <span>{value}</span> : <span>{`${title} ${value}`}</span>}
      {children}
    </div>
  );
};
