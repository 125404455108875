import { useDispatch } from "react-redux";
import { accountFeatures, useSSelector } from "../util";
import { config } from "../Config";
import i18n from "i18next";
import React from "react";
import { track } from "../track";
import { showModal } from "../actions";

export const showSubscriptionModal = (
  dispatch: (any) => void,
  msg = i18n.t("Subscribe to use this functionality"),
  category = "pro",
  modal = "subscribe"
) => {
  track("modal", "subscription", category);
  dispatch(showModal({ text: msg, modal: modal, feature: "Pro" }));
};

export function freeTrialClick() {
  track("bb_trial", "btn_click");
}

interface ProProps {
  required?: string;
  msg?: string;
  click?: boolean;
  wrapper?: { className?: string; content: string };
}

export const Pro = ({ required = "pro", msg, click = true, wrapper }: ProProps) => {
  const dispatch = useDispatch();
  const features = useSSelector((state) => accountFeatures(state.activeUser.account));
  let label = required.toUpperCase();
  if (config.bb_site.beta) {
    label = i18n.t("Account");
  }
  if (features[required]) return null;
  const elem = (
    <span
      className="badge label-info bb_pro_feature font-size-small align-self-center"
      style={{ cursor: "pointer" }}
      onClick={() => {
        click && showSubscriptionModal(dispatch, msg);
      }}
    >
      {label}
    </span>
  );
  return wrapper ? (
    <div className={wrapper.className}>
      {elem} {wrapper.content}
    </div>
  ) : (
    elem
  );
};
