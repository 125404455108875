import { DependencyList, useCallback } from "react";
import i18n from "i18next";

export const useShareMobile = () =>
  useCallback((e, text, url) => {
    e.stopPropagation();
    e.preventDefault();
    const _navigator: any = navigator;
    const shareLink: string = window.location.origin + url;
    if (window.innerWidth < 1024 && _navigator.share) {
      // 2021-03-30 window.innerWidth check as requested by Richard
      // see https://developers.google.com/web/updates/2016/09/navigator-share
      _navigator.share({
        title: i18n.t("CropFields link"),
        url: shareLink,
        text: i18n.t("View {{subject}} on CropFields", { subject: text.thisSubject }),
      });
      return true;
    }
  }, []);

export const useClick = (callback, deps?: DependencyList) =>
  useCallback((e, ...args) => {
    e.preventDefault();
    e.stopPropagation();
    return callback(e, ...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);
