import { config } from "../../Config";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import memoize from "lodash/memoize";
import i18n from "i18next";

const { BaseLayer, Overlay } = LayersControl;
export const ignApiKey = "an7nvfzojv5wa96dsga5nk8w";
const geoserviceOpts = {
  ignApiKey: ignApiKey,
  ignLayer: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2",
  styleParam: "normal",
  format: "image/jpeg",
  service: "WMTS",
  attribution: '&copy; <a href="https://data.geopf.fr/">IGN</a>',
  zIndex: 50,
};
const ignLayer =
  "https://data.geopf.fr/wmts?" +
  "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM" +
  "&LAYER={ignLayer}&STYLE={styleParam}&FORMAT={format}" +
  "&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}";
export const layers = memoize(() => ({
  // vertalingen pas na intialisatie actief, daarom in functie
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  // gwp: i18n.t("Ground water protection"),
  dem: i18n.t("Altitude"),
  erosion: i18n.t("Erosion sensitivity"),
  no_fly_zone: "No fly zones",
  cadastre: i18n.t("Cadastre"),
  organic: i18n.t("Organic"),
  n2k: "Natura 2000",
}));

// webservice voor bepalen hoogte van verschillende punten, of punten langs een lijn
// see https://depot.ign.fr/geoportail/api/develop/tech-docs-js/fr/developpeur/alti.html
// see https://geoservices.ign.fr/documentation/geoservices/alti.html
// see https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities

export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png?country=FR";
  const refp = { zIndex: 50 };
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name="Cartes IGN">
        <TileLayer
          url={ignLayer}
          {...geoserviceOpts}
          ignLayer="GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2"
          format="image/png"
        />
      </BaseLayer>
      <BaseLayer name={i18n.t("Orthophoto")}>
        <TileLayer url={ignLayer} {...geoserviceOpts} ignLayer="ORTHOIMAGERY.ORTHOPHOTOS" />
      </BaseLayer>
      <BaseLayer name="Google maps">
        <GoogleLayer maptype="HYBRID" googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y" />
      </BaseLayer>

      <Overlay name={`Parcelles ${config.crop_year}`} checked={layers.includes("parcel")}>
        <TileLayer url={histUrl} tms={true} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name="Parcelles O" checked={showPlotLayer}>
        <TileLayer url={histUrl + "&style=line"} tms={true} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name="Type de Sol" checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="105" {...refp} />
      </Overlay>
      <Overlay name="Relief" checked={layers.includes("dem")}>
        <TileLayer
          url={ignLayer}
          {...geoserviceOpts}
          ignLayer="ELEVATION.SLOPES"
          maxNativeZoom={14}
        />
      </Overlay>
      <Overlay name="Pentes" checked={layers.includes("erosion")}>
        <TileLayer
          url={ignLayer}
          {...geoserviceOpts}
          ignLayer="GEOGRAPHICALGRIDSYSTEMS.SLOPES.PAC"
          styleParam="GEOGRAPHICALGRIDSYSTEMS.SLOPES.PAC"
          maxNativeZoom={14}
          format="image/png"
        />
      </Overlay>
      <Overlay name="No fly zones" checked={layers.includes("no_fly_zone")}>
        <TileLayer
          url={ignLayer}
          {...geoserviceOpts}
          ignLayer="TRANSPORTS.DRONES.RESTRICTIONS"
          maxNativeZoom={14}
          format="image/png"
        />
      </Overlay>
      <Overlay name="Cadastre" checked={layers.includes("cadastre")}>
        <WMSTileLayer
          url={`${config.GEOSERVER_URL}/geoserver/reference/wms`}
          layers="reference:cadastre_fr"
          format="image/png"
          transparent={true}
          minZoom={13}
        />
      </Overlay>
      <Overlay name="Bio" checked={layers.includes("organic")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="168" {...refp} />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} tms={true} opacity={0.5} type="data" src="57" {...refp} />
      </Overlay>
    </LayersControl>
  );
});
