import React from "react";
import { useSSelector } from "../../util";
import "leaflet/dist/leaflet.css";
import { Layer } from "./Layer";
import { geometry } from "./types";
import { getStyle, STYLETYPES } from "../map_util";
import { GeoJSON } from "react-leaflet";

type searchData = {
  type: string;
  id: string;
  geometry: geometry;
};

export const FilterResultLayer = () => {
  const data = useSSelector((state) => state.filterResults as searchData[]);

  if (!data) {
    return null;
  }
  return (
    <Layer name={"filtered-fields"}>
      {data.map((f) => (
        <GeoJSON key={f.id} style={getStyle(STYLETYPES.FILTERED)} data={f as any} />
      ))}
    </Layer>
  );
};
