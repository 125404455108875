import { config } from "../Config";
import React from "react";
import { getCountry } from "../util";

const supported = {
  NL: true,
  BE: true,
  DK: true,
  WA: true,
  FR: true,
  DE_NWPRV: true,
  DE_NIPRV: true,
  //  DE_SHPRV: true,
  ATPRV: true,
  LU: true,
  SEPRV: true,
};

export const Flag = ({ field, attr, type, className = undefined }) => {
  const country = getCountry(field);
  const value = attr ? field[attr] : field;
  const source = `${country}${type}`;
  if (!(value && (supported[country] || supported[source]))) return null;
  const prefix = `${config.LAYER_URL}/static/sprite/${source}`;
  return (
    <img
      alt={value.name}
      title={value.name}
      src={prefix + ".webp"}
      srcSet={`${prefix}.webp 1x, ${prefix}@2x.webp 2x`}
      className={`${source}-${value.ext_id} flag-icon ${className || ""}`}
    />
  );
};
