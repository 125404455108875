import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { selectList, showModal } from "../../../actions";
import { GenericPopper } from "../../../components/GenericPopper";
import { ListView } from "../../../components/ListView";
import { useSSelector } from "../../../util";
import i18n from "i18next";
import { IconButton } from "../../../components/IconButton";

type Props = {
  liked: boolean;
  location?: string;
  fieldKey?: string;
};

// This is the heart button
export const LikeButton = (props: Props) => {
  const { liked = false, location, fieldKey } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { loggedIn } = useSSelector((s) => ({
    loggedIn: s.activeUser.state === "loggedIn",
  }));
  if (!loggedIn) {
    return (
      <IconButton
        key="fav"
        icon={"heart"}
        onClick={() => {
          dispatch(
            showModal({
              text: i18n.t("Login to like farms and plots"),
              modal: "subscribe",
              feature: "Pro",
            })
          );
        }}
      />
    );
  }
  return (
    <GenericPopper
      content={<ListView locations={[location]} fieldKey={fieldKey} />}
      open={open}
      placement="bottom"
      onClose={() => {
        setOpen(false);
        dispatch(selectList({ selectedList: null }));
      }}
    >
      <div>
        <IconButton
          icon="heart"
          key="fav"
          active={liked}
          style={liked ? "fas" : "far"}
          onClick={(e) => {
            setOpen(!open);
            e.preventDefault();
          }}
        />
      </div>
    </GenericPopper>
  );
};
