import { SectionProps } from "./Sections";
import { SectionTitle } from "../util";
import React from "react";
import { Cadastre, Lease } from "../reference";
import i18n from "i18next";
import { config } from "../../Config";
import { getCode, TS, useSSelector } from "../../util";
import imgSampleKadaster from "../../../img/sample/sample_kadaster.png";

// config.cadastre

const CadastreBackup = () => (
  <div>
    <div className="row">
      <div className="col-md-6">
        <p>
          <TS
            t={'<a href="/authorize">Login</a> to CropFields to view the cadastral field mapping'}
          />
          .
        </p>
        <table className="table d-none d-sm-block">
          <tbody>
            <tr>
              <th>{i18n.t("Field")}</th>
              <th className="text-right">{i18n.t("Area")}</th>
            </tr>
            <tr>
              <td>
                <i>{i18n.t("l_cadastre_p1", "GEM X 189E")}</i>
              </td>
              <td className="text-right">2.1 ha</td>
            </tr>
            <tr>
              <td>
                <i>{i18n.t("l_cadastre_p2", "GEM X 190E")}</i>
              </td>
              <td className="text-right">1.8 ha</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-md-6 d-none d-sm-block">
        <img
          className="media-object"
          src={imgSampleKadaster}
          alt={`${i18n.t("Cadastre")} ${i18n.t("Field")}`}
        />
      </div>
    </div>
  </div>
);

export const SectionProperty = ({
  active,
  field,
  smallScreen,
  features,
  cropfield,
  BackupComponent = CadastreBackup,
}: SectionProps & { cropfield: any; BackupComponent?: React.FunctionComponent }) => {
  const showLease =
    useSSelector((s) => s.activeUser?.settings?.showLease) && config.show.includes("lease");

  if (!(features.pro || config.cadastre || showLease)) return null;
  return (
    <section id="section_property" className={`${active ? " active" : ""}`}>
      <SectionTitle name={i18n.t("Ownership")}>
        <TS t="l_info_property" />
      </SectionTitle>
      {features.pro ? (
        <Cadastre data={field.cadastre} cropfield={cropfield} smallScreen={smallScreen} />
      ) : (
        config.cadastre && <BackupComponent />
      )}
      {features.pro && field.property_i && (
        <div className="pb-1">
          <TS
            t="This plot is owned by state institute <i>{{name}}</i>"
            o={{ name: getCode(field.property_i, "name") }}
          />
        </div>
      )}
      {showLease && (
        <div className="pt-2">
          <Lease
            data={field.use_title}
            field={field}
            smallScreen={smallScreen}
            cropfield={cropfield}
          />
        </div>
      )}
    </section>
  );
};
