import React, { useCallback } from "react";
import { NavDropdown, NavItem } from "react-bootstrap";
import { Icon } from "react-fa";
import "./Header.scss";
import { FIcon } from "./FIcon";

export const FarmSwitch = ({ farm, farms, click, refreshing }) => {
  const onClick = useCallback(
    (e) => {
      const externalId = e.currentTarget.dataset.external_id;
      if (externalId !== farm.external_id) {
        click(farms.find((f) => f.external_id === externalId));
      }
    },
    [click, farm?.external_id, farms]
  );
  if (refreshing)
    return (
      <NavItem className="d-none d-md-block disabled bb_farm" as={"li"}>
        <Icon spin name="spinner" /> ...
      </NavItem>
    );
  if (!farm)
    return (
      <NavItem className="d-none d-md-block disabled bb_farm" as={"li"}>
        <FIcon name="farm" /> -
      </NavItem>
    );
  if (farms.length < 2)
    return (
      <NavItem className="d-none d-md-block disabled bb_farm" as={"li"}>
        <FIcon name="farm" /> {farm.name}
      </NavItem>
    );
  return (
    <NavDropdown
      id="farmswitch"
      key="1"
      as={"li"}
      className="d-none d-md-block bb_farm_switch dropdown-menu-left"
      title={
        <span>
          <FIcon name="farm" /> {farm.name}
          <span className={"caret"} />
        </span>
      }
    >
      <ul style={{ display: "contents" }}>
        {farms.map((f) => (
          <NavDropdown.Item
            key={f.external_id}
            active={f.external_id === farm.external_id}
            data-external_id={f.external_id}
            eventKey={f.external_id}
            onClick={onClick}
            href="#"
            as={"li"}
          >
            {f.external_id === farm.external_id && <span className="fa fa-check mr-1" />}
            {f.name}
          </NavDropdown.Item>
        ))}
      </ul>
    </NavDropdown>
  );
};
