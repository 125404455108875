import React, { memo } from "react";
import { getCode } from "../../util";
import { SimpleMap } from "../../map/SimpleMap";
import { SoilProps } from "./Soil";

const lst: [number, string, string, string?, string?, string?, string?][] = [
  [104, "Grovsanded jord", "#ffff00", "0-5", "0-20", "0-50", "75-100"],
  [83, "Finsandet jord", "#d79e9e", "0-5", "0-20", "50-100", "75-100"],
  [84, "Lerblandet sandjord", "#ffaa00", "5-10", "0-25", "", "65-95"],
  [85, "Sandblandet lerjord", "#cd8974", "5-10", "0-25", "", "55-90"],
  [86, "Lerjord", "#89440b", "15-25", "0-35", "", "40-85"],
  [87, "Svær lerjord og siltjord", "#5c8907", "0-100", "0-100", "", "0-80"],
  [103, "Humusjord", "#a5f500"],
  [106, "Speciel jordtype", "#d69d00"],
];

const SoilDK = ({ cropfield, smallScreen, size = 300 }: SoilProps) => {
  const features = (cropfield.soil && cropfield.soil.features) || [];
  const selected = features.reduce((memo, f) => {
    const group = getCode(f.properties.code, "group");
    if (group) memo[group.id] = true;
    return memo;
  }, {});

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>FK</th>
            <th>Jordtype</th>
            <th>
              Ler
              <br />
              <span className="small">&lt; 2um</span>
            </th>
            <th>
              Silt
              <br />
              <span className="small">2-20 um</span>
            </th>
            <th>
              Finsand
              <br />
              <span className="small">20-200 um</span>
            </th>
            <th>
              Sand
              <br />
              <span className="small">20-2000 um</span>
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "10px" }}>
          {lst.map((e, i) => (
            <tr key={i} style={selected[e[0]] ? { backgroundColor: e[2] } : null}>
              <td>{i + 1}</td>
              <td>{e[1]}</td>
              <td>{e[3]}</td>
              <td>{e[4]}</td>
              <td>{e[5]}</td>
              <td>{e[6]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {smallScreen ? (
        <SimpleMap feature={cropfield.feature} height={size}>
          {cropfield.soil.features.map((f, i) => (
            <SimpleMap.TopPolygon
              key={`${i}_${f.id}`}
              feature={f}
              fillOpacity={1}
              fillColor={getCode(f.properties.code, "color")}
              stroke={false}
            />
          ))}
        </SimpleMap>
      ) : null}
    </div>
  );
};

const m = memo(SoilDK);
export { m as SoilDK };
