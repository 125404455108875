const translations = {
"da-DK": {translation: {
    "l_cadastre_p1": "1234 1c",
    "l_cadastre_p2": "1234 2b",
    "l_info_facts": "Landmand skal hvert år sende et fællesskema og markkort til Landbrugsstyrelsen. <br /> Kilde: <a href=\"https://mfvm.dk/landbrug/\">Miljø- og Fødevareministeriet</a>",
    "l_info_height": "Danmarks Højdemodel (DHM) er en samlet betegnelse for en række datasæt, som hver på sin måde beskriver højden af landskabet for hele Danmark.",
    "l_info_property": "Matrikelregistret indeholder oplysninger om alle matrikelnumre i Danmark. Kilde: <a href=\"https://gst.dk/matriklen/\">gst.dk/matriklen/</a>",
    "l_info_soil": "For mere info se <a href=\"https://ign.ku.dk/partnerlandskab/god-vaekstjord/beskrivelse-af-jord/\">Beskrivelse af jord</a>"
}},
"de-AT": {translation: {
    "Municipality": "Bezirk",
    "Province": "Bundesland",
    "l_info_facts": "Zur Berechnung der Fruchtfolge verwenden wir die INVEKOS Schläge Österreich von <a href=\"https://www.data.gv.at/\">Open Data Österreich/</a>"
}},
"de-DE_NI": {translation: {
    "l_info_facts": "Zur Berechnung der Fruchtfolge verwenden wir die Schlaginfo für dem Agrarförderung des <a href=\"https://sla.niedersachsen.de/mapbender_sla/\">Niedersächsische Ministeriums für Ernährung, Landwirtschaft und Verbraucherschutz</a>.",
    "l_info_height": "In Niedersachsen sind die von der Regierung erhobenen Höhenkarten nicht kostenlos. Daher verwenden wir das EU Digital Elevation Model (EU-DEM). Das EU-DEM kombiniert Daten aus verschiedenen Quellen zu einem einzigen konsistenten und homogenen Höhendatensatz, aber die Auflösung ist ziemlich niedrig (25 Meter)."
}},
"de-DE_NW": {translation: {
    "l_info_facts": "Wir verwenden die Beantragte und als förderfähig festgestellte Teilschläge in NRW von <a href=\"https://www.opengeodata.nrw.de/\">opengeodata.nrw.de/</a>",
    "l_info_height": "Die Landesvermessung NRW erzeugt flächendeckend ein Digitales Geländemodell (DGM) der natürlichen Geländeform der Erdoberfläche. Zur Datenerfassung der Höhenpunkte wird das flugzeuggestützte Laserscanning eingesetzt."
}},
"de-LU": {translation: {
    "Municipality": "Gemeinde",
    "Province": "Kanton"
}},
"fr-FR": {translation: {
    "Municipality": "Departement",
    "Province": "Region",
    "l_cadastre_p1": "1234000AH0123",
    "l_cadastre_p2": "1234000AH0124",
    "l_info_facts": "Registre parcellaire graphique (RPG) : contours des parcelles et îlots culturaux et leur groupe de cultures majoritaire",
    "l_info_height": "Le premier modèle de hauteur est basé sur <a href=\"https://www.copernicus.eu/en\">copernicus EU-DEM v1.1</a>, aux pas de 30m. <br />La deuxième modèle est BD ALTI® aux pas de 75m. La BD ALTI® est le modèle numérique de terrain (MNT) maillé qui décrit le relief du territoire français à moyenne échelle. La BD ALTI® et apporte une 3ème dimension pour représenter et analyser le territoire à moyenne échelle. Voici la Donnée <a href=\"http://professionnels.ign.fr/bdalti\">BD Alti®</a> du Institut national de l'information géographique et forestière (IGN-F).",
    "l_info_soil": "arte du nom de sol dominant en WRB issue de la Base de Données Géographique des Sols de France.<br /><i>Base de Données Géographique des Sols de France à 1/1 000 000 version 3.2.8.0, 10/09/1998</i>, INRA, 2018. Voici <a href=\"https://doi.org/10.15454/1.5093789090876233E12\">la ressource</a>."
}},
"fr-LU": {translation: {
    "Municipality": "Commune",
    "Province": "Canton",
    "l_cadastre_p1": "1234000AH0123",
    "l_cadastre_p2": "1234000AH0124",
    "l_info_facts": "Référentiel des parcelles FLIK",
    "l_info_height": "Le premier modèle de hauteur est basé sur <a href=\"https://www.copernicus.eu/en\">copernicus EU-DEM v1.1</a>, aux pas de 30m. <br />La deuxième modèle est LiDAR 2019 (résolution du raster : 50 cm). Le modèle numérique de terrain est dérivé des données du survol LiDAR 2019. Pour le calcul du MNT, uniquement les points du nuage de points classés comme « sol » ont été utilisés. Avec une densité de 15 points par m2, il se peut que plusieurs points se localisent dans un pixel. La valeur du pixel (50x50 cm) représente la moyenne des valeurs altimétriques à l’intérieur de la surface du pixel.<br/>Source: <a href=\"https://map.geoportail.lu/theme/main?version=3&zoom=8&X=667917&Y=6394482&lang=fr&layers=1795&opacities=1&bgLayer=basemap_2015_global\">geoportail.lu</a>"
}},
"fr-WA": {translation: {
    "l_cadastre_p1": "1234C0123/00D000",
    "l_cadastre_p2": "1234C0124/00D001",
    "l_info_facts": "Parcellaire Agricole Anonyme de <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://geoportail.wallonie.be/\">Géoportail de la Wallonie</a>.<br />Le parcellaire agricole anonyme reprend l’utilisation du sol dans les zones agricoles et forestières gérées dans le cadre de la mise œuvre de la Politique Agricole Commune par l’Organisme Payeur de Wallonie.",
    "l_info_growth": "Carte de végétation actuelle de la Belgique obtenue à partir d'images satellites. Parexemple, suivez quand par parcelle est semé et récolté.<br />Source: <a href=\"https://copernicus.eu/\">copernicus.eu</a>",
    "l_info_height": "Modèle Numérique de Terrain (MNT) de la Wallonie d'une résolution d'un mètre issu d'acquisitions Lidar effectuées entre le 12/12/2012 et le 09/03/2014. L'acquisition de la donnée a été réalisée avec la technologie Lidar. <br />Source: <a href=\"https://geoportail.wallonie.be/\">Géoportail de la Wallonie</a><br />Licence n°: 191014-1645.",
    "l_info_property": "Cadastre. Open Data de <a href=\"https://finances.belgium.be/fr/particuliers/habitation/cadastre/plan-cadastral\">Plan Cadastral de SPF</a>. Regarde aussi le <a href=\"https://geoportail.wallonie.be/\">Géoportail de la Wallonie</a>.<br />",
    "l_info_soil": "La Carte des Principaux Types de Sols de Wallonie a été dérivée de la Carte Numérique desSols de Wallonie. Voici le <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"http://geoportail.wallonie.be/catalogue/64bbc088-367c-485c-bd7c-d2d08baedf9d.html\">Géoportail de la Wallonie</a>."
}},
"nl-BE": {translation: {
    "l_cadastre_p1": "1234B1234/00_000",
    "l_cadastre_p2": "1234B1235/00_000",
    "l_info_facts": "Boeren moeten jaarlijks een perceelsopgave indienen bij de Overheid. Deze landbouwgebruikspercelen zijn open, en hiermee is vanaf 2008 de gewasrotatie te construeren. <br />Bron: <a href=\"https://lv.vlaanderen.be/nl/home/over-ons/departement-landbouw-en-visserij/gegevensuitwisseling/landbouwgebruikspercelen-ter\">Agentschap voor Landbouw en Visserij</a>",
    "l_info_growth": "Actuele vegetatiekaart van België verkregen uit satellietbeelden. Je kan per perceel bijvoorbeeld zien wanneer wordt gezaaid en geoogst.<br />Bron: <a href=\"https://copernicus.eu/\">copernicus.eu</a>",
    "l_info_height": "Digitale hoogtekaart van Vlaanderen, uitgedrukt in meters in TAW. Standaard tonen we de DHMV-1. Boer&Bunder Pro gebruikers kunnen met knoppen wisselen tussen DHMV-1 en DHMV-2.<br />De DHMV-1 is gemeten in de periode 2001-2004 en de DHMV-2 is gemeten in de periode 2013-2015.<br />Bron: <a href=\"https://overheid.vlaanderen.be/informatie-vlaanderen/producten-diensten/digitaal-hoogtemodel-dhmv\">Digitaal Hoogtemodel (DHMV)</a>",
    "l_info_nature": "Natura 2000 is een Europees netwerk van beschermde natuurgebieden. In Natura 2000-gebieden worden bepaalde diersoorten en hun natuurlijke leefomgeving beschermd om de biodiversiteit te behouden.<br />Zie het <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">Europese overzicht</a>.",
    "l_info_property": "Digitale kadastrale percelenplannen, administratieve percelen uit het <i>Grootschalig Referentie Bestand Vlaanderen, AGIV</i>. De attributen van een perceel (de eigenaar, erfdienstbaarheden etc) zijn niet open, maar kunnen tegen betaling opgevraagd worden bij het kadaster. <br />Zie <a href=\"https://www.kadaster.be/Kadaster/Het_kadaster_online\">Online kadaster</a> en <a href=\"https://financien.belgium.be/nl/particulieren/woning/kadaster/kadastraal-uittreksel\">kadastrale uittreksels</a>.",
    "l_info_soil": "Digitale Bodemkaart van het Vlaamse Gewest, met de Belgische classificatie.<br />Bron: <a href=\"https://www.geopunt.be/catalogus/datasetfolder/5c129f2d-4498-4bc3-8860-01cb2d513f8f\">geopunt.be</a>"
}},
"nl-NL": {translation: {
    "CropFields crop prediction for {{year}}": "Boer&Bunder voorspelt voor {{year}} de volgende gewassen",
    "l_cadastre_p1": "GEM X 189E",
    "l_cadastre_p2": "GEM X 190E",
    "l_info_facts": "Boeren moeten jaarlijks een opgave van al hun percelen indienen bij de overheid. Met deze open data kan je vanaf 2005 de gewasrotatie bekijken.<br />Bron: <a href=\"https://www.pdok.nl/introductie/-/article/basisregistratie-gewaspercelen-brp-\">Ministerie EZ / RVO</a> via PDOK",
    "l_info_height": "Digitale hoogtekaart van Nederland. Standaard tonen we AHN1. Abonnees wisselen met verschillende jaartallen tussen AHN1, AHN2, AHN3 en AHN4.<br /> Bron: <a href=\"https://www.ahn.nl/over-ahn\">AHN</a> / <a href=\"https://www.pdok.nl/rasterdata\">PDOK</a>",
    "l_info_nature": "Natura 2000 is een Europees netwerk van beschermde natuurgebieden. In Natura 2000-gebieden worden bepaalde diersoorten en hun natuurlijke leefomgeving beschermd om de biodiversiteit te behouden.<br />Zie <a href=\"https://www.rijksoverheid.nl/onderwerpen/natuur-en-biodiversiteit/natura-2000\">Natura 2000 in NL</a> voor meer informatie of het <a href=\"https://ec.europa.eu/environment/nature/natura2000/index_en.htm\">Europese overzicht</a>.",
    "l_info_property": "Het Kadaster deelt de perceelsgrenzen als open data onder de CC-BY licentie. De attributen van een perceel (de eigenaar, erfdienstbaarheden etc) zijn niet open, maar kunnen tegen betaling opgevraagd worden bij het kadaster. <br />Zie <a href=\"https://www.kadaster.nl/zakelijk/datasets/open-datasets\">het kadaster en open data</a>",
    "l_info_soil": "Globaal overzicht van de bodemgesteldheid van Nederland. We onderscheiden 10 verschillende grondsoorten (incl. 'Bebouwing, enz.' en 'Water'). <br />Bron: <a href=\"https://www.wageningenur.nl/en/show/Grondsoortenkaart.htm\">Alterra grondsoortenkaart 2006</a><br />Over de Wettelijke Grondsoortenkaart bij het uitvoeringsbesluit Meststoffenwet is <a href=\"https://www.rvo.nl/onderwerpen/mest/grasland-scheuren\">meer informatie te vinden bij RVO</a>."
}},
};
export default translations;
