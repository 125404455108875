import React, { memo } from "react";
import i18n from "i18next";
import { accountFeatures, getHeightLabels, useSSelector } from "../util";
import { ReportComponentProps } from "./Report";
import { SectionSoil, SectionGrowth, SectionHeight, SectionNature, SectionFacts } from "./sections";
import { RestrictedContent } from "./RestrictedContent";

const ReportDESH = ({ section = "" }: ReportComponentProps) => {
  const { account, field, bunder } = useSSelector(({ selection, activeUser }) => ({
    account: activeUser.account,
    field: selection.field,
    bunder: selection.bunder,
  }));
  const cropfield = field?.state === "fetched" ? field.feature : bunder,
    smallScreen = window.innerWidth < 768,
    features = accountFeatures(account);
  const p = { features, field, cropfield, smallScreen };
  return (
    <main className="sections">
      <SectionFacts
        active={!section || section === "facts"}
        features={features}
        smallScreen={smallScreen}
        cropfield={cropfield}
        field={field}
        title={i18n.t("Attributes")}
      />
      <SectionSoil size="" active={section === "soil"} {...p} />
      <SectionGrowth
        active={section === "growth"}
        inView={["soil", "property", "growth", "height"].includes(section)}
        {...p}
      />
      <SectionHeight
        active={section === "height"}
        inView={["property", "growth", "height"].includes(section)}
        names={["1"]}
        labels={getHeightLabels(field, ["2010"])}
        {...p}
      />
      <SectionNature active={section === "nature"} {...p} />
      <section id="section_last" />
    </main>
  );
};

const m = memo(({ section = "" }: ReportComponentProps) => (
  <RestrictedContent Component={ReportDESH} section={section} />
));

export { m as ReportDESH };
