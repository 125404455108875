import { BBMap } from "./BBMap";
import { connect } from "react-redux";
import { accountFeatures } from "../util";
import { layers as NLlayers, Layers as NLLayers } from "./countryOverlays/MapNL";
import { layers as BElayers, Layers as BELayers } from "./countryOverlays/MapBE";
import { layers as DKlayers, Layers as DKLayers } from "./countryOverlays/MapDK";
import { layers as WAlayers, Layers as WALayers } from "./countryOverlays/MapWA";
import { layers as FRlayers, Layers as FRLayers } from "./countryOverlays/MapFR";
import { layers as DENWlayers, Layers as DENWLayers } from "./countryOverlays/MapDENW";
import { layers as DENIlayers, Layers as DENILayers } from "./countryOverlays/MapDENI";
import { layers as DESHlayers, Layers as DESHLayers } from "./countryOverlays/MapDESH";
import { layers as ATlayers, Layers as ATLayers } from "./countryOverlays/MapAT";
import { layers as LUlayers, Layers as LULayers } from "./countryOverlays/MapLU";
import { layers as Globallayers, Layers as GlobalLayers } from "./countryOverlays/MapGlobal";
import { config } from "../Config";

import isFunction from "lodash/isFunction";

const layerNames = {
  BE: BElayers,
  NL: NLlayers,
  DK: DKlayers,
  WA: WAlayers,
  FR: FRlayers,
  DE_NW: DENWlayers,
  DE_NI: DENIlayers,
  DE_SH: DESHlayers,
  AT: ATlayers,
  LU: LUlayers,
  __: Globallayers,
};
export const Layers = {
  BE: BELayers,
  NL: NLLayers,
  DK: DKLayers,
  WA: WALayers,
  FR: FRLayers,
  DE_NW: DENWLayers,
  DE_NI: DENILayers,
  DE_SH: DESHLayers,
  AT: ATLayers,
  LU: LULayers,
  __: GlobalLayers,
};

export const mapMapStateToProps = (state) => ({
  activeUser: state.activeUser,
  layers: state.layers.selected,
  accountFeatures: accountFeatures(state.activeUser.account),
  showControls: state.router.location.pathname === "/controls",
  showSearch: state.router.location.pathname === "/search",
  layerNames: isFunction(layerNames[config.bb_country])
    ? layerNames[config.bb_country](state.activeUser)
    : layerNames[config.bb_country],
  Layers: Layers[config.bb_country],
});
export { SimpleMap } from "./SimpleMap";
export default connect(mapMapStateToProps)(BBMap);
