import React, { useCallback, useState } from "react";
import i18n from "i18next";
import { IconButton } from "../../../components/IconButton";
import { track } from "../../../track";
import { ShareModal } from "../../../modals";

// This is the share-link button
export const ShareButton = () => {
  const [shareModal, setShareModal] = useState(false);

  const text = {
    thisSubject: i18n.t("this field"),
    subject: i18n.t("Field"),
  };
  const shareMobile = useCallback((text) => {
    const _navigator: any = navigator;
    const shareLink: string = window.location.href;
    if (window.innerWidth < 1024 && _navigator.share) {
      // 2021-03-30 window.innerWidth check as requested by Richard
      // see https://developers.google.com/web/updates/2016/09/navigator-share
      _navigator.share({
        title: i18n.t("CropFields link"),
        url: shareLink,
        text: i18n.t("View {{subject}} on CropFields", { subject: text.thisSubject }),
      });
      return true;
    }
  }, []);

  const shareBunder = useCallback(() => {
    track("share", "show_modal", String(window.location));
    shareMobile({
      thisSubject: i18n.t("this field selection"),
      subject: i18n.t("Field selection"),
    });
    setShareModal(true);
  }, [shareMobile]);

  return (
    <>
      <IconButton icon="share-alt" onClick={shareBunder} className="pr-1" />
      {shareModal && <ShareModal text={text} onClose={() => setShareModal(false)} />}
    </>
  );
};
