import React from "react";
import { CropfieldMap, StaticMap } from "../map/map_util";
import { Link } from "react-router-dom";
import { accountFeatures, periodString, useSSelector } from "../util";
import i18n from "i18next";
import { LikeButtonLight } from "../components/ListView";
import { Media } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setHighlightField, removeHighlightField } from "../actions/lists";

interface BunderProps {
  className?: string;
  bunder: any;
  returnUrl?: string;
}

export const Bunder = (props: BunderProps) => {
  const { className = "bb_bunder media", bunder, returnUrl = "" } = props;
  const features = useSSelector((state) => accountFeatures(state.activeUser?.account));

  const gpsString = bunder.point;
  const period = periodString(bunder, "DD/MM/YY");
  const decimals = features.pro ? 4 : 0;

  let xy = [0, 0];
  let link = `${returnUrl}/plot/${bunder.key}`;

  const highlightID = useSSelector((state) => state.highlight.id);
  const dispatch = useDispatch();
  return (
    <Link
      to={link}
      className={`${className} d-block ${bunder.id === highlightID ? "media--hover" : "bg-light"}`}
      onMouseEnter={() => dispatch(setHighlightField(bunder.id))}
      onMouseLeave={() => dispatch(removeHighlightField())}
    >
      <Media className="p-2">
        {bunder.type === "PUBLIC" ? (
          <StaticMap className="media-object align-self-center" gps={bunder.point} size={60} />
        ) : (
          <CropfieldMap className="media-object align-self-center" id={bunder.id} size={60} />
        )}
        <Media.Body className="px-2 d-flex text-secondary">
          <dl>
            {bunder.name || bunder.crop_name ? (
              <>
                <dt>{i18n.t("Field")}</dt>
                <dd>
                  {bunder.name && <span>{bunder.name} </span>}
                  {bunder.crop_name && <i>{bunder.crop_name}</i>}
                </dd>
              </>
            ) : (
              <>
                <dt>XY</dt>
                <dd>
                  {xy[0] | 0} {xy[1] | 0}
                </dd>
              </>
            )}
            {bunder.area ? (
              <>
                <dt>{i18n.t("Area")}</dt>
                <dd>
                  {decimals < 2 ? "~" : ""}
                  {bunder.area ? bunder.area.toFixed(decimals) : "?"} ha{" "}
                </dd>
              </>
            ) : (
              <>
                <dt>GPS</dt>
                <dd>{bunder.point}</dd>
              </>
            )}
            {period && (
              <>
                <dt>{i18n.t("Period")}</dt>
                <dd>{period}</dd>
              </>
            )}
          </dl>
          {bunder.type === "PUBLIC" && (
            <LikeButtonLight location={gpsString} fieldKey={bunder.key} />
          )}
        </Media.Body>
      </Media>
    </Link>
  );
};
