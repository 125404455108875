import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reducers from "./reducers";
export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [routerMiddleware(history), thunk];
if (false && process.env.NODE_ENV === "development") {
  middleware.unshift(createLogger({ logErrors: false }));
}

export function makeStore(preloadedState = undefined) {
  return createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
}
export const store = makeStore();
export type GetState = typeof store.getState;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
