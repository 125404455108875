import React, { useCallback, useEffect, useState } from "react";
import i18n from "i18next";
import { config } from "../../Config";
import "./Map.scss";
import { bbAxios } from "../../util";

const BBOX = {};
function max(xy1, xy2) {
  if (xy1.length !== xy2.length || xy1.length !== 4) console.error("Wrong length");
  return xy1.map((val, index) =>
    index < 2 ? (val < xy2[index] ? val : xy2[index]) : val > xy2[index] ? val : xy2[index]
  );
}
const MAPS = {};

interface DataProperties {
  key: string;
  value: number;
  unit: string;
}

interface MapProperties {
  data: DataProperties[];
  zone: "zone1" | "zone2";
  onClick: (name: string, e: any) => void;
  style: React.CSSProperties;
}
export const Map = ({ data, zone, onClick, style }: MapProperties) => {
  const [status, setStatus] = useState("");
  const [shapes, setShapes] = useState<Record<string, string>>();
  const m = config.bb_country === "AT" ? ["LAND", "PRV"] : ["PRV", "MUN"];
  const region = config.bb_country + m[zone === "zone1" ? 0 : 1];
  useEffect(() => {
    if (status || shapes) return;
    if (MAPS[region]) {
      setShapes(MAPS[region]);
      return;
    }
    setStatus(i18n.t("Loading"));
    bbAxios
      .get(`${config.LAYER_URL}/administrativereference/svgpath/${region}`)
      .then(({ data }) => {
        setShapes((MAPS[region] = data));
      })
      .catch(function (error) {
        console.error("Error fetch field", error);
        setStatus(i18n.t("error"));
      });
  }, [shapes, region, status]);
  const onMapClick = useCallback((e) => onClick?.(e.target.dataset.name, e), [onClick]);
  if (typeof shapes === "string" || !shapes) {
    return <div className="center">{shapes || i18n.t("Loading")}</div>; // loading
  }
  if (!data || !shapes) return <div className="center">{status || i18n.t("Loading")}</div>;

  const offset = data.reduce((max, { value }) => (value > max ? value : max), 0);
  const dataPerShape = data.reduce((m, { key, value, unit }) => {
    m[key] = { value, unit };
    return m;
  }, {});
  const bbox = Object.entries(shapes).reduce(
    (m, [name, path]) => {
      if ((data.length && !dataPerShape[name]) || !path) return m;
      let b = BBOX[name];
      if (!b) {
        const matches = path.match(/-?\d+/g);
        if (matches.length % 2 === 1) return console.error("Unexpected path");
        b = [1000, 1000, 0, 0];
        matches.forEach((nr, index) => {
          const n = parseInt(nr, 10),
            i = index % 2;
          if (b[i] > n) b[i] = n;
          if (b[i + 2] < n) b[i + 2] = n;
        });
        BBOX[name] = b;
      }
      return max(m, b);
    },
    [1000, 1000, 0, 0]
  );
  return (
    <svg
      viewBox={`${bbox[0] - 20} ${bbox[1] - 20} ${bbox[2] - bbox[0] + 20} ${
        bbox[3] - bbox[1] + 20
      }`}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className="facet-map"
    >
      {Object.entries(shapes).map(([name, path], index) => {
        const d = dataPerShape[name];
        if (data.length && !d) return null;
        const color = d ? Math.round(250 - (d.value / offset) * 250) : 250;
        return (
          <path
            className="shape"
            key={name}
            data-name={name}
            d={path}
            style={{ fill: `rgb(${color}, ${color}, 255)` }}
            onClick={onMapClick}
          >
            <title>
              {name} {d ? (d.unit === "ha" ? d.value.toFixed(2) : d.value) : ""}{" "}
              {d ? (d.unit === "ha" ? "ha" : i18n.t("fields")) : ""}
            </title>
          </path>
        );
      })}
    </svg>
  );
};
