import React, { useEffect, useState } from "react";
import i18n from "i18next";
import L from "leaflet";
import { useDispatch } from "react-redux";
import { goBack, replace } from "connected-react-router";
import { accountFeatures, accountFilterHistory, getCenter, getCode, useSSelector } from "../util";
import { toggleCropYear, updateGrowth, updateSatelliteImage } from "../actions";
import { CropHistory, DEMDiagram, GrowthDiagram } from "../report/diagrams";
import { Cadastre, Nature } from "../report/reference";
import { Soil } from "../report/soil/Soil";
import { Icon } from "react-fa";
import { QRCodeSVG } from "qrcode.react";
import { SimpleMap } from "../map";
import { Marker } from "react-leaflet";
import { Helmet } from "react-helmet";
import { config } from "../Config";
import imgLogoDK from "../../img/logo/dacom-markogbonde-logo-fullcolor.png";
import imgLogoFR from "../../img/logo/dacom-fermieretchamp-logo-fullcolor.png";
import imgLogoDE from "../../img/logo/dacom-bauerngrund-logo-fullcolor.png";
import imgLogoNL from "../../img/logo/dacom-boerenbunder-logo-fullcolor.png";
import { FieldLocationInfo } from "../report/sections/FieldLocationInfo";
import "./PrintReportView.scss";

const imgLogo =
  { DK: imgLogoDK, WA: imgLogoFR, FR: imgLogoFR, DE: imgLogoDE }[config.bb_country.split("_")[0]] ||
  imgLogoNL;

const PrintBtn = ({ delay = 3000 }) => {
  const [hidden, setHidden] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHidden(false);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);
  return (
    <div className="print-btn">
      {hidden ? (
        <Icon spin name="spinner" size="3x" />
      ) : (
        <button onClick={() => window.print()} className="btn-print" />
      )}
    </div>
  );
};

const DEMStatistics = ({ statistics }) => (
  <div id="dem-summary">
    <table className="table table-condensed">
      <tbody>
        <tr>
          <td>{i18n.t("lowest")}:</td>
          <td className="text-right">{statistics.dem_min.toFixed(2)}&nbsp;m</td>
        </tr>
        <tr>
          <td>{i18n.t("average")}:</td>
          <td className="text-right">{statistics.dem_avg.toFixed(2)}&nbsp;m</td>
        </tr>
        <tr>
          <td>{i18n.t("heighest")}:</td>
          <td className="text-right">{statistics.dem_max.toFixed(2)}&nbsp;m</td>
        </tr>
        <tr>
          <td>{i18n.t("difference")}:</td>
          <td className="text-right">
            {(statistics.dem_max - statistics.dem_min).toFixed(2)}&nbsp;m
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export const PrintReportView = () => {
  const dispatch = useDispatch();
  const props = useSSelector((state) => {
    const { field, bunder, history, dem, shadow, growth, water, year } = state.selection;
    const fields = state.favorites?.fields || [];
    return {
      account: state.activeUser.account,
      activeUserSettings: state.activeUser.settings || {},
      field,
      year,
      bunder, // null is not fetched, {'state': 'fetching'}, {'state': 'fetched', data:null} is nothing there
      selectionHistory: history, // null is not fetched, {'state': 'fetching'}, {'state': 'fetched', data:null} is nothing there
      growth, // null is not fetched, {'state': 'fetching'}, {'state': 'fetched', data:null} is nothing there
      dem,
      shadow,
      water,
      liked: field && fields?.filter((likedField) => likedField["id"] === field["id"]),
    };
  });

  const { field, bunder, dem, shadow, growth, account } = props;
  const cropfield = field && field.state === "fetched" ? field.feature : bunder,
    cropHistory = field && accountFilterHistory(field.crops, account, 10),
    features = accountFeatures(account),
    smallScreen = true;
  if (!cropfield) {
    dispatch(replace("/"));
    return null;
  }
  const center = getCenter(cropfield).reverse();

  return (
    <div id="printable-content">
      <Helmet>
        <title>{`${i18n.t("Print field")} ${cropfield.id} ${center[0].toFixed(
          5
        )},${center[1].toFixed(5)}`}</title>
      </Helmet>
      <div className="print-width px-2">
        <div className="action-btns">
          <PrintBtn delay={3000} />
          <div className="back-btn">
            <button onClick={() => dispatch(goBack())} className="btn-close" />
          </div>
        </div>

        <div>
          <div className="float-right qr">
            <QRCodeSVG
              size={90}
              value={`${window.location.protocol}//${
                window.location.host
              }/report/${center[0].toFixed(5)},${center[1].toFixed(5)}`}
            />
          </div>
          <img alt={i18n.t("CropFields")} className="bb_logo" height="48" src={imgLogo} />
          <h2>
            {i18n.t("Field")} {cropfield.id}
          </h2>
        </div>

        <div className="unbreakable-content">
          <div className="row">
            <div className="col-6">
              <FieldLocationInfo field={field} />
              <dl>
                {field.zonew && [
                  <dt key={1}>{i18n.t("Water board")}</dt>,
                  <dd key={2}>{field.zonew.name}</dd>,
                ]}
                {field.gwp && [
                  <dt key={1}>{i18n.t("Ground water")}</dt>,
                  <dd key={2}>{i18n.t("Ground water protection area")}</dd>,
                ]}
                {field.water_trap && [
                  <dt key={1}>{i18n.t("Water trap")}</dt>,
                  <dd key={2}>{getCode(field.water_trap, "name")}</dd>,
                ]}
                {field.permanent_grass && [
                  <dt key={1}>{i18n.t("Grass land")}</dt>,
                  <dd key={2}>{i18n.t("Permanent grassland")}</dd>,
                ]}
                {field.erosion && [
                  <dt key={1}>{i18n.t("Erosion")}</dt>,
                  <dd key={2}>
                    {i18n.t("Sensitivity")} {getCode(field.erosion, "name")}
                  </dd>,
                ]}
                {field.natura_2000 && [
                  <dt key={1}>{i18n.t("Natura 2000")}</dt>,
                  <dd key={2}>{getCode(field.natura_2000, "name")}</dd>,
                ]}
                {field.potato_disease && [
                  <dt key={1}>{i18n.t("Potato limit")}</dt>,
                  <dd key={2}>{getCode(field.potato_disease, "name")}</dd>,
                ]}
                {field.forbidden_irrigation && [
                  <dt key={1}>{i18n.t("Irrigation limit")}</dt>,
                  <dd key={2}>{getCode(field.forbidden_irrigation, "name")}</dd>,
                ]}
              </dl>
              <CropHistory
                data={cropHistory}
                select={(year) => dispatch(toggleCropYear(year))}
                year={props.year}
                itemWidth={5}
                hasMore={false}
              />
            </div>
            <div className="col-6" style={{ paddingLeft: "20px" }}>
              <SimpleMap
                feature={cropfield}
                extraBounds={[
                  L.latLngBounds(
                    [center[0] - 0.05, center[1] - 0.05],
                    [center[0] + 0.05, center[1] + 0.05]
                  ),
                ]}
                height={200}
              >
                <Marker position={center} />
              </SimpleMap>
              <SimpleMap
                feature={cropfield}
                extraBounds={[
                  L.latLngBounds(
                    [center[0] - 0.01, center[1] - 0.01],
                    [center[0] + 0.01, center[1] + 0.01]
                  ),
                ]}
                height={200}
              />
            </div>
          </div>
        </div>

        <div className="unbreakable-content">
          <h2>{i18n.t("Soil type")}</h2>
          <Soil cropfield={field} smallScreen={smallScreen} size={200} />
        </div>

        <div className="unbreakable-content clearfix">
          <h2>{i18n.t("Altitude")}</h2>
          <DEMDiagram
            dem={dem || {}}
            shadow={shadow || {}}
            cropfield={cropfield}
            smallScreen={smallScreen}
            inView={true}
            height={200}
            histWidth={240}
            statisticsComponent={DEMStatistics}
            features={features}
          />
        </div>

        <div className="unbreakable-content cadastre">
          <h2>{i18n.t("Ownership")}</h2>
          <Cadastre
            data={field.cadastre}
            cropfield={cropfield}
            smallScreen={smallScreen}
            mapProps={{ height: 200 }}
          />
        </div>

        <div className="unbreakable-content">
          <h2>{i18n.t("Growth")}</h2>
          <GrowthDiagram
            growth={growth || {}}
            cropfield={cropfield}
            smallScreen={smallScreen}
            refresh={() => {
              dispatch(updateGrowth());
            }}
            select={(type, key) => {
              dispatch(updateSatelliteImage(cropfield, { key: key, imageType: type }));
            }}
            inView={true}
            height={200}
          />
        </div>

        {field.natura_2000 || field.natura_2000_buffer ? (
          <div className="unbreakable-content">
            <h2>{i18n.t("Nature")}</h2>
            <Nature field={field} smallScreen={smallScreen} mapProps={{ height: 200 }} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
