import React, { useEffect, useMemo, useRef, useState } from "react";
import i18n from "i18next";
import animateScrollTo from "animated-scroll-to";
import { fetchCropField, setSection, getSharedField } from "../actions";
import { accountFeatures, isPointInsideFeature, useSSelector } from "../util";
import { ReportButtons } from "./ReportButtons";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import Sticky from "react-sticky-el";
import { track } from "../track";
import Cookies from "universal-cookie";
import wellknown from "wellknown";
import { Header } from "./header/Header";
import "./Report.scss";
import "../panels/Tabs.scss";

export interface ReportComponentProps {
  section?: string;
}

interface ReportProps {
  gps: string;
  select: (path?) => void;
  section?: string;
  returnUrl?: string;
  Component: React.ComponentType<ReportComponentProps>;
}

const scrollTo = (id: string) => {
  if (!id) return;
  const elem = document.getElementById("section_" + id);
  if (!elem) return console.warn("can not scroll to section", id);
  const base = document.getElementById("mod_bb_main_panel")?.offsetTop || 0;
  animateScrollTo(base ? base + elem.offsetTop - 126 : elem.offsetTop - 59);
};

const Affix = ({ gps, section = "", sectionRef }) => {
  const [affixed, setAffixed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!affixed) return;
    const handler = () => {
      let i;
      const sections = sectionRef.current?.getElementsByTagName("section");
      for (i = 0; i < sections.length && sections[i].offsetTop - 66 < window.scrollY; i++);
      const activeSection = (i > 1 && sections[i - 1].id?.substring(8)) || "";
      if (section !== activeSection) {
        dispatch(setSection(activeSection, gps));
      }
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, [gps, affixed, section, sectionRef, dispatch]);
  return (
    <Sticky
      offsetTransforms={true}
      onFixedToggle={() => setAffixed(!affixed)}
      className="mod_bb_affix"
      scrollElement={document.getElementById("mod_bb_datasets")}
    >
      <div className="mod_bb_tabs bunder_tabs" id="nav_bb_tabs">
        <ReportButtons section={section} onClick={scrollTo} />
      </div>
    </Sticky>
  );
};

export const Report = ({
  gps,
  section: selectedSectionFromURL,
  select,
  returnUrl = "",
  Component,
}: ReportProps) => {
  const { account, field, bunder, ownLists, ownFields, sharedField } = useSSelector(
    ({ selection, favorites, activeUser }) => ({
      account: activeUser.account,
      field: selection.field,
      bunder: selection.bunder,
      ownLists: favorites?.lists || [],
      ownFields: favorites?.fields || [],
      sharedField: favorites.sharedField,
    })
  );
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const scrolledTo = useRef(null);
  const initialSectionFromUrl = useRef(selectedSectionFromURL);

  const coords = useMemo(() => {
    return gps.split(",").map((d) => (d.length === 32 ? d : parseFloat(d)));
  }, [gps]);

  useEffect(() => {
    if (coords.length === 1 && typeof coords[0] === "string") {
      if (!sharedField || sharedField?.key !== gps) {
        dispatch(getSharedField(coords[0]));
      } else {
        dispatch(fetchCropField(coords));
      }
    } else {
      dispatch(fetchCropField(coords));
    }
    scrolledTo.current = null;
    initialSectionFromUrl.current = selectedSectionFromURL; // not exhausistive by design
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, dispatch, gps, sharedField]);

  useEffect(() => {
    if (field?.state === "fetched" && field?.gps === coords && scrolledTo.current !== gps) {
      scrolledTo.current = gps;
      setTimeout(() => {
        // make sure we didn't click another field in the meantime
        if (scrolledTo.current !== gps) return;
        scrollTo(initialSectionFromUrl.current);
      }, 100); // bit hacky here
    }
  }, [gps, coords, field, scrolledTo]);

  useEffect(() => {
    if (field?.state !== "fetched") return;
    // @ts-ignore
    const count = new Cookies().get("clicks")?.length;
    track("report", "view", count && `count:${count}`);
  }, [dispatch, field?.state]);

  const cropfield = field?.state === "fetched" ? field.feature : bunder;
  // When arriving at this page via coordinates, its not possible to determine of
  // this field is liked by checking the key, because we only have coordinates. The
  // selected farmfield in the state does not contain this key either. So the best way
  // to check if this field has been liked in checking in the coordinates are within
  // one of the liked fields.
  let liked;
  if (coords.length === 1 && typeof coords[0] === "string") {
    liked =
      field &&
      Boolean(
        ownLists.filter((l) => l.type === "CUSTOM" && l.fields.includes(field?.bunder?.id)).length
      );
  } else {
    liked = Boolean(
      ownFields.filter((f) => {
        return isPointInsideFeature(gps, { geometry: wellknown.parse(f.geometry) });
      }).length
    );
  }

  const features = accountFeatures(account);

  if (!cropfield)
    return (
      <article className="mod_bb mod_bb_report active">
        <hr className="mobile-hr" />
        <Header
          accountFeatures={features}
          field={field}
          cropfield={cropfield}
          select={select}
          gps={gps}
          liked={liked}
          returnUrl={returnUrl}
        />
        {sharedField?.state === "fetched" && field?.state === "fetched" && (
          <div>{i18n.t("This field could not be found!")}</div>
        )}
      </article>
    );
  return (
    <article className="mod_bb mod_bb_report active" ref={componentRef}>
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Field report")}</title>
      </Helmet>

      <Header
        accountFeatures={features}
        field={field}
        cropfield={cropfield}
        select={select}
        gps={gps}
        liked={liked}
        returnUrl={returnUrl}
      />
      <div className="bunder_tabs_affix">
        <Affix gps={gps} section={selectedSectionFromURL} sectionRef={componentRef} />
      </div>
      <Component section={selectedSectionFromURL} />
    </article>
  );
};
