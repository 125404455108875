import { Modal } from "react-bootstrap";
import i18n from "i18next";
import bertHank from "../../img/logo/bert-hank_abo.png";
import { TS } from "../util";
import React from "react";

export const AccountModal = (showModal?: boolean, onClose?: () => void) => {
  return (
    <Modal
      show={showModal}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_subscribe"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {i18n.t("Create a free account or login to use this functionality")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="float-right">
          <img alt="Bert&Hank" className="d-none d-sm-block" width="200" src={bertHank} />
          <img alt="Bert&Hank" className="d-inline-block d-sm-none" width="150" src={bertHank} />
        </div>
        <h4>{i18n.t("With a free account you can use many extra functionalities:")}</h4>
        <ul>
          <TS t="AccountFeatures" />
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <a className="btn btn-success float-left" href="/authorize?create=1">
          {i18n.t("Create an account")}
        </a>
        <a className="btn btn-info" href="/authorize">
          {i18n.t("Login")}
        </a>
      </Modal.Footer>
    </Modal>
  );
};
