import { block, CheckboxItemList } from "searchkit";
import React from "react";

// TODO hooks

export class MyCheckboxItemList extends CheckboxItemList {
  /* Overriden to insert hectares in count */
  render() {
    const {
      mod,
      itemComponent,
      items = [],
      translate,
      toggleItem,
      setItems,
      multiselect,
      countFormatter,
      disabled,
      showCount,
      className,
      docCount,
    } = this.props;

    const bemBlocks = {
      container: block(mod).el,
      option: block(`${mod}-option`).el,
    };

    const toggleFunc = multiselect ? toggleItem : (key) => setItems([key]);

    const actions = items.map((option) => {
      const label = option.title || option.label || option.key;
      return React.createElement(itemComponent, {
        label: translate(label),
        onClick: () => toggleFunc(option.key),
        bemBlocks: bemBlocks,
        key: option.key,
        itemKey: option.key,
        count: option.area ? option.area.value.toFixed() + " ha" : countFormatter(option.doc_count),
        rawCount: option.doc_count,
        listDocCount: docCount,
        disabled: option.disabled,
        showCount,
        active: this.isActive(option),
      });
    });
    return (
      <div data-qa="options" className={bemBlocks.container().mix(className).state({ disabled })}>
        {actions}
      </div>
    );
  }
}
