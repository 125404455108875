import { Desc, FieldProp, FieldProps } from "./FieldProps";

export class LUFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "In der Europäischen Union wurde 1992 beschlossen ein Schutzgebietsnetz (Natura 2000) aufzubauen, welches dem Erhalt wildlebender Pflanzen- und Tierarten und ihrer natürlichen Lebensräume dient. Dafür wurden verschiedene Richtlinien erlassen und in den einzelnen Ländern Schutzgebiete nach diesen Richtlinien erlassen",
          e,
          "https://environnement.public.lu/fr/natur/biodiversite/mesure_3_zones_especes_proteges/natura_2000.html",
          "Les zones Natura 2000 au Grand-Duché de Luxembourg"
        )
      ),
    ];
  }
}
