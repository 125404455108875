import { ReportNL } from "./ReportNL";
import { ReportBE } from "./ReportBE";
import { ReportDK } from "./ReportDK";
import { ReportWA } from "./ReportWA";
import { ReportFR } from "./ReportFR";
import { ReportDENW } from "./ReportDENW";
import { ReportDENI } from "./ReportDENI";
import { ReportDESH } from "./ReportDESH";
import { ReportAT } from "./ReportAT";
import { ReportLU } from "./ReportLU";
import { ReportFallback } from "./ReportFallback";
import { ReportGlobal } from "./ReportGlobal";

import { config } from "../Config";
export { Selection } from "./Selection";
export { Report } from "./Report";
export const getReportComponent = (country?: string) =>
  ({
    NL: ReportNL,
    BE: ReportBE,
    DK: ReportDK,
    WA: ReportWA,
    FR: ReportFR,
    DE_NW: ReportDENW,
    DE_NI: ReportDENI,
    DE_SH: ReportDESH,
    AT: ReportAT,
    LU: ReportLU,
    __: ReportGlobal,
  }[country || config.bb_country] || ReportFallback);
