import React from "react";
import i18n from "i18next";
import { FarmLink, numberFormat as fmt, useSSelector } from "../util";
import { config } from "../Config";
import imgSample from "../../img/sample/sample_avatar.png";
import imgLogo from "../../img/icons/boerenbunder-logo-full.svg";
import imgIcon from "../../img/logo/logo_bb_icon.svg";
import { refreshAccount } from "../actions";
import { Icon } from "react-fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Media } from "react-bootstrap";
import { PanelHeader } from "./PanelHeader";

const LoggedOut = () => (
  <Media>
    <img alt="" className="avatar" src={imgSample} />
    <Media.Body className="ml-3">
      <p>
        <a className="label label-primary" href="/authorize/">
          {i18n.t("Login")}
        </a>{" "}
        {i18n.t("TextBetterUse")}
      </p>
      <p>
        {i18n.t("For more information, see")}{" "}
        <a href={`${config.bb_productPage}`}>{i18n.t("the CropFields subscription")}</a>.
      </p>
      <p>
        <a className="btn btn-primary" href="/authorize/">
          {i18n.t("Login or create an account")}
        </a>
      </p>
    </Media.Body>
  </Media>
);

const ProfileUser = ({ user, features }) => {
  const subscriptionType =
    (features?.premium && i18n.t("Premium")) || (features?.pro && i18n.t("Pro")) || null;

  return (
    <Media>
      <img alt="" className="avatar" src={user.avatar_url || imgLogo} width="80" />
      <Media.Body className="ml-3">
        <dl>
          <dt>{i18n.t("Name")}</dt>
          <dd>{userTitle(user)}</dd>
          <dt>{i18n.t("Email")}</dt>
          <dd>{user.email}</dd>
          {!features?.pro ? (
            <div>
              <dt>{i18n.t("No CropFields subscription yet")}</dt>
              <dd>
                <a href={`${config.bb_productPage}`}>
                  {i18n.t("More information about our subscriptions")}
                </a>
                .
              </dd>
            </div>
          ) : (
            <div>
              <dt>{i18n.t("Subscription")}</dt>
              <dd>
                <a
                  className="d-none d-sm-block"
                  href={`${config.DACOM_URL}/subscription/my_subscriptions`}
                >
                  {i18n.t("CropFields")} {subscriptionType}
                </a>
                <span className="d-inline-block d-sm-none-block">
                  {i18n.t("CropFields")} {subscriptionType}
                </span>
              </dd>
            </div>
          )}
          <dt>{i18n.t("Active sessions")}</dt>
          <dd>
            <Link to="/page/sessions">{i18n.t("Manage sessions")}</Link>
          </dd>
        </dl>
      </Media.Body>
    </Media>
  );
};

const ProfileFarm = ({ farm }) => {
  if (!farm) return null;
  return (
    <div>
      <h4>
        {i18n.t("Farm")} <i>{farm.name}</i>
      </h4>
      <Media>
        <img alt="" className="avatar" src={farm.photo || imgIcon} width="80" />
        <Media.Body className="ml-3">
          <dl>
            <dt>{i18n.t("Name")} </dt>
            <dd>
              <FarmLink farm={farm} />
            </dd>
            {farm.street && (
              <div>
                <dt>{i18n.t("Address")}</dt>
                <dd>{farm.street}</dd>
              </div>
            )}
            {farm.city && (
              <div>
                <dt>{i18n.t("Zipcode/city")}</dt>
                <dd>
                  {farm.zipcode} {farm.city}
                </dd>
              </div>
            )}
            <dt>{i18n.t("Plots")}</dt>
            <dd>
              <Link to="/lists">{i18n.t("Liked fields")}</Link>
            </dd>
          </dl>
        </Media.Body>
      </Media>
    </div>
  );
};

const ProfileExports = ({ exports }) => {
  if (!exports?.size) return null;
  return (
    <div>
      <h4>{i18n.t("Search filter exports")}</h4>
      <Media>
        <Icon
          name="download"
          style={{ fontSize: "80px", color: "#139CC2", paddingTop: "5px", width: "80px" }}
        />
        <Media.Body className="ml-3">
          <dl className="bb-nr-specification">
            <dt className="w-70">{i18n.t("Max annual fields export")}</dt>
            <dd className="w-30">{fmt(exports.total)}</dd>
            <dt className="w-70">{i18n.t("Max fields in single export")}</dt>
            <dd className="w-30">{fmt(exports.size)}</dd>
            <dt className="w-70">{i18n.t("Exported fields")}</dt>
            <dd className="w-30">{fmt(exports.count)}</dd>
            <dt className="w-70">{i18n.t("Field export quota")}</dt>
            <dd className="w-30">{fmt(exports.total - exports.count)}</dd>
          </dl>
        </Media.Body>
      </Media>
    </div>
  );
};

const userTitle = (user) => `${user.first_name} ${user.last_name}`;

const ProfileContent = () => {
  const dispatch = useDispatch();
  const activeUser = useSSelector((state) => state.activeUser);
  const user = activeUser.account?.user;
  const refreshing = activeUser.refreshing;

  if (!user) return <LoggedOut />;
  const { farm, features, exports } = activeUser.account || {};
  return (
    <div className="col-12">
      <h4>
        {i18n.t("Logged in as")} <i>{userTitle(user)}</i>
      </h4>
      <ProfileUser user={user} features={features} />
      <ProfileFarm farm={farm} />
      <ProfileExports exports={exports} />

      <div className="row d-none d-sm-block">
        <div className="col-12">
          <br />
          <a href={`${config.DACOM_URL}/cropr/settings/profile/`} className="btn-info btn">
            {i18n.t("Edit my profile")}
          </a>
          &nbsp;
          <button
            onClick={() => {
              dispatch(refreshAccount(null, null));
            }}
            className="btn btn-default btn-refresh"
            disabled={refreshing}
          >
            <i className={`fa fa-refresh${refreshing ? " fa-spin" : ""}`} /> {i18n.t("Refresh")}
          </button>
        </div>
      </div>
    </div>
  );
};

export const Profile = ({ select }) => (
  <article className="mod_bb_profile active">
    <Helmet>
      <title>{i18n.t("Profile")}</title>
    </Helmet>
    <main className="bb_profile p-3">
      <PanelHeader onHide={select}>
        <span>{i18n.t("Profile")}</span>
      </PanelHeader>
      <div className="row">
        <ProfileContent />
      </div>
    </main>
  </article>
);
