import i18n from "i18next";
import { capitalize } from "lodash";
import React from "react";
import { getCode } from "../../util";
import { ColorBox } from "../util";

type Props = { k: string; data: any; area?: number; color?: string };

export const SummaryItems = ({ k, data: pdata, area, color }: Props) => {
  const data = pdata[k]?.features || null;
  if (!data) return null;
  let total = 0;
  const summed = data.reduce((memo, f) => {
    const { code, area } = f.properties;
    memo[code] = memo[code] || { area: 0 };
    memo[code].area += area;
    total += area;
    return memo;
  }, {});
  if (area) total = area;
  return (
    <dl>
      <dt>
        {
          {
            r_soil: i18n.t("Reg soil type"),
            soil: i18n.t("Soil type"),
            nature: i18n.t("Nature conservation"),
            natura_2000: i18n.t("Natura 2000"),
            natura_2000_buffer: "Natura 2000 bufferzone",
          }[k]
        }
        :
      </dt>
      <dd>
        {Object.keys(summed).map((code) => {
          const percentage = (summed[code].area / total) * 100;
          return percentage < 0.5 ? null : (
            <div key={code}>
              <ColorBox color={color || getCode(code, "color")} />
              <span>{capitalize(i18n.t(getCode(code, "name")))}</span>:{" "}
              {total ? percentage.toFixed() : 100}%
            </div>
          );
        })}
      </dd>
    </dl>
  );
};
