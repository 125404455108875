import { useEffect, useRef } from "react";
import React from "react";
import useWindowSize from "../map/hooks/useWindowSize";
import "./BBAdvertisement.scss";
interface Advertisement {
  adId: number;
  size: [number, number];
}

interface Advertisements {
  map: {
    desktop: Advertisement;
    mobile: Advertisement;
  };
  report: {
    desktop: Advertisement;
    mobile: Advertisement;
  };
}

interface BBAdvertismentViewProps {
  type: string;
}

export const BBAdvertismentView = ({ type }: BBAdvertismentViewProps) => {
  const advertisements = {
    map: {
      desktop: {
        adId: 709787,
        size: [390, 260],
      },
      mobile: {
        adId: 709789,
        size: [390, 100],
      },
    },
    report: {
      desktop: {
        adId: 709788,
        size: [390, 260],
      },
      mobile: {
        adId: 709791,
        size: [390, 100],
      },
    },
  };
  const { width, height } = useWindowSize();
  const adPlacementRef = useRef(null);
  useEffect(() => {
    const loadAdButlerScript = () => {
      if (!window.AdButler) {
        const script = document.createElement("script");
        script.async = true;
        script.type = "text/javascript";
        script.src = "https://servedbyadbutler.com/app.js";

        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(script, firstScript);

        script.onload = () => {
          createAdPlacement();
        };
      } else {
        createAdPlacement();
      }
    };
    const createAdPlacement = () => {
      const adButler = window.AdButler || {};
      // @ts-ignore
      adButler.ads = adButler.ads || [];
      const abkw = window.abkw || "";
      const divId = "map_advertisement";
      if (document.querySelector(divId) === null) {
        const adPlacementDiv = document.createElement("div");
        adPlacementDiv.id = divId;
        adPlacementRef.current.appendChild(adPlacementDiv);
      }

      let config = advertisements[type].mobile;
      if (width > 768) {
        config = advertisements[type].desktop;
      }
      if (!window.loadedAdbutlerId || window.loadedAdbutlerId !== config.adId) {
        window.loadedAdbutlerId = config.adId;
        // @ts-ignore
        adButler.ads.push({
          handler: function (opt) {
            // @ts-ignore
            adButler.register(186857, config.adId, config.size, divId, opt);
          },
          opt: {
            place: 0,
            keywords: abkw,
            domain: "servedbyadbutler.com",
            click: "CLICK_MACRO_PLACEHOLDER",
          },
        });
      }
    };
    loadAdButlerScript();
  }, [width]);
  return (
    <>
      <div ref={adPlacementRef}></div>
    </>
  );
};
