import React from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import { Link } from "react-router-dom";
import bertHank from "../../img/logo/bert-hank_abo.png";
import { config } from "../Config";

interface TrialEpxiredModalProperties {
  onClose: () => void;
}

export const TrialExpiredModal = ({ onClose }: TrialEpxiredModalProperties) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_subscribe"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n.t("Your one-month trial subscription has expired")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="float-right">
          <img alt="Bert&Hank" className="d-none d-sm-block" width="200" src={bertHank} />
          <img alt="Bert&Hank" className="d-inline-block d-sm-none" width="150" src={bertHank} />
        </div>
        <p>
          {i18n.t(
            "For only €16/month you will get all the functionalities of CropFields Pro again"
          )}
        </p>
        <div>
          <ul>
            <li>{i18n.t("Find and quickly navigate to fields")}</li>
            <li>
              {i18n.t("Always know the exact cultivable area of fields for planning and invoicing")}
            </li>
            <li>{i18n.t("View crop rotations for creating a healthy cultivation plan")}</li>
            <li>
              {i18n.t(
                "Start with precision farming, with insight into altitude maps and satellite images"
              )}
            </li>
            <li>{i18n.t("Find suitable land for renting or purchasing")}</li>
            <li>{i18n.t("Create and share your own field lists")}</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default" onClick={onClose} to="/page/subscription">
          {i18n.t("More information")}
        </Link>
        <a className="btn btn-success" href={config.bb_orderPage}>
          {i18n.t("Order Pro")}
        </a>
      </Modal.Footer>
    </Modal>
  );
};
