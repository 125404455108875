import React, { FunctionComponent } from "react";
import { IconTooltip } from "../panels/Tooltip";
import i18n from "i18next";
import { Pro } from "../components/Pro";

export const SubscriptionFeature = ({ show, title, description }) =>
  show ? (
    <Pro
      msg={description}
      wrapper={{ className: "bb_subscriptionFeature d-flex mr-1", content: title }}
    />
  ) : null;

interface SectionTitleProps {
  name: string;
  icon?: string;
  spin?: boolean;
  feature?: string;
  extra?: React.ReactNode | string;
  className?: string;
  Component?: React.ElementType;
}
export const SectionTitle: FunctionComponent<SectionTitleProps> = ({
  name,
  icon,
  spin,
  feature,
  children,
  extra,
  className,
  Component = "h2",
}) => (
  <div className={`title align-items-center ${className}`}>
    <Component>
      {name} {feature ? <Pro msg={i18n.t("This is a pro feature")} required={feature} /> : null}
    </Component>
    {extra}
    <IconTooltip icon={icon || "info-circle"} className="right" spin={spin}>
      {children}
    </IconTooltip>
  </div>
);

export const ColorBox = ({ color }) => (
  <span className="color-box" style={{ border: "8px solid " + color, boxSizing: "border-box" }} />
);

interface VerticalTodayLabelProps {
  viewBox?: { x: number };
}
export const VerticalTodayLabel = ({ viewBox }: VerticalTodayLabelProps) => {
  const x = (viewBox && viewBox.x) || 247;
  return (
    <text
      x={x}
      y={40}
      dy={2}
      transform={`rotate(-90, ${x}, ${40})`}
      fontSize={10}
      textAnchor="middle"
    >
      {i18n.t("Today")}
    </text>
  );
};
