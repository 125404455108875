import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import { PanelHeader } from "../PanelHeader";
import { Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { getCenter, useSSelector } from "../../util";
import { getEWSConfig, selectFarm, updateEWSMulti } from "../../actions";
import "./EWS.scss";
import { ConfigurableLabel } from "../../components";
import { ewsCropKeys, ewsSecondaryCropsMapping } from "./EWSCropKeysMapping";
import { Spinner } from "../../components";
import { EWSTable } from "./EWSTable";

const getRelevantEWSCropEdiCodes = (fields) =>
  (fields || []).reduce((result, f) => {
    const mainCropDacomCode = ewsSecondaryCropsMapping[f.properties?.crop_dacom_code];
    if (ewsCropKeys[mainCropDacomCode] !== undefined) result.push(mainCropDacomCode);
    return result;
  }, []);

export const EWS = ({ select }) => {
  const dispatch = useDispatch();
  const stateTab = 1;
  const { ewsConfig, account, sfamToken, layers, ewsMulti, selection } = useSSelector((state) => ({
    ewsConfig: state.ewsConfig || {},
    account: state.activeUser?.account || {},
    sfamToken: state.activeUser && state.activeUser.tokens.sfam,
    layers: state.layers.selected || [],
    ewsMulti: state.selection.ewsMulti,
    selection: state.selection,
  }));
  const farmId = account.farm_id;
  const fields = selection?.farmFields;
  let relevantEWSCropEdiCodes = useMemo(() => getRelevantEWSCropEdiCodes(fields), [fields]);
  const fetchConfigState = ewsConfig.state || "fetching";
  const fetchMultiState = relevantEWSCropEdiCodes.length
    ? ewsMulti?.state || "fetching"
    : "fetched";
  const centroids = fields?.filter((f) => f?.geometry).map((f) => getCenter(f, "", 5).join(" "));
  const multipointOfCentroids = centroids ? "MULTIPOINT (" + centroids.join(", ") + ")" : "";
  const onHide = useCallback(() => {
    dispatch({ type: "DESELECT" });
    select();
  }, [select, dispatch]);

  useEffect(() => {
    if (farmId) dispatch(selectFarm(farmId));
  }, [farmId, dispatch]);
  useEffect(() => {
    if (sfamToken) dispatch(getEWSConfig());
  }, [sfamToken, dispatch]);
  useEffect(() => {
    if (sfamToken && multipointOfCentroids) {
      dispatch(updateEWSMulti(multipointOfCentroids));
    }
  }, [sfamToken, multipointOfCentroids, dispatch]);

  useEffect(() => {
    if (!layers.includes("ewsP")) {
      dispatch({ type: "LAYER_TOGGLE", layer: "ewsP" });
    }
    return () => {
      if (layers.includes("ewsP")) {
        dispatch({ type: "LAYER_TOGGLE", layer: "ewsP" });
      }
    };
  }, [layers, dispatch]);

  return (
    <article className="mod_bb mod_bb_ews active">
      <hr className="mobile-hr" />
      <Helmet>
        <title>{i18n.t("Infection radar")}</title>
      </Helmet>
      <main className="bb_lists">
        <PanelHeader onHide={onHide} explanation={<span>{i18n.t("l_info_ews")}</span>}>
          {i18n.t("Infection radar")}
          <ConfigurableLabel inHeader={true} />
        </PanelHeader>
        <div className="bb_bunder_container">
          <Tabs activeKey={stateTab} id="controlled-tab-example">
            <Tab eventKey={1} title={`${i18n.t("Crop")}`}>
              {(fetchConfigState === "fetching" || fetchMultiState === "fetching") && <Spinner />}
              {fetchConfigState === "fetched" && fetchMultiState === "fetched" && (
                <EWSTable relevantEWSCropEdiCodes={relevantEWSCropEdiCodes} />
              )}
            </Tab>
          </Tabs>
        </div>
      </main>
    </article>
  );
};
