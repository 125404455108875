import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { favorite, removeHighlightField, setHighlightField } from "../../actions";
import { useSSelector, toWKT, getCenter, getCode } from "../../util";
import { DefaultZIndex } from "../brp/Layer";
import { config } from "../../Config";
import { BRPLayer } from "../brp/BRPLayer";
import { HighlightLayer } from "../brp/HighlightLayer";
import { SelectedFarmFieldsLayer } from "../brp/SelectedFarmFieldsLayer";
import { BaseMap } from "./BaseMap";
import { SelectedField } from "../selectedField/SelectedField";

type ListMapProps = {
  listKey?: string;
};

export const ListMap = ({ listKey }: ListMapProps) => {
  const dispatch = useDispatch();
  const selectedList = useSSelector((state) =>
    (state.favorites.lists || []).find((l) => l.key === listKey)
  );

  const isEditing = useSSelector((state) => isEditingSelector(state, selectedList?.id));
  const parseField = useCallback((feature: any) => {
    const year = config.crop_year;
    const geometry = toWKT(feature.geometry, "") || "";
    const cropCode = feature.properties?.crop_code;
    return {
      source: year,
      external_id: feature?.id || "",
      name: "",
      area: feature?.properties?.area,
      geometry: geometry,
      crop_name: getCode(cropCode)?.name || "",
      variety_name: "",
      crop_code: cropCode,
      period_start: year ? year + "-05-01" : "",
    };
  }, []);

  const handleSelect = useCallback(
    (gpsOriginal, field, event) => {
      const gps = getCenter(field)
        .map((c) => c.toFixed(5))
        .reverse()
        .join(",");

      const submittableData = parseField(field);
      dispatch(favorite(null, selectedList.id, [gps], "", [submittableData]));
    },
    [dispatch, parseField, selectedList?.id]
  );

  const handleDeselect = useCallback(
    (field) => {
      dispatch(favorite(null, selectedList.id, [field.properties.point], field.properties.key));
    },
    [dispatch, selectedList?.id]
  );

  const handleClick = useCallback(
    (field) => {
      let url = `/plot/${field.properties.point}`;
      if (listKey) {
        url = `/lists/${listKey}${url}`;
      }
      dispatch(push(url));
    },
    [dispatch, listKey]
  );

  const handleHover = useCallback(
    (field: any | null) => {
      if (field === null) {
        dispatch(removeHighlightField());
        return;
      }
      dispatch(setHighlightField(field.properties.id));
    },
    [dispatch]
  );

  if (isEditing) {
    return (
      <BaseMap>
        <BRPLayer zIndex={DefaultZIndex - 1} onPolygonClick={handleSelect} />
        <SelectedFarmFieldsLayer
          zIndex={DefaultZIndex}
          onPolygonHover={handleHover}
          onPolygonClick={handleDeselect}
          enablePanning={false}
        />
        <HighlightLayer zIndex={DefaultZIndex + 1} />
      </BaseMap>
    );
  }
  return (
    <BaseMap>
      <SelectedFarmFieldsLayer
        zIndex={DefaultZIndex}
        onPolygonHover={handleHover}
        onPolygonClick={handleClick}
      />
      <HighlightLayer zIndex={DefaultZIndex + 1} />
      <SelectedField />
    </BaseMap>
  );
};

const isEditingSelector = (state, listID) => {
  const { editingList } = state?.favorites;
  if (!editingList) {
    return false;
  }

  return editingList === listID;
};
