export const designs = {
  usdaTexture: {
    labels: ["sand", "silt", "clay"],
    categories: [
      {
        name: "sand",
        color: "#FFCE9C",
        points: [
          [100, 0, 0],
          [90, 0, 10],
          [90, 10, 0],
        ],
      },
      {
        name: "loamy sand",
        color: "#FFCECE",
        points: [
          [90, 10, 0],
          [90, 0, 10],
          [85, 0, 15],
          [70, 30, 0],
        ],
      },
      {
        name: "sandy loam",
        color: "#FF9CFF",
        points: [
          [70, 30, 0],
          [85, 0, 15],
          [80, 0, 20],
          [53, 32, 20],
          [53, 42, 5],
          [45, 50, 5],
          [50, 50, 0],
        ],
      },
      {
        name: "sandy clay loam",
        color: "#FF9C9C",
        points: [
          [80, 0, 20],
          [65, 0, 35],
          [45, 20, 35],
          [45, 27, 28],
          [53, 32, 20],
        ],
      },
      {
        name: "sandy clay",
        color: "#FF0000",
        points: [
          [65, 0, 35],
          [45, 20, 35],
          [45, 0, 55],
        ],
      },
      {
        name: "clay",
        color: "#FFFF9C",
        points: [
          [45, 0, 55],
          [0, 0, 100],
          [0, 40, 60],
          [20, 40, 40],
          [45, 15, 40],
        ],
      },
      {
        name: "clay loam",
        color: "#CEFF63",
        points: [
          [45, 15, 40],
          [20, 40, 40],
          [20, 52, 28],
          [45, 27, 28],
        ],
      },
      {
        name: "silty clay",
        color: "#9CFFCE",
        points: [
          [0, 40, 60],
          [0, 60, 40],
          [20, 40, 40],
        ],
      },
      {
        name: "silty clay loam",
        color: "#63CE9C",
        points: [
          [0, 72, 28],
          [20, 52, 28],
          [20, 40, 40],
          [0, 60, 40],
        ],
      },
      {
        name: "silty loam",
        color: "#9CCE00",
        points: [
          [50, 50, 0],
          [22, 50, 28],
          [0, 72, 28],
          [0, 88, 12],
          [8, 80, 12],
          [20, 80, 0],
        ],
      },
      {
        name: "silt",
        color: "#00FF31",
        points: [
          [0, 100, 0],
          [20, 80, 0],
          [8, 80, 12],
          [0, 88, 12],
        ],
      },
      {
        name: "loam",
        color: "#CE9C00",
        points: [
          [45, 27, 28],
          [22, 50, 28],
          [45, 50, 5],
          [53, 42, 5],
          [53, 32, 20],
        ],
      },
    ],
  },
  stibokaTexture: {
    labels: ["clay", "silt", "sand"],
    categories: [
      {
        name: "sand",
        color: "#FDD17D",
        points: [
          [100, 0, 0],
          [50, 50, 0],
          [50, 42, 8],
          [92, 0, 8],
        ],
      },
      {
        name: "loam",
        color: "#E50303",
        points: [
          [50, 50, 0],
          [0, 100, 0],
          [0, 92, 8],
          [50, 42, 8],
        ],
      },
      {
        name: "lichte zavel",
        color: "#A7F874",
        points: [
          [92, 0, 8],
          [0, 92, 8],
          [0, 83, 17],
          [83, 0, 17],
        ],
      },
      {
        name: "zware zavel",
        color: "#71A90B",
        points: [
          [83, 0, 17],
          [0, 83, 17],
          [0, 75, 25],
          [75, 0, 25],
        ],
      },
      {
        name: "lichte klei",
        color: "#2B7507",
        points: [
          [75, 0, 25],
          [0, 75, 25],
          [0, 65, 35],
          [65, 0, 35],
        ],
      },
      {
        name: "heavy clay",
        color: "#4A8B6E",
        points: [
          [65, 0, 35],
          [0, 65, 35],
          [0, 0, 100],
        ],
      },
    ],
  },
  ediTexture: {
    labels: ["sand", "silt", "clay"],
    categories: [
      {
        name: "sand",
        color: "#FFCE9C",
        points: [
          [100, 0, 0],
          [80, 20, 0],
          [80, 12, 8],
          [92, 0, 8],
        ],
      },
      {
        name: "loamy sand",
        color: "#FFCECE",
        points: [
          [80, 20, 0],
          [50, 50, 0],
          [50, 42, 8],
          [80, 12, 8],
        ],
      },
      {
        name: "sandy loam",
        color: "#FF9CFF",
        points: [
          [50, 50, 0],
          [18, 82, 0],
          [18, 60, 22],
          [50, 42, 8],
        ],
      },
      {
        name: "silty loam",
        color: "#9CCE00",
        points: [
          [18, 82, 0],
          [0, 100, 0],
          [0, 70, 30],
          [18, 60, 22],
        ],
      },
      {
        name: "zavel",
        color: "#CE9C00",
        points: [
          [92, 0, 8],
          [50, 42, 8],
          [11, 65, 25],
          [75, 0, 25],
        ],
      },
      {
        name: "lichte klei",
        color: "#CEFF63",
        points: [
          [75, 0, 25],
          [11, 65, 25],
          [0, 70, 30],
          [0, 65, 35],
          [65, 0, 35],
        ],
      },
      {
        name: "clay",
        color: "#FFFF9C",
        points: [
          [65, 0, 35],
          [0, 65, 35],
          [0, 0, 100],
        ],
      },
    ],
  },
  beTexture: {
    labels: ["clay", "silt", "sand"],
    categories: [
      {
        name: "zand",
        color: "#e9d74e",
        points: [
          [100, 0, 0],
          [83, 17, 0],
          [83, 9, 8],
          [92, 0, 8],
        ],
      },
      {
        name: "lemig zand",
        color: "#a9e92c",
        points: [
          [92, 0, 8],
          [83, 9, 8],
          [83, 17, 0],
          [67, 33, 0],
          [67, 16, 17],
          [83, 0, 17],
        ],
      },
      {
        name: "zandleem",
        color: "#e9c481",
        points: [
          [67, 21, 12],
          [50, 38, 12],
          [50, 50, 0],
          [15, 85, 0],
          [15, 62, 23],
          [30, 53, 17],
          [67, 16, 17],
        ],
      },
      {
        name: "licht zandleem",
        color: "#fea61e",
        points: [
          [67, 33, 0],
          [50, 50, 0],
          [50, 38, 12],
          [67, 21, 12],
        ],
      },
      {
        name: "leem",
        color: "#e95c51",
        points: [
          [15, 85, 0],
          [0, 100, 0],
          [0, 70, 30],
          [15, 62, 23],
        ],
      },
      {
        name: "klei",
        color: "#18a633",
        points: [
          [83, 0, 17],
          [30, 53, 17],
          [15, 62, 23],
          [0, 70, 30],
          [0, 55, 45],
          [10, 55, 35],
          [65, 0, 35],
        ],
      },
      {
        name: "zware klei",
        color: "#176f1c",
        points: [
          [65, 0, 35],
          [10, 55, 35],
          [0, 55, 45],
          [0, 0, 100],
        ],
      },
    ],
  },
  dkTexture: {
    // https://ign.ku.dk/partnerlandskab/god-vaekstjord/beskrivelse-af-jord/
    labels: ["ler", "silt", "sand"],
    categories: [
      {
        name: "Sandjord",
        color: "#e9d74e",
        points: [
          [100, 0, 0],
          [80, 20, 0],
          [75, 20, 5],
          [95, 0, 5],
        ],
      },
      {
        name: "Lerblandet sandjord",
        color: "#e9c481",
        points: [
          [95, 0, 5],
          [70, 25, 5],
          [65, 25, 10],
          [90, 0, 10],
        ],
      },
      {
        name: "Sandblandet lerjord",
        color: "#A7F874",
        points: [
          [90, 0, 10],
          [60, 30, 10],
          [55, 30, 15],
          [85, 0, 15],
        ],
      },
      {
        name: "Lerjord",
        color: "#71A90B",
        points: [
          [85, 0, 15],
          [50, 35, 15],
          [40, 35, 25],
          [75, 0, 25],
        ],
      },
      {
        name: "Svær lerjord",
        color: "#2B7507",
        points: [
          [75, 0, 25],
          [30, 45, 25],
          [10, 45, 45],
          [55, 0, 45],
        ],
      },
      {
        name: "Meget svær lerjord",
        color: "#4A8B6E",
        points: [
          [55, 0, 45],
          [5, 50, 45],
          [0, 50, 50],
          [0, 0, 100],
        ],
      },
      {
        name: "Siltjord",
        color: "#e95c51",
        points: [
          [80, 20, 0],
          [0, 100, 0],
          [0, 50, 50],
          [5, 50, 45],
          [10, 45, 45],
          [30, 45, 25],
          [40, 35, 25],
          [50, 35, 15],
          [55, 30, 15],
          [60, 30, 10],
          [65, 25, 10],
          [70, 25, 5],
          [75, 20, 5],
        ],
      },
    ],
  },
  peat: {
    labels: ["silt+sand", "clay", "organic matter"],
    categories: [
      {
        name: "(see other)",
        color: "#CCC",
        points: [
          [100, 0, 0],
          [0, 100, 0],
          [0, 70, 30],
          [85, 0, 15],
        ],
        noselect: true,
      },
      {
        name: "peaty sand",
        color: "#ffde9c",
        points: [
          [85, 0, 15],
          [75.4, 8, 16.6],
          [66.23, 8, 25.77],
          [77.5, 0, 22.5],
        ],
      },
      {
        name: "peaty clay",
        color: "#e9cfa2",
        points: [
          [75.4, 8, 16.6],
          [0, 70, 30],
          [0, 55, 45],
          [66.23, 8, 25.77],
        ],
      },
      {
        name: "sandy peat",
        color: "#aaaa68",
        points: [
          [77.5, 0, 22.5],
          [66.23, 8, 25.77],
          [47.47, 8, 44.53],
          [65, 0, 35],
        ],
      },
      {
        name: "clayey peat",
        color: "#d3a0a8",
        points: [
          [66.23, 8, 25.77],
          [0, 55, 45],
          [0, 30, 70],
          [47.47, 8, 44.53],
        ],
      },
      {
        name: "peat",
        color: "#be71ae",
        points: [
          [65, 0, 35],
          [0, 30, 70],
          [0, 0, 100],
        ],
      },
    ],
  },
  peatSimple: {
    labels: ["organic matter", "clay", "silt+sand"],
    categories: [
      {
        name: "(see other)",
        color: "#CCC",
        points: [
          [100, 0, 0],
          [0, 100, 0],
          [0, 70, 30],
          [85, 0, 15],
        ],
        noselect: true,
      },
      {
        name: "moerig op zand",
        color: "#FEAA04",
        points: [
          [85, 0, 15],
          [75.4, 8, 16.6],
          [47.47, 8, 44.53],
          [65, 0, 35],
        ],
      },
      {
        name: "peat",
        color: "#D49CBA",
        points: [
          [65, 0, 35],
          [47.47, 8, 44.53],
          [75.4, 8, 16.6],
          [0, 70, 30],
          [0, 0, 100],
        ],
      },
    ],
  },
};
