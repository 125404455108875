import React, { useContext } from "react";

export const MenuContext = React.createContext(null);

export const useMenu = () => {
  return useContext(MenuContext);
};

export const MenuContextProvider = ({ closeModal, children }) => {
  return <MenuContext.Provider value={closeModal}>{children}</MenuContext.Provider>;
};
