import { useEffect } from "react";

import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { useMap } from "react-leaflet";
import "../../../leaflet/MeasureControl.js";
import { showSubscriptionModal } from "../../components";
import { useDispatch } from "react-redux";

interface MeasureControlProps {
  isPro: boolean;
}
export const MeasureControl = (props: MeasureControlProps) => {
  const map = useMap();
  const dispatch = useDispatch();
  const { isPro } = props;
  useEffect(() => {
    const showModal = () => {
      if (!isPro) {
        showSubscriptionModal(dispatch, "");
      }
    };
    const control = getControl(isPro, showModal);
    map.addControl(control);
    return () => {
      map.removeControl(control);
    };
  }, [isPro, map]);

  return null;
};

const getControl = (isPro, showModal) => {
  return new (L.Control as any).MeasureControl({
    position: "bottomright",
    handler: {
      shapeOptions: {
        color: "#ffc341",
        weight: 4,
        opacity: 0.8,
      },
      icon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: "leaflet-div-icon leaflet-editing-icon",
      }),
      touchIcon: new L.DivIcon({
        iconSize: new L.Point(10, 10),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon",
      }),
    },
    pro: isPro
      ? null
      : () => {
          showModal();
        },
  });
};
