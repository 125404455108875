import React, { memo } from "react";
import { SectionTitle } from "../util";
import { Chart } from "../Chart";
import { WeatherDiagram } from "../diagrams";
import { updateLayerConfig, updateWeather } from "../../actions";
import { SectionProps } from "./Sections";
import { TS, useSSelector } from "../../util";
import { useDispatch } from "react-redux";
import i18n from "i18next";

const SectionWeather = ({ active, cropfield, inView }: SectionProps) => {
  const dispatch = useDispatch();
  const weather = useSSelector(({ selection }) => selection.weather);
  return (
    <section id="section_ews" className={`${active ? " active" : ""}`}>
      <SectionTitle name={i18n.t("Precipitation deficit")}>
        <TS t="l_info_weather" />
      </SectionTitle>
      <Chart
        component={WeatherDiagram}
        data={weather || {}}
        cropfield={cropfield}
        onClick={(data) =>
          dispatch(updateLayerConfig({ precip_deficit: { day: data.activeLabel } }))
        }
        refresh={() => dispatch(updateWeather(cropfield))}
        inView={inView}
      />
    </section>
  );
};
const m = memo(SectionWeather);
export { m as SectionWeather };
