import React from "react";
import { GenericPopper } from "../../../components/GenericPopper";
import { MenuContextProvider } from "./MenuContext";
import { useClick } from "../hooks";

type BunderMenuProps = {
  menu: React.ReactElement;
  onCloseMenu: () => void;
  visible: boolean;
  onClick: () => void;
};

export const BunderMenuList = ({
  menu,
  onCloseMenu,
  visible = false,
  onClick,
}: BunderMenuProps) => {
  const clickIt = useClick(onClick, [onClick]);
  return (
    <MenuContextProvider closeModal={onCloseMenu}>
      <GenericPopper content={menu} open={visible} placement="bottom" onClose={onCloseMenu}>
        <button className="btn btn-tertiary" onClick={clickIt}>
          <i className="fas fa-ellipsis-v" />
        </button>
      </GenericPopper>
    </MenuContextProvider>
  );
};
