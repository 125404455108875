import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "react-fa";
import i18n from "i18next";
import { updateWater } from "../../actions";
import { ColorBox } from "../util";
import { Tooltip } from "../../panels";
import { Toggle } from "../../components";
import { SimpleMap } from "../../map";
import { getCode } from "../../util";
import { track } from "../../track";
import "./Water.scss";

// TODO replaced with config.codes, can be removed
export const waterColors = (name, showShore) => {
  const colors = {
    "greppel, droge sloot": "#ffaa00",
    waterloop: "#0055ff",
    watervlakte: "#00eaff",
    "oever, slootkant": showShore ? "#00ff2b" : "#0055ff",
    transitie: "#b2cbed",
    zee: "#8d949f",
  };
  return colors[name];
};

const filterTrenches = (features, show) =>
  show ? features : features.filter((f) => !f.properties.isShore);
export const filteredTrenches = (water) =>
  water?.data ? filterTrenches(water.data.features, water.showShore) : [];

const Water = ({ water, inView, refresh, cropfield, smallScreen, height }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!water && inView) refresh();
  }, [water, refresh, inView]);

  if (!water) return null;
  const { showShore = false, highlighted, state } = water;
  if (state === "fetching")
    return (
      <div>
        <span className="loading">
          {i18n.t("Loading")} <Icon spin name="spinner" />
        </span>
      </div>
    );
  if (state === "failed") return <p>{i18n.t("No data")}</p>;
  const trenches = water.data.features;
  const trenchesNoShore = filterTrenches(trenches, false);
  let total = 0;
  return (
    <div className="mod_bb_water">
      <div className="key">
        <table className="table">
          <tbody>
            <tr>
              <th>{i18n.t("nr")}</th>
              <th>{i18n.t("type")}</th>
              <th className="text-right">{i18n.t("length")}</th>
            </tr>
            {trenchesNoShore.map((trench, index) => {
              let { length = 0, name, code } = trench.properties;
              length = Math.round(length / 2);
              total += length;
              const on = highlighted === trench.id;
              return (
                <tr
                  className={on ? "highlighted" : ""}
                  key={index}
                  onClick={() => {
                    dispatch(updateWater({ highlighted: on ? null : trench.id }));
                    track("report", "water", "select_trench");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td>
                    <i>{name}</i>{" "}
                    <ColorBox color={getCode(code, "color") || waterColors(name, showShore)} />
                  </td>
                  <td className="text-right">{length} m</td>
                </tr>
              );
            })}
            <tr>
              <td className="bold_text" colSpan={2}>
                {i18n.t("Total")}:
              </td>
              <td className="text-right bold_text">{total} m</td>
            </tr>
            {!smallScreen && trenches.length !== trenchesNoShore.length && (
              <tr>
                <td className="bold_text" colSpan={2}>
                  {i18n.t("Show trenches")}:
                </td>
                <td className="text-right ">
                  <Tooltip description={i18n.t("show trenches, ditch sides")}>
                    <Toggle
                      isToggled={showShore}
                      callback={() => {
                        dispatch(updateWater({ showShore: !showShore }));
                        track("report", "water", "toggle_trench");
                      }}
                    />
                  </Tooltip>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {smallScreen && trenches ? (
        <div className="keymap">
          <SimpleMap feature={cropfield} height={height}>
            <SimpleMap.TopPolygon
              key="minicropfield"
              feature={cropfield}
              color={"yellow"}
              opacity={0.5}
            />
            {trenchesNoShore.map((trench, index) => (
              <SimpleMap.TopPolygon
                key={index}
                feature={trench.properties?.buffered?.geometry || trench}
                color={
                  trench.id === highlighted
                    ? "red"
                    : getCode(trench.properties.code, "color") ||
                      waterColors(trench.properties.name, showShore)
                }
              />
            ))}
          </SimpleMap>
        </div>
      ) : null}
    </div>
  );
};

const m = memo(Water);
export { m as Water };
