import React from "react";
import "searchkit/theming/theme.scss";
import "./Facet.scss";
import i18n from "i18next";
import { Icon } from "react-fa";

export const InitialLoading = (props) => (
  <div>
    <Icon spin name="spinner" /> {i18n.t("Loading")}...
  </div>
);
