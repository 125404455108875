import { config } from "../../Config";
import React, { memo } from "react";
import { LayersControl, TileLayer, WMSTileLayer } from "react-leaflet";
import { GoogleLayer } from "react-leaflet-google-v2";
import i18n from "i18next";
import memoize from "lodash/memoize";
import { getBounds } from "../map_util";
import { useZoom } from "../effects/effects";
const { BaseLayer, Overlay } = LayersControl;

// the max zoom level should be the same for all layers, otherwise a
// layer will dissapear when zooming in past their own max. If a layer
// does not support that many zoomlevels set  maxNativeZoom to the max
// level the layer  supports.
const MAX_ZOOM = 21;

export const layers = memoize(() => ({
  parcel: i18n.t("Fields"),
  soil: i18n.t("Soil type"),
  dem: i18n.t("Altitude"),
  n2k: "Natura 2000",
}));

const ref = memoize(() => ({
  tms: true,
  type: "data",
  maxNativeZoom: 18,
  maxZoom: MAX_ZOOM,
  bounds: getBounds(config.bounds),
  zIndex: 50,
}));

export const Layers = memo(({ layers, showPlotLayer }) => {
  const layerUrl = config.LAYER_URL + "/{type}reference/tile/{z}/{x}/{y}.png?src={src}";
  const histUrl = config.LAYER_URL + "/georeferencehist/tile/{z}/{x}/{y}.png";
  const refp = ref();
  const zoomLevel = useZoom();
  return (
    <LayersControl position="topright" autoZIndex={false}>
      <BaseLayer checked={true} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={MAX_ZOOM}
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer>
      <BaseLayer name="Google maps">
        <GoogleLayer maptype="HYBRID" googlekey="AIzaSyC6_fKnnuaiLyH6M0sjdP-AStLAMqwN73Y" />
      </BaseLayer>
      <Overlay name={i18n.t("Fields")} checked={layers.includes("parcel") && zoomLevel > 13}>
        <TileLayer url={histUrl} opacity={0.7} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Fields")} checked={layers.includes("parcel") && zoomLevel <= 13}>
        <WMSTileLayer
          url={`${config.GEOSERVER_URL}/geoserver/reference/wms`}
          layers="reference:cropfields"
          format="image/png"
          transparent={true}
          opacity={0.7}
        />
      </Overlay>
      <Overlay name="plots" checked={showPlotLayer}>
        <TileLayer url={histUrl + "?style=line"} opacity={0.5} {...refp} />
      </Overlay>
      <Overlay name={i18n.t("Soil type")} checked={layers.includes("soil")}>
        <TileLayer url={layerUrl} opacity={0.5} src="97" {...refp} type="raster" />
      </Overlay>
      <Overlay name={i18n.t("Altitude")} checked={layers.includes("dem")}>
        <WMSTileLayer
          url={`${config.GEOSERVER_URL}/geoserver/reference/wms`}
          layers="reference:EUDEM"
          format="image/png"
          transparent={true}
          minZoom={2}
        />
      </Overlay>
      <Overlay name="Natura 2000" checked={layers.includes("n2k")}>
        <TileLayer url={layerUrl} opacity={0.5} src="57" {...refp} />
      </Overlay>
    </LayersControl>
  );
});
