import i18n from "i18next";
import { push } from "connected-react-router";
import React, { useCallback } from "react";
import $ from "jquery";
import { config } from "../../Config";
import { NotificationManager } from "react-notifications";
import { csrfHeaders } from "../../util";
import { useDispatch } from "react-redux";

const send = (e, done) => {
  e.preventDefault();
  $.ajax({
    type: "POST",
    headers: csrfHeaders,
    url: "/mailer/",
    data: $(".bb_pages_contact").serialize(),
    success: (data) => {
      NotificationManager.info(i18n.t("Thanks for you message"));
      done();
    },
    error: function (response) {
      console.error("failure", response);
      NotificationManager.error(<span>{i18n.t("Failed to send mail, sorry")}</span>);
    },
  });
};

const ContactForm = ({ account }) => {
  const { user, farm } = (account?.user?.email !== "fake@dacom.nl" && account) || {};
  const dispatch = useDispatch();
  const done = useCallback(() => dispatch(push("/")), [dispatch]);
  return (
    <form className="bb_pages_contact" onSubmit={(e) => send(e, done)} method="POST">
      <input name="url" type="hidden" value={String(window.location)} />
      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-4 col-xs-12">
          {i18n.t("Name")} <span className="req">*</span>
        </label>
        <div className="col-sm-8 col-xs-12">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder={i18n.t("Enter your name")}
            required
            defaultValue={user ? `${user.first_name} ${user.last_name}` : null}
          />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCompany" className="col-sm-4 col-xs-12">
          {i18n.t("Company")}
        </label>
        <div className="col-sm-8 col-xs-12">
          <input
            type="text"
            className="form-control"
            name="company"
            placeholder={i18n.t("Enter company name")}
            defaultValue={farm ? farm.name : null}
          />
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputEmail" className="col-sm-4 col-xs-12">
          {i18n.t("Email")}
          <span className="req">*</span>
        </label>
        <div className="col-sm-8 col-xs-12">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder={i18n.t("Enter a valid email address")}
            required
            defaultValue={user ? user.email : null}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-4 col-xs-12">
          <label htmlFor="textareaMessage">
            {i18n.t("Message")} <span className="req">*</span>
          </label>
          <br />
        </div>
        <div className="col-sm-8 col-xs-12">
          <textarea
            className="form-control"
            name="message"
            rows={10}
            placeholder={i18n.t("Your question or remark")}
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-xs-12 col-sm-8 col-sm-offset-4">
          <button className="btn btn-default submit">{i18n.t("Send")}</button>
        </div>
      </div>
    </form>
  );
};
export const Contact = ({ activeUser }) => (
  <div className="row">
    <div className="col-xs-12 col-sm-8">
      <h3>{i18n.t("Send a question or remark")}</h3>
      <p>{i18n.t("TextLetUsKnow")}</p>
      <div>
        <ContactForm account={activeUser ? activeUser.account : null} />
      </div>
    </div>
    <div className="col-xs-12 col-sm-4">
      <h3>{i18n.t("Contact")}</h3>
      {i18n.t("Tel +31 88 3226600")}
      <br />
      {i18n.t("Mail {{serviceEmail}}", { serviceEmail: config.SERVICE_EMAIL })}
      <h3>{i18n.t("Socials")}</h3>
      <p>
        <a
          href={`https://twitter.com/${{ DK: "markogbonde" }[config.bb_country] || "boerenbunder"}`}
        >
          <span style={{ width: "14px", display: "inline-block" }}>
            <i className="fa fa-twitter" />
          </span>
          &nbsp;Twitter
        </a>
        <br />
        <a href="https://www.facebook.com/boerenbunder/">
          <span style={{ width: "14px", display: "inline-block" }}>
            <i className="fa fa-facebook-f" />
          </span>
          &nbsp;Facebook
        </a>
        <br />
        <a href="https://www.linkedin.com/company/dacom-bv">
          <span style={{ width: "14px", display: "inline-block" }}>
            <i className="fa fa-linkedin" />
          </span>
          &nbsp;LinkedIn
        </a>
        <br />
      </p>
    </div>
  </div>
);
