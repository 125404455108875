import React from "react";
import { Button, Modal } from "react-bootstrap";
import i18n from "i18next";
import { config } from "../Config";
import { Link } from "react-router-dom";
import bertHank from "../../img/logo/bert-hank_abo.png";

export const ForcedLogoutModal = ({ onClose }) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title"
      className="mod_bb_subscribe"
      animation={false}
      backdrop="static"
      backdropClassName="bb-modal-backdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n.t("Session logged out due to inactivity or remote logout")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="float-right">
          <img alt="Bert&Hank" width="160" src={bertHank} />
        </div>
        <p>
          {i18n.t(
            "This session has expired or has been terminated remotely with a different login"
          )}{" "}
          {i18n.t("You can be logged in on up to two devices")}
        </p>
        <p>
          {i18n.t(
            "With a crop recording subscription, you can work with multiple users on the same company & subscription"
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Link className="btn btn-default float-left" onClick={onClose} to="/page/subscription">
          {i18n.t("More information")}
        </Link>
        <a
          className="btn btn-success float-left"
          href={`${config.DACOM_URL}/subscription/order/subscription`}
        >
          {i18n.t("Tell me more about the subscriptions")}
        </a>
        <Button onClick={onClose}>{i18n.t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
};
