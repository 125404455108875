import L from "leaflet";
import React, { useMemo } from "react";
import { ImageOverlay } from "react-leaflet";
import { config } from "../../Config";

type GrowthOverlayProps = {
  growthSelection: { imgkeyData: string; bbox: any };
  bounds: any;
};

export const GrowthOverlay = (props: GrowthOverlayProps) => {
  const { growthSelection, bounds } = props;
  const { imgkeyData, bbox } = growthSelection;

  const newBounds = useMemo(() => {
    if (!bbox?.length) {
      return bounds;
    }

    const latlng = [L.latLng(bbox[0][1], bbox[0][0]), L.latLng(bbox[3][1], bbox[3][0])];
    return L.latLngBounds(latlng);
  }, [bbox, bounds]);

  if (!imgkeyData) {
    return null;
  }

  return (
    <ImageOverlay
      url={`${config.SATELLITE_URL}/download_images/${imgkeyData}`}
      bounds={newBounds}
    />
  );
};
