import React, { memo, useEffect } from "react";
import { Area, AreaChart, Label, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment";
import { Chart } from "../Chart";

const round100 = (v) => Math.round(v * 100) / 100;
interface GrowthTooltipProps {
  active?: boolean;
  payload?: any;
  label?: number;
  dataKey?: string;
}
const GrowthTooltip = ({ active, payload, label, dataKey }: GrowthTooltipProps) => {
  if (!active) return null;
  const value = payload[0].value,
    p = payload[0].payload;

  return (
    <div className="growth-tooltip">
      <div className="growth-tooltip-title">{moment.unix(label).format("DD-MM-YYYY")}</div>
      <table>
        <tbody>
          <tr>
            <td>{`${dataKey}:`}</td>
            <td>{round100(value[0])}</td>
            <td>&plusmn; {round100(value[1] - value[0])}</td>
          </tr>
          {p.ndre && (
            <tr>
              <td>NDRE:</td>
              <td>{round100(p.ndre.average)}</td>
              <td>&plusmn; {round100(p.ndre.std)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const GrowthDiagramComponent = ({
  data,
  dataProps,
  satDataKey = "ndvi",
  height = 250,
  filterNdviImg,
  warnSubscription,
  select,
  smallscreen,
}) => {
  const growthData = data[satDataKey];
  useEffect(() => {
    const img = dataProps.imgkeyData;
    if (!img && !dataProps.imgState) {
      select(); // select last image
    }
  });
  const handleClick = (clickData, e) => {
    if (!clickData) return; //  https://sentry.crop-r.com/boerenbunder/boerenbunder/issues/48815/
    if (filterNdviImg) {
      const list = filterNdviImg(growthData);
      if (!list.find((e) => e.unix === clickData.activeLabel)) return warnSubscription();
    }
    select("ndvi", clickData.activeLabel, true);
  };
  if (!growthData?.[0]) return null;
  return (
    <AreaChart
      width={smallscreen ? 360 : 400}
      height={height + 50}
      data={growthData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      onClick={handleClick}
    >
      <XAxis
        dataKey="unix"
        tickFormatter={(unix) => moment.unix(unix).format("DD-MM-YYYY")}
        type="number"
        scale="time"
        domain={[growthData[0].unix, growthData[growthData.length - 1].unix]}
      />
      <YAxis />
      <Tooltip content={<GrowthTooltip dataKey={satDataKey.toUpperCase()} />} />
      <Area
        type="monotone"
        dataKey="high"
        stroke="#ffc341"
        fill="#ffc341"
        name={`${satDataKey}+`}
      />
      <Area type="monotone" dataKey="low" stroke="#eeb230" fill="#eeb230" name={`${satDataKey}-`} />
      {dataProps.key && (
        <ReferenceLine x={dataProps.key} stroke="#A9B6BA">
          <Label
            value={moment.unix(dataProps.key).format("LL")}
            position={
              dataProps.key > (growthData[growthData.length - 1].unix + growthData[0].unix) / 2
                ? "insideTopRight"
                : "insideTopLeft"
            }
          />
        </ReferenceLine>
      )}
    </AreaChart>
  );
};

const GrowthDiagram = ({ cropfield, growth, smallScreen, height = undefined, ...rest }) => {
  return (
    <div>
      <div className="key">
        <Chart
          data={growth}
          satDataKey={growth.satDataKey}
          component={GrowthDiagramComponent}
          cropfield={cropfield}
          height={height}
          smallscreen={smallScreen}
          {...rest}
        />
      </div>
    </div>
  );
};
const m = memo(GrowthDiagram);
export { m as GrowthDiagram };
