import { push } from "connected-react-router";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { removeHighlightField, setHighlightField } from "../../actions";
import { HighlightLayer } from "../brp/HighlightLayer";
import { DefaultZIndex } from "../brp/Layer";
import { SelectedFarmFieldsLayer } from "../brp/SelectedFarmFieldsLayer";
import { SelectedField } from "../selectedField/SelectedField";
import { BaseMap } from "./BaseMap";

type FarmMapProps = {
  planKey?: string;
};

export const FarmMap = (props: FarmMapProps) => {
  const { planKey } = props;
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (field) => {
      let url = `/plot/${field.properties.point}`;
      if (planKey) {
        url = `/my_farm/plans/${planKey}${url}`;
      }
      dispatch(push(url));
    },
    [dispatch, planKey]
  );

  const handleHover = useCallback(
    (field: any | null) => {
      if (field === null) {
        dispatch(removeHighlightField());
        return;
      }
      dispatch(setHighlightField(field.properties.id));
    },
    [dispatch]
  );

  return (
    <BaseMap>
      <SelectedField />
      <SelectedFarmFieldsLayer onPolygonClick={handleClick} onPolygonHover={handleHover} />
      <HighlightLayer zIndex={DefaultZIndex + 1} />
    </BaseMap>
  );
};
