import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

interface SelectionButtonsProps {
  names: string[];
  labels?: string[];
  active: string;
  title?: string;
  disabled?: boolean;
  select: (string) => void;
}

export const SelectionButtons = ({
  names,
  labels,
  active,
  title,
  disabled,
  select,
}: SelectionButtonsProps) => {
  return (
    <ButtonGroup
      size="sm"
      className={`bb_selectionButtons bb_selectionButtons_${names.length}`}
      title={title || ""}
    >
      {names.map((name, index) => (
        <Button
          key={name}
          className={`${name === active ? "active" : ""}${disabled ? " disabled" : ""}`}
          variant="default"
          onClick={() => select(name)}
        >
          {(labels || names)[index]}
        </Button>
      ))}
    </ButtonGroup>
  );
};
