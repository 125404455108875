import React from "react";
import { getCenter } from "../../util";
import { Label, latLon, TopPolygon } from "../../map/map_util";
import { SimpleMap } from "../../map";
import { config } from "../../Config";
import i18n from "i18next";
import L from "leaflet";
import capitalize from "lodash/capitalize";
import "./Cadastre.scss";
import { updateCadastre } from "../../actions";
import { track } from "../../track";
import { useDispatch } from "react-redux";

export function cadastreShortName(f) {
  const n = cadastreName(f) || "?";
  switch (config.bb_country) {
    case "NL":
    case "DK": // show only after the space
      return n.replace(/.* /, "");
    case "FR": // show only last digits
      return n.replace(/.*[^\d]0*(\d+)$/, "$1");
    case "DE":
    case "DE_NW":
    case "DE_SH":
      return n.replace(/.*0+(.*)$/, "$1");
    case "LU":
      const a = parseInt(n.substring(4, 9), 10);
      const b = parseInt(n.substring(10), 10);
      return b ? `${a}/${b}` : `${a}`; // skip if b == '00000'
    default:
      return n.replace(/\d+\w0*(\d+)\/[0_]*([A-Z1-9]*)[0_]*/, "$1$2");
  }
}

export function cadastreName(f) {
  return config.bb_country === "LU" ? f.id.replace(/.*-/, "") : f?.properties?.name;
}

const cadastreNamesString = (features) =>
  features
    .reduce((m: String[], f) => {
      const n = capitalize(f.properties.name);
      if (!m.includes(n)) m.push(n);
      return m;
    }, [])
    .join(", ");

export const Cadastre = ({ data, cropfield, smallScreen, mapProps = {} }) => {
  const dispatch = useDispatch();
  const features = data?.features || [];
  if (!features.length) return <p>{i18n.t("Cadastre data unknown")}</p>;
  const highlighted = data?.highlighted;
  return (
    <div className="mod_bb_cadastre">
      <div className="key">
        {config.bb_country === "LU" && <div className="names">{cadastreNamesString(features)}</div>}
        <table className={`table ${smallScreen ? " table-condensed" : ""}`}>
          <thead>
            <tr>
              <th>{i18n.t("Cadastral field")}</th>
              <th className="text-right">{i18n.t("Area")}</th>
              <th className="text-right">{i18n.t("Overlap")}</th>
            </tr>
          </thead>
          <tbody>
            {features.map((f) => (
              <tr
                key={f.id}
                className={highlighted === f.id ? "highlighted" : undefined}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(
                    updateCadastre({ highlighted: highlighted !== f.id ? f.id : undefined })
                  );
                  track("report", "cadastre", "select_field");
                }}
              >
                <td>
                  <i>{cadastreName(f)}</i>
                </td>
                <td className="text-right">{f.properties.area.toFixed(2)} ha</td>
                <td className="text-right">{f.properties.overlap_area.toFixed(2)} ha</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!smallScreen ? null : (
        <div className="keymap">
          <SimpleMap
            key="map"
            feature={cropfield}
            extraBounds={features.map((f) => new L.Polygon(latLon(f)).getBounds())}
            {...mapProps}
          >
            {features.map((f) => [
              <Label
                key={`l${f.id}`}
                position={getCenter(f).reverse()}
                html={cadastreShortName(f)}
              />,
              <TopPolygon
                key={f.id}
                feature={f}
                weight={1}
                color="black"
                fillOpacity={0.5}
                fillColor={highlighted === f.id ? "red" : "#FFFF44"}
              />,
            ])}
            <TopPolygon key="feature" feature={cropfield} weight={1} fill={false} />
          </SimpleMap>
        </div>
      )}
    </div>
  );
};
