import { SummaryItems } from "../header/SummaryItems";
import { SimpleMap } from "../../map/SimpleMap";
import { getCode } from "../../util";
import React from "react";
import i18n from "i18next";

export const Nature = ({ field, smallScreen, mapProps = {}, emptyMessage = undefined }) => {
  const nature = field.natura_2000 || field.natura_2000_buffer;
  if (!nature) return <p>{emptyMessage || i18n.t("No nature data known")}.</p>;
  return (
    <div>
      <div className="key">
        {field.natura_2000 ? (
          <SummaryItems
            key="natura_2000"
            k="natura_2000"
            data={field}
            area={field.feature.properties.area}
          />
        ) : null}
        {field.natura_2000_buffer ? (
          <SummaryItems
            key="natura_2000_buffer"
            k="natura_2000_buffer"
            data={field}
            area={field.feature.properties.area}
            color="#cc0000"
          />
        ) : null}
      </div>
      {!smallScreen ? null : (
        <>
          {field.natura_2000 ? (
            <div className="keymap">
              <SimpleMap feature={field.feature} {...mapProps}>
                {field.natura_2000.features.map((f) => (
                  <SimpleMap.TopPolygon
                    key={f.id}
                    feature={f}
                    fillOpacity={1}
                    fillColor={getCode(f.properties.code, "color")}
                  />
                ))}
              </SimpleMap>
            </div>
          ) : null}
          {field.natura_2000_buffer ? (
            <div className="keymap">
              <SimpleMap feature={field.feature} {...mapProps}>
                {field.natura_2000_buffer.features.map((f) => (
                  <SimpleMap.TopPolygon
                    key={f.id}
                    feature={f}
                    fillOpacity={1}
                    fillColor="#cc0000"
                  />
                ))}
              </SimpleMap>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
