import React, { memo } from "react";
import { CropImage, cropName, getCode, groupName, shortCropName } from "../../util";
import { Pro } from "../../components/Pro";
import { Tooltip } from "../../panels";
import { config } from "../../Config";
import { ColorBox } from "../util";
import i18n from "i18next";
import { Prediction } from "../Prediction";

interface CropData {
  extra: {
    area: number;
    color: string;
  }[];
}

interface CropProps {
  obj: CropData;
  selected: boolean;
}

const Crop = ({ obj, selected }: CropProps) => {
  const description = Object.entries(obj.extra)
      .filter(([key, value]) => value.area >= 1)
      .map(([key, value]) => (
        <div key={key}>
          <ColorBox color={value.color} /> {value.area}%{" "}
          {config.__ ? `${groupName(obj)} (${cropName(obj)})` : key}
        </div>
      )),
    elem = (
      <div
        className="icon-placeholder"
        style={selected ? { backgroundColor: getCode(obj, "color"), borderRadius: "10px" } : null}
      >
        <CropImage code={obj} />
      </div>
    );
  if (description) {
    return (
      <Tooltip delayHide={150} description={description}>
        {elem}
      </Tooltip>
    );
  }
  return elem;
};

interface CropHistoryProps {
  data: any;
  select?: (number) => void;
  itemWidth?: number;
  year?: number;
  prediction?: any;
  hasMore?: boolean;
}

const CropHistory = ({
  data,
  select,
  itemWidth = 1000,
  year,
  prediction,
  hasMore,
}: CropHistoryProps) => {
  if (!data) return <p>{i18n.t("No information found for this field")}</p>;
  let d = data.slice(), // copy
    orderedData = [];
  while (d.length) {
    orderedData.push(d.splice(0, itemWidth));
  }
  return (
    <div className="history-container">
      <table className="table" id="crop-history-table">
        <tbody>
          {orderedData.map((d, index) => (
            <tr key={index}>
              {hasMore
                ? [
                    <td key="moreall">
                      <div className="icon-placeholder">
                        <CropImage name="no-crop" />
                      </div>
                      <div>
                        {config.crop_year_start}..{config.crop_year - 3}
                      </div>
                      <div>
                        <Pro msg={i18n.t("Review many years with a subscription")} />
                      </div>
                    </td>,
                  ].concat(
                    Array(Math.max(0, 2 - config.history_free))
                      .fill(undefined)
                      .map((_, i) => (
                        <td key={`more${i}`}>
                          <div className="icon-placeholder">
                            <CropImage name="no-crop" />
                          </div>
                          <div>{config.crop_year - 2 + i}</div>
                          <div>...</div>
                        </td>
                      ))
                  )
                : null}
              {d.map((obj) => (
                <td key={obj.year} onClick={(e) => select?.(obj.year)}>
                  <Crop selected={year === obj.year} obj={obj} />
                  <div>{obj.year}</div>
                  <div>{config.isNative ? shortCropName(cropName(obj)) : groupName(obj)}</div>
                </td>
              ))}
              {prediction && (
                <td style={{ color: "#139CC2" }} onClick={(e) => select?.(config.crop_year + 1)}>
                  <Prediction prediction={prediction} selected={year === config.crop_year + 1} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const mCropHistory = memo(CropHistory);
export { mCropHistory as CropHistory };
