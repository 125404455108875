import { FieldProp, FieldProps, Desc } from "./FieldProps";

export class WAFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup
    this.fieldProps = [
      new FieldProp("gwp", "gwp-img", (e) =>
        Desc(
          "Ce terrain est situé dans une zone de protection des eaux souterraines",
          e,
          "#",
          "Zones de protection des eaux souterraines"
        )
      ),
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "La Wallonie fait face aujourd'hui à un défi majeur en matière de conservation de la nature: mettre un terme au recul de la biodiversité et, si possible, la restaurer. En ce domaine l'Union européenne a adopté deux directives la Directive Oiseaux (1979) et la Directive Habitats (1992) qui constituent la base d'un vaste réseau écologique: le réseau Natura 2000",
          e,
          "http://natura2000.wallonie.be/",
          "Natura 2000 en Wallonie"
        )
      ),
    ];
  }
}
