import React, { useCallback, useEffect, useRef, useState } from "react";
import { GeoJSON, useMap, useMapEvents } from "react-leaflet";
import { config } from "../../Config";
import { bbAxios, getCenter } from "../../util";
import { Layer } from "./Layer";
import { geometry } from "./types";
import { getStyle, STYLETYPES } from "../map_util";
import { DomEvent, LeafletMouseEvent } from "leaflet";
import "leaflet/dist/leaflet.css";

interface ResponseElement {
  type: string;
  id: string;
  geometry: geometry;
}
type ResponseData = ResponseElement[] & { sliced?: boolean };
const maxZoomLevel = 14;

type Props = {
  onPolygonClick?: (gps: string, field: any, e: MouseEvent) => void;
  zIndex?: number;
};

export const BRPLayer = (props: Props) => {
  const { onPolygonClick, zIndex } = props;
  const [data, setData] = useState<ResponseData>(null);
  const [fetching, setFetching] = useState(false);
  const prevBounds = useRef(null);
  const map = useMap();

  const getFields = useCallback(() => {
    const mapBounds = map.getBounds();
    if (map.getZoom() < maxZoomLevel) {
      setData(null);
      prevBounds.current = null;
      return;
    }

    // Check if the bounds are still within the fetched bounds. This
    // prevent too many fetches when zooming in or panning slightly
    if (prevBounds.current?.contains(mapBounds) && !data?.sliced) {
      return;
    }

    // Prevent fetching too often by fetching slightly more then needed.
    const newBounds = mapBounds.pad(0.2);
    setFetching(true);
    bbAxios(`${config.GEO_REFERENCE_URL}${newBounds.toBBoxString()}`)
      .then((response) => {
        const data = response.data.features as ResponseData;
        data.sliced = response.data.sliced;
        setData(data);
        setFetching(false);
        prevBounds.current = newBounds;
        if (!newBounds.contains(map.getBounds())) {
          // if the map moved outside fetched-data-bounds during fetching, all new fetching was blocked
          getFields();
        }
      })
      .catch((response) => setFetching(false));
  }, [map, data]);

  const viewChange = useCallback(() => {
    // Both useMapEvents callbacks sometimes get executed when the component is mounted
    // This prevent unneeded fetching.
    if (!fetching) getFields();
  }, [fetching, getFields]);

  const onClick = useCallback(
    (e: LeafletMouseEvent) => {
      if (onPolygonClick) {
        DomEvent.stopPropagation(e);
        const feature = e.layer.feature;
        const gps = getCenter(feature, undefined, 5).reverse().join(",");
        // @ts-ignore
        onPolygonClick(gps, feature, e);
      }
    },
    [onPolygonClick]
  );

  useMapEvents({
    moveend: viewChange,
    zoomend: viewChange,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(viewChange, []);
  if (!data) return null;

  return (
    <Layer name={"brp"} zIndex={zIndex}>
      {data.map((f) => (
        <BRPPolygon key={f.id} field={f} onClick={onClick} />
      ))}
    </Layer>
  );
};

type BRPPolygonProps = {
  field: ResponseElement;
  onClick?: (e: LeafletMouseEvent) => void;
};

const BRPPolygon = ({ field, onClick }: BRPPolygonProps) => (
  <GeoJSON
    style={getStyle(STYLETYPES.DEFAULT)}
    data={field as any}
    eventHandlers={{
      click: onClick,
    }}
  />
);
