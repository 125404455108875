import React from "react";
import i18n from "i18next";

import { SectionTitle } from "../util";
import { Chart } from "../Chart";
import { EWSDiagram } from "../diagrams";
import { updateEWS } from "../../actions";
import { SectionProps } from "./Sections";
import { TS, useSSelector } from "../../util";
import { useDispatch } from "react-redux";

export const SectionEWS = ({ active, cropfield, inView }: SectionProps) => {
  const dispatch = useDispatch();
  const ews = useSSelector(({ selection }) => selection.ews);
  return (
    <section id="section_ews" className={`${active ? " active" : ""}`}>
      <SectionTitle name={i18n.t("Infection chance potato fungus")}>
        <TS t="l_info_ews" />
      </SectionTitle>
      <Chart
        component={EWSDiagram}
        data={ews || {}}
        cropfield={cropfield}
        refresh={() => {
          dispatch(updateEWS(cropfield));
        }}
        inView={inView}
      />
    </section>
  );
};
