import React from "react";
import { ZoomControl } from "react-leaflet";
import { useMapStateRedux } from "../effects/effects";
import { GPSControl } from "../gps/GPSControl";
import { MeasureControl } from "../measureControl/MeasureControl";
import { accountFeatures, useSSelector } from "../../util";

type BaseMapProps = {
  children: React.ReactElement | React.ReactElement[];
};
export const BaseMap = ({ children }: BaseMapProps) => {
  const account = useSSelector((s) => s.activeUser?.account);
  const features = accountFeatures(account);
  useMapStateRedux();
  return (
    <>
      {children}
      <MeasureControl isPro={features.pro} />
      <GPSControl position="bottomright" />
      <ZoomControl position="bottomright" />
    </>
  );
};
