import React, { memo } from "react";
import { accountFeatures, getHeightLabels, useSSelector } from "../util";
import { ReportComponentProps } from "./Report";
import {
  SectionEWS,
  SectionFacts,
  SectionGrowth,
  SectionHeight,
  SectionNature,
  SectionProperty,
  SectionSoil,
  SectionWeather,
} from "./sections";
import { SoilNL } from "./soil/SoilNL";
import { RestrictedContent } from "./RestrictedContent";
import { BBAdvertismentView } from "../components/BBAdvertisement";

const ReportNL = ({ section = "" }: ReportComponentProps) => {
  const { account, field, bunder, activeUserSettings } = useSSelector(
    ({ selection, activeUser }) => ({
      account: activeUser.account,
      field: selection.field,
      bunder: selection.bunder,
      activeUserSettings: activeUser.settings || {},
    })
  );
  const cropfield = field?.state === "fetched" ? field.feature : bunder,
    smallScreen = window.innerWidth < 768,
    features = accountFeatures(account);
  const p = { features, field, cropfield, smallScreen };

  return (
    <main className="sections">
      <SectionFacts
        active={!section || section === "facts"}
        features={features}
        smallScreen={smallScreen}
        cropfield={cropfield}
        field={field}
      />
      {!features.pro && (
        <section id="section_advertisement">
          <BBAdvertismentView type="report" />
        </section>
      )}
      <SectionSoil active={section === "soil"} SoilComponent={SoilNL} {...p} />
      <SectionProperty active={section === "property"} {...p} />
      <SectionGrowth
        active={section === "growth"}
        inView={["property", "growth", "height"].includes(section)}
        {...p}
      />
      <SectionHeight
        active={section === "height"}
        inView={["property", "growth", "height"].includes(section)}
        names={["1", "2", "3", "4"]}
        labels={getHeightLabels(field, ["1", "2", "3", "4"])}
        defaultVersion={features.pro ? "4" : "1"}
        {...p}
      />
      {features.ews && activeUserSettings.showEWS && (
        <SectionEWS
          active={section === "ews"}
          inView={["growth", "height", "ews", "weather", "nature"].includes(section)}
          {...p}
        />
      )}
      {features.precipDeficit && activeUserSettings.precipDeficit && (
        <SectionWeather
          active={section === "weather"}
          inView={["growth", "height", "ews", "weather", "nature"].includes(section)}
          {...p}
        />
      )}
      <SectionNature active={section === "nature"} {...p} />
      <section id="section_last" />
    </main>
  );
};

const m = memo(({ section = "" }: ReportComponentProps) => (
  <RestrictedContent Component={ReportNL} section={section} />
));

export { m as ReportNL };
