import React, { memo } from "react";
import { Soil } from "./Soil";

const translations = {
  sand: "zand",
  "loamy sand": "lemig zand",
  "sandy loam": "zandig e leem",
  "sandy clay loam": "zandige zavel",
  "sandy clay": "zavel",
  clay: "klei",
  "clay loam": "kleiige leem",
  "silty clay loam": "siltige zavel",
  "silty loam": "siltige leem",
  silt: "silt",
  loam: "leem",
  "silt+sand": "silt+zand",
  "organic matter": "organische stof",
  "(see other)": "(zie boven)",
  "peaty sand": "venig zand",
  "peaty clay": "venige klei",
  "sandy peat": "zandig veen",
  "clayey peat": "kleiig veen",
  peat: "veen",
  "heavy clay": "zware klei",
};

const soilTrans = (s) => translations[s] || s;
const SoilNL = (props) => <Soil {...props} triangle="stibokaTexture" trans={soilTrans} />;
const m = memo(SoilNL);
export { m as SoilNL };
