/*eslint camelcase: ["error", {allow: ["geo_bounding_box", "top_left", "bottom_right", "post_filter"]}]*/

import React from "react";
import { config } from "../Config";
import { push } from "connected-react-router";
import { checkLoggedInUser } from "../actions";
import { Icon } from "react-fa";
import {
  ActionBarRow,
  encodeObjUrl,
  Hits,
  InitialLoader,
  NoHits,
  ResetFilters,
  SearchkitManager,
  AxiosESTransport,
  SearchkitProvider,
  SelectedFilters,
  SortingSelector,
} from "searchkit";
import { Helmet } from "react-helmet";
import "searchkit/theming/theme.scss";
import { accountFeatures, applyHeaders, attachRetryAuthHandler } from "../util";
import i18n from "i18next";
import "./Facet.scss";
import { MyHitsStats } from "./results/MyHitsStats";
import { CropFieldGridItem } from "./results/CropFieldGridItem";
import { MyHitsList } from "./results/MyHitsList";
import { MyResetFiltersDisplay } from "./filters/MyResetFiltersDisplay";
import { AggregateSelector } from "./filters/AggregateSelector";
import { ChartResult } from "./results/ChartResult";
import { track } from "../track";
import { InitialLoading } from "./InitialLoading";
import { connect, useDispatch } from "react-redux";
import { Filters } from "./Filters";
import axios from "axios";

/*
 TODO 2021-05-23 Searchkit heeft zijn architectuur in versie 3 sterk verbeterd. In v2 doet de client rechtstreeks
 een query op elastic. Wij proberen dat wat te beveiligen met nginx/njs, maar dat is niet waterdicht.
 In v3 is er een soort van 'tussenserver' die requests van de web-client vertaalt naar elastic-queries.
 Dit maakt alles veel logischer/veiliger, maar verandert alles wat we nu hebben. Bovendien moeten we opeens
 een node-server draaien met die tussenlaag.
 */

/*
Hectares in de resultaatboxes

Standaard werken we met een Aggregation met term-count. Maar voor hectare moeten met een Sum Aggregation werken:
https://www.elastic.co/guide/en/elasticsearch/reference/current/search-aggregations-metrics-sum-aggregation.html

Daarvoor moet de query anders worden. Een facet werkt normaal met een 'terms' or 'range' aggregation.
Daarin komt standaard een doc_count. Wij voegen nu een extra sum-aggregation toe in elke term.
Vervolgens wordt deze sum-aggregration uitgelezen in het component voor de rendering.

"aggs": {
 "cat_2018": {
    "terms": {
      "field": "cat_2018",
      "size": 10
    }, // deze aggregatie toevoegen zodat per term-bucket de area gesumd wordt
    "aggs": {
      "area": {
        "sum": {
          "field": "area"
        }
      }
    }
  }
}

Dat kan door per component andere Filters te genereren (accessor), en dan dan rendering dan aan te passen
om de area value te gebruiken ipv de doc_count (kan met itemComponent als er een listComponent gebruikt wordt).

itemComponent={MyCheckboxItemComponent}
MyHierarchicalMenuFilter, MyRefinementListFilter, NumericRefinementListFilter
Maar misschien is props.bucketsTransform een betere truc.
Nee, het zit in de AbstractItemList.render() (gebruikt door alle andere ItemListComponents)
*/

const Dummy = () => <div />; // or null
const Header = ({ viewMode, features, setViewMode }) => {
  const dispatch = useDispatch();
  return (
    <header className="pb-2">
      <div className="row">
        <div className="col-6">
          <ResetFilters component={MyResetFiltersDisplay} />
        </div>
        <div className="col-6 col-md-5 d-none d-md-block">
          <div className="btn-group" role="group" aria-label="">
            {/*We could make this a SearchkitComponent with StatefulAccessor, so view is managed in url*/}
            <button
              onClick={() => setViewMode("map")}
              type="button"
              title={i18n.t("Map")}
              className={`btn-map show_map_results btn btn-default${
                viewMode === "map" ? " active" : ""
              }`}
            >
              <Icon name="map-o" />
            </button>
            <button
              onClick={() => setViewMode("table")}
              type="button"
              title={i18n.t("Fields")}
              className={`btn-map show_table_results btn btn-default${
                viewMode === "table" ? " active" : ""
              }`}
            >
              <Icon name="th-large" />
            </button>
            {features.premium && (
              <button
                onClick={() => setViewMode("chart")}
                type="button"
                title={i18n.t("Analysis")}
                className={`btn-map show_chart_results btn btn-default${
                  viewMode === "chart" ? " active" : ""
                }`}
              >
                <Icon name="bar-chart" />
              </button>
            )}
          </div>
        </div>
        <div className="col-6 col-md-1">
          <button onClick={() => dispatch(push("/"))} className="btn-close" />
        </div>
      </div>
    </header>
  );
};

const Loading = () => (
  <aside className="mod_bb_main_panel bg-white is-open">
    <article className="mod_bb_facet active">
      <h2>
        {i18n.t("Fetch data")} <Icon spin name="spinner" />
      </h2>
    </article>
  </aside>
);

function sourceToCropfield(hit) {
  const s = hit._source;
  return {
    id: hit._id,
    type: "Feature",
    properties: { area: s.area },
    geometry: s.geometry,
    _center: s.center,
  };
}

class Facet extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.bounds !== this.props.bounds) this.searchkit.performSearch();
  }

  setViewMode(mode) {
    this.setState({ viewMode: mode });
    track("facet", "toggle_view", mode);
  }

  constructor(props) {
    super(props);
    const transport = new AxiosESTransport(config.SEARCH_URL, { timeout: 10000 });
    transport.axios = axios.create({
      baseURL: config.SEARCH_URL,
      headers: {},
      searchUrlPath: "/_search",
      timeout: 10000,
    });
    transport.axios.interceptors.request.use(applyHeaders);
    attachRetryAuthHandler(transport.axios);

    const searchkit = new SearchkitManager(config.SEARCH_URL, { transport });
    this.searchkit = searchkit;
    searchkit.shouldPerformSearch = function () {
      return !this.loading; // voorkom dubbele searchRequests
    };

    const i18nData = {
      "pagination.next": i18n.t("Next page"),
      "pagination.previous": i18n.t("Previous page"),
      "facets.view_all": i18n.t("All") + "...",
      "facets.view_more": i18n.t("More"),
      "facets.view_less": i18n.t("Less"),
      "reset.clear_all": i18n.t("Reset all filters"),
    };
    searchkit.translateFunction = (key) => i18nData[key] || i18n.t(key);

    // hitsCount klopt niet meer na de post_filter, dat fixen we hier weer
    const setResults = searchkit.setResults.bind(searchkit);
    searchkit.setResults = function (results) {
      const key =
        Object.keys(results.aggregations).find((i) => {
          return i.startsWith("area");
        }) ||
        Object.keys(results.aggregations).find((i) => {
          return i.startsWith(`cat_${config.crop_year}`);
        });
      results.hits.total = results.aggregations[key]?.doc_count || 0;
      setResults(results);
    };

    this.searchkit.setQueryProcessor((query) => {
      const b = this.props.bounds;
      if (b) {
        const term = {
          geo_bounding_box: {
            center: {
              top_left: {
                lat: b.getNorth(),
                lon: b.getWest(),
              },
              bottom_right: {
                lat: b.getSouth(),
                lon: b.getEast(),
              },
            },
          },
        };
        const p = query.post_filter;
        if (!p) {
          query.post_filter = term;
        } else if (p.bool) {
          p.bool.must = [...(p.bool.must || []), term];
        } else {
          query.post_filter = { bool: { must: [p, term] } };
        }
      }
      return query;
    });
    // this.searchkit.addAccessor(new BoundsAccessor()); // Werkt niet, filter werkt overal voor
    this.state = { viewMode: "map", toggle: undefined, error: undefined };
    this.setViewMode = this.setViewMode.bind(this);
  }

  componentDidMount() {
    this.offResults?.(); // just to be sure
    this.offSearch?.();
    this.offResults = this.searchkit.addResultsListener((results) => {
      if (results.hits && results.hits.hasChanged) {
        const cropfields = results.hits.hits.map(sourceToCropfield);
        this.props.dispatch({ type: "FILTER_RESULTS_UPDATE", results: cropfields });
      }
    });
    this.offSearch = this.searchkit.resultsEmitter.addListener(() => {
      this.props.dispatch({
        type: "SEARCH_FILTER_UPDATE",
        filter: encodeObjUrl(this.searchkit.state),
      });
    });
    this.errorHandler = this.searchkit.emitter.addListener(() => {
      if (this.searchkit.error) this.setState({ error: this.searchkit.error });
    });
  }

  componentWillUnmount() {
    if (this.offSearch) {
      this.offSearch();
      delete this.offSearch;
    }
    if (this.offResults) {
      this.offResults();
      delete this.offResults;
      this.props.dispatch({ type: "FILTER_RESULTS_UPDATE", results: null });
    }
    if (this.errorHandler) {
      this.errorHandler();
      delete this.errorHandler;
    }
  }

  render() {
    const { center, activeUser } = this.props,
      viewMode = this.state.viewMode,
      features = accountFeatures(activeUser?.account),
      isPro = features.pro;

    if (!activeUser.state) {
      this.props.dispatch(checkLoggedInUser());
      return <Loading />;
    }

    if (!center) return <Loading />;

    return (
      <SearchkitProvider searchkit={this.searchkit}>
        <aside className="mod_bb_main_panel bg-white is-open">
          <Helmet>
            <title>{i18n.t("Search filter")}</title>
          </Helmet>
          <article className="mod_bb mod_bb_facet active">
            <hr className="mobile-hr" />
            <Header features={features} viewMode={viewMode} setViewMode={this.setViewMode} />
            <div className="row">
              <div className="col-5 col-sm-6">
                <MyHitsStats features={features} center={center} />
              </div>
              <div className="col-7 col-sm-6">
                {/*https://www.elastic.co/guide/en/elasticsearch/reference/5.3/search-request-sort.html#geo-sorting*/}
                {isPro && (
                  <button
                    onClick={() => this.setState({ toggle: !this.state.toggle })}
                    className="btn btn-default sk-mtoggle pull-left"
                  >
                    {this.state.toggle === undefined ? "o" : this.state.toggle ? "-" : "+"}
                  </button>
                )}

                <SortingSelector
                  options={[
                    {
                      label: i18n.t("Random"),
                      field: "_score",
                      order: "desc",
                      defaultOption: true,
                    },
                    { label: i18n.t("Large-small"), field: "area", order: "desc" },
                    { label: i18n.t("Small-large"), field: "area", order: "asc" },
                  ]}
                />
                {features.premium && <AggregateSelector />}
              </div>
            </div>

            {this.state.error && (
              <div>
                {i18n.t("Error occurred")}: {this.state.error.message || ""}
              </div>
            )}
            <Filters features={features} toggle={this.state.toggle} />
            <ActionBarRow>
              <SelectedFilters />
            </ActionBarRow>

            <div
              id="resultList"
              className={`sk-layout__results sk-results-list ${
                this.state.viewMode === "table" ? "" : "d-none"
              }`}
            >
              <Hits
                hitsPerPage={10000}
                mod="sk-hits-grid"
                scrollTo={false}
                sourceFilter={{
                  includes: [
                    `cat_${config.crop_year}`,
                    `crop_${config.crop_year}`,
                    "center",
                    "soil",
                    "area",
                    "geometry",
                    "zone1",
                    "zone2",
                  ],
                }}
                listComponent={viewMode === "table" ? MyHitsList : Dummy}
                itemComponent={CropFieldGridItem}
              />
              <NoHits suggestionsField={`cat_${config.crop_year}`} />
            </div>
            <InitialLoader component={InitialLoading} />

            <div
              id="resultChart"
              className={`sk-layout__results sk-results-chart ${
                this.state.viewMode !== "chart" ? "d-none" : ""
              }`}
            >
              <ChartResult enabled={this.state.viewMode === "chart"} />
            </div>
          </article>
        </aside>
      </SearchkitProvider>
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  center: state.inView.center,
  bounds: state.inView.bounds,
  activeUser: state.activeUser,
  ...ownProps,
});
const m = connect(mapStateToProps)(Facet);
export { m as Facet };
