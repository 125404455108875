import React, { useCallback, useEffect, useState, useRef, ChangeEvent } from "react";
import i18n from "i18next";
import { uploadP8, uploadLeaseShapeFile } from "../../actions";
import { useSSelector } from "../../util";
import { useDispatch } from "react-redux";
import { ListAccordion } from "./ListAccordion";
import { NewListButton } from "./NewListButton";
import { IconTooltip } from "../Tooltip";

type Props = {
  listKey: any;
};

export const LeaseList = (props: Props) => {
  const { listKey } = props;
  const dispatch = useDispatch();
  const [lists, fields] = useSSelector(({ favorites }) => [
    favorites.lists || null,
    favorites.fields || null,
  ]);

  const uploading = useSSelector((s) => s.favorites.state) === "updating";
  const leaseList = lists?.find((list) => list.type === "LEASE");
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const removeFile = useCallback(() => {
    setFile(null);
    inputRef.current.value = null;
  }, []);

  useEffect(() => {
    if (uploading === false) {
      removeFile();
    }
  }, [uploading, removeFile]);

  const handleSelectFileClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
  };

  const handleUpload = useCallback(() => {
    var formData = new FormData();

    if (file.type.includes("zip")) {
      formData.append("shapeFile", file);
      dispatch(uploadLeaseShapeFile(formData));
    }
    if (file.type.includes("csv")) {
      formData.append("p8File", file);
      dispatch(uploadP8(formData));
    }
  }, [file, dispatch]);

  return (
    <>
      <h5 className="mt-4 mb-2 mx-2">{i18n.t("Lease list")}</h5>
      <p className="pl-2">Je kunt je pachtpercelen op 3 manieren toevoegen:</p>
      <ul>
        <li>
          Upload je pachtpercelen met een csv export, bijvoorbeeld uit p8
          <IconTooltip icon={"info-circle"} className="right ml-1 text-left">
            <div className="text-left">
              <span>Voeg minimaal de volgende kolommen toe*:</span>
              <ul className="pl-3">
                <li>kadastraleCode</li>
                <li>Oppervlakte (Ha)</li>
                <li>Burgerlijke gemeente</li>
                <li>P8-Perceelcode</li>
              </ul>
              <span>* Kolommen moeten exact overeenkomen met bovenstaande titels</span>
            </div>
          </IconTooltip>
        </li>
        <li>Upload je pachtpercelen met een shapefile</li>
        <li>Voeg je pachtpercelen handmatig toe (kies een bestaande lijst)</li>
      </ul>

      {leaseList && (
        <ul className="accordion accordion__items mb-2">
          <ListAccordion key={leaseList.id} list={leaseList} listKey={listKey} fields={fields} />
        </ul>
      )}
      <div className="flex mx-2 mb-2">
        {!uploading && (
          <button className="mr-2 btn btn-success" onClick={handleSelectFileClick}>
            {i18n.t("Choose file")}
          </button>
        )}

        {file ? (
          <button onClick={handleUpload} className="btn btn-light" disabled={uploading}>
            <i className={`mr-2 ${uploading ? "fa fa-spin fa-refresh" : "fa fa-upload"}`} />

            {uploading ? i18n.t("Uploading") : i18n.t("Upload")}
          </button>
        ) : (
          !leaseList && <NewListButton newListType="LEASE" />
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".csv,.zip"
      />

      {file?.name && !uploading && (
        <div className="ml-2">
          <div className="alert alert-info" role="alert">
            <span className="d-flex font-weight-bold">
              {file?.name} <i className="ml-auto fa fa-times" onClick={removeFile}></i>
            </span>
            <span>{i18n.t("Uploading this file will overwrite your current lease list")}</span>
          </div>
        </div>
      )}
    </>
  );
};
