import { useCallback, useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useDispatch } from "react-redux";
import { useSSelector } from "../../util";

export const useFollowGPS = () => {
  const follow = useSSelector((state) => state.followGps);
  const latlng = useSSelector((state) => state.currentLocation.latlng);

  const selection = useSSelector((state) => state.selection);
  const map = useMap();

  const dispatch = useDispatch();

  useEffect(() => {
    map.locate({ maxZoom: 15, watch: true, maximumAge: 60000, enableHighAccuracy: true });

    if (selection.field) {
      dispatch({ type: "FOLLOW_GPS", followGps: false });
      return;
    }
    dispatch({ type: "FOLLOW_GPS", followGps: true });
    // Only pressing the control should re-enable following.
    // This effect is only to by default enable following when loading the page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragStart = useCallback(
    () => dispatch({ type: "FOLLOW_GPS", followGps: false }),
    [dispatch]
  );

  useMapEvents({
    dragstart: handleDragStart,
  });

  useEffect(() => {
    if (follow && latlng) {
      map.panTo(latlng);
    }
  }, [map, follow, latlng]);

  return;
};
