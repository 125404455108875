import { LeafletMouseEvent } from "leaflet";
import { useCallback, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { config } from "../../Config";
import { bbAxios } from "../../util";
import { parseLatLng } from "../map_util";

type callback = (gps: string, field?: { [key: string]: any }, e?: MouseEvent) => void;

export const useReferenceFieldClick = (callback: callback) => {
  const [measuring, setMeasuring] = useState(false);
  const map = useMap();
  const handleMapClick = useCallback(
    (e: LeafletMouseEvent) => {
      if (measuring) return;
      // @ts-ignore
      if (map.getZoom() > 13 && e.originalEvent.target?.tagName !== "path")
        return callback("", null, e.originalEvent);
      (async () => {
        const gps = parseLatLng(e.latlng);
        const selected = await fetchSelectedSimple(gps);
        callback(gps, selected || null, e.originalEvent);
      })();
    },
    [callback, measuring, map]
  );

  useMapEvents({
    click: handleMapClick,
    "draw:drawstart": () => setMeasuring(true),
    "draw:drawstop": () => setMeasuring(false),
  } as any);
};

export const fetchSelectedSimple = async (gps: string) => {
  const url = config.GEO_REFERENCE_URL.replace("bbox=", "p=");
  const response = await bbAxios(`${url}${gps}`);
  return response?.data?.features[0] || null;
};
