import React, { memo } from "react";
import { QuickSearch } from ".";
import { Tooltip } from "../panels";
import { Pro } from "./Pro";
import i18n from "i18next";
import { ButtonGroup, Button } from "react-bootstrap";
import "./DataSets.scss";

type DataSetItemProps = {
  click: any;
  layer: any;
  name: string;
  active: boolean;
  tooltip?: boolean;
};

export const DataSetItem = (props: DataSetItemProps) => {
  const { click, layer, name, active, tooltip = false } = props;
  const button = (
    <Button
      onClick={() => click(layer)}
      className={"btn-dataset " + layer + (active ? " active" : "")}
    />
  );
  return tooltip ? (
    <Tooltip description={name} delayHide={200}>
      {button}
    </Tooltip>
  ) : (
    <div>
      {button} <span className="text-white">{name}</span>
    </div>
  );
};

const DataSets = ({ showControls, showSearch, layers, layerNames, clickLayer }) => (
  <div
    className={`mod_bb_datasets container-fluid ${showControls ? " show" : ""}${
      showSearch ? " showSearch" : ""
    }`}
    id="mod_bb_datasets"
  >
    <div className="row">
      <div className="col-md-6 col-lg-5">
        <QuickSearch forceShow={showSearch} />
      </div>
      <div className="col btn-group-wrapper">
        <ButtonGroup>
          <Pro msg={i18n.t("View all datasets")} />
          {/*fixed width for scrolling*/}
          {Object.keys(layerNames).map((layer) => (
            <DataSetItem
              key={layer}
              click={clickLayer}
              layer={layer}
              name={layerNames[layer]}
              active={layers.includes(layer)}
              tooltip={!showControls}
            />
          ))}
        </ButtonGroup>
      </div>
    </div>
  </div>
);
const mDataSets = memo(DataSets);
export { mDataSets as DataSets };
