import React, { useCallback } from "react";
import i18n from "i18next";
import { DataSets, freeTrialClick, showSubscriptionModal, TrialButton } from "../components";
import { track } from "../track";
import { clickGPS } from "../actions";
import { config } from "../Config";
import { MapView } from "./BBMapView";
import { useDispatch } from "react-redux";
import { Layers } from "./index";
import "./BBMap.scss";
import { replace } from "connected-react-router";
import { useLocation } from "react-router";
import { BBAdvertismentView } from "../components/BBAdvertisement";

interface BBMapProps {
  accountFeatures: any;
  layers: string[];
  layerNames: string[];
  Layers: typeof Layers[keyof typeof Layers];
  showControls: boolean;
  showSearch: any;
  activeUser: any;
}

const freeLayers: string[] = ["nva"];

export const BBMap = ({
  accountFeatures,
  layers,
  layerNames,
  Layers,
  showControls,
  showSearch,
  activeUser,
}: BBMapProps) => {
  const dispatch = useDispatch();
  const clickLayer = useCallback(
    (layer) => {
      track("toggle_layer", layer);
      const allowed = accountFeatures.pro || config.__ || freeLayers?.includes(layer);
      if (allowed) {
        if (showControls) {
          dispatch(replace("/"));
        }
        return dispatch({ type: "LAYER_TOGGLE", layer: layer });
      }
      showSubscriptionModal(
        dispatch,
        i18n.t("TextNationalLayer", { layer: layerNames[layer] }),
        `layer ${layer}`
      );
    },
    [accountFeatures, showControls, layerNames, dispatch]
  );
  const clickPolygon = useCallback(
    (e) => dispatch(clickGPS(e.latlng, e.propagatedFrom?.feature)),
    [dispatch]
  );
  const trialClick = useCallback(
    (e) => {
      freeTrialClick();
      showSubscriptionModal(dispatch);
    },
    [dispatch]
  );
  const location = useLocation();
  const path = location.pathname;
  const open = /^\/($|page|search|filter|controls)/.test(path) ? "" : "is-open";

  const trialEndDate = activeUser?.account?.trialEndDate;

  let loadAdvertisement = false;
  if (activeUser.state !== "fetching" && !activeUser.account?.features?.pro) {
    loadAdvertisement = true;
  }
  return (
    <div
      className={"mod_bb_map" + (activeUser?.account?.features?.pro ? " pro_map" : "")}
      id="mapContainer"
    >
      <DataSets
        layers={layers}
        layerNames={layerNames}
        showControls={showControls}
        showSearch={showSearch}
        clickLayer={clickLayer}
      />
      <MapView layersComponent={Layers} polygonClick={clickPolygon} layerNames={layerNames} />
      {!open && loadAdvertisement && (
        <div id="advertisement">
          <BBAdvertismentView type="map" />
        </div>
      )}
      {config.bb_site.free_trial && activeUser.state === "loggedOut" && (
        <div className="low-bar">
          <button className="btn btn-success btn-sm d-lg-none" onClick={trialClick}>
            {i18n.t("Free trial")}
          </button>
        </div>
      )}
      {trialEndDate && (
        <div className="low-bar">
          <TrialButton trialEndDate={trialEndDate} className="btn-sm d-md-none" />
        </div>
      )}
    </div>
  );
};
