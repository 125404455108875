import { BEFieldProps } from "./FieldPropsBE";
import { NLFieldProps } from "./FieldPropsNL";
import { WAFieldProps } from "./FieldPropsWA";
import { DKFieldProps } from "./FieldPropsDK";
import { DENWFieldProps } from "./FieldPropsDENW";
import { LUFieldProps } from "./FieldPropsLU";
import { config } from "../../Config";
import { FRFieldProps } from "./FieldPropsFR";

export const fieldProps = {
  NL: NLFieldProps,
  BE: BEFieldProps,
  WA: WAFieldProps,
  DK: DKFieldProps,
  DE_NW: DENWFieldProps,
  LU: LUFieldProps,
  FR: FRFieldProps,
};

const Dummy = () => null;
export const getFieldPropsComponent = () => fieldProps[config.bb_country] || Dummy;
