import React from "react";
import { Nav, NavItem, NavLink } from "react-bootstrap";
import { track } from "../../track";

type Props = {
  buttons: any;
  onClick: any;
  active: any;
  id: any;
};

export const ButtonBar = (props: Props) => {
  const { buttons, onClick, active, id = "bb_buttonbar" } = props;

  return (
    <Nav
      id={id}
      activeKey={active}
      onSelect={onClick}
      className={`nav-tabs bar_${buttons.length}`}
      as={"ul"}
    >
      {buttons.map(([cname, name]) => (
        <NavItem
          key={cname}
          as={"li"}
          role={"presentation"}
          className={cname === active ? "active" : ""}
        >
          <NavLink
            onClick={() => {
              track("report", "button", cname);
              onClick(cname);
            }}
          >
            <i className={`icon_${cname}`} />
            <span>{name}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};
