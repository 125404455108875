import React from "react";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import { getCenter, useSSelector } from "../../util";
import { config } from "../../Config";
import "./EWS.scss";
import moment from "moment";
import { ewsCropKeys, ewsSecondaryCropsMapping } from "./EWSCropKeysMapping";
import { track } from "../../track";
import { updateLayerConfig } from "../../actions";
import { EwsMultiProps } from "../../reducers";

const dateRange = [
  moment().add(-1, "days"),
  moment(),
  moment().add(1, "days"),
  moment().add(2, "days"),
  moment().add(3, "days"),
];

export const getEWSValueColor = (value) => {
  if (value === null) return "rgb(0,0,0,0)"; // transparent
  if (value >= 0 && value <= 35) return "#28A745";
  else if (value >= 36 && value <= 50) return "#F3921C";
  else if (value >= 51 && value <= 100) return "#DC3545";
  else return "#e3e3e5";
};

interface rowData {
  id: number;
  name: string;
  // eslint-disable-next-line camelcase
  infection_chances: Record<string, number | null>[];
}

interface EWSDataForFieldsProperties {
  data: rowData[];
  delta: number;
  show: boolean;
}

const EWSDataForFields = ({ data, delta, show }: EWSDataForFieldsProperties) => (
  <>
    {data.map((d) => (
      <tr key={d.id} hidden={!show}>
        <td />
        <td>{d.name}</td>
        {dateRange.map((day, index) => {
          const ic = d.infection_chances[day.format("YYYY-MM-DD")];
          return (
            <td
              className={delta === index - 1 ? "ews-value zoom" : "ews-value"}
              key={`ic_${index}`}
            >
              <div style={{ backgroundColor: getEWSValueColor(ic) }}>{ic === null ? "-" : ic}</div>
            </td>
          );
        })}
      </tr>
    ))}
  </>
);

const getDiseaseDataForField = (ewsMulti: EwsMultiProps, field, forCrop, forDisease) => {
  if (!ewsMulti?.data) return [];
  const diseaseData = ewsMulti.data["NL"][forCrop][forDisease];
  return diseaseData.filter((d) => {
    const diseaseCoordinates = d.location.replace("(", "").replace(")", "").split(", "),
      centroid = getCenter(field, "", 5);
    return (
      parseFloat(diseaseCoordinates[0]) === parseFloat(centroid[0]) &&
      parseFloat(diseaseCoordinates[1]) === parseFloat(centroid[1])
    );
  });
};

const selectCrop = (dispatch, cropId) => {
  dispatch({
    type: "UPDATE_EWS_CONFIG",
    selectedCrop: cropId,
    selectedDisease: "",
  });
  if (cropId) track("ews", "crop", cropId);
};
const selectDisease = (dispatch, diseaseId) => {
  dispatch({
    type: "UPDATE_EWS_CONFIG",
    selectedDisease: diseaseId,
  });
  if (diseaseId) track("ews", "disease", diseaseId);
};

// I don't understand why getMaxEWSFieldDataForDate & getDiseaseDataForField are not combined
const getMaxEWSFieldDataForDate = (fields, selectedCrop, ewsMulti, disease, selectedDate) => {
  if (!selectedCrop || !fields) return "";
  const sd = selectedDate.format("YYYY-MM-DD"),
    ewsFieldsDataForDay = fields.reduce((memo, f) => {
      if (ewsSecondaryCropsMapping[f.properties?.crop_dacom_code] !== selectedCrop) return memo;
      const diseaseDataForField = getDiseaseDataForField(ewsMulti, f, selectedCrop, disease);
      const value = diseaseDataForField[0]?.["infection_chances"]?.[sd];
      if (value !== null) memo.push(value);
      return memo;
    }, []);
  return ewsFieldsDataForDay.length ? Math.max(...ewsFieldsDataForDay) : null;
};

const EWSCropRow = ({
  ewsConfig,
  ewsFieldsData,
  delta,
  relevantEWSCropEdiCodes,
  fields,
  ewsMulti,
}) => {
  const dispatch = useDispatch();
  const data = ewsConfig.data[config.bb_country] || {};
  const selectedCrop = ewsConfig.selectedCrop || "";
  const selectedDisease = ewsConfig.selectedDisease || "";

  return (
    <>
      {Object.entries(data).map(([k, v]) => (
        <React.Fragment key={k}>
          <tr onClick={() => selectCrop(dispatch, k === selectedCrop ? "" : k)}>
            <td>
              <span className={`fa ${selectedCrop === k ? "fa-caret-up" : "fa-caret-down"}`} />
            </td>
            <td className="blue" colSpan={6}>
              {i18n.t(ewsCropKeys[k])}
            </td>
          </tr>
          {selectedCrop === k &&
            v["fungi"].map((d) => {
              const maxInfectionChances = dateRange.map((day) =>
                  getMaxEWSFieldDataForDate(fields, selectedCrop, ewsMulti, d.code, day)
                ),
                hasCropsForEWSDisease = relevantEWSCropEdiCodes.includes(k);
              return (
                <React.Fragment key={d.code}>
                  <tr
                    className={selectedDisease === d.code ? "active" : "inactive"}
                    onClick={() => {
                      selectDisease(dispatch, selectedDisease === d.code ? "" : d.code);
                    }}
                  >
                    <td>
                      <span
                        className={`fa ${
                          selectedDisease === d.code ? "fa-caret-up" : "fa-caret-down"
                        }`}
                        style={{
                          display: hasCropsForEWSDisease ? "block" : "none",
                        }}
                      />
                    </td>
                    <td>{d.name}</td>
                    {maxInfectionChances.map((mic, index) => (
                      <td
                        className={
                          delta === index - 1 && hasCropsForEWSDisease
                            ? "ews-value zoom"
                            : "ews-value"
                        }
                        key={index}
                      >
                        <div
                          style={{
                            backgroundColor: getEWSValueColor(mic),
                          }}
                          hidden={!hasCropsForEWSDisease}
                        >
                          {mic === null ? "-" : mic}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <EWSDataForFields
                    data={ewsFieldsData}
                    delta={delta}
                    show={selectedDisease === d.code}
                  />
                </React.Fragment>
              );
            })}
        </React.Fragment>
      ))}
    </>
  );
};

export const EWSTable = ({ relevantEWSCropEdiCodes }) => {
  const dispatch = useDispatch();
  const { ewsConfig, layersConfig, ewsMulti, fields } = useSSelector((state) => ({
    ewsConfig: state.ewsConfig || {},
    layersConfig: state.layers.config,
    ewsMulti: state.selection.ewsMulti,
    fields: state.selection.farmFields,
  }));

  const delta = layersConfig?.["ewsP"]?.delta || 0;
  const selectedCrop = ewsConfig.selectedCrop || "";
  const selectedDisease = ewsConfig.selectedDisease || "";

  let ewsFieldsData: rowData[];
  if (!selectedCrop || !selectedDisease || !fields) {
    ewsFieldsData = [];
  } else {
    ewsFieldsData = fields
      .filter((f) => ewsSecondaryCropsMapping[f.properties?.crop_dacom_code] === selectedCrop)
      .map((ff) => {
        const diseaseDataForField = getDiseaseDataForField(
          ewsMulti,
          ff,
          selectedCrop,
          selectedDisease
        );
        return {
          id: ff.properties.id,
          name: ff.properties.name,
          // eslint-disable-next-line camelcase
          infection_chances: diseaseDataForField[0]?.["infection_chances"] || {},
        };
      });
  }
  //dispatch(updateLayerConfig({ ewsP: { index } }))
  return (
    <table className="table table-hover crop-table">
      <thead>
        <tr hidden={!relevantEWSCropEdiCodes.length}>
          <th />
          <th className="ews-crop-disease-name" />
          {dateRange.map((day, index) => (
            <th className={delta === index - 1 ? "ews-date zoom" : "ews-date"} key={index}>
              <button onClick={() => dispatch(updateLayerConfig({ ewsP: { delta: index - 1 } }))}>
                {day.format("D/M")}
              </button>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <EWSCropRow
          ewsFieldsData={ewsFieldsData}
          delta={delta}
          ewsConfig={ewsConfig}
          relevantEWSCropEdiCodes={relevantEWSCropEdiCodes}
          fields={fields}
          ewsMulti={ewsMulti}
        />
      </tbody>
    </table>
  );
};
