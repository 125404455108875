import React, { useCallback, useMemo } from "react";
import { DomEvent, LeafletMouseEvent } from "leaflet";

import { useSSelector } from "../../util";

import { fieldGeoData } from "./types";
import { getStyle, STYLETYPES } from "../map_util";
import { GeoJSON } from "react-leaflet";
import { Layer } from "./Layer";
import { usePanToFarmFields } from "./utils";

type Props = {
  onPolygonClick?: (field: any) => void;
  onPolygonHover?: (field: any) => void;
  polygonStyle?: string;

  enablePanning?: boolean;
  zIndex?: number;
};

// Although this is called SelectedFarmFieldsLayer, it does not strictly display farm fields.
// Most importantly, it also renders the selected list.
export const SelectedFarmFieldsLayer = (props: Props) => {
  const { onPolygonHover, onPolygonClick, polygonStyle, enablePanning = true, zIndex } = props;
  const farmFields = useSSelector(
    (state) => (state.selection?.farmFields as fieldGeoData[]) || null
  );

  usePanToFarmFields(enablePanning);

  if (!farmFields) {
    return null;
  }

  return (
    <Layer name={"selected-fields"} zIndex={zIndex}>
      {farmFields.map((field) => (
        <FieldPolygon
          polygonStyle={polygonStyle}
          key={field.properties.key}
          field={field}
          onPolygonClick={onPolygonClick}
          onPolygonHover={onPolygonHover}
        />
      ))}
    </Layer>
  );
};

type FieldPolygonProps = {
  field: fieldGeoData;
  onPolygonClick?: (field: any) => void;
  onPolygonHover?: (field: any) => void;
  polygonStyle?: string;
};

const FieldPolygon = (props: FieldPolygonProps) => {
  const { field, onPolygonClick, onPolygonHover, polygonStyle } = props;

  const handle = useCallback(
    (
      e: LeafletMouseEvent,
      callbackFunction: (field: any) => void | undefined,
      callbackData: any
    ) => {
      if (callbackFunction) {
        DomEvent.stopPropagation(e);
        callbackFunction(callbackData);
      }
    },
    []
  );

  const handleClick = useCallback(
    (e: LeafletMouseEvent) => {
      handle(e, onPolygonClick, field);
    },
    [field, handle, onPolygonClick]
  );

  const handleMouseOver = useCallback(
    (e: LeafletMouseEvent) => {
      handle(e, onPolygonHover, field);
    },
    [field, handle, onPolygonHover]
  );

  const handleMouseOut = useCallback(
    (e: LeafletMouseEvent) => {
      handle(e, onPolygonHover, null);
    },
    [handle, onPolygonHover]
  );

  const style = useMemo(() => {
    if (polygonStyle) {
      return getStyle(polygonStyle, { feature: field });
    }

    return getStyle(STYLETYPES.SHOW_CROP_COLOR, { feature: field });
  }, [field, polygonStyle]);

  return (
    <GeoJSON
      style={style}
      data={field as any}
      eventHandlers={{
        click: handleClick,
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
      }}
    />
  );
};
