import React, { useMemo } from "react";

import { useSSelector } from "../../util";

import { getStyle, STYLETYPES } from "../map_util";
import { GeoJSON } from "react-leaflet";
import { Layer } from "./Layer";
import { MultiSelectField } from "../../reducers";

type Props = {
  zIndex?: number;
};

// Although this is called SelectedFarmFieldsLayer, it does not strictly display farm fields.
// Most importantly, it also renders the selected list.
export const MultiSelectLayer = (props: Props) => {
  const { zIndex } = props;
  const farmFields = useSSelector((state) => state.selection?.selectedFields || null);

  if (!farmFields) {
    return null;
  }

  return (
    <Layer name={"selected-fields"} zIndex={zIndex}>
      {farmFields.map((field) => (
        <FieldPolygon key={field.feature.id} field={field} />
      ))}
    </Layer>
  );
};

type MultiSelectPolygonProps = {
  field: MultiSelectField;
};

const FieldPolygon = (props: MultiSelectPolygonProps) => {
  const { field } = props;

  const style = useMemo(() => {
    return getStyle(STYLETYPES.SELECTED);
  }, []);

  return <GeoJSON style={style} data={field.feature as any} />;
};
