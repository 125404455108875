import React, { useCallback } from "react";
import i18n from "i18next";
import { Form } from "react-bootstrap";

const ListSelectorOptions = ({ lists, listGroupName = "" }) => {
  const options = lists
    .sort((a, b) => -a.name.localeCompare(b.name))
    .map((l) => (
      <option key={l.key} value={l.key}>
        {l.name}
      </option>
    ));

  return listGroupName ? <optgroup label={listGroupName}>{options}</optgroup> : <>{options}</>;
};

export const ListSelector = ({
  lists,
  listKey,
  onChange,
  disabled = false,
  groupLists = false,
}) => {
  const changeHandler = useCallback((e) => onChange(e.currentTarget.value), [onChange]);
  let options;
  if (groupLists) {
    const plans = lists.filter((l) => l.type === "PLAN"),
      regulatory = lists.filter((l) => l.type === "REGULATORY"),
      custom = lists.filter((l) => l.type === "CUSTOM"),
      lease = lists.filter((l) => l.type === "LEASE");
    options = (
      <>
        <option key="emptyOption" value="">
          {"---------"}
        </option>
        {custom.length && (
          <ListSelectorOptions lists={custom} listGroupName={i18n.t("Custom lists")} />
        )}
        {lease.length && <ListSelectorOptions lists={lease} listGroupName={i18n.t("Lease list")} />}
        {plans.length && <ListSelectorOptions lists={plans} listGroupName={i18n.t("Plans")} />}
        {regulatory.length && (
          <ListSelectorOptions lists={regulatory} listGroupName={i18n.t("Regulatory")} />
        )}
      </>
    );
  } else {
    options = <ListSelectorOptions lists={lists} />;
  }
  return (
    <Form.Control
      as="select"
      id="plan-select"
      className="plan-select custom-select my-2"
      value={listKey}
      onChange={changeHandler}
      disabled={disabled}
    >
      {options}
    </Form.Control>
  );
};
