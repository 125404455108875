import i18n from "i18next";

import React from "react";
import { hot } from "react-hot-loader";
import { Redirect, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { Header } from "./topbar/Header";
import { MainPanel as Panel, NavAside, Page, PrintReportView } from "./components";
import { Facet } from "./facet/Facet";
import { GlobalModals } from "./modals";
import { Helmet } from "react-helmet";
import BBMap from "./map";
import "./modals/Modals.scss";

const BBApp = () => (
  <>
    <Helmet>
      <title>{i18n.t("CropFields")}</title>
    </Helmet>
    <Header />
    <Route path="/" component={BBMap} />
    <NavAside />
    <Route exact path="/pacht">
      <Redirect to="/page/lease" />
    </Route>
    <Route path="/page/:name" component={Page} />
    <Route path="/filter" component={Facet} />
    <Route path="/" component={Panel} />
    <Route exact path="/print_report/" component={PrintReportView} />
    <NotificationContainer />
    <GlobalModals />
  </>
);
const m = hot(module)(BBApp);
export { m as BBApp };
