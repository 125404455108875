/* eslint-disable camelcase */
import { LOCATION_CHANGE } from "connected-react-router";
import { config, Country } from "./Config";
import L, { LatLng } from "leaflet";
import { FarmType } from "./types";
import { LatLngTuple } from "leaflet";

export interface BBWindow extends Window {
  refhdrs: any;
  sathdrs: any;
}
declare let window: BBWindow;

// Selected Field
interface SelectionField {
  lease?: {
    myRewards: {
      long: boolean;
      other: boolean;
      discount: boolean;
      otherDesc: string;
      discountDesc: string;
    };
    myConditions: {
      chemical: boolean;
      nat: boolean;
      obi: boolean;
      skal: boolean;
      fertilizer: boolean;
      other: boolean;
      otherDesc: string;
    };
  };
  bunder?: any;
  country?: Country;
  feature: any; // geojson feature
  cadastre?: any;
  state: "fetched" | string;
  crops: any[]; // ?
  zonew?: any;
  zonea?: any;
  zone1?: any;
  zone2?: any;
  gwp?: any;
  water_trap?: any;
  permanent_grass?: any;
  erosion?: any;
  natura_2000?: any;
  natura_2000_buffer?: any;
  potato_disease?: any;
  forbidden_irrigation?: any;
  gps?: any;
}
export interface MultiSelectField {
  feature: any; // geojson feature
  point: string;
  gps?: [number, number];
}
export interface EwsMultiProps {
  data?: Record<
    string,
    Record<string, Record<string, { location: string; infection_chances: any[] }[]>>
  >;
  state?: string;
}

interface SelectionState {
  field?: SelectionField;
  bunder?: any;
  multiSelect?: boolean;
  gps?: [number, number];
  selectedFields?: MultiSelectField[];
  growth?: any;
  water?: any;
  ews?: any;
  ewsMulti?: EwsMultiProps;
  dem?: any;
  shadow?: any;
  weather?: any;
  year?: number;
  section?: string;
  history?: any; // ?
  farm?: FarmType;
  farmFields?: any; //?
}
interface SelectionAction {
  type:
    | "FETCH_FIELD"
    | "SELECT_FARM"
    | "FETCHED_FIELD"
    | "TOGGLE_MULTI_SELECT"
    | "FETCH_GROWTH"
    | "SELECT_GROWTH"
    | "FETCH_SATELLITE_IMG_KEY"
    | "FETCH_WATER_DATA"
    | "UPDATE_WATER"
    | "UPDATE_CADASTRE"
    | "FETCH_HEIGHT_STATISTICS"
    | "FETCH_SHADOW_STATISTICS"
    | "FETCH_EWS"
    | "FETCH_EWS_MULTI"
    | "FETCH_WEATHER"
    | "SELECT_YEAR"
    | "SET_SECTION"
    | "DESELECT"
    | "QUICK_SEARCH_RESULTS_UPDATE"
    | "UPDATE_LEASE"
    | typeof LOCATION_CHANGE; // comes from router, maybe merge this type?
  multiSelect?: boolean;
  year?: number;
  section?: string;
  payload?: any; // comes from router, maybe merge this type?
  data?: any;
}

// This whole piece of the state is confusing to me, and probably could use some restructing.
// It seems to have quite a few distinct responsibilities, which could be split up into their own reducer.
// The state can currently contain any of the following, sometimes combined:
//     - the selected farm, with its fields
//     - the selected field
//     - various features of the selected field
//     - some remainder of the multiselect beta
//     - gps, which could mean anything really
//     - any arbitrtary key:value, since the actions don't respect the typings.
// Most of these things are related to whatever is selected, but are not actually things selected on the map.
const selection = (state: SelectionState = {}, action: SelectionAction): SelectionState => {
  const { type, ...props } = action;
  switch (type) {
    case "SELECT_FARM":
      return { ...props };
    case "FETCH_FIELD":
      return { farmFields: state.farmFields, ...props };
    case "FETCHED_FIELD":
      return { ...state, ...props };
    case "TOGGLE_MULTI_SELECT":
      const selectedFields = []; // [{point:[5,53], feature: {}}]
      if (props.multiSelect && state.field?.feature) {
        selectedFields.push({
          point: state.gps.join(","),
          gps: state.gps,
          feature: state.field.feature,
        });
      }
      return { ...state, selectedFields };
    case "FETCH_GROWTH":
      return { ...state, growth: { ...props } };
    case "SELECT_GROWTH":
    case "FETCH_SATELLITE_IMG_KEY":
      return { ...state, growth: { ...state.growth, ...props } };
    case "FETCH_WATER_DATA":
    case "UPDATE_WATER":
      return { ...state, water: { ...state.water, ...props } };
    case "UPDATE_CADASTRE":
      return {
        ...state,
        field: { ...state.field, cadastre: { ...state.field.cadastre, ...props } },
      };
    case "UPDATE_LEASE":
      return {
        ...state,
        field: {
          ...state.field,
          lease: {
            ...state.field.lease,
            myConditions: props.payload.myConditions,
            myRewards: props.payload.myRewards,
          },
        },
      };
    case "FETCH_HEIGHT_STATISTICS":
      return { ...state, dem: { ...props } };
    case "FETCH_SHADOW_STATISTICS":
      return { ...state, shadow: { ...props } };
    case "FETCH_EWS":
      return { ...state, ews: { ...props } };
    case "FETCH_EWS_MULTI":
      return { ...state, ewsMulti: { ...props } };
    case "FETCH_WEATHER":
      return { ...state, weather: { ...props } };
    case "SELECT_YEAR":
      return { ...state, year: props.year };
    case "SET_SECTION":
      return { ...state, section: props.section };
    case "DESELECT":
    case "QUICK_SEARCH_RESULTS_UPDATE": // deselect
      return {};
    case LOCATION_CHANGE: // not sure if this is the way
      if (action.payload.location.pathname === "/") return {};
      return state;
    default:
      return state;
  }
};

// Facet-search results
const filterResults = (state = null, action): any => {
  switch (action.type) {
    case "FILTER_RESULTS_UPDATE":
      return action.results;
    default:
      return state;
  }
};

// Facet search parameters
const searchFilter = (state = null, action): any => {
  switch (action.type) {
    case "SEARCH_FILTER_UPDATE":
      return action.filter || "";
    default:
      return state;
  }
};

// Quicksearch Search results (for showing on map)
type QuickSearchResultsType = null | LatLng; // or L.latlng
const quickSearchResults = (
  state: QuickSearchResultsType = null,
  action
): QuickSearchResultsType => {
  switch (action.type) {
    case "QUICK_SEARCH_RESULTS_UPDATE":
      return action.marker;
    case "FETCH_FIELD": // cancels search results. TODO maybe CHANGE_ROUTE?
      return null;
    default:
      return state;
  }
};

// Selected Layers on the map
interface LayerState {
  selected: string[];
  config: any;
}
const layers = (
  state: LayerState = { selected: config.__ ? ["parcel"] : [], config: {} },
  action
): LayerState => {
  const { type, layer, config } = action;
  switch (type) {
    case "LAYER_TOGGLE":
      return { ...state, selected: state.selected.includes(layer) ? [] : [layer] };
    case "LAYER_UPDATE":
      return { ...state, config };
    default:
      return state;
  }
};

// Current ViewPort
interface InViewState {
  background: string;
  bounds?: number[];
  center?: L.LatLng;
  cropfields?: any[];
}
const inView = (state = { background: "OpenStreetMap" }, action): InViewState => {
  // background, center, zoom, cropfields, layer_config: {layer->config}
  switch (action.type) {
    case "SET_BACKGROUND":
      return { ...state, background: action.layer };
    case "VIEW_UPDATE":
      return { ...action.data, background: state.background };
    default:
      return state;
  }
};

type FarmState = string | Record<number, FarmType>;
const farms = (state: FarmState = "", action): FarmState => {
  switch (action.type) {
    case "FETCH_FARMS":
      if (!action.farms) return "fetching";
      let farms = {};
      action.farms.forEach((f) => {
        farms[f.id] = f;
      });
      return farms;
    default:
      return state;
  }
};

interface CurrentLocationType {
  latlng?: LatLngTuple;
}
const currentLocation = (state: CurrentLocationType = {}, action): CurrentLocationType => {
  switch (action.type) {
    case "SET_LOCATION":
      return action.location;
    default:
      return state;
  }
};
const multiSelect = (state: boolean = false, action): boolean => {
  switch (action.type) {
    case "TOGGLE_MULTI_SELECT":
      return action.multiSelect;
    case "SET_MULTI_SELECT":
      return action.multiSelect;
    default:
      return state;
  }
};

const followGps = (state: boolean = false, action): boolean => {
  switch (action.type) {
    case "FOLLOW_GPS":
      return action.followGps;
    case "QUICK_SEARCH_RESULTS_UPDATE":
    case "FILTER_RESULTS_UPDATE":
    case "SELECT_FARM":
    case "FETCH_FIELD":
      return false;
    default:
      return state;
  }
};

interface ActiveUserState {
  tokens: any;
  account?: any;
  refreshing?: boolean;
  settings?: any;
  state?: string;
  user?: any;
  rvo?: any;
}
const activeUser = (
  state: ActiveUserState = { tokens: { reference: window.refhdrs, sat: window.sathdrs } },
  action
): ActiveUserState => {
  const { type, refreshing, ...data } = action;
  switch (type) {
    case "UPDATE_TOKENS":
      return { ...state, tokens: data.tokens };
    case "GET_USER":
      return { ...data, tokens: { reference: window.refhdrs, sat: window.sathdrs } };
    case "REFRESH_ACCOUNT": // account: {account: {farm, farm_id, ...}, lists:[], fields:[], plans...}
      if (refreshing !== undefined) return { ...state, refreshing: refreshing };
      return { ...state, refreshing: false, account: { ...state.account, ...data.account } };
    case "UPDATE_SETTINGS":
      return { ...state, settings: { ...state.settings, ...data.settings } };
    case "GET_RVO_ACCOUNT":
      return { ...state, rvo: { ...state.rvo, account: data } };
    case "REFRESH_RVO":
      return { ...state, rvo: { ...state.rvo, refreshing: refreshing } };
    case "UPDATE_FARM_LEASE_DATA":
      // account?.farm?.lease_data
      const account = state.account;
      return { ...state, account: { ...account, farm: { ...account.farm, lease_data: data } } };
    case "EXPORTS_DECREASE":
      const a = state.account;
      if (a?.exports)
        return {
          ...state,
          account: {
            ...a,
            exports: { ...a.exports, count: a.exports.count + data.decreaseCount },
          },
        };
      return state;
    default:
      return state;
  }
};

interface NewMapState {
  center?: [number, number];
  zoom?: number;
  bounds?: [number, number, number, number];
}
const newMapState = (state = null, action): NewMapState | null => {
  switch (action.type) {
    case "MAP_UPDATE":
      return action.state;
    default:
      if (!state && config.center) {
        state = { center: config.center, zoom: config.__ ? 6 : 15 };
      }
      return state;
  }
};

const favorites = (state = {}, action) => {
  const { type, ...data } = action;
  switch (type) {
    case "UPDATE_FAVORITES":
      return { ...state, ...data };
    case "SELECT_LIST":
      return { ...state, selectedList: data.selectedList };
    case "SHARED_LIST":
      return { ...state, ...data };
    case "SHARED_FIELD":
      return { ...state, ...data };
    case "SET_EDITED_LIST":
      return { ...state, editingList: data.id };
    default:
      return state;
  }
};

const productLinks = (state = {}, action) => {
  switch (action.type) {
    case "GET_PRODUCT_LINKS":
      const { type, ...likes } = action;
      return { ...state, ...likes };
    default:
      return state;
  }
};

const modal = (state = {}, action): any => {
  const { type, ...data } = action;
  switch (type) {
    case "HIDE_MODAL":
      return {};
    case "SHOW_MODAL":
      return { ...data, show: true };
    default:
      return state;
  }
};

const ewsConfig = (state = {}, action): any => {
  const { type, ...data } = action;
  switch (type) {
    case "GET_EWS_CONFIG":
      return { ...data };
    case "UPDATE_EWS_CONFIG":
      return { ...state, ...data };
    default:
      return state;
  }
};

const highlight = (state = { id: null }, action): any => {
  switch (action.type) {
    case "SET_HIGHLIGHT":
      return { ...state, id: action.id };
    case "REMOVE_HIGHLIGHT":
      return { ...state, id: null };
    default:
      return state;
  }
};

const reducers = {
  selection,
  layers,
  newMapState,
  inView,
  farms,
  searchFilter, // todo combine searchFilter with filterResults?
  filterResults,
  quickSearchResults,
  currentLocation,
  followGps, // user.followGps
  multiSelect,
  activeUser,
  favorites, // user lists and fields
  modal, // todo display modal inside component?
  productLinks,
  ewsConfig,
  highlight,
};

/*
 selection: {type, **data}, // dem: null, projects: {a: '', b:''}, soil: null, // selection.dem == none displays loading, else data
 layer: null, // ("dem", "projects", "soil", "...") sluiten elkaar uit. Mag ook een lijst zijn voor maximale flexibitieit
 mapState: {zoom: null, center: null},
 inView: {},
 gpsData: {}
 */

export default reducers;
