export const ewsCropKeys = {
  CROP_POTATO: "Potato",
  CROP_APPLE: "Apple",
  CROP_CARROT: "Carrot",
  CROP_LEEK: "Leek",
  CROP_LETTUCE: "Lettuce",
  "CROP_KAI-LAN": "Cabbage", // originele naam "Chinese broccoli" of "Kai lan"
  CROP_SUGARBEET: "Sugar beet",
  CROP_ONION: "Onion",
  CROP_BEAN: "Bean",
  CROP_CHICORY: "Chicory",
};

export const ewsSecondaryCropsMapping = {
  CROP_CARROT: "CROP_CARROT",
  "CROP_COS-LETTUCE": "CROP_LETTUCE",
  "CROP_GRAPE-GRAPE-VINE": "CROP_GRAPE-GRAPE-VINE",
  "CROP_WIJNSTOK-VITIS": "CROP_GRAPE-GRAPE-VINE",
  "CROP_ICEBERG-LETTUCE": "CROP_LETTUCE",
  "CROP_COMMON-GRAPE": "CROP_GRAPE-GRAPE-VINE",
  "CROP_KIDNEY-BEAN": "CROP_BEAN",
  "CROP_FIELD-BEAN": "CROP_BEAN",
  "CROP_LEAF-LETTUCE": "CROP_LETTUCE",
  "CROP_FRENCH-BEAN": "CROP_BEAN",
  "CROP_GREEN-CAULIFLOWER": "CROP_KAI-LAN",
  "CROP_WHITE-CAULIFLOWER": "CROP_KAI-LAN",
  CROP_CAULIFLOWER: "CROP_KAI-LAN",
  "CROP_KAI-LAN": "CROP_KAI-LAN",
  CROP_BROCCOLI: "CROP_BROCCOLI",
  "CROP_PURPLE-CAULIFLOWER": "CROP_KAI-LAN",
  "CROP_SWEDISH-TURNIP-RUTABAGA": "CROP_KAI-LAN",
  "CROP_CHINESE-CABBAGE": "CROP_KAI-LAN",
  CROP_TOMATO: "CROP_TOMATO",
  "CROP_BLUE-VIOLET-KOHLRABI": "CROP_KAI-LAN",
  CROP_KALE: "CROP_KAI-LAN",
  "CROP_WHITE-KALE": "CROP_KAI-LAN",
  "CROP_GREEN-KALE": "CROP_KAI-LAN",
  CROP_CORN: "CROP_CORN",
  "CROP_LAMBS-LETTUCE-CORN-SALAD": "CROP_LETTUCE",
  CROP_SPINACH: "CROP_SPINACH",
  CROP_SEAKALE: "CROP_KAI-LAN",
  CROP_APPLE: "CROP_APPLE",
  "CROP_MERROW-STEM-KALE": "CROP_KAI-LAN",
  CROP_WITLOOF: "CROP_WITLOOF",
  CROP_ONION: "CROP_ONION",
  CROP_LEEK: "CROP_LEEK",
  "CROP_BLACK-TURTLE-BEAN": "CROP_BEAN",
  CROP_POTATO: "CROP_POTATO",
  CROP_SUGARBEET: "CROP_SUGARBEET",
  "CROP_HARICOT-BEAN": "CROP_BEAN",
  "CROP_PINTO-BEAN": "CROP_BEAN",
  "CROP_BROWN-BEAN": "CROP_BEAN",
  CROP_BLEEKSELDERIJ: "CROP_CELERY",
  "CROP_COW-CABBAGE": "CROP_KAI-LAN",
  "CROP_HEAD-LETTUCE": "CROP_LETTUCE",
  "CROP_CURLY-LETTUCE-LEAVED-LOOSE": "CROP_LETTUCE",
  "CROP_CUTTING-KALE": "CROP_KAI-LAN",
  "CROP_CURLY-KALE": "CROP_KAI-LAN",
  CROP_ASPARAGUS: "CROP_ASPARAGUS",
  CROP_SHALLOT: "CROP_ONION",
  "CROP_WINTER-WHEAT": "CROP_WINTER-WHEAT",
  "CROP_YELLOW-SAVOY-CABBAGE": "CROP_KAI-LAN",
  "CROP_HEAD-CABBAGE": "CROP_KAI-LAN",
  "CROP_RED-CABBAGE": "CROP_KAI-LAN",
  "CROP_ORNAMENTAL-CABBAGE": "CROP_KAI-LAN",
  "CROP_GREEN-SAVOY-CABBAGE": "CROP_KAI-LAN",
  "CROP_OXHEART-CABBAGE": "CROP_KAI-LAN",
  "CROP_WHITE-CABBAGE": "CROP_KAI-LAN",
  "CROP_BRUSSELS-SPROUTS": "CROP_KAI-LAN",
  CROP_TULIP: "CROP_TULIP",
  "CROP_SWEET-CORN": "CROP_CORN",
  CROP_LETTUCE: "CROP_LETTUCE",
  "CROP_GARDEN-PEA": "CROP_GARDEN-PEA",
  "CROP_ROMAINE-LETTUCE": "CROP_LETTUCE",
  "CROP_OAKLEAF-LETTUCE": "CROP_LETTUCE",
  "CROP_DRILLED-ONION": "CROP_ONION",
  "CROP_SECOND-YEAR-ONION-SET": "CROP_ONION",
  "CROP_FIRST-YEAR-ONION-SET": "CROP_ONION",
  "CROP_SPRING-ONION": "CROP_ONION",
  "CROP_PICKLER-ONION": "CROP_ONION",
  CROP_CHICORY: "CROP_CHICORY",
  "CROP_CONSUMPTION-POTATO": "CROP_POTATO",
  "CROP_SEED-POTATO": "CROP_POTATO",
  "CROP_STARCH-POTATO": "CROP_POTATO",
  CROP_BEAN: "CROP_BEAN",
  "CROP_BUTTER-BEAN": "CROP_BEAN",
  "CROP_YELLOW-BEAN": "CROP_BEAN",
  "CROP_YARDLONG-BEAN-ASPARAGOUS-BE": "CROP_BEAN",
  "CROP_LIMA-BEAN": "CROP_BEAN",
  "CROP_SCARLET-RUNNER": "CROP_BEAN",
  "CROP_BUSH-BEAN": "CROP_BEAN",
  "CROP_SNAP-BEAN": "CROP_BEAN",
  "CROP_STRING-BEAN": "CROP_BEAN",
  "CROP_CLIMBING-FRENCH-BEAN": "CROP_BEAN",
  "CROP_CLIMBING-HARICOT": "CROP_BEAN",
  "CROP_BROAD-BEAN": "CROP_BEAN",
  "CROP_GRAIN-CORN": "CROP_CORN",
  "CROP_SILAGE-CORN": "CROP_CORN",
};
