import { FarmType } from "../../types";
import { followOnSocialMedia, useSSelector } from "../../util";
import i18n from "i18next";
import { config } from "../../Config";
import { MissingAddress } from "./PublicFarm";
import { RefreshButton } from "../../components";
import React from "react";
import { Link } from "react-router-dom";

interface MyFarmProps {
  id: number;
  farm: FarmType;
  own?: boolean;
}

export const MyFarmOverview = (props: MyFarmProps) => {
  const { id, farm, own = false } = props;
  const farmId = useSSelector((s) => s.activeUser.account?.farm?.external_id);
  const planId = useSSelector((s) => s.activeUser.account?.current_plan_id);

  if (!id) return null;
  return (
    <div className="bb_farm media">
      <div className="media-body p-3 p-md-0">
        {!own && (
          <p>
            {i18n.t("Farm")} {farm.name}
          </p>
        )}
        <div className="farm-info">
          <h5 className="info-type mt-3">
            {i18n.t("About the farm")}{" "}
            {own && (
              <Link className="link" to="/user">
                <i className="icn_user" />
              </Link>
            )}
          </h5>
          <dl className="info">
            {own && (
              <a href={`${config.DACOM_URL}/cropr/farm/plan/`} className="d-none d-md-block">
                {i18n.t("Add your own plots")}
              </a>
            )}
          </dl>
          <h5 className="info-type mt-3">{i18n.t("Address")}</h5>
          <div className="info">
            {farm.street || (own && <MissingAddress />)}
            <br />
            {farm.zipcode} {farm.city}
          </div>
          {farm.website && (
            <>
              <h5 className="info-type mt-3">{i18n.t("Website")}</h5>
              <div className="info">{farm.website}</div>
            </>
          )}
          {followOnSocialMedia(farm)}
        </div>
        <div className="row d-none d-sm-block">
          <div className="col-12">
            <br />
            <a
              href={`${config.DACOM_URL}/cropr/settings/farm/?next=${
                window.location
              }&message=${i18n.t("Back to CropFields")}`}
              className="btn btn-primary d-none d-md-inline-block mr-3"
            >
              <i className="fa fa-pencil mr-2" />
              {i18n.t("Edit my profile")}
            </a>
            {farmId && planId && <RefreshButton farmExternalId={farmId} planExternalId={planId} />}
          </div>
        </div>
      </div>
    </div>
  );
};
