import React, { memo, useCallback } from "react";
import i18n from "i18next";
import cx from "classnames";

import { accountFeatures, useSSelector } from "../util";
import { config } from "../Config";
import { Nav, Navbar, NavDropdown, NavItem, NavLink, Container } from "react-bootstrap";
import { RNavDropdownItem, RNavItem } from "../../util/router-bootstrap";
import { useDispatch } from "react-redux";
import { checkLoggedInUser, refreshAccount } from "../actions";
import { FIcon } from "./FIcon";
import { FarmSwitch } from "./FarmSwitch";
import { CountrySelect, ImageCountry } from "./CountrySelect";
import { ApplicationSwitch } from "./ApplicationSwitch";
import { TrialButton, freeTrialClick, showSubscriptionModal } from "../components";
import "./Header.scss";

const FreeTrialButton = ({ show }) => {
  const dispatch = useDispatch();
  if (!show) return null;
  return (
    <NavItem as="li">
      <span className="d-none d-lg-inline-block">
        <button
          className="btn btn-sm btn-success"
          onClick={() => {
            freeTrialClick();
            showSubscriptionModal(dispatch);
          }}
        >
          {i18n.t("Free trial")}
        </button>
      </span>
    </NavItem>
  );
};

const LoggedInBar = ({ activeUser }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    (farm) => dispatch(refreshAccount(farm.external_id, null)),
    [dispatch]
  );
  const account = activeUser.account || {},
    { user, farm, farms } = account,
    features = accountFeatures(account);

  const avatar = user?.avatar_url || farm?.avatar_url,
    title = avatar ? (
      <>
        <img alt={i18n.t("Profile")} width="40" height="40" src={avatar} />
        <span className={"caret"} />
      </>
    ) : (
      i18n.t("Profile")
    );
  return (
    <>
      <FarmSwitch farm={farm} farms={farms} click={onClick} refreshing={activeUser.refreshing} />
      <RNavItem eventKey={5.1} className="d-block d-md-none" href="/user">
        {i18n.t("Profile")}
      </RNavItem>
      <RNavItem eventKey={5.2} className="d-block d-md-none" href="/settings">
        {i18n.t("Settings")}
      </RNavItem>
      <RNavItem eventKey={5.3} className="d-block d-md-none bb_header_contact" href="/page/contact">
        {i18n.t("Contact")}
      </RNavItem>
      <NavItem className="d-block d-md-none" as="li">
        <Nav.Link href="/logout_user/">{i18n.t("Log out")}</Nav.Link>
      </NavItem>
      <NavDropdown
        className={`d-none d-md-block dropdown-menu-left ${avatar ? " avatar" : ""}`}
        title={title}
        id="header_nav_dropdown"
        as="li"
      >
        <ul style={{ display: "contents" }}>
          <RNavDropdownItem eventKey={5.1} href="/user">
            <FIcon name="user" /> {user.first_name} {user.last_name}
          </RNavDropdownItem>
          <RNavDropdownItem className="bb_header_contact" eventKey={5.3} href="/page/contact">
            <FIcon name="horn" /> {i18n.t("Contact")}
          </RNavDropdownItem>
          <NavDropdown.Item className={features.pro && "d-none"} eventKey={5.4} as="li">
            <Nav.Link href={config.bb_orderPage}>
              <FIcon name="add" /> {i18n.t("Subscribe")}
            </Nav.Link>
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey={5.5} as="li">
            <Nav.Link href="/logout_user/">
              <FIcon name="logout" /> {i18n.t("Log out")}
            </Nav.Link>
          </NavDropdown.Item>
        </ul>
      </NavDropdown>
    </>
  );
};

export const Header = memo(({ className }: { className?: string }) => {
  const dispatch = useDispatch();
  const activeUser = useSSelector((s) => s.activeUser);
  const user = activeUser.account?.user;
  const trialEndDate = activeUser?.account?.trialEndDate;

  if (!activeUser.state) {
    dispatch(checkLoggedInUser());
  }

  const showSubscription = !!(!config.bb_site.beta && config.MAX_FREE_CLICKS);
  const loggedIn = !!user?.username;
  const loggedOut = activeUser.state === "loggedOut";
  let aboutItems = [
    { url: "/page/about", title: i18n.t("About CropFields"), key: "about" },
    { url: "/page/data", title: i18n.t("Data"), key: "data" },
    { url: "/page/api", title: i18n.t("API"), key: "api" },
    { url: "/page/legal", title: i18n.t("Terms"), key: "legal" },
  ];
  if (config.show.includes("lease")) {
    aboutItems.push({ url: "/page/lease", title: i18n.t("Lease"), key: "lease" });
  }

  return (
    <Navbar className={cx("mod_bb_header", className)} expand="md">
      <Container>
        <ApplicationSwitch />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav as={"ul"} className="nav navbar-nav navigation-list flex-grow-1">
            <NavDropdown
              id="about_dropdown"
              className="about_select"
              title={
                <span>
                  <span className="d-sm-inline-block">{i18n.t("About")} </span>
                  <span className={"caret"} />
                </span>
              }
              as={"li"}
            >
              <ul style={{ display: "contents" }}>
                {aboutItems.map((item, i) => (
                  <NavDropdown.Item key={item.key} eventKey={i} as={"li"}>
                    <Nav.Link eventKey={i} href={item.url}>
                      {item.title}
                    </Nav.Link>
                  </NavDropdown.Item>
                ))}
              </ul>
            </NavDropdown>

            <RNavItem eventKey={5.4} href="/page/subscription" show={showSubscription && !loggedIn}>
              {i18n.t("Subscription")}
            </RNavItem>
          </Nav>
          <Nav as={"ul"} className="nav navbar-nav navigation-list navbar-right">
            <FreeTrialButton show={loggedOut && config.bb_site.free_trial} />
            {trialEndDate && (
              <TrialButton
                trialEndDate={trialEndDate}
                className="d-none btn-sm d-md-inline-block"
              />
            )}
            <CountrySelect />
            {loggedIn ? (
              <LoggedInBar activeUser={activeUser} />
            ) : (
              <>
                <RNavItem className="bb_header_contact" eventKey={5.3} href="/page/contact">
                  <FIcon className="d-none d-sm-inline-block" name="horn" /> {i18n.t("Contact")}
                </RNavItem>
                <NavItem className="bb_header_login" as="li">
                  <NavLink href="/authorize/">
                    <FIcon className="d-none d-sm-inline-block" name="farm" /> {i18n.t("Login")}
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
});
