import { push } from "connected-react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeListName,
  deleteList,
  selectList,
  selectMapFields,
  setEditingList,
} from "../../actions";
import { GenericPopper } from "../../components/GenericPopper";
import { Accordion } from "./Components/Accordion";
import { BunderListItem } from "./Components/BunderListItem";
import { Tag } from "./Tag";
import "./Dropdown.scss";
import { ShareModal } from "../../modals";
import i18n from "i18next";
import { DropdownItem } from "./Components/DropdownItem";
import { accountFeatures, useSSelector } from "../../util";
import { stopEditingList } from "../../actions";
import { useClick, useShareMobile } from "./hooks";
import ListPlusIcon from "../../../img/icons/icon_list_plus.svg";

interface Ilist {
  creator?: any;
  key?: string;
  point?: any;
  fields: number[];
  id?: string;
  name?: string;
  public?: boolean;
  type?: "CUSTOM" | "PLAN" | "LEASE";
  period_start?: string;
  // eslint-disable-next-line camelcase
  period_end?: string;
}

type Props = {
  list: Ilist;
  listKey: string;
  fields: any;
  ownList?: boolean;
};

type OptionMenuProps = {
  list: any;
};

export const OptionMenuList = ({ list }: OptionMenuProps) => {
  const isEditing = useSSelector((s) => s?.favorites?.editingList === list.id);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const downloadShapeFile = useClick(
    () => window.open(`/export/shape?key=${list.key}`),
    [list.key]
  );
  const shareMobile = useShareMobile();
  const deleteClick = useClick(() => {
    dispatch(deleteList(list.id));
    dispatch(push("/lists/"));
    setOpen(false);
  }, [dispatch, list.id]);

  const shareListClick = useClick((e) => setShowModal(true));

  const handleSetEditing = useClick(() => {
    dispatch(setEditingList(list.id));
    dispatch(push(`/lists/${list.key}`));
    setOpen(false);
  }, [dispatch, list.id, list.key]);

  const handleStopEditing = useClick(() => {
    dispatch(stopEditingList());
    setOpen(false);
  }, [dispatch]);

  if (isEditing) {
    return (
      <button className="btn" onClick={handleStopEditing}>
        {i18n.t("Stop editing")}
      </button>
    );
  }

  return (
    <>
      <button className="btn btn-plus" title={i18n.t("Edit list")} onClick={handleSetEditing}>
        <img width="24" height="24" src={ListPlusIcon} alt={i18n.t("Edit")} />
      </button>
      <div className="icon">
        <GenericPopper
          content={
            <ul className="c-dropdown__menu">
              <DropdownItem
                title={i18n.t("Share")}
                icon={"share-alt"}
                onClick={(e) => {
                  setOpen(false);
                  shareMobile(
                    e,
                    {
                      thisSubject: i18n.t("this field selection"),
                      subject: i18n.t("Field selection"),
                    },
                    `/lists/${list.key}`
                  ) || shareListClick(e);
                }}
              />
              <DropdownItem
                title={i18n.t("Download Shapefile")}
                icon={"download"}
                onClick={downloadShapeFile}
                onFeatureRequired={() => setOpen(false)}
                featureRequired={"premium"}
              />
              <DropdownItem
                title={i18n.t("Remove")}
                icon={"trash-alt"}
                danger
                onClick={deleteClick}
              />
            </ul>
          }
          open={open}
          placement="bottom"
          onClose={() => setOpen(false)}
        >
          <button
            className="btn btn-tertiary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(!open);
            }}
          >
            <i className="fas fa-ellipsis-v" />
          </button>
        </GenericPopper>
        {showModal && (
          <ShareModal
            text={{
              thisSubject: i18n.t("this field selection"),
              subject: i18n.t("Field selection"),
            }}
            url={`/lists/${list.key}`}
            onClose={(e) => {
              setShowModal(false);
              e?.stopPropagation();
              e?.preventDefault();
            }}
          />
        )}
      </div>
    </>
  );
};

export const ListAccordion = ({ list, listKey, fields, ownList = true }: Props) => {
  const [bunders, setBunders] = useState([]);
  const dispatch = useDispatch();
  const isPro = useSSelector((state) => accountFeatures(state.activeUser.account).pro);
  const returnUrl = list.key ? `/lists/${list.key}` : "";

  useMemo(() => {
    setBunders(fields?.filter((field) => list?.fields.includes(field.id)) || []);
  }, [fields, list.fields]);

  useEffect(() => {
    if (listKey === list.key) {
      dispatch(selectMapFields(bunders));
      // Keep the selected list up to date
    }
  }, [listKey, list.key, dispatch, bunders, list.id]);

  const onClick = useCallback(() => {
    dispatch(stopEditingList());

    if (!ownList) {
      dispatch(push(`/lists/${list.key}`));
      return;
    }
    if (listKey === list.key) {
      dispatch(push(`/lists/`));
      return;
    }
    dispatch(selectList({ selectedList: list.id }));
  }, [dispatch, list.id, list.key, listKey, ownList]);

  const changeName = useCallback(
    (name) => dispatch(changeListName(name, list.id)),
    [dispatch, list.id]
  );

  const numberFields = bunders.length;
  const totalArea = bunders.reduce((area, bunder) => area + bunder?.area, 0);
  const fixedArea = isPro ? totalArea.toFixed(2) : `~ ${totalArea.toFixed(0)}`;
  return (
    <Accordion
      title={list.name}
      open={list.key === listKey}
      onClick={onClick}
      updateName={changeName}
      optionMenu={ownList && <OptionMenuList list={list} />}
      subtitle={
        <>
          <Tag title={i18n.t("{{fields}} Fields", { fields: numberFields })} />{" "}
          <Tag title={`${fixedArea} ha`} />
        </>
      }
    >
      {list.key === listKey &&
        bunders.map((bunder) => (
          <BunderListItem
            key={bunder.id}
            listKey={list.key}
            bunder={bunder}
            returnUrl={returnUrl}
            listId={list.id}
          />
        ))}
    </Accordion>
  );
};
