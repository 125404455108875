import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSSelector } from "../../util";
import { CustomControl } from "../customControl/CustomControl";
import { useFollowGPS } from "./useFollowGPS";

type Props = {
  position: L.ControlPosition;
};

export const GPSControl = (props: Props) => {
  const { position } = props;
  const follow = useSSelector((state) => state.followGps);
  const dispatch = useDispatch();

  useFollowGPS();

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch({ type: "FOLLOW_GPS", followGps: !follow });
    },
    [dispatch, follow]
  );

  return (
    <CustomControl position={position}>
      <div className="leaflet-bar">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="leaflet-control-track-location" role="button" onClick={handleClick}>
          <i className={`fa fa-${follow ? "location-arrow" : "map-marker"}`} />
        </a>
      </div>
    </CustomControl>
  );
};
