import i18n from "i18next";
import React from "react";
import memoize from "lodash/memoize";
import { config } from "../Config";
import { useSSelector } from "../util";
import { ButtonBar } from "./header/ButtonBar";

export const getButtons = memoize(() =>
  [
    ["facts", i18n.t("Attributes")],
    ["soil", i18n.t("Soil type")],
    config.cadastre && ["property", i18n.t("Ownership")],
    ["growth", i18n.t("Growth")],
    ["height", i18n.t("Altitude")],
    !config.cadastre && ["nature", i18n.t("Nature")],
  ].filter((b) => b)
);

export const ReportButtons = ({ onClick, section, ...rest }) => {
  return (
    <ButtonBar buttons={getButtons()} onClick={onClick} active={section || "facts"} {...rest} />
  );
};
