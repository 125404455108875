import React, { memo, useState } from "react";
import { VerticalTodayLabel } from "../util";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
import { ReferenceLine } from "./util";
import moment from "moment";
import i18n from "i18next";
import { EWS_MAPPING } from "../../components/Legend";
import minBy from "lodash/minBy";
import { uniqueId } from "lodash/core";

interface EWSTooltipProps {
  active?: boolean;
  payload?: any;
  label?: number;
  now?: number;
}
const EWSTooltip = ({ active, payload, label, now }: EWSTooltipProps) => {
  if (!(active && payload[0])) return null;
  const p = payload[0];
  const name = minBy(EWS_MAPPING, (e) => Math.abs(e.pressure - p.value)).label;
  return (
    <div className="ews-tooltip">
      <div className="ews-tooltip-title">{moment.unix(label).format("DD-MM-YYYY")}</div>
      <table style={{ width: "200px" }}>
        <tbody>
          <tr>
            <td>{p.name}:</td>
            <td>
              {p.value}% ({name})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
interface EWSDiagramProps {
  height: number;
  data: any[];
}

const EWSDiagram = ({ height = 250, data }: EWSDiagramProps) => {
  const [_id] = useState(uniqueId("ewscolordef"));
  const now = moment().startOf("day").unix();
  return (
    <AreaChart
      width={400}
      height={height + 50}
      data={data}
      margin={{ top: 5, right: 40, left: 0, bottom: 5 }}
    >
      <XAxis
        dataKey="unix"
        tickFormatter={(unix) => moment.unix(unix).format("DD-MM-YYYY")}
        type="number"
        scale="time"
        domain={[data[0].unix, data[data.length - 1].unix]}
      />
      <YAxis domain={[0, 100]} />
      <ReferenceLine x={now} strokeWidth="40" label={<VerticalTodayLabel />} stroke="#ddd" />
      <defs>
        <linearGradient id={_id} x1="0" y1="0" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
          {EWS_MAPPING.map((e, i) => (
            <stop key={i} offset={`${100 - e.pressure}%`} stopColor={e.color} />
          ))}
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="value"
        stroke={`url(#${_id})`}
        fill={`url(#${_id})`}
        name={i18n.t("Infection chance")}
      />
      <Tooltip content={<EWSTooltip now={now} />} />
    </AreaChart>
  );
};
const mEWSDiagram = memo(EWSDiagram);
export { mEWSDiagram as EWSDiagram };
