import React, { memo, useCallback, useEffect } from "react";
import { SelectionButtons } from "./SelectionButtons";
import { SubscriptionFeature } from "../util";
import { Icon } from "react-fa";
import { SimpleMap } from "../../map";
import { ImageOverlay } from "react-leaflet";
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts";
import { config } from "../../Config";
import i18n from "i18next";
import { showSubscriptionModal } from "../../components";
import L from "leaflet";
import { useDispatch } from "react-redux";
import { track } from "../../track";
import { ToggleButton } from "./ToggleButton";
import { CreatePrescriptionMap } from "./CreatePrescriptionMap";
import { A, useSSelector } from "../../util";
import { updateHeightStatistics, updateShadowStatistics } from "../../actions";
import ahnImg from "../../../img/sample/sample_ahn4.png";
import { ButtonGroup } from "react-bootstrap";
import { Tooltip as PTooltip } from "../../panels/Tooltip";

type StatisticsComponent = ({ statistics: any }) => React.ReactElement;

const SimpleStatistics: StatisticsComponent = ({ statistics }) => (
  <div id="dem-summary">
    <p className="center">
      {i18n.t("min")}:&nbsp;{statistics.dem_min.toFixed(2)}, {i18n.t("avg")}:&nbsp;
      {statistics.dem_avg.toFixed(2)}, {i18n.t("max")}:&nbsp;{statistics.dem_max.toFixed(2)},{" "}
      {i18n.t("difference")}:&nbsp;{(statistics.dem_max - statistics.dem_min).toFixed(2)}&nbsp;
      {i18n.t("meter")}
    </p>
  </div>
);

interface HistogramProps {
  data: any;
  height: number;
  width: number;
}

const Histogram = ({ data, height = 300, width = 400 }: HistogramProps) => {
  const histogram = data && data.histogram;
  if (!data) return null;
  return (
    <BarChart width={width} height={height} data={histogram}>
      <XAxis dataKey="height" />
      <YAxis />
      <Tooltip
        labelFormatter={(e) => `${i18n.t("Altitude")}: ${e}m`}
        formatter={(value) => `${value}%`}
        contentStyle={{ backgroundColor: "rgba(255,255,255,.9)" }}
      />
      <Bar dataKey="percentage" name={i18n.t("Percentage")}>
        {histogram.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Bar>
    </BarChart>
  );
};

const DrainageButton = ({ show, drainage, shadow, version, data }) => {
  const dispatch = useDispatch();
  if (!show) return null;
  return (
    <ToggleButton
      active={drainage}
      tooltip={i18n.t("Show drainage lines")}
      onClick={() => {
        // TODO why is all this data repeated in action parameters? The reducer can compose this...
        // Answer: it requires a developer to understand Redux reducers, they're not only verbose repeaters of actions
        dispatch({
          type: "FETCH_SHADOW_STATISTICS",
          ...shadow,
          showShadow: false,
        });
        dispatch({
          type: "FETCH_HEIGHT_STATISTICS",
          state: "fetched",
          version,
          data,
          drainage: !drainage,
        });
        !drainage && track("report", "height", "drainage");
      }}
    >
      <span className="fa fa-tint"> </span>
    </ToggleButton>
  );
};

const GeoTiffButton = ({ url, show }) => {
  if (!(url && show)) return null;
  return (
    <PTooltip placement="right" description={i18n.t("Download Geotiff")}>
      <a href={`${url}${url.includes("?") ? "&" : "?"}type=tiff`} className="btn btn-default">
        <Icon name="download" />
      </a>
    </PTooltip>
  );
};

const ShadowButton = ({ show, shadow, dem, refresh }) => {
  const dispatch = useDispatch();
  if (!show) return null;
  const { shadowState, shadowData, showShadow } = shadow || {};
  return (
    <ToggleButton
      active={showShadow}
      tooltip={i18n.t("Show shadows")}
      onClick={() => {
        dispatch({
          type: "FETCH_HEIGHT_STATISTICS",
          ...dem,
          drainage: false,
        });
        if (!shadowData) {
          refresh();
        } else {
          dispatch({
            type: "FETCH_SHADOW_STATISTICS",
            shadowState,
            shadowData,
            showShadow: !showShadow,
          });
        }
        !showShadow && track("report", "height", "shadow");
      }}
    >
      <i className={shadowState === "fetching" ? "fa fa-circle-o-notch fa-spin" : "fa fa-tree"} />
    </ToggleButton>
  );
};

const Statistics = ({ state, statisticsComponent, statistics, version }) => {
  if (state === "fetched" && statistics)
    return React.createElement(statisticsComponent, { statistics: statistics });
  if (state === "fetching")
    return (
      <p>
        {i18n.t("Fetching data")} <Icon spin name="spinner" />
      </p>
    );
  if (version === "4" && config.bb_country === "NL")
    return (
      <div style={{ paddingBottom: "32px" }}>
        Er is geen beschikbare data op dit moment. De AHN-4 is slechts in een deel van Nederland
        beschikbaar. Zie onderstaande overzicht of kijk op <A href="https://www.ahn.nl/">ahn.nl</A>
        <br />
        <img className="media-object" src={ahnImg} alt="AHN-4 indeling in Nederland" />
      </div>
    );
  return (
    <p style={{ paddingBottom: "32px" }}>{i18n.t("No data available, please try again later")}</p>
  );
};

interface DEMDiagramProps {
  cropfield: any;
  smallScreen: boolean;
  height?: number;
  statisticsComponent?: StatisticsComponent;
  multiple?: boolean;
  dem?: any;
  shadow?: any;
  names?: string[];
  labels?: string[];
  histWidth?: number;
  inView: boolean;
  defaultVersion?: string;
  features: any;
}

const DEMDiagram = ({
  cropfield,
  smallScreen,
  height,
  statisticsComponent = SimpleStatistics,
  multiple,
  defaultVersion,
  dem,
  shadow,
  names = ["1", "2", "3"],
  labels,
  histWidth = smallScreen ? 360 : 400,
  inView,
  features,
}: DEMDiagramProps) => {
  const dispatch = useDispatch();
  const refresh = useCallback(
    (version) => {
      dispatch(updateHeightStatistics(cropfield, version));
    },
    [cropfield, dispatch]
  );
  const refreshShadow = useCallback(
    () => dispatch(updateShadowStatistics(cropfield)),
    [cropfield, dispatch]
  );
  const selectSelectionButton = useCallback(
    (version) => {
      if (multiple) {
        track("report", "height", version);
        refresh(version);
      } else {
        showSubscriptionModal(dispatch, undefined, "height");
      }
    },
    [multiple, dispatch, refresh]
  );

  const { state, data, version = "1", drainage = false } = dem || {},
    statistics = data?.statistics,
    imgKey = data?.img_key;
  const { shadowData, showShadow } = shadow || {},
    shadowImgKey = showShadow && shadowData?.img_key;
  const activeUserSettings = useSSelector(({ activeUser }) => activeUser.settings || {});

  useEffect(() => {
    if (!state && inView) return refresh(defaultVersion); // TODO or smallscreen?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!(state || inView)]);
  const extra = drainage ? "?type=drainage" : "";
  const url =
      (shadowImgKey || imgKey) &&
      `${config.LAYER_URL}/height/img/${shadowImgKey || imgKey}/${extra}`,
    bounds = L.geoJSON(cropfield).getBounds();
  return (
    <div id="dem-statistics" className={`state_${state}`}>
      {smallScreen && url ? (
        <SimpleMap feature={cropfield} height={height}>
          <ImageOverlay url={url} bounds={bounds} />
        </SimpleMap>
      ) : null}
      <Statistics
        state={state}
        statisticsComponent={statisticsComponent}
        statistics={statistics}
        version={version}
      />
      <Histogram data={data} height={height} width={histWidth} />
      <SelectionButtons
        names={names}
        active={version}
        disabled={!multiple}
        labels={labels}
        title={multiple ? "" : i18n.t("Subscribe to use all options")}
        select={selectSelectionButton}
      />
      <ButtonGroup size="sm" className="bb_toggleButton">
        <DrainageButton
          show={multiple && activeUserSettings?.showDrainageMap}
          drainage={drainage}
          shadow={shadow}
          version={version}
          data={data}
        />
        <ShadowButton
          show={multiple && activeUserSettings?.showShadowMap && config.shadow_map}
          shadow={shadow}
          dem={dem}
          refresh={refreshShadow}
        />
        <GeoTiffButton url={url} show={state === "fetched" && features.premium} />
      </ButtonGroup>
      <SubscriptionFeature
        show={!smallScreen && !multiple && names.length > 1}
        title={i18n.t("more years")}
        description={i18n.t("Subscribe to view all available height data sources")}
      />
      <CreatePrescriptionMap
        show={state === "fetched" && url && features.pro && config.bb_country === "NL"}
        video={"Taakkaart_hoogtekaart"}
      />
    </div>
  );
};
const mDEMDiagram = memo(DEMDiagram);
export { mDEMDiagram as DEMDiagram };
