import { useCallback, useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";
// import Control from "react-leaflet-control";
import { useSSelector } from "../../util";
import "leaflet/dist/leaflet.css";
import { useDispatch } from "react-redux";
import { updateView } from "../../actions";
// import MeasureControl from "../../leaflet/MeasureControl";

export const useZoom = () => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  const [zoomLevel, setZoomLevel] = useState(mapEvents.getZoom());

  return zoomLevel;
};

export const usePanToFoundLocation = () => {
  const searchLocation = useSSelector((state) => state.quickSearchResults);
  const map = useMap();

  useEffect(() => {
    if (searchLocation) {
      map.panTo([searchLocation.lat, searchLocation.lng]);
    }
  }, [map, searchLocation]);
};

export const useMapStateRedux = () => {
  const map = useMap();
  const dispatch = useDispatch();

  const updateMapState = useCallback(() => {
    const data = {
      center: map.getCenter(),
      zoom: map.getZoom(),
      bounds: map.getBounds().pad(0.1),
    };
    // This action is way too general. It basically just sets
    // arbitrary data in a redux state for the map. This  data is mainly
    // used for tracking i think, but for some reason it also contains the cropfields?
    // Since tracking is everywhere, lots of other components also indirectly depend on
    // this data being available, such as the filter.
    //
    // The redux state resulting from this action should only be used outside the map.
    // Within the map `useMap` must be used.
    dispatch(updateView(data));
  }, [map, dispatch]);

  useEffect(() => {
    updateMapState();
  }, [updateMapState]);

  useMapEvents({
    dragend: updateMapState,
    zoom: updateMapState,
  });

  return;
};
