import React from "react";

type Props = {
  k: any;
  v: any;
  small?: any;
};

export const KeyVal = (props: Props) => {
  const { k, v, small = undefined } = props;
  if (v) {
    if (small) {
      return (
        <>
          <dt>
            <span className="d-none d-sm-inline-block">{k}</span>
            <span className="d-sm-none">{small}</span>
          </dt>
          <dd>{v}</dd>
        </>
      );
    }
    return (
      <>
        <dt>{k}</dt>
        <dd>{v}</dd>
      </>
    );
  }
  return null;
};
