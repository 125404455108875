import React, { useCallback } from "react";
import { Switch } from "react-router";
import { push } from "connected-react-router";
import { Route, useLocation } from "react-router-dom";
import { PublicFarm, MyFarm, Profile, Settings, EWS } from "../panels";
import i18n from "i18next";
import { useDispatch } from "react-redux";
import "./MainPanel.scss";
import { Helmet } from "react-helmet";
import { getReportComponent } from "../report";
import { Selection, Report } from "../report";
import { NewBunderOverview } from "../panels/list/NewBunderOverview";

export function MainPanel() {
  const dispatch = useDispatch();
  const location = useLocation();
  const select = useCallback(
    (name = "") => {
      name = String(name).startsWith("show_") ? name.substring(5) : "";
      dispatch(push("/" + name));
    },
    [dispatch]
  );
  const path = location.pathname;
  if (path === "/search")
    return (
      <Helmet>
        <title>{i18n.t("Search")}</title>
      </Helmet>
    );
  const open = /^\/($|blog|page|search|filter|controls)/.test(path) ? "" : "is-open",
    component = getReportComponent();
  return (
    <aside id="mod_bb_main_panel" className={`mod_bb_main_panel bg-white ${open}`}>
      <Switch>
        <Route
          path="/report/:gps/:section?"
          render={({ match }) => (
            <Report
              select={select}
              gps={match.params.gps}
              section={match.params.section}
              Component={component}
            />
          )}
        />
        <Route
          path={[
            "/(lists)?/:listKey?/plot/:fieldKey?/:section?",
            "/(my_farm)?/(rvo)?/plot/:fieldKey?/:section?",
            "/(my_farm)?/(plans)?/:planKey?/plot/:fieldKey?/:section?",
            "/farm/:id/:name?/plot/:fieldKey?/:section?",
            "/plot/:fieldKey/:section?",
          ]}
          exact={true}
          render={({ match }) => {
            return (
              <Report
                select={select}
                gps={match.params.fieldKey}
                section={match.params.section}
                returnUrl={match.url.substr(0, match.url.indexOf("/plot/"))}
                Component={component}
              />
            );
          }}
        />
        <Route
          path="/select/:gps?"
          render={({ match }) => <Selection select={select} gps={match.params.gps} />}
        />
        <Route
          path="/farm/:id/:name?"
          render={({ match }) => <PublicFarm select={select} id={match.params.id} />}
        />
        <Route path="/user">
          <Profile select={select} />
        </Route>
        <Route
          path="/lists/:listKey?"
          exact={true}
          // render={({ match }) => <BunderOverview select={select} listKey={match.params.listKey} />}
          render={({ match }) => (
            <NewBunderOverview select={select} listKey={match.params.listKey} />
          )}
        />
        <Route path="/my_farm" exact={false}>
          <MyFarm select={select} />
        </Route>
        <Route path="/settings">
          <Settings select={select} />
        </Route>
        <Route path="/ews">
          <EWS select={select} />
        </Route>
      </Switch>
    </aside>
  );
}
